/***
 *
 * Controller class for user.
 * @file OneL2Vlan.js
 * @description OneL2Vlan component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./OneL2Vlan.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { breadcrumbActions } from "../../../redux/slices";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, CustomInput, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import createRequest, { services } from "../../../services";
import { CatchedWebError } from "../../../configs";
import ToolTip from "react-portal-tooltip";
import Select from 'react-select'
import { ReactComponent as WarningIcon } from "../../../assets/images/icons/Warning.svg"
import DummySwitch, { includeProperty } from "../_switches/DummySwitch";
import { checkConfig, commaSeparatedList } from "../L2Vlan";
import { make_custom_toast } from "../../../helpers/toasts";
import store from "../../../redux/store";
import { Toggle } from "../../../components";

const DEFAULT_VLAN = 1;
const DEFAULT_MODE = 'Access'

export const DEFAULT_VLAN_CONFIG = {
  "mode": DEFAULT_MODE,
  "untaggedVlan": DEFAULT_VLAN,
  // "taggedVlans": []
}
let timer;
const debounce = (callback) => {
  clearTimeout(timer);
  timer = setTimeout(callback, 800);
}

export function isInteger(value) {
  return /^[0-9]+$/.test(value);
}

export function isRange(value) {
  return /^[0-9]+-[0-9]+$/.test(value);
}

/** Converts ports syntax (1, 3-5, 6) to array [1, 3, 4, 5, 6] */
export const parsePortsSyntax = (portsString, config = {}) => {
  let showToActual = store?.getState()?.oneInfra?.showToActual
  let ports = new Set();
  let allowedPorts = new Set(Object.keys(config?.configuration?.portsConfiguration ?? {}).map(port => {
    return Number(port.replace('Ethernet', ''))
  }));

  let fragments = portsString.split(",");
  if (portsString == '') fragments = []
  for (let uncleanfragment of fragments) {
    let fragment = uncleanfragment.trim();
    if (isInteger(fragment)) {
      const num = Number(fragment);
      const actualPort = showToActual[num]
      if (allowedPorts.has(actualPort)) {
        ports.add(actualPort);
      }
      else return "Invalid port entered"
    } else if (isRange(fragment)) {
      const [start, end] = fragment.split("-").map(item => Number(item));
      if (start <= end && allowedPorts.has(showToActual[start]) && allowedPorts.has(showToActual[end])) {
        for (let i = start; i <= end; i++) {
          if (allowedPorts.has(showToActual[i])) ports.add(showToActual[i]);
        }
      } else {
        return "Not available on switch";
      }
    } else {
      return "Only comma separated numbers and ranges allowed";
    }
  }
  return ports;
};

const Port = ({ identifier, disabled, portConfig, portStatus, lldp = '-', onClick, hoverable = true }) => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const [warning, setWarning] = useState(false);
  const activeInfra = useSelector(store => store.oneInfra.data);
  const infraTypes = useSelector(store => store.infraTypes.data)

  useEffect(() => {
    const lanSpecs = infraTypes?.find(it => it.infraTypeId == activeInfra.infraTypeId)?.ports?.LAN?.find(port => port?.portNumber == identifier + 1);
    if (!!lanSpecs) {
      if (portConfig.speed < lanSpecs.Speed && portConfig?.duplex !== 'full')
        setWarning(true)
      else setWarning(false)
    }
    else setWarning(false)
  }, [portConfig, infraTypes, activeInfra, identifier])


  return (
    <>
      <span
        style={{
          minWidth: "34px", padding: "4px", marginTop: "12px", marginRight: "5px",
          marginLeft: "5px", cursor: ((!portConfig?.enabled || disabled) ? "not-allowed" : "pointer")
        }}
        className={'d-inline-block text-center rounded shadow-sm port ' +
          ((!portConfig?.enabled || disabled)
            ? ' disabled '
            : warning
              ? ' warning bg-light-danger '
              : (' status--' +
                (portStatus == 'Connected'
                  ? 'connected bg-gradient-success '
                  : 'disconnected bg-gradient-secondary ')))
          + (' type--' +
            (portConfig?.vlan?.taggedVlans?.includes(identifier)
              ? 'trunk '
              : portConfig?.vlan?.untaggedVlan == identifier
                ? 'access '
                : 'none '))}
        onClick={() => { setIsTooltipActive(false); onClick(identifier) }}
        id={`port${identifier}`}
        onMouseEnter={() => setIsTooltipActive(true)}
        onMouseLeave={() => setIsTooltipActive(false)}
      >
        <small><strong>{identifier + 1}</strong></small>
      </span>
      {hoverable ?
        <ToolTip className='p-2' active={isTooltipActive} parent={`#port${identifier}`} position="bottom" arrow="center">
          <Container style={{ minWidth: '300px' }} className="p-1">
            <Row style={{ fontWeight: 600 }} className="pb-50">Ethernet {identifier + 1}</Row>
            <Row className="pb-50">
              <Col className="mr-1">
                <Row className="d-flex justify-content-between">
                  <span>Connected</span>
                  <span style={{ fontWeight: 600 }}>{portStatus === 'Connected' ? 'Yes' : 'No'}</span>
                </Row>
                <Row className="d-flex justify-content-between">
                  <span>Native VLAN</span>
                  <span style={{ fontWeight: 600 }}>{portConfig?.vlan?.untaggedVlan ?? '-'}</span>
                </Row>
              </Col>
              <Col>
                <Row className="d-flex justify-content-between">
                  <span>Speed</span>
                  <span style={{ fontWeight: 600 }}>{portConfig?.speed ?? '-'}</span>
                </Row>
                <Row className="d-flex justify-content-between">
                  <span>Duplex</span>
                  <span style={{ fontWeight: 600 }} className="text-capitalize">{portConfig?.duplex ?? '-'}</span>
                </Row>
              </Col>
            </Row>
            <Row>Trunk VLANs</Row>
            <Row>{portConfig?.vlan?.taggedVlans?.length > 0 ? portConfig?.vlan?.taggedVlans : '-'}</Row>
            <Row>LLDP</Row>
            <Row>{lldp}</Row>
          </Container>
        </ToolTip> : null}
    </>
  );
};

const Legend = ({ bgColor, name, borderColor }) => {
  return (
    <div className="d-flex align-items-center mx-50">
      <div className="legend-rectangle mr-50" style={{ background: bgColor ?? '#fff', border: (!!borderColor ? ('2px solid ' + borderColor) : '') }}></div>
      <span>{name}</span>
    </div>
  );
}

// const SwitchDisplay = ({vlanId, vlanIdError, setPortModal, config, stats}) => {
//   let portStrings = Object.keys(config?.configuration?.portsConfiguration??{})
//   let row0 = []
//   let row1 = []
//   let row = 0
//   for(let i=0;i<=portStrings.length-1;i++) {
//     const k = Number(portStrings[i].replace('Ethernet',''));
//     let component = <Port 
//     key={k}
//     identifier={k}
//     disabled={vlanId == null || !!vlanIdError}
//     portConfig={config?.configuration?.portsConfiguration[`Ethernet${k}`]??{}}
//     portStats={stats?.EthernetStatus[`Ethernet${k}`]}
//     lldp={stats?.devices[`Ethernet${k}`]?stats?.devices[`Ethernet${k}`][0]?.lldp:"-"}
//     onClick={(id) => {
//       if(vlanId != null && !vlanIdError && (config?.configuration?.portsConfiguration[`Ethernet${k}`]??{}).enabled) {
//         setPortModal(id)
//       }
//     }} 
//   />
//     if(row == 0) {
//       row = 1
//       row0.push(component)
//     }
//     else {
//       row = 0
//       row1.push(component)
//     }
//   }
//   return (
//     <Container className="border rounded">
//       <div className="mb-1 port">
//       <Row className="justify-content-center">
//         {row0}
//       </Row>
//       <Row className="justify-content-center">
//         {row1}
//       </Row>
//       </div>
//       <div className="d-flex align-items-center justify-content-center">
//         <Legend bgColor="#1FC78F" name="Connected" />
//         <Legend bgColor="#808080" name="Disconnected" />
//         <Legend bgColor="#000" name="Disabled" />
//         <Legend bgColor="#FF7940" name="Warning" />
//         <Legend borderColor="#7A0303" name="Trunk"/>
//         <Legend borderColor="#7367F0" name="Access"/>
//       </div>
//     </Container>
//   );
// }

const OneL2Vlan = (props) => {
  const { vlanId: vlanIdFromParams } = useParams();
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const infraId = useSelector(store => store.oneInfra.data.infraItemId);
  const infraData = useSelector(store => store.oneInfra.data);
  const infraType = useSelector(store => store.infraTypes.data.find(it => it.infraTypeId == infraData.infraTypeId))
  const actualToShow = useSelector(store => store?.oneInfra?.actualToShow)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [vlanId, setVlanId] = useState(null);
  const [vlanInput, setVlanInput] = useState('');
  const [vlanIdError, setVlanIdError] = useState(null);
  const [error, setError] = useState(null);
  const [currentConfigError, setCurrentConfigError] = React.useState(null);
  const [currentConfig, setCurrentConfig] = React.useState(null);
  const [currentConfigLoading, setCurrentConfigLoading] = React.useState(false);

  const infraTypes = useSelector(store => store.infraTypes.data);
  const infraFeatures = useSelector(store => store.oneInfra.features);
  const stats = useSelector(store => store?.oneInfra?.stats);
  const [submitting, setSubmitting] = useState(false)
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null);
  const [trunkPorts, setTrunkPorts] = useState(commaSeparatedList([]));
  const [trunkError, setTrunkError] = useState(null);
  const [accessPorts, setAccessPorts] = useState(commaSeparatedList([]));
  const [accessError, setAccessError] = useState(null);
  const [lanPorts, setLanPorts] = useState([]);
  const [statsLoading, setStatsLoading] = useState(true);
  const [statsError, setStatsError] = useState(null);
  const [config, setConfig] = useState(null);
  const [portModal, setPortModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [typeOptions, setTypeOptions] = useState([{ value: 'Trunk', label: 'Trunk Port' }, { value: 'Access', label: 'Access Port' }, { value: 'NotMember', label: 'Not a Member' }]);
  const [currentVlans, setCurrentVlans] = useState(new Set());
  const [currentTrunks, setCurrentTrunks] = useState(new Set());
  const [currentAccess, setCurrentAccess] = useState(new Set());
  const [overallWarning, setOverallWarning] = useState(null)
  const [handlingChange, setHandlingChange] = useState(false)

  const [sviEnabled, setSVIEnabled] = useState(false);
  const [sviIp, setSviIp] = useState(null);
  const [sviError, setSviError] = useState(null);
  const [dhcpEnabled, setDhcpEnabled] = useState(false);
  const [relay, setRelay] = useState(null);
  const [relayError, setRelayError] = useState(null);

  useEffect(() => {
    if (props.edit && vlanIdFromParams) {
      setVlanId(Number(vlanIdFromParams))
      setVlanInput(vlanIdFromParams)
      let currVlanConfig = currentConfig?.configuration?.vlans? currentConfig?.configuration?.vlans[vlanIdFromParams]:null

      let currName = currVlanConfig?.name
      if(!!currName)  setName(currName)

      let currIpv4 = currVlanConfig?.ipv4
      let svi = null
      let dhcp = null
      if(!!currIpv4?.subnet?.length>0 && !!currIpv4?.subnet[0]["prefix"]) {
        svi = currIpv4.subnet[0]["prefix"]
      }
      if(!!currIpv4?.dhcp && !!currIpv4?.dhcp["relay-server"])
        dhcp = currIpv4.dhcp["relay-server"]
      let trunks = new Set();
      let access = new Set();
      let portsConfig = currentConfig?.configuration?.portsConfiguration ?? {}
      Object.keys(portsConfig)?.map(portString => {
        const portNumber = Number(portString.replace('Ethernet', ''));
        portsConfig[portString]?.vlan?.taggedVlans?.map(vlanId => {
          if (vlanId == vlanIdFromParams) {
            trunks.add(actualToShow[portNumber])
          }
        })
        if (portsConfig[portString]?.vlan?.untaggedVlan == vlanIdFromParams) {
          access.add(actualToShow[portNumber])
        }
      })
      setTrunkPorts(commaSeparatedList(Array.from(trunks ?? [])))
      setAccessPorts(commaSeparatedList(Array.from(access ?? [])))
      if (!!svi) {
        setSVIEnabled(true);
        setSviIp(svi)
      }
      if (!!dhcp) {
        setDhcpEnabled(true)
        setRelay(dhcp)
      }
    }
  }, [props.edit, vlanIdFromParams, currentConfig, actualToShow])

  useEffect(() => {
    if (portModal !== null) {
      let portConfig = config?.configuration?.portsConfiguration[`Ethernet${portModal}`]
      let initialOption = (portConfig?.vlan?.mode == 'Trunk' && portConfig?.vlan?.taggedVlans?.includes(vlanId))
      ? 'Trunk'
      : portConfig?.vlan?.untaggedVlan == vlanId
        ? 'Access'
        : 'NotMember'
      setModalData({
        option: initialOption,
        initialOption: initialOption,
        portConfig: portConfig,
        portStatus: (!!stats?.EthernetStatus)?stats?.EthernetStatus[`Ethernet${portModal}`]?.status:'',
        isMember: (portConfig?.vlan?.taggedVlans?.includes(vlanId) || portConfig?.vlan?.untaggedVlan == vlanId)
      })
    }
    else setModalData(null)
  }, [portModal, config, stats, vlanId])



  useEffect(() => {
    if (infraId) {
      setCurrentConfigError(null);
      setCurrentConfigLoading(true);
      const { run, controller } = createRequest(services.networks.SWITCHCONFIG_GET, [infraId, activeOrgId]);
      run()
        .then(response => {
          setCurrentConfig(response.data);
        })
        .catch(err => {
          const x = new CatchedWebError(err);
          setCurrentConfigError(x.message);
          setError(x.message);
        })
        .finally(() => {
          setCurrentConfigLoading(false);
        })
      return () => {
        controller.abort();
      }
    }
  }, [infraId]);

  useEffect(() => {
    dispatch(breadcrumbActions.setData(
      [{
        path: `/organization/${activeOrgId}/infra`,
        text: "Infrastructure",
        active: false
      },
      {
        path: `/organization/${activeOrgId}/infra/${infraData.infraItemId}/`,
        text: infraData.infraDisplayName,
        active: false
      },
      {
        path: `/organization/${activeOrgId}/infra/${infraData.infraItemId}/l2vlans`,
        text: "L2-VLANs",
        active: false
      },
      props.edit
        ? {
          path: `/organization/${activeOrgId}/infra/${infraData.infraItemId}/l2vlans/${vlanIdFromParams}/`,
          text: vlanIdFromParams,
          active: true
        }
        : {
          path: `/organization/${activeOrgId}/infra/${infraData.infraItemId}/l2vlans/add`,
          text: "Add VLAN",
          active: true
        }]
    ))
  }, [infraData.infraItemId, vlanId, activeOrgId, dispatch, infraData.infraDisplayName])

  useEffect(() => {
    let portsConfig = currentConfig?.configuration?.portsConfiguration;
    if (!!portsConfig) {
      let currVlans = new Set();
      let currTrunks = new Set();
      let currAccess = new Set();
      Object.keys(portsConfig ?? {}).map(portString => {
        const portNumber = Number(portString.replace('Ethernet', ''));
        if (portsConfig[portString]?.vlan?.taggedVlans?.includes(vlanId))
          currTrunks.add(portNumber);
        else if (portsConfig[portString]?.vlan?.untaggedVlan == vlanId)
          currAccess.add(portNumber);
        portsConfig[portString]?.vlan?.taggedVlans?.map(vlan => {
          currVlans.add(vlan);
        })
        const untaggedVlan = portsConfig[portString]?.vlan?.untaggedVlan
        if (!!untaggedVlan) {
          currVlans.add(untaggedVlan);
        }
      })
      setCurrentVlans(currVlans);
      setCurrentTrunks(currTrunks)
      setCurrentAccess(currAccess)
    }
  }, [currentConfig])


  useEffect(() => {
    setConfig(JSON.parse(JSON.stringify(currentConfig)));
  }, [currentConfig])

  useEffect(() => {
    setLanPorts(infraTypes.find(infraType => infraType.infraTypeId === infraData.infraTypeId).ports.LAN);
  }, [infraTypes, infraData])


  const handleVlanInputChange = (vid) => {
    vid = Number(vid);
    if (isInteger(vid) && 1 <= vid && vid <= 4094 && !currentVlans.has(vid)) {
      setVlanId(vid);
      setVlanIdError(null);
      setConfig(JSON.parse(JSON.stringify(currentConfig)));
      setName('');
      setTrunkPorts('');
      setAccessPorts('');
      setNameError(null);
      setTrunkError(null);
      setAccessError(null);
    }
    else if (!isInteger(vid)) {
      if (vid === '')
        setVlanIdError("Required");
      else
        setVlanIdError("Must be a number");
    }
    else if (vid < 1) {
      setVlanIdError("Allowed range is 1 to 4094");
    }
    else if (vid > 4094) {
      setVlanIdError("Allowed range is 1 to 4094");
    }
    else if (currentVlans.has(vid)) {
      setVlanIdError("Already exists");
    }
    // if (statefulTableData[vid]) {
    //   setVlanEditor({title: "Edit VLAN", id: vid});
    //   setPortsString(commaSeparatedList(statefulTableData[vid]));
    // } else {
    //   setVlanEditor({title: "Add VLAN", id: vid});
    //   setPortsString("");
    // }
  }

  const handleNameChange = (newName) => {
    if (vlanId !== null && vlanId !== '') {
      if (newName.length >= 0 && newName.length <= 30) {
        setName(newName);
        setConfig(prevState => {
          let vlans = prevState?.configuration?.vlans??{};
          let vlanConfig={};
          if(!!vlans[vlanId]) {
            vlanConfig = vlans[vlanId]
          }
          vlanConfig.name = newName
          return {
            ...prevState,
            vlans: {
              ...vlans,
              vlanId: vlanConfig
            }
          }
        })
        setNameError(null);
      }
      else 
        setNameError("Must have less than 30 characters")
    }
  }

  const handleChange = (mode, value) => {
    setHandlingChange(true);
    if (vlanId) {
      let ports = parsePortsSyntax(value, currentConfig);
      if (typeof ports !== "string") {
        if (mode === "Trunk") {
          let accessPortsSet = parsePortsSyntax(accessPorts, currentConfig)
          if(vlanId == DEFAULT_VLAN && typeof accessPortsSet != 'string') {
            for(const port of ports) {
              if(accessPortsSet.has(port)) {
                setTrunkError('Port(s) is/are access ports')
                setHandlingChange(false)
                return;
              }
            }
          }
          setTrunkError(null);
          let newConfig = JSON.parse(JSON.stringify(currentConfig));

          //apply trunk
          ports.forEach(port => {
            let portConfig = { ...newConfig?.configuration?.portsConfiguration[`Ethernet${port}`] };
            portConfig.vlan = {
              "mode": "Trunk",
              "untaggedVlan": portConfig?.vlan?.untaggedVlan == vlanId ? DEFAULT_VLAN : portConfig?.vlan?.untaggedVlan,
              "taggedVlans": portConfig?.vlan?.taggedVlans?.includes(vlanId)
                ? portConfig?.vlan?.taggedVlans
                : [...(
                  Array.isArray(portConfig?.vlan?.taggedVlans)
                    ? portConfig?.vlan?.taggedVlans
                    : [])
                  , vlanId]
            }
            newConfig.configuration.portsConfiguration[`Ethernet${port}`] = portConfig;
          })

          //delete trunks no longer present
          if (props.edit) {
            currentTrunks.forEach(port => {
              if (!ports.has(port)) {
                let portConfig = { ...newConfig?.configuration?.portsConfiguration[`Ethernet${port}`] };
                portConfig.vlan = {
                  "mode": "Trunk",
                  "untaggedVlan": portConfig?.vlan?.untaggedVlan,
                  "taggedVlans": portConfig?.vlan?.taggedVlans?.filter(id => id != vlanId)
                }
                newConfig.configuration.portsConfiguration[`Ethernet${port}`] = portConfig;
              }
            })
          }

          //remove if it has moved to trunk
          ports.forEach(port => {
            if (typeof accessPortsSet !== "string" && accessPortsSet.has(port)) {
              accessPortsSet.delete(port);
              let newArray = Array.from(accessPortsSet)
              newArray = newArray.map(port => actualToShow[port])
              // setAccessPorts(newArray.join(","))
              setAccessPorts(commaSeparatedList(newArray))
            }
          })

          //delete access
          if (props.edit && typeof accessPortsSet !== "string") {
            currentAccess.forEach(port => {
              if (!accessPortsSet.has(port) && !ports?.has(port)) {
                let portConfig = { ...newConfig?.configuration?.portsConfiguration[`Ethernet${port}`] };
                portConfig.vlan = DEFAULT_VLAN_CONFIG
                newConfig.configuration.portsConfiguration[`Ethernet${port}`] = portConfig;
              }
            })
          }
          //set access
          if (typeof accessPortsSet !== "string") {
            accessPortsSet.forEach(port => {
              let portConfig = newConfig?.configuration?.portsConfiguration[`Ethernet${port}`];
              if(!!portConfig?.vlan) portConfig.vlan.untaggedVlan = vlanId
            })
          }
          setConfig(newConfig);
        }
        else if (mode === "Access") {
          setAccessError(null);
          let newConfig = JSON.parse(JSON.stringify(currentConfig));

          //set new access
          ports.forEach(port => {
            let portConfig = newConfig?.configuration?.portsConfiguration[`Ethernet${port}`];
            if(!!portConfig?.vlan) {
              if(portConfig.vlan.taggedVlans) {
                portConfig.vlan.taggedVlans = portConfig.vlan.taggedVlans.filter(vId => vId != vlanId)
              }
              portConfig.vlan.untaggedVlan = vlanId
            }
            // portConfig.vlan = {
            //   mode: "Access",
            //   untaggedVlan: vlanId
            // }
          })

          //delete access not currently present but were present before
          if (props.edit) {
            currentAccess.forEach(port => {
              if (!ports.has(port)) {
                let portConfig = newConfig?.configuration?.portsConfiguration[`Ethernet${port}`];
                portConfig.vlan.untaggedVlan = DEFAULT_VLAN
              }
            })
          }

          let trunkPortsSet = parsePortsSyntax(trunkPorts, currentConfig)

          //remove from trunk ports if moved to access
          ports.forEach(port => {
            if (typeof trunkPortsSet !== "string" && trunkPortsSet.has(port)) {
              if (trunkPortsSet.has(port)) {
                trunkPortsSet.delete(port);
                let newArray = Array.from(trunkPortsSet)
                newArray = newArray.map(port => actualToShow[port])
                // setTrunkPorts(newArray.join(","))
                setTrunkPorts(commaSeparatedList(newArray))
              }
            }
          })

          //delete trunks that are not currently present
          if (props.edit && typeof trunkPortsSet !== "string") {
            currentTrunks.forEach(port => {
              if (!trunkPortsSet.has(port) && !ports?.has(port)) {
                let portConfig = newConfig?.configuration?.portsConfiguration[`Ethernet${port}`];
                if(!!portConfig.vlan) portConfig.vlan.taggedVlans = portConfig?.vlan?.taggedVlans?.filter(id => id != vlanId)
              }
            })
          }

          //set trunk that are currently present
          if (typeof trunkPortsSet !== "string") {
            trunkPortsSet.forEach(port => {
              let portConfig = { ...newConfig?.configuration?.portsConfiguration[`Ethernet${port}`] };
              portConfig.vlan = {
                "mode": "Trunk",
                "untaggedVlan": portConfig?.vlan?.untaggedVlan == vlanId ? DEFAULT_VLAN : portConfig?.vlan?.untaggedVlan,
                "taggedVlans": portConfig?.vlan?.taggedVlans?.includes(vlanId)
                  ? portConfig?.vlan?.taggedVlans
                  : [...(
                    Array.isArray(portConfig?.vlan?.taggedVlans)
                      ? portConfig?.vlan?.taggedVlans
                      : [])
                    , vlanId]
              }
              // if(!newConfig?.configuration) newConfig.configuration={portsConfiguration:{}};
              // else if(!newConfig?.configuration?.portsConfiguration) newConfig.configuration.portsConfiguration={}; 
              newConfig.configuration.portsConfiguration[`Ethernet${port}`] = portConfig;
            })
          }
          setConfig(newConfig)
        }
      }
      else {
        if (mode === 'Trunk') setTrunkError(ports);
        if (mode === 'Access') setAccessError(ports);
      }
    }
    setHandlingChange(false)
  }

  const handleSviIpChange = (value) => {
    let ip = value;
    // let pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([1-2][0-9]|3[0-2]|[8-9]))?$/
    let pattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[0-2]?[0-9])$/
    if (pattern.test(ip)) {
      setSviError(null)
    }
    else setSviError("Enter a valid ip address")
  }

  const handleRelayChange = (value) => {
    let ip = value;
    let pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    if (pattern.test(ip)) {
      setRelayError(null)
    }
    else setRelayError("Enter a valid IP address")
  }

  const submitConfig = () => {
    if (!trunkPorts && !accessPorts) {
      // setError("Enter atleast one trunk or access port")
      make_custom_toast('error', 'Validation', "Enter atleast one trunk or access port")
      return
    }
    let finalConfig = JSON.parse(JSON.stringify(config));
    Object.keys(finalConfig.configuration.portsConfiguration??{}).forEach(it =>{
      finalConfig.configuration.portsConfiguration[it] = includeProperty(finalConfig.configuration.portsConfiguration[it], ["speed", "enabled", "poe", "duplex", "lldp", "vlan", "name"])
      if(finalConfig.configuration.portsConfiguration[it]?.vlan?.inherit == true) 
        finalConfig.configuration.portsConfiguration[it].vlan = "inherit";
      else{
        finalConfig.configuration.portsConfiguration[it].vlan = includeProperty(finalConfig.configuration.portsConfiguration[it].vlan, ["mode", "taggedVlans", "untaggedVlan"])
      }
    })
    // Object.keys(finalConfig.configuration.portsConfiguration).map(port => {
    //   delete finalConfig.configuration.portsConfiguration[port].vlan
    // })
    let vlans = {}
    Object.keys(finalConfig.configuration.vlans??{}).map(vlan => {
      if(!!finalConfig.configuration.vlans[vlan]?.ipv4?.subnet){
          vlans[vlan] = {
              "subnet" : finalConfig.configuration.vlans[vlan]?.ipv4?.subnet
          }
      }

    if(!sviEnabled)
      delete vlans[vlanId]
    else
      vlans[vlanId] = {"subnet":[{prefix:sviIp}]}
  })
    // let result = checkConfig({ vlans: vlans })
    // if(result.incorrect === true) {
    //   if (props.edit) {
    //     make_custom_toast('error', 'Edit L2-VLAN', result.error)
    //   }
    //   else
    //     make_custom_toast('error', 'Add L2-VLAN', result.error)
    //   return
    // }
    const { run } = createRequest(services.networks.SWITCHCONFIG_SET, [infraData?.infraItemId, activeOrgId], {orgId: activeOrgId, configuration: {
      portsConfiguration: finalConfig.configuration.portsConfiguration,
      vlans: vlans
    } });
    setSubmitting(true);
    run()
      .then(response => {
        setError(null)
        navigate(`/organization/${activeOrgId}/infra/${infraId}/l2vlans`)
      })
      .catch(err => {
        // setError((new CatchedWebError(err).message))
        if (props.edit) {
          make_custom_toast('error', 'Edit L2-VLAN', (new CatchedWebError(err).message))
        }
        else
          make_custom_toast('error', 'Add L2-VLAN', (new CatchedWebError(err).message))
      })
      .finally(() => {
        setSubmitting(false);
      })
  }

  useEffect(() => {
    if (currentConfig && config) {
      let warningNative = "Changing Native VLAN of one or more ports"
      let warningTrunk = "Changing one or more Trunk VLANs on one or more ports"
      let warningBoth = "Changing one or more Trunk VLANs and Native VLAN of one or more ports"
      let native = false, trunk = false
      let portsConfigCurr = currentConfig?.configuration?.portsConfiguration
      let portsConfig = config?.configuration?.portsConfiguration
      for (let portString of Object.keys(portsConfigCurr)) {
        if (native && trunk) break
        let currUntaggedVlan = portsConfigCurr[portString]?.vlan?.untaggedVlan
        let untaggedVlan = portsConfig[portString]?.vlan?.untaggedVlan
        if (currUntaggedVlan !== DEFAULT_VLAN && currUntaggedVlan !== untaggedVlan) {
          native = true
        }
        let taggedCurr = new Set(portsConfigCurr[portString]?.vlan?.taggedVlans ?? [])
        let tagged = new Set(portsConfig[portString]?.vlan?.taggedVlans ?? [])
        if (tagged.size !== 0 &&
          !(taggedCurr.size === tagged.size && portsConfigCurr[portString]?.vlan?.taggedVlans?.every(vlan => tagged.has(vlan))))
          trunk = true
      }
      if (native && trunk)
        setOverallWarning(warningBoth)
      else if (native)
        setOverallWarning(warningNative)
      else if (trunk)
        setOverallWarning(warningTrunk)
      else
        setOverallWarning(null)
    }
  }, [config, currentConfig])

  const submitModal = () => {
    //change config.configuration.portsConfiguration[port]
    if(modalData?.changed) {
      if (modalData?.option === 'Trunk') {
        let ports = parsePortsSyntax(trunkPorts, config)
        if (typeof ports !== 'string') {
          ports.add(portModal)
          // let newPortsString = Array.from(ports).map(port => (port + 1)).join(",")
          let newPortsString = commaSeparatedList(Array.from(ports).map(port => actualToShow[port]))
          setTrunkPorts(newPortsString)
          handleChange("Trunk", newPortsString)
        }
        else {
          setTrunkPorts(String(actualToShow[portModal]))
          handleChange("Trunk", String(actualToShow[portModal]))
        }
      }
      else if (modalData?.option === 'Access') {
        let ports = parsePortsSyntax(accessPorts, config)
        if (typeof ports !== 'string') {
          ports.add(portModal)
          // let newPortsString = Array.from(ports).map(port => (port + 1)).join(",")
          let newPortsString = commaSeparatedList(Array.from(ports).map(port => actualToShow[port]))
          setAccessPorts(newPortsString)
          handleChange("Access", newPortsString)
        }
        else {
          setAccessPorts(String(actualToShow[portModal]))
          handleChange("Access", String(actualToShow[portModal]))
        }
      }
      else if (modalData?.option === 'NotMember') {
        if (modalData?.portConfig?.vlan?.taggedVlans?.includes(vlanId)) {
          let ports = parsePortsSyntax(trunkPorts, config)
          if (typeof ports !== 'string') {
            ports.delete(portModal)
            let newPortsString = commaSeparatedList(Array.from(ports).map(port => actualToShow[port]))
            setTrunkPorts(newPortsString)
            handleChange("Trunk", newPortsString)
          }
          else {
            setTrunkPorts('')
            handleChange("Trunk", '')
          }
        }
        else if (modalData?.portConfig?.vlan?.untaggedVlan === vlanId) {
          let ports = parsePortsSyntax(accessPorts, config)
          if (typeof ports !== 'string') {
            ports.delete(portModal)
            let newPortsString = commaSeparatedList(Array.from(ports).map(port => actualToShow[port]))
            setAccessPorts(newPortsString)
            handleChange("Access", newPortsString)
          }
          else {
            setAccessPorts('')
            handleChange("Access", '')
          }
        }
      }
    }
    setPortModal(null);
  }

  const ModalBodyRow = ({ leftValue, rightValue }) => {
    return (
      <Row className="my-50">
        <Col xs={3}>{leftValue}</Col>
        <Col><strong>{rightValue}</strong></Col>
      </Row>
    )
  }

  return (
    <div className="OneL2Vlan bg-white p-1 mt-1" data-testid="OneL2Vlan">
      <div className="heading">L2-VLAN Settings</div>
      <Container className="p-0">
        <Modal className="l2-vlan-port-modal" centered isOpen={portModal != null} toggle={() => setPortModal(null)}>
          <ModalHeader className="bg-white" toggle={() => setPortModal(null)}>
          </ModalHeader>
          <ModalBody>
            <div className="d-flex align-items-center">
              <span className="mb-50">
                <Port
                  identifier={portModal} disabled={false}
                  portConfig={config?.configuration?.portsConfiguration[`Ethernet${portModal}`] ?? {}}
                  portStatus={!!(stats?.EthernetStatus)?stats?.EthernetStatus[`Ethernet${portModal}`]?.status:""}
                  hoverable={false} onClick={() => { }} />
              </span>
              <span className="heading ml-50">Ethernet {actualToShow[portModal]}</span>
            </div>
            <div className="mt-1 ml-50">
              <Label>Type</Label>
              <Select
                className='react-select w-50'
                classNamePrefix='select'
                options={typeOptions}
                value={typeOptions.find(option => option.value === modalData?.option)}
                isClearable={false}
                isDisabled={vlanId == DEFAULT_VLAN && modalData?.initialOption === 'Access'}
                onChange={event => setModalData(ps => {
                  return {
                    ...ps,
                    changed: modalData?.initialOption != event.value,
                    option: event.value,
                    warning: modalData?.portConfig?.vlan?.initialOption !== event.value
                      ? event.value === 'Trunk'
                        ? modalData?.portConfig?.vlan?.untaggedVlan == vlanId
                          ? "This will remove the native VLAN from this port"
                          : null
                        : event.value === 'Access'
                          ? (modalData?.portConfig?.vlan?.taggedVlans?.includes(vlanId) && modalData?.portConfig?.vlan?.untaggedVlan != vlanId)
                            ? "This will change the port Native VLAN and remove all trunked VLANs"
                            : modalData?.portConfig?.vlan?.taggedVlans?.includes(vlanId)
                              ? "This wiil change the port to access and will remove all trunked VLANs"
                              : modalData?.portConfig?.vlan?.untaggedVlan != vlanId && modalData?.portConfig?.vlan?.untaggedVlan != DEFAULT_VLAN
                                ? "This will change the port Native VLAN"
                                : null
                          : event.value === 'NotMember'
                            ? (modalData?.portConfig?.vlan?.taggedVlans?.includes(vlanId))
                              ? "This change will remove this VLAN from this port"
                              : modalData?.portConfig?.vlan?.untaggedVlan == vlanId
                                ? "This will remove the native VLAN from this port"
                                : null
                            : null
                      : null
                  }
                })}
              />
            </div>
            {(modalData?.changed && !!modalData?.warning) ?
              <div className="mb-2 ml-50">
                <small><WarningIcon /><span className="text-warning">Warning!&nbsp;</span>
                  <strong>{modalData?.warning}</strong></small>
              </div> : null}
            <Container>
              <ModalBodyRow leftValue={"Enabled"} rightValue={modalData?.portConfig?.enabled ? 'True' : 'False'} />
              <ModalBodyRow leftValue={"Speed"} rightValue={modalData?.portConfig?.speed ?? '-'} />
              <ModalBodyRow leftValue={"Duplex"} rightValue={<span className="text-capitalize">{modalData?.portConfig?.duplex}</span>} />
              <ModalBodyRow leftValue={"Connected"} rightValue={modalData?.portStatus === 'Connected' ? "Yes" : "No"} />
              <ModalBodyRow leftValue={"Trunk VLANs"} rightValue={
                modalData?.portConfig?.vlan?.taggedVlans?.length>0
                ? modalData?.portConfig?.vlan?.taggedVlans
                : '-'} />
              <ModalBodyRow leftValue={"Native VLAN"} rightValue={
                modalData?.portConfig?.vlan?.untaggedVlan != null && modalData?.portConfig?.vlan?.untaggedVlan !== 0
                ? modalData?.portConfig?.vlan?.untaggedVlan
                : '-'} />
            </Container>
            <div className="d-flex justify-content-end gap-1">
              <Button.Ripple outline color='primary' className='mr-50'
                onClick={() => { setModalData(null); setPortModal(null) }}>Cancel
              </Button.Ripple>
              <Button.Ripple color='primary'
                disabled={vlanId == DEFAULT_VLAN && modalData?.initialOption === 'Access'}
                onClick={() => submitModal()}>Confirm</Button.Ripple>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-50">
              <Legend bgColor="#1FC78F" name="Connected" />
              <Legend bgColor="#808080" name="Disconnected" />
              <Legend bgColor="#000" name="Disabled" />
              <Legend bgColor="#FFBE40" name="Warning" />
            </div>
          </ModalBody>
          {/* <ModalBody>
            <div className="text-center text-warning">
              <span class="material-symbols-outlined">
                info
              </span>
            </div>
            <div className="text-center">Are you sure?</div>
            <div className="text-center">There is another VLAN has this port configured as access port already</div>
            <div className="d-flex justify-content-center align-items-center">
              <Button.Ripple outline color='primary' onClick={() => portModal.toggle}>Cancel</Button.Ripple>
              <Button.Ripple color='primary' onClick={() => portModal.update}>Update Anyway</Button.Ripple>
            </div>
          </ModalBody> */}
        </Modal>
        <Row className="mt-2">
          <Col xs={3}>
            <Label>VLAN Number<span className="text-danger">*</span></Label>
            <Input value={vlanInput} name='vlanNumber'
              disabled={props.edit || true}
              onChange={(e) => {
                setVlanInput(e.target.value);
                handleVlanInputChange(e.target.value);
              }} />
            {vlanIdError &&
              <small className="text-danger">&nbsp;
                {vlanIdError}
              </small>}
          </Col>
          <Col xs={3}>
            <Label>VLAN Name</Label>
            <Input type="text" className="w-100" value={name}
              disabled={vlanIdError || !vlanId || true}
              onChange={(e) => {
                setName(e.target.value);
                handleNameChange(e.target.value);
              }} />
            {nameError &&
              <small className="text-danger">&nbsp;
                {nameError}
              </small>}
          </Col>
          <Col xs={3}>
            <Label>Trunk Ports</Label>
            <Input type="text" className="w-100" value={trunkPorts}
              // valid={!trunkError && !(vlanIdError || !vlanId)} 
              disabled={vlanIdError || !vlanId || true}
              onChange={(e) => {
                // setPortsString(e.target.value)
                setTrunkPorts(e.target.value);
                setHandlingChange(true)
                debounce(() => {
                  handleChange("Trunk", e.target.value);
                });
              }} />
            {trunkError ?
              <small className="text-danger">&nbsp;
                {trunkError}
              </small> : null}
          </Col>
          <Col xs={3}>
            <Label>Access Ports</Label>
            <Input type="text" className="w-100" value={accessPorts}
              // valid={!accessError && !(vlanIdError || !vlanId)}
              disabled={vlanIdError || !vlanId || vlanId == DEFAULT_VLAN || true}
              onChange={(e) => {
                // setPortsString(e.target.value)
                setAccessPorts(e.target.value)
                setHandlingChange(true)
                debounce(() => {
                  handleChange("Access", e.target.value)
                });
              }} />
            {accessError ?
              <small className="text-danger">&nbsp;
                {accessError}
              </small> : null}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12}>
            <Label>VLAN Association</Label>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {/* <SwitchDisplay vlanId={vlanId} vlanIdError={vlanIdError} setPortModal={setPortModal} config={config} stats={stats}/> */}
            <DummySwitch
              // online={infra?.connected} 
              disabled={vlanIdError || !vlanId}
              mode='vlan'
              lanPorts={infraType.ports.LAN}
              entireConfig={config}
              dummy={config?.configuration?.portsConfiguration ? false : true}
              config={config?.configuration?.portsConfiguration}
              sfpPorts={infraType?.ports?.Uplink?.filter(it => ["SFP+", "SFP28"].includes(it.InterfaceType))}
              infraId={infraId}
              vlanId={vlanId}
              onClick={() => {}}
            // onSubmit={() => {fetchConfig();fetchStats();}}
            />
            {/* <Container className="border rounded">
            <div className="d-flex justify-content-center">
              <Container className="inline-block mb-1 port" style={{columns: `${Object.keys(config?.configuration?.portsConfiguration??{}).length/2} auto`}}>
                {Object.keys(config?.configuration?.portsConfiguration??{}).map(portNoString => {
                  const k = Number(portNoString.replace('Ethernet',''));
                  return (
                    <Port 
                      // disabled={currentConfig?.configuration?.portsConfiguration[`Ethernet${k}`].vlan?.mode !== "Trunk"} 
                      key={k}
                      identifier={k}
                      disabled={vlanId == null || !!vlanIdError}
                      portConfig={config?.configuration?.portsConfiguration[`Ethernet${k}`]??{}}
                      portStats={stats?.EthernetStatus[`Ethernet${k}`]}
                      onClick={(id) => {
                        if(vlanId != null && !vlanIdError && (config?.configuration?.portsConfiguration[`Ethernet${k}`]??{}).enabled) {
                          setPortModal(id)
                        }
                        // if (currentConfig?.configuration?.portsConfiguration[`Ethernet${k}`].vlan?.mode === "Trunk") {
                        // const selectedPorts = parsePortsSyntax(portsString);
                        // const ispicked = !!(selectedPorts.find(it => it === k+1));
                        // if (ispicked) { // if this port was already selected
                        //   let filteredPorts = selectedPorts.filter(pno => pno !== k+1);
                        //   filteredPorts.sort((a, b) => (a-b));
                        //   setPortsString(commaSeparatedList(filteredPorts));
                        // } else { // if this port was not selected yet
                        //   let addedPorts = selectedPorts.concat([k+1]);
                        //   addedPorts.sort((a, b) => (a-b));
                        //   setPortsString(commaSeparatedList(addedPorts));
                        // }
                      // }
                      }} 
                    />
                  );
                })}
              </Container>
            </div>
              <div className="d-flex align-items-center justify-content-center">
                <Legend bgColor="#1FC78F" name="Connected" />
                <Legend bgColor="#808080" name="Disconnected" />
                <Legend bgColor="#000" name="Disabled" />
                <Legend bgColor="#FF7940" name="Warning" />
                <Legend borderColor="#7A0303" name="Trunk"/>
                <Legend borderColor="#7367F0" name="Access"/>
              </div>
            </Container> */}
          </Col>
        </Row>
        {infraFeatures?.sviSupport?
        <>
        <div className="heading mt-1">L3 Settings</div>
        <div className="d-flex align-items-center">
          <Col xs={3} className="d-flex align-items-center mt-2">
            <Toggle id='switch-sviip'
              disabled={!vlanId || (trunkPorts == '' && accessPorts == '')}
              value={sviEnabled}
              displayText={false}
              onClick={() => {
                if(sviEnabled)
                  setSviError(null);
                else if(sviIp?.length>0)
                  handleSviIpChange(sviIp);
                setSVIEnabled(prevState => !prevState)
              }}
            />
            SVI IP Address
          </Col>
          <Col xs={3}>
            <Label>IP Address/Subnet
              {/* <span className="text-danger">*</span> */}
            </Label>
            <Input value={sviIp} name='sviIp'
              disabled={!sviEnabled || !vlanId || (trunkPorts == '' && accessPorts == '')}
              onChange={(e) => {
                setSviIp(e.target.value);
                handleSviIpChange(e.target.value);
              }} />
            {sviError &&
              <small className="text-danger">&nbsp;
                {sviError}
              </small>}
          </Col>
          {/* <TextInput autocomplete="off" isrequired type="text" label="DHCP Relay" name="dhcpRelay" placeholder="1.1.1.1" /> */}
        </div>
        {infraFeatures?.dhcpSupport?
        <div className="d-flex alig-items-center">
          <Col xs={3} className="d-flex align-items-center mt-2">
            <Toggle id='switch-dhcp'
              disabled={!sviEnabled || !vlanId || (trunkPorts == '' && accessPorts == '') || true}
              value={dhcpEnabled}
              displayText={false}
              onChange={() => { setDhcpEnabled(prevState => !prevState) }}
            />
            DHCP Relay
          </Col>
          <Col xs={3}>
            <Label>Relay Server
              {/* <span className="text-danger">*</span> */}
            </Label>
            <Input value={relay} name='relay'
              disabled={!sviEnabled || !dhcpEnabled || !vlanId || (trunkPorts == '' && accessPorts == '') || true}
              onChange={(e) => {
                setRelay(e.target.value);
                handleRelayChange(e.target.value);
              }} />
            {relayError &&
              <small className="text-danger">&nbsp;
                {relayError}
              </small>}
          </Col>
          {/* <TextInput autocomplete="off" isrequired type="text" label="DHCP Relay" name="dhcpRelay" placeholder="9.9.9.9" /> */}
        </div>
        :null}
        </>:null}
        <Row className="text-right my-50">
          <Col className={overallWarning ? "" : "invisible"}>
            <WarningIcon />
            <span >Warning:</span>
            {overallWarning ? <strong>{overallWarning}</strong> : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-end align-items-center">
              <Button className="px-2 mr-1" color="outline-primary" disabled={submitting} onClick={() => { navigate(`/organization/${activeOrgId}/infra/${infraId}/l2vlans`) }}>Discard</Button>
              <Button color="primary"
                disabled={!vlanId || !!error || !!vlanIdError || !!nameError || !!trunkError || !!accessError || !!sviError || !!relayError
                  || !!statsError || submitting || handlingChange}
                onClick={() => { 
                    if((sviEnabled && sviIp?.length>0) || !sviEnabled)
                      submitConfig()
                    else
                      handleSviIpChange(sviIp)
                }}>{submitting ? <Spinner size="sm"/> : "Save"}</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

OneL2Vlan.propTypes = {};

OneL2Vlan.defaultProps = {};

export default OneL2Vlan;
