import { createSlice } from "@reduxjs/toolkit";
import { DELETE, MANAGE, UPDATE, VIEW } from "../../utility/constants";

const rbacInitialState = {
    status: {
        loading: null,
        error: null,
    },
    permissions: {},
    venuePermissions: {},
    data: {
        identityId: null,
        userName: null,
        email: null,
        roleName: null,
        roles: [],
        orgTypeId: null,
        isCustomRole: null,
        systemPermission: {
            manageOrganization: null,
            manageVenue: null,
            manageInfra: null,
            manageScan: null,
            manageNetwork: null,
            manageProfile: null,
            manageTicket: null,
            manageFirmware: null,
            manageHierarchy: null,
            manageQuote: null,
            manageCart: null,
            manageOrder: null,
            manageInvoice: null,
            manageDevice: null,
            manageIdentity: null,
            manageIntegration: null
        },
        session_id: null,
        orgs: [],
        permissionId: null,
        orgId: null,
        hierarchy: null,
        organization: {
            orgId: null,
            parentOrgId: null,
            orgDisplayName: null,
            status: null,
            orgTypeId: null,
            leafNode: null,
            OLSEnable: null,
            marketplaceEnable: null,
            orgAddress: {
                latitude: null,
                longitude: null,
                addressLine: null
            },
            orgType: []
        }
    }
}

const converter = (input) => {
    let defaultPermissions = {}
    switch(input) {
        case MANAGE:
            defaultPermissions = {create: true, view: true, update: true, delete: true};
        break;
        case DELETE:
            defaultPermissions = {create: false, view: true, update: true, delete: true};
        break;
        case UPDATE:
            defaultPermissions = {create: false, view: true, update: true, delete: false};
        break;
        case VIEW:
            defaultPermissions = {create: false, view: true, update: false, delete: false};
        break;
        default:
            defaultPermissions = {create: false, view: false, update: false, delete: false}
    }
    return defaultPermissions;
}

const formatPermissions = (sysPermissions) => {
    let permissions = {...sysPermissions};
    Object.keys(sysPermissions)?.map((resource) => {
        permissions[resource] = converter(sysPermissions[resource]);
    })
    return permissions;
}
const formatVenuePermissions = (venues) => {
    let venuePermissions = {
        venueIds: [],
        infra: 0,
        network: 0,
        profile: 0,
        device: 0
    }
    const formatter = (permissions) => {
        for( let resource of Object.keys(permissions)) {
            if(resource == 'infra') {
                if(permissions[resource] > venuePermissions.infra) {
                    venuePermissions.infra = permissions[resource]
                }
            }
            else if(resource == 'network') {
                if(permissions[resource] > venuePermissions.network) {
                    venuePermissions.network = permissions[resource]
                }
            }
            else if(resource == 'profile') {
                if(permissions[resource] > venuePermissions.profile) {
                    venuePermissions.profile = permissions[resource]
                }
            }
            else if(resource == 'device') {
                if(permissions[resource] > venuePermissions.device) {
                    venuePermissions.device = permissions[resource]
                }
            }
            permissions[resource] = converter(permissions[resource])
        }
        return permissions
    }
    for(const venue of venues) {
        if(Object.keys(venue?.permissions??{}).length>0) {
            venuePermissions.venueIds.push(venue.venueId)
            venuePermissions[venue.venueId] = formatter(venue.permissions)
        }
    }
    
    venuePermissions.infra = converter(venuePermissions.infra)
    venuePermissions.network = converter(venuePermissions.network)
    venuePermissions.profile = converter(venuePermissions.profile)
    venuePermissions.device = converter(venuePermissions.device)

    if(venuePermissions.venueIds.length === 0)
        return {}
    return venuePermissions
}

const rbacSlice = createSlice({
    name: "rbac",
    initialState: {...rbacInitialState},
    reducers: {
        setLoading: (state, action) => {
            state.status.loading = action.payload;
        },
        setError: (state, action) => {
            state.status.error = action.payload;
        },
        set: (state, action) => {
            state.data = action.payload;
            state.permissions = formatPermissions(JSON.parse(JSON.stringify(action?.payload?.systemPermission)));
            state.venuePermissions = formatVenuePermissions(JSON.parse(JSON.stringify(action?.payload?.venues)));
        },
        setOrgMFA: (state, action) => {
            state.data.organization.mfa = action.payload;
        },
        reset: (state) => {
            state.status = { loading: null, error: null };
            state.data = {...rbacInitialState.data};
            state.permissions = {};
        }
    }
})

export const rbacReducer = rbacSlice.reducer;
const rbacActions = rbacSlice.actions;
export default rbacActions;