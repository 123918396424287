import { createSlice } from "@reduxjs/toolkit";

/* Initial Data Template for Blazer Graphs */
const I_Template = {
  isLoading: true,
  isError: null,
  isSuccess: false,
  time: null,
  data: null,
  id: null,
  period: null,
  active: null,
}

/* Blazer Slice Maker */
const createBlazerSlice = name => createSlice({
  name,
  initialState: { ...I_Template },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
    setSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    setData: (state, action) => {
      state.data = JSON.stringify(action.payload.data);
      state.id = action.payload.id;
      state.period = action.payload.period;
    },
    setTime: (state, action) => {
      state.time = action.payload
    },
    setActive: (state, action) => {
      state.active = action.payload
    },
    resetData: () => {
      return { ...I_Template };
    }
  }
});

/* Blazer */
const Blazer = {
  dashboard_infra: createBlazerSlice("dashboard_infra"),
  dashboard_venue: createBlazerSlice("dashboard_venue"),
  dashboard_support: createBlazerSlice("dashboard_support"),
  dashboard_devices: createBlazerSlice("dashboard_devices"),
  dashboard_network: createBlazerSlice("dashboard_network"),
  dashboard_firmwareVersion: createBlazerSlice("dashboard_firmwareVersion"),
  dashboard_ap_health: createBlazerSlice("dashboard_ap_health"),
  dashboard_ap_reboot: createBlazerSlice("dashboard_ap_reboot"),
  dashboard_online_infra: createBlazerSlice("dashboard_online_infra"),
  dashboard_venue_alarms: createBlazerSlice("dashboard_venue_alarms"),
  dashboard_clients_count: createBlazerSlice("dashboard_clients_count"),
  venueDash_venue: createBlazerSlice("venueDash_venue"),
  venueDash_tasks: createBlazerSlice("venueDash_tasks"),
  venueDash_alarms: createBlazerSlice("venueDash_alarms"),
  infraDash_infra: createBlazerSlice("infraDash_infra"),
  infraDash_online_infra: createBlazerSlice("infraDash_online_infra"),
  warehouseDash_infra: createBlazerSlice("warehouseDash_infra"),
  warehouseDash_online_infra: createBlazerSlice("warehouseDash_online_infra"),
  my_infra: createBlazerSlice("my_infra"),
  my_venue: createBlazerSlice("my_venue"),
  my_support: createBlazerSlice("my_support"),
  my_devices: createBlazerSlice("my_devices"),
  my_network: createBlazerSlice("my_network"),
  my_clients: createBlazerSlice("my_clients"),
  my_firmwareVersion: createBlazerSlice("my_firmwareVersion"),
  my_ap_health: createBlazerSlice("my_ap_health"),
  my_ap_reboot: createBlazerSlice("my_ap_reboot"),
  venue_network: createBlazerSlice("venue_network"),
  venue_ap_reboot: createBlazerSlice("venue_ap_reboot"),
  venue_cum_ap_reboot: createBlazerSlice("venue_ap_reboot"),
  venue_ap_health: createBlazerSlice("venue_cum_ap_health"),
  venue_cum_ap_health: createBlazerSlice("venue_cum_ap_health"),
  venue_devices: createBlazerSlice("venue_devices"),
  venue_infra: createBlazerSlice("venue_infra"),
  venue_health: createBlazerSlice("venue_health"),
  venue_events: createBlazerSlice("venue_events"),
  venue_event_count: createBlazerSlice("venue_event_count"),
  venue_cum_network: createBlazerSlice("venue_cum_network"),
  venue_cum_devices: createBlazerSlice("venue_cum_devices"),
  venue_cum_infra: createBlazerSlice("venue_cum_infra"),
  infra_devices: createBlazerSlice("infra_devices"),
  infra_network: createBlazerSlice("infra_network"),
  infra_load: createBlazerSlice("infra_load"),
  channel_utilization_2g: createBlazerSlice("channel_utilization_2g"),
  channel_utilization_5g: createBlazerSlice("channel_utilization_5g"),
  channel_utilization_6g: createBlazerSlice("channel_utilization_6g"),
  infra_memory: createBlazerSlice("infra_memory"),
  infra_events: createBlazerSlice("infra_events"),
  infra_health: createBlazerSlice("infra_health"),
  network_network: createBlazerSlice("network_network"),
  device_network: createBlazerSlice("device_network"),
  device_phyRate: createBlazerSlice("device_phyRate"),
  device_rssi: createBlazerSlice("device_rssi"),
  webhook: createBlazerSlice("webhook"),
  webhook_trend: createBlazerSlice("webhook_trend"),
  webhook_error_trend: createBlazerSlice("webhook_error_trend"),
};

export default Blazer;