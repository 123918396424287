import { useSelector } from "react-redux";
import ChartCardWithAreaCharts from "./ChartCardWithAreaCharts";

const WebhookTrendChart = ({ categories, series, colors, isLoading, height, width,ticks }) => {
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  return (
      <ChartCardWithAreaCharts
          title="Webhook Success"
          categories={categories}
          series={[series]}
          height={height}
          xlabel="Count"
          ylabel={range>0 ? "Date" : "Time"}
          ticks={ticks}
          colors={colors}
          width={width}
          isLoading={isLoading}
      />
  );
};

export default WebhookTrendChart;