import { CatchedWebError } from "./errorCodes";

export const createErrorContext = (apiContext, action, ticketCategory, err) => {
  let context = apiContext || {}

  context.action = action || 'UNDEFINED';
  context.category = ticketCategory || "Network";
  // context.error = err?.response?.data?.error || {}
  context.error = JSON.stringify(err)
  context.errorMessage = (new CatchedWebError(err)?.message)
  // context.showReportButton = showReportButton
  return context;
};
