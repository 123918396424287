import { oneInfraActions } from "../slices";
import store from "../store";
import createRequest, { services } from "../../services";
import { CatchedWebError } from "../../configs";
import blazer from "../../services/blazer.service";

const getInfra = (infraItemId, loading = true) => {
  store.dispatch(oneInfraActions.setLoading(loading));

  // const { controller, run } = createRequest(services.infra.GET, [infraItemId]);
  const { controller, run } = createRequest(services.telemetry.GET_INFRA_DETAILS, [infraItemId]);

  run()
    .then(response => {
      store.dispatch(oneInfraActions.setError(null));
      store.dispatch(oneInfraActions.setInfra({ ...response.data, connected: response?.data?.status == "connected" }));
      store.dispatch(oneInfraActions.setLoading(false));
      getAlarms(response?.data?.macAddress);
      // response?.data?.realInfra ?
      //   getDetails(response?.data?.macAddress)
      //   : store.dispatch(oneInfraActions.setDetailsLoading(false));
    })
    .catch(err => {
      let x = new CatchedWebError(err);
      store.dispatch(oneInfraActions.setError(x.message));
    })
    .finally(() => {
      store.dispatch(oneInfraActions.setLoading(false));
    });
  return controller;
};

const getDetails = (macAddress) => {
  store.dispatch(oneInfraActions.setDetailsLoading(true))
  const { run } = createRequest(services.telemetry.GET_STATISTICS, [macAddress]);

  run().then((response) => {
    store.dispatch(oneInfraActions.setDetails(response.data));
    store.dispatch(oneInfraActions.setDetailsError(null));
  })
    .catch(err => {
      store.dispatch(oneInfraActions.setDetailsError((new CatchedWebError(err)).message));
      store.dispatch(oneInfraActions.setDetails({}))
    })
    .finally(() => {
      store.dispatch(oneInfraActions.setDetailsLoading(false));
    })
};

const getIssues = (infraId, orgId) => {
  const cols = {
    Status: true,
    Severity: true,
    Title: true,
    Organization: true,
    Venue: true,
    Updated: true,
    Category: true,
    Reportedby: true,
    Comment: false,
    "Sub-Category": false,
    "Ticket reference": false
  }
  const columns = Object.keys(cols ?? {})
    .map((col) => (cols[col] ? col : null))
    .filter((it) => it);
  if (orgId) {
    const { run } = createRequest(services.ticket.GET_OPEN_TICKETS, [orgId, 0, 6, "All", { order: "ASC", orderBy: "subject" }, { "infraId": infraId }, "", columns]);

    run().then((response) => {
      store.dispatch(oneInfraActions.setIssues(response.data.tickets))

    })
      .catch(err => {
      })
  }
};

const getAlarms = (infraId) => {
  const { run } = createRequest(blazer.INFRA_ALERT, [infraId]);

  run().then((response) => {
    store.dispatch(oneInfraActions.setAlarms({
      count: Number(response.data["active_alarms"]),
      updatedAt: Date.now()
    }))

  })
    .catch(err => {
    })
};

export { getDetails, getIssues }
export default getInfra;