import { useSelector } from "react-redux";
import ChartCardWithAreaCharts from "./ChartCardWithAreaCharts";

const WebhookErrorTrendChart = ({ categories, series1, series2, series3, colors, isLoading, height, width,ticks }) => {
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  return (
      <ChartCardWithAreaCharts
          title="Webhook Errors"
          categories={categories}
          series={[series1, series2, series3]}
          colors={colors}
          height={height}
          xlabel="Count"
          ylabel={range>0 ? "Date" : "Time"}
          ticks={ticks}
          width={width}
          isLoading={isLoading}
          chartOptions={(options) => {
            if(!!options?.fill) {
              options.fill = {
                type: 'solid',
                colors: ['transparent'],
                opacity: 0
              }
            }
            return options
          }}
      />
  );
};

export default WebhookErrorTrendChart;