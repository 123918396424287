/***
 *
 * Controller class for user.
 * @file SupportModal.js
 * @description SupportModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import { Form, Formik } from "formik";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Alert, Button, Col, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import * as Yup from "yup";
import { CatchedWebError } from "../../configs";
import { make_toast } from "../../helpers";
import { CATEGORY_LIST, FILE_UPLOAD_ERROR_TEXT, PRIORITY_LIST, SUB_CATEGORY, TICKET_SEVERITY, TICKET_TYPE, editorConfig } from "../../pages/Tickets/TicketConstants";
import { extractFileExtension, extractFileName } from "../../pages/Tickets/utility/StringFunction";
import { uploadFile } from "../../pages/Tickets/utility/ticketUtility";
import createRequest, { services } from "../../services";
import TextInput from "../TextInput";
// import PropTypes from 'prop-types';
import "./SupportModal.scss";
import TextEditor from "../TextEditor";
import { useRef } from "react";
import { useEffect } from "react";
import { make_custom_toast } from "../../helpers/toasts";
import { BlobToBase64New, extractBlobFromDescription, isBlobPresent } from "../../pages/Tickets/CreateTicket";
import { useNavigate } from "react-router-dom";
import OrgIcon from "../../assets/images/icons/OrgGlobe.svg";
import VenueIcon from "../../assets/images/icons/Site.svg";
import InfraIcon from "../../assets/images/icons/AP_Black.svg"
import PencilIcon from "../../assets/images/icons/Pencil.svg"
import SiteSelector from "../SiteSelector";
import InfraSelector from "../InfraSelector";

const SupportModal = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeOrgName = useSelector(store => store.activeOrg.data.orgDisplayName);

  const identityData = useSelector(state => state.identity.data)
  const identityId = identityData.identityId;
  const requesterEmail = identityData.email;
  const requesterName = identityData.userName
  // const [error, setError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [uploadUrl, setUploadUrl] = useState([])
  const [fileUploadLoading, setFileUploadLoading] = useState(false)

  const [ticketPriority, setTicketPriority] = useState(TICKET_SEVERITY.MINOR)
  const [ticketApiLoading, setTicketApiLoading] = useState(false);
  const [emptyDescription, setEmptyDescription] = useState(false)
  const [ticketTitle, setTicketTitle] = useState("")
  const [selectedInfrastructure, setSelectedInfrastructure] = useState({
    infraItemId: props?.ticketDetails?.infraId,
    infraName: props?.ticketDetails?.infraName
  });
  const [selectedVenue, setSelectedVenue] = useState({
    venueId: props?.ticketDetails?.venueId ?? 0,
    venueName: props?.ticketDetails?.venueName ?? "",
  });
  const [showVenueModal, setShowVenueModal] = useState(false)
  const [showInfraModal, setShowInfraModal] = useState(false)
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [ticketReference, setTicketReference] = useState("")
  const [ticketCategory, setTicketCategory] = useState(CATEGORY_LIST[0]);
  const [ticketSubCategory, setTicketSubCategory] = useState(SUB_CATEGORY[CATEGORY_LIST[0].label][0]);

  const editor = useRef(null);
  const navigate = useNavigate()
  const searchParam = new URLSearchParams(document.location.search)


  useEffect(() => {
    setEmptyDescription(false)
  }, [description])

  const uploadFiles = (files) => {
    if (files == null || files == undefined || files.length < 1) {
      return
    }
    let tempUploadedFiles = [...uploadedFiles]
    let tempUploadUrl = [...uploadUrl]
    setFileUploadLoading(true)
    const promiseList = files.map(file => uploadFile(file, activeOrgId))
    Promise.all(promiseList)
      .then(resArray => {
        setFileUploadLoading(false)
        resArray.map((res) => {

          tempUploadUrl.push({
            filename: res.fileName
          })
          tempUploadedFiles.push({
            filename: res.displayName
          })
        })
        setUploadUrl(tempUploadUrl)
        setUploadedFiles(tempUploadedFiles)
      })
      .catch(errArray => {
        setFileUploadLoading(false)
        make_custom_toast('error', 'File Upload', FILE_UPLOAD_ERROR_TEXT)
        // setUploadError(FILE_UPLOAD_ERROR_TEXT)
      })
  }

  const deleteFile = (index) => {
    const fileName = encodeURIComponent(extractFileName(uploadUrl[index].filename))
    const extension = extractFileExtension(uploadedFiles[index].filename)

    const { run } = createRequest(services.ticket.REMOVE_ATTACHMENT, [activeOrgId, fileName, extension])
    run()
      .then(res => {
        let tempUploadedFiles = [...uploadedFiles]
        let tempUploadUrl = [...uploadUrl]
        tempUploadedFiles.splice(index, 1)
        tempUploadUrl.splice(index, 1)
        setUploadedFiles(tempUploadedFiles)
        setUploadUrl(tempUploadUrl)
      })
      .catch(err => {
        make_custom_toast('error', 'Remove Ticket Attachment', (new CatchedWebError(err)).message)
        // setError((new CatchedWebError(err)).message)
      })
  }


  const createTicket = () => {
    if (description.length < 10) {
      setEmptyDescription(true)
      return
    }
    let createTicketPayload = {
      subject: ticketTitle,
      description: description,
      type: TICKET_TYPE.BUG,
      status: 2, // 'open' status by default 
      severity: ticketPriority,
      category: props.ticketDetails?.category,
      subCategory: props.ticketDetails?.subCategory,
      source: 2,
      attachments: uploadUrl,
      venueId: props.ticketDetails?.venueId ?? 0,
      infraId: props.ticketDetails?.infraId ?? 0,
      networkId: props.ticketDetails?.networkId ?? 0,
      venueName: props.ticketDetails?.venueName ?? "",
      infraName: props.ticketDetails?.infraName ?? "",
      networkName: props.ticketDetails?.networkName ?? "",
      identityId: identityId,
      requesterEmail: requesterEmail,
      requesterName: requesterName,
      orgId: activeOrgId,
      ticketReference: ticketReference,
      orgName: activeOrgName,
      macAddress: props.ticketDetails.macAddress ?? ''

    }
    if (props.context) {
      createTicketPayload.reportError = props.context
    }



    let newDescription = description
    const blobArray = extractBlobFromDescription(newDescription);

    if (isBlobPresent(newDescription) && blobArray.length > 0) {
      for (let i = 0; i < blobArray.length; i++) {
        let tempBlobLink = blobArray[i]
        let convertedLink = BlobToBase64New(tempBlobLink)
        newDescription = newDescription.replace(tempBlobLink, convertedLink)
      }
    }
    createTicketPayload.description = newDescription

    const { run } = createRequest(services.ticket.CREATE_TICKET, [], createTicketPayload)
    setTicketApiLoading(true)
    run()
      .then((res) => {
        window.scrollTo(0, 0)
        const newTicket = res.data
        make_custom_toast(
          "success",
          "Ticket Successfully created",
          `Your Ticket #${newTicket.freshworksTicketId} is in review`,
          true,
          "View Ticket",
          () => {
            navigate(
              `/organization/${activeOrgId}/support/${newTicket.freshworksTicketId}`
            );
          }
        );
        setTicketTitle("")
        setDescription("")
        setTicketApiLoading(false)

        props.onSuccess && props.onSuccess();
        setUploadedFiles([]);
        setUploadUrl([]);
        props.setIsOpen(false);
      })
      .catch((err) => {
        window.scrollTo(0, 0)
        setTicketApiLoading(false)
        make_custom_toast('error', 'Create Ticket', (new CatchedWebError(err)).message)
        // setError((new CatchedWebError(err)).message)
      })

  }

  useEffect(() => {
    if (selectedVenue == null) {
      setSelectedInfrastructure({
        infraItemId: 0,
        infraName: "",
        macAddress: ""
      })
    }
  }, [selectedVenue])


  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100
    }),
    dropdownIndicator: (base) => ({
      ...base,
      "svg": {
        height: "20px",
        width: "20px"
      }
    })
  };

  return (
    <div className="SupportModal" data-testid="SupportModal">
      <Modal centered className="modal-xl support-modal-style" isOpen={props.isOpen} toggle={() => {
        setUploadedFiles([]);
        setUploadUrl([]);
        setEmptyDescription(false);
        props.setIsOpen(false);
      }}>
        <ModalHeader className="bg-white" toggle={() => {
          setUploadedFiles([]);
          setUploadUrl([]);
          setEmptyDescription(false);
          props.setIsOpen(false)
        }}></ModalHeader>
        <ModalBody>
          <h3 className="pl-1">Create Ticket</h3>
          {/* <Alert color="danger" isOpen={!!error} toggle={() => setError(null)}>
            <div className="alert-body">{error}</div>
          </Alert> */}
          <div className="CreateTicket  mt-2">
            <Row>
              <Col md="5">
                <div className="bg-white rounded shadow-sm  p-1 h-100">
                  <h5 className="fw-500 dark-black" >Title<span className="text-danger">*</span></h5>
                  <textarea
                    className="w-100 no-border-textarea"
                    rows="5"
                    placeholder="Add Title"
                    value={ticketTitle}
                    onChange={(e) => {
                      setTicketTitle(e.target.value)
                      setSubject(e.target.value)
                    }}

                  />
                </div>
              </Col>
              <Col md="3" className=" ">
                <div className="d-flex flex-column justify-content-between bg-white rounded shadow-sm h-100 p-1">
                  <div className="d-flex align-items-center">
                    <img src={OrgIcon} className="mr-1" />
                    <span>{activeOrgName}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between selector-container">
                    <div className="d-felx align-items-center">
                      <img src={VenueIcon} className="mr-1" />
                      <span>{selectedVenue?.venueId ? selectedVenue?.venueName ? selectedVenue?.venueName : '-' : "No Venue Selected"}</span>
                    </div>
                    <img src={PencilIcon} style={{ height: '16px' }} className="hover-show cursor-pointer" onClick={() => {
                      setShowVenueModal(true)
                    }} />
                  </div>
                  <div className="d-flex align-items-center justify-content-between selector-container">
                    <div className="d-flex align-items-center">
                      <img src={InfraIcon} className="mr-1" />
                      <span>{selectedInfrastructure?.infraItemId ? selectedInfrastructure?.infraName ? selectedInfrastructure?.infraName : '-' : "No Infrastructure Selected"}</span>
                    </div>

                    <img src={PencilIcon} style={{ height: '16px' }} className={`hover-show ${selectedVenue?.venueId ? "cursor-pointer" : "cursor-not-allowed"}`} onClick={() => {
                      if (selectedVenue?.venueId)
                        setShowInfraModal(true)
                    }} />

                  </div>
                  <div className="d-flex">
                    <div className="fw-500 dark-black">Ticket reference:&nbsp;</div>
                    <input
                      className="ticket-reference-input"
                      placeholder="Add Reference"
                      value={ticketReference}
                      onChange={(e) => { setTicketReference(e.target.value) }}
                    />
                  </div>
                </div>

              </Col>
              <Col md="4">
                <div className="bg-white rounded shadow-sm h-100 w-100 p-1">
                  <div className="d-flex align-items-center mb-50">
                    <span className="w-50 fw-500 dark-black">Status<span className="text-danger">*</span></span>
                    <div className="border rounded  w-50 pr-1 py-50 pl-0 numb-text padding-left-custom" style={{
                      backgroundColor: "#f6f6f6",
                      borderColor: "rgba(212, 212, 212, 1)",

                    }}>
                      Open
                    </div>
                  </div>


                  <div className="d-flex align-items-center mb-50">
                    <span className="w-50 fw-500 dark-black">Severity<span className="text-danger">*</span></span>
                    <Select
                      className="w-50"
                      name={"ticketpriority"}
                      classNamePrefix="select"

                      defaultValue={PRIORITY_LIST[0]}
                      options={PRIORITY_LIST}
                      isClearable={false}
                      isSearchable={false}
                      styles={customStyles}
                      onChange={(option) => {
                        setTicketPriority(
                          option.value
                        );
                      }}
                    />
                  </div>

                  {
                    searchParam.get("type") != TICKET_TYPE.FEATURE &&
                    <div className="d-flex align-items-center mb-50">
                      <span className="w-50 fw-500 dark-black">Category<span className="text-danger">*</span></span>
                      <Select
                        className="w-50"
                        name={"ticketpriority"}
                        classNamePrefix="select"
                        isDisabled
                        defaultValue={CATEGORY_LIST?.find(category => category.label == props?.ticketDetails?.category)}
                        options={[...CATEGORY_LIST].slice(0, 3)}
                        isClearable={false}
                        isSearchable={false}
                        styles={customStyles}
                        onChange={(option) => {
                          setTicketCategory(
                            option
                          );
                          setTicketSubCategory(SUB_CATEGORY[option.label][0])
                        }}
                      />
                    </div>
                  }
                  {
                    searchParam.get("type") != TICKET_TYPE.FEATURE &&
                    <div className="d-flex align-items-center">
                      <span className="w-50 fw-500 dark-black">Sub-Category<span className="text-danger">*</span></span>
                      <Select
                        className="w-50"
                        classNamePrefix="select"
                        // defaultValue={}
                        isDisabled
                        value={SUB_CATEGORY[props?.ticketDetails?.category]?.find(category => category.label == props?.ticketDetails?.subCategory)}
                        options={SUB_CATEGORY[props?.ticketDetails?.category]}
                        isClearable={false}
                        isSearchable={false}
                        styles={customStyles}
                        onChange={(option) => {
                          setTicketSubCategory(
                            option
                          );
                        }}
                      />
                    </div>
                  }
                </div>
              </Col>
            </Row>


            {/* {showSelectVenue && (
    <Row className="fadable">
        <Col xs="8" md="6">
            <Label className="labelfont">
                Select Venue (optional)
            </Label>
            <InputGroup>
                <Input
                    type="text"
                    disabled
                    value={
                        selectedVenue &&
                            selectedVenue.venueId !==
                            0
                            ? selectedVenue.venueName
                            : "No Venue Selected"
                    }
                    isrequired
                />
                <Button
                    color="outline-primary rounded-custom"
                    onClick={() => {
                        setVenueSelectorModal(
                            true
                        );
                    }}
                >
                    Select Venue
                </Button>
            </InputGroup>
        </Col>
        <Col xs="8" md="6" className="d-flex ">
            <div className="d-flex flex-column w-100">
                <Label className={"labelfont"}>
                    Select Infrastructure
                    (optional)
                </Label>
                <Select
                    key={`SELECT_INFRA_KEY_${infrastructureList[0]?.label}`}
                    isDisabled={
                        selectedVenue &&
                            selectedVenue.venueId ==
                            0
                            ? true
                            : false
                    }
                    className={"selectBox"}
                    classNamePrefix="select"
                    // value={selectedInfrastructure}
                    defaultValue={
                        selectedInfrastructure.infraName
                            ? {
                                label: selectedInfrastructure.infraName,
                                value: selectedInfrastructure.infraItemId,
                            }
                            : infrastructureList[0] ||
                            "Select"
                    }
                    options={infrastructureList}
                    isClearable={false}
                    isSearchable
                    styles={customStyles}
                    onChange={(option) => {
                        setSelectedInfrastructure(
                            {
                                infraItemId:
                                    option.value,
                                infraName:
                                    option?.label,
                                macAddress:
                                    option.macAddress,
                            }
                        );
                    }}
                />
            </div>
        </Col>
    </Row>
)} */}


            {/* --- Decription and Attachment View --- */}

            <div className="my-2">
              <Row>
                <Col md="8">
                  {/* <TextInput label="Description (Not more than 1000 characters)" placeholder='Add description' rows='5' type="textarea" name="ticketdescription" isrequired /> */}
                  <div className="bg-white p-1 rounded shadow-sm h-100">

                    <Label className="fw-500 dark-black" style={{ fontSize: "1em" }}>
                      Description
                      <span className="text-danger">
                        *
                      </span>
                    </Label>
                    <TextEditor
                      ref={editor}
                      value={description ?? ""}
                      config={editorConfig}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newDescription) =>
                        setDescription(newDescription)
                      } // preferred to use only this option to update the content for performance reasons
                    // onChange={(newDescription) => { setDescription(newDescription) }}
                    />
                    {emptyDescription && (
                      <span className="empty-description">{`Minimum 10 characters required.`}</span>
                    )}
                  </div>
                </Col>
                <Col md="4">
                  <div className="bg-white rounded shadow-sm p-1 h-100">
                    <h5 className="cursor-default">
                      Upload Attachment
                    </h5>

                    <div className="fileupload">

                      <Dropzone onDrop={uploadFiles}>
                        {({
                          getRootProps,
                          getInputProps,
                        }) => (
                          <section className="d-flex w-100">
                            <div
                              {...getRootProps()}
                              className="d-flex justify-content-center align-items-center  w-100 text-center"
                            >
                              <input
                                {...getInputProps()}
                              />
                              <div className="text-center">
                                <p className="cursor-default">
                                  <div className="d-flex align-items-center mt-1">
                                    <span className="material-symbols-outlined large text-primary cursor-default">
                                      file_upload
                                    </span>
                                    <span className="cursor-pointer text-primary">
                                      Upload a file
                                    </span>&nbsp;
                                    or drag and drop
                                  </div>
                                  <div className="text-muted text-center" style={{ fontSize: '12px' }}>
                                    (Maximum Upload file size: 20MB)
                                  </div>
                                </p>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>

                      {fileUploadLoading ? (
                        <Spinner color={`primary`} />
                      ) : uploadedFiles.length > 0 ? (
                        null
                      ) : (
                        null
                      )}
                    </div>
                    <div className="pt-1">
                      {uploadedFiles.length > 0 && uploadedFiles.map(
                        (file, index) => {
                          return (
                            <div
                              key={index}
                              className="ticket-file d-flex align-items-center justify-content-between rounded py-50 px-1 mb-1 "
                            >
                              <span>
                                <span></span>
                                <span className="text-primary">{file.filename}</span>
                              </span>
                              <span
                                className="bin-icon ml-1 cursor-pointer d-inline material-symbols-outlined"
                                onClick={() => { deleteFile(index); }}
                              >
                                delete
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>

                  </div>
                </Col>
              </Row>
            </div>

            {/* <div className="d-flex justify-content-between">
    {uploadedFiles.length > 0 ? (
        <Dropzone onDrop={uploadFiles}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()}>
                        <input
                            {...getInputProps()}
                        />
                        <div>
                            <Button
                                outline
                                className="mr-1"
                                disabled={
                                    ticketApiLoading
                                }
                                color="primary"
                                onClick={() => { }}
                            >
                                Add Files
                            </Button>
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
    ) : (
        <div />
    )}
</div> */}


            {/* --- Create Ticket Button --- */}
            <div className="d-flex justify-content-end">
              <Button
                outline
                className="mr-1 small-add-button"
                disabled={ticketApiLoading}
                color="primary"
                onClick={() => {
                  setUploadedFiles([]);
                  setUploadUrl([]);
                  setEmptyDescription(false);
                  props.setIsOpen(false);
                }}>

                Discard
              </Button>
              <Button
                disabled={ticketApiLoading}
                className="small-add-button"
                // type={"submit"}
                onClick={() => {
                  createTicket()
                }}
                color="primary"
              >
                {ticketApiLoading ? (
                  <Spinner size={"sm"} />
                ) : (
                  `Create`
                )}
              </Button>
            </div>




            {
              showVenueModal &&
              <Modal size="lg" centered isOpen={showVenueModal} toggle={() => { setShowVenueModal(false); }}>
                <ModalHeader className="p-0 bg-white" toggle={() => { setShowVenueModal(false); }}></ModalHeader>
                <ModalBody>
                  <SiteSelector
                    setShowModal={setShowVenueModal}
                    selectedVenue={selectedVenue}
                    setSelectedVenue={setSelectedVenue}
                    columns={{
                      Country: true,
                      Infrastructure: true
                    }}
                    showFilters
                    modalTitle="Select Venue"

                  />
                </ModalBody>
              </Modal>
            }

            {
              showInfraModal &&
              <Modal style={{ maxWidth: '1020px', width: '100%' }} isOpen={showInfraModal} centered toggle={() => setShowInfraModal(false)}>
                <ModalHeader className="p-0 bg-white" toggle={() => setShowInfraModal(false)}></ModalHeader>
                <ModalBody>

                  <InfraSelector
                    headingText="Select Infrastructure"
                    venueId={selectedVenue?.venueId}
                    venueName={selectedVenue?.venueName}
                    // defaultDeviceType={props.data?.infraTypeId}
                    // defaultDeviceCategory={props.data?.infraCategory}
                    selectedInfra={selectedInfrastructure}
                    setSelectedInfra={setSelectedInfrastructure}
                    setShowModal={setShowInfraModal}
                    hideState={true}
                  />
                </ModalBody>
              </Modal>
            }
          </div >
          {/* <Formik
            initialValues={
              {
                ticketsubject: props?.ticketDetails?.subject ?? '',
                ticketdescription: props?.ticketDetails?.description ?? '',
                ticketpriority: TICKET_SEVERITY.MINOR
              }}
            validationSchema={Yup.object({
              ticketsubject: Yup.string()
                .min(3, "Minimum 3 characters are required")
                .max(128, "Maximum 128 characters are allowed")
                .required("Required"),

            })}
            onSubmit={
              createTicket
            }
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <Form onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}>
                <div className="infra-support-div p-1">
                  <div>
                    <Row>
                      <Col xs="12" md="8">
                        <TextInput label="Subject" placeholder='Add subject for ticket' type="text" name="ticketsubject" isrequired onChange={(e) => {
                          setFieldValue("ticketsubject", e.target.value);
                        }} />
                      </Col>

                      <Col xs="12" md="4" className="d-flex">
                        <div className="d-flex flex-column w-100">
                          <Label className={"labelfont"}>Select Severity</Label>
                          <Select
                            name={'ticketpriority'}
                            classNamePrefix='select'
                            defaultValue={PRIORITY_LIST[0]}
                            options={PRIORITY_LIST}
                            isClearable={false}
                            isSearchable={false}
                            styles={customStyles}
                            onChange={
                              (option) => {
                                setTicketPriority(option.value)
                              }
                            }
                          />
                        </div>
                      </Col>
                    </Row>


                  </div>



                  <div className="mb-2">
                    <Row>
                      <Col >

                        <Label style={{ fontSize: '1em' }}>Description<span className="text-danger">*</span></Label>
                        <TextEditor
                          ref={editor}
                          value={values.ticketdescription}
                          config={editorConfig}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={newDescription => { if (newDescription.length < 10) setEmptyDescription(true); else setEmptyDescription(false); }} // preferred to use only this option to update the content for performance reasons
                          onChange={newDescription => {
                            setEmptyDescription(false);
                            setFieldValue("ticketdescription", newDescription)
                          }}
                        />
                        {emptyDescription && <span className="text-danger">{`Minimum 10 characters required.`}</span>}
                      </Col>
                    </Row>
                    <Row>
                      <Col >
                        <div className="d-flex align-items-center">

                          <h5 className="my-2 mr-1 mb-0 cursor-default">Upload Attachment</h5>
                          <span className="text-muted cursor-default">(Maximum upload file size: 20MB)</span>
                        </div>

                        <div className='fileupload'>
                          {fileUploadLoading ? <Spinner color={`primary`} /> : uploadedFiles.length > 0 ?
                            <div className='d-flex align-center flex-wrap flex-1'>
                              {uploadedFiles.map((file, index) => {
                                return (
                                  <div key={index} className="d-flex align-items-center justify-content-between rounded p-1 m-1 shadow ">
                                    <span>{file.filename}</span>
                                    <span
                                      className="bin-icon ml-1 cursor-pointer d-inline material-symbols-outlined"
                                      onClick={() => {
                                        deleteFile(index)
                                      }} >
                                      delete
                                    </span>
                                  </div>)
                              })}

                            </div> :
                            <Dropzone
                              onDrop={uploadFiles} >
                              {({ getRootProps, getInputProps }) => (
                                <section className="d-flex w-100">
                                  <div {...getRootProps()} className="d-flex justify-content-center align-items-center  w-100 text-center">
                                    <input {...getInputProps()} />
                                    <div className="test-center">
                                      <p className="material-symbols-outlined large cursor-default">file_upload</p>
                                      <p className="cursor-default"><span className="cursor-pointer text-primary">Upload a file</span> or drag and drop</p>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>

                      {
                        uploadedFiles.length > 0 ?
                          <Dropzone
                            onDrop={uploadFiles} >
                            {({ getRootProps, getInputProps }) => (
                              <section >
                                <div {...getRootProps()} >
                                  <input {...getInputProps()} />
                                  <div >
                                    <Button
                                      outline
                                      className='mr-1'
                                      disabled={ticketApiLoading}
                                      color="primary"
                                      onClick={() => { }}
                                    >
                                      Add Files
                                    </Button>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                          : <div />
                      }
                    </div>
                    <div>
                      <Button
                        outline
                        className='mr-1'
                        disabled={ticketApiLoading}
                        color="primary"
                        onClick={() => {
                          setUploadedFiles([]);
                          setUploadUrl([]);
                          setEmptyDescription(false);
                          props.setIsOpen(false);
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        disabled={ticketApiLoading}
                        type={'submit'}
                        color="primary"
                      >{ticketApiLoading ? (<Spinner size={'sm'} />) : `Create Ticket`}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik> */}
        </ModalBody>
      </Modal>
    </div>
  );
};

SupportModal.propTypes = {};

SupportModal.defaultProps = {};

export default SupportModal;
