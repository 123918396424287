import { useSelector } from "react-redux";
import { dateTimeFormatter } from "../../../utility/Localization";
import Chart from "react-apexcharts";
import { bottom } from "@popperjs/core";
import { formatDecimalPoints, formatNumber, roundOffValue } from "../../../utility/Utils";
import { useState } from "react";
import "./AreaApexChartWrapper.scss";

export const extractChannels = (channelList) => {
    if (channelList == null || channelList == undefined || channelList?.length < 1) {
        return "-"
    }
    let text = ""
    for (let i in channelList) {
        text = text + channelList[i]
        if (i < channelList.length - 1) {
            text = text + ','
        }
    }
    return text
}

const AreaApexChartWrapper = ({ showBorders, categories, series, channels, colors, showLegend, showPercentage, noMarker, borderColorArray, isRssi, showChannel, ...props }) => {

    const range = useSelector(store => store.activeOrg.meta.dateRange)
    let options = {
        xaxis: {
            type: 'datetime',
            categories: categories,
            tickAmount: props.ticks ?? 6,
            labels: {
                rotate: 340,
                style: {
                    fontSize: '11px'
                },
                formatter: (value) => {
                    if (value) {
                        if (!!props.timeInterval)
                            return dateTimeFormatter(new Date(new Date(value).setMinutes(Math.round(new Date(value).getMinutes() / (props.timeInterval)) * (props.timeInterval))), "", "short", false, false, false);
                        if (Number(props.range ?? range) <= 0.25)
                            return dateTimeFormatter(new Date(new Date(value).setMinutes(Math.round(new Date(value).getMinutes() / 30) * 30)), "", "short", false, false, false);
                        // return dateTimeFormatter(value, "", "short", false, false, false);
                        return dateTimeFormatter(value, "medium", "", true);
                    }
                },
            }
        },
        yaxis: {
            show: true,
            forceNiceScale: true,
            // tickAmount: 4,
            min: 0,
            max: (max) => {
                if (props?.isTraffic) {
                    if (max === 0)
                        return 1;
                    else return Math.max(1.1, max)
                }
                return Math.max(1, max);
            },
            labels: {
                offsetX: -16,
                minHeigth: 10,
                formatter: (value) => {
                    // props.isTraffic will be "true" only for "Network Chart"
                    if (props.isTraffic)
                        return formatDecimalPoints(value)
                    else if (isRssi)
                        return roundOffValue(value)
                    return formatNumber(value);
                }
            }

        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        colors: colors,
        stroke: {
            width: 3
        },
        // fill: {
        //     colors: colors,
        //     opacity: 0.3,
        //     type: "solid"
        // },
        fill: {
            type: "solid",
            opacity: 0.2
        },
        markers: {
            size: 3,
            colors: ["#fff"],
            strokeColor: colors,
            strokeWidth: 3
        },
        grid: {
            show: false,
            padding: {
                right: 45,
                left: -3,
                top: 1
            }
        },
        chart: {
            parentHeightOffset: 0,
            animations: {
                enabled: false
            },
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
                tools: {
                    zoomin: true,
                    zoomout: true,
                    download: false
                }

            },
            events: {
                beforeZoom: (e, { xaxis }) => {
                    let maindifference = (new Date(categories[categories.length - 1])).valueOf() - new Date(categories[0]).valueOf();
                    let zoomdifference = xaxis.max - xaxis.min;
                    if (zoomdifference > maindifference)
                        return {
                            // dont zoom out any further
                            xaxis: {
                                min: categories[0],
                                max: categories[categories.length - 1]
                            }
                        };
                    else {
                        return {
                            // keep on zooming
                            xaxis: {
                                min: xaxis.min,
                                max: xaxis.max
                            }
                        }
                    }
                }
            },
        },
        tooltip: {
            // x: { show: false },
            y: {
                formatter: props.isTraffic ?
                    function (value = 0, { series, seriesIndex, dataPointIndex, w }) {
                        if (seriesIndex === 0) return value.toFixed(2) + ' ' + props.typeSeriesTrafficUplink ?? "MB";
                        else return value.toFixed(2) + ' ' + props.typeSeriesTrafficDownlink ?? "MB";
                    } : function (value, { series, seriesIndex, dataPointIndex, w }) {
                        if (showPercentage)
                            return value + '%'
                        return value
                    }
            }
        }
    }
    if (!!props.chartOptions)
        options = props.chartOptions(options)

    if (showPercentage) {
        options.yaxis.min = 0
        options.yaxis.max = 100
        options.yaxis.forceNiceScale = false
        options.yaxis.tickAmount = 4
    }
    if (borderColorArray) {
        options.stroke.colors = borderColorArray
        options.stroke.width = 2
    }

    if (noMarker) {
        delete options.markers
    }
    if (showChannel) {
        options.extraData = {
            channels: channels
        }
    }

    if (showChannel) {
        options.tooltip.custom = function ({ series, seriesIndex, dataPointIndex, w }) {
            // console.log("rdebug custom series:", series)
            // console.log("rdebug custom seriesIndex:", seriesIndex)
            // console.log("rdebug custom data:", series[seriesIndex + 1][dataPointIndex])
            // console.log('rdebug custom w:', w?.config?.extraData?.channels?.data[dataPointIndex])
            return '<div class="chart-tooltip-box-container">' +
                '<div class="chart-tooltip-date-box">' + (range < 6 ? dateTimeFormatter(w?.config?.xaxis?.categories[dataPointIndex], null, "short", false, true, false) : dateTimeFormatter(w?.config?.xaxis?.categories[dataPointIndex], "short", null, true, false)) + '</div>' +
                '<div class="chart-tooltip-data-container">' +
                '<div class="d-flex align-items-center mb-50">' + '<div class="chart-total-circle"></div>' + w?.config?.series[0]?.name + ": " + '<span class="chart-tooltip-bold-text">' + series[0][dataPointIndex].toFixed(2) + "%" + '</span>' + '</div>' +
                '<div class="d-flex align-items-center mb-50">' + '<div class="chart-ap-circle"></div>' + w?.config?.series[1]?.name + ": " + '<span class="chart-tooltip-bold-text">' + series[1][dataPointIndex].toFixed(2) + "%" + '</span>' + '</div>' +
                '<div class="mb-50">' + "Channel(s): " + '<span class="chart-tooltip-bold-text">' + extractChannels(w?.config?.extraData?.channels?.data[dataPointIndex]) + '</span>' + '</div>' +
                '</div>' +
                '</div>'
        }
    }

    return (
        <Chart options={options} series={series} type="area" width={props.width ?? "104%"} height={props.height} />
    );
};

export default AreaApexChartWrapper;