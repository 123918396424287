/***
 *
 * Controller class for user.
 * @file VenueInfraWidget.js
 * @description VenueInfraWidget component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, {
  Suspense,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import PropTypes from 'prop-types';
import "./VenueInfraWidget.scss";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Progress,
  Spinner,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GroupButton from "../GroupButton";
import {
  BUSINESS_VIEW,
  DEPLOYMENT_VIEW,
  INFRA_DELETE_OPTIONS,
  NETWORK_VIEW,
  SITE,
} from "../../utility/constants";
import SearchIconAddon from "../SearchIconAddon";
import lodash from "lodash-es";
import SidePanel from "../SidePanel";
import ColSelector from "../ColSelector";
import InfiniteScroll from "react-infinite-scroll-component";
import ColumnHeader from "../ColumnHeader";
import createRequest, { services } from "../../services";
import { ExpCell } from "../ExpTable";
import ApexChartsTrendLine from "../ApexChartsTrendLine";
import { CatchedWebError } from "../../configs";
import { CollapseIcon, ExpandIcon } from "../../assets/images/icons/Icons";
import {
  DowngradeModal,
  VenueIcon,
  statusIcons,
  versionColors,
} from "../../pages/Venue/VenueTree";
import { formatSingleNetworkCardData } from "../../pages/Dashboard/NewDashboard/BlazerUtil";
import FirmwarePopup from "../FirmwarePopup";
import LightBadge from "../LightBadge";
import { make_custom_toast } from "../../helpers/toasts";
import { UPTIME } from "../../pages/Infrastructure/Details";
import { createErrorContext } from "../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../pages/Tickets/TicketConstants";
import FilterButton from "../FilterButton";
import FilterSetter from "../FilterSetter";
import {
  CategoryFilter,
  ConnectedFilter,
  FWFilter,
  InfraTypeFilter,
  LocationFilter,
  OperationsFilter,
  OrgFilter,
  VenueStateFilter,
} from "../../pages/Filters/filters";
import FirmwareReschedule from "../FirmwareRescheduleVenue";
import APSVG from "../../pages/Infrastructure/InfraList/APSVG";
import SwitchSVG from "../../pages/Infrastructure/InfraList/SwitchSVG";
import { ipFormater, radioFormater, statusColor, statusText } from "../../pages/Infrastructure/InfraList";
import {
  ImageTooltip,
  InstallTooltip,
  LayoutTooltip,
  MAC_ADDRESS,
  ValidateTooltip,
} from "../../pages/Venue/Infrastructure";
import { cmpCol, getColumns, getViewType, setColumns, setViewType } from "../../utility/colSaver";
import OperationCounters from "../OperationCounters";
import { LeftBracket, RightBracket } from "../PendingTaskBreakdown";
import { columnObj, filterObj } from "./utils";
import MoveInfraModal from "../MoveInfraModal";
import { v4 as uuidv4 } from 'uuid';
import BulkActions from "../BulkActions";
import DeleteInfraModal from "../DeleteInfraModal";
import { identityActions } from "../../redux/slices";
import { EllipsedText } from "../OrgWidget";
import { ReactComponent as CsvIconGrey } from "../../assets/images/icons/xls_icon_grey.svg";
import { ReactComponent as CsvIcon } from "../../assets/images/icons/xls_icon.svg";
import { capitalizeSentence, capitalizeWord, downloadSpreadsheet, replaceParam } from "../../utility/Utils";
import { ReactComponent as List } from "../../assets/images/icons/List.svg";
import { ReactComponent as Success } from "../../assets/images/icons/Success.svg"

import PendingTasksSubHeading from "../PendingTasksSubHeading";
import LinkWrapper from "../LinkWrapper";
import { Tooltip } from "react-tooltip";
import { DEFAULT_ASSOCIATION } from "../../utility/tooltip-config-global";
import InfraStatusChangeModal from "../InfraStatusChangeModal";
import DynamicComponent from "../DynamicComponent";

export const deleteTypes = {
  VENUE: 1,
  INFRA: 2,
  INFRAS: 3
}

export const checkAllVenueTask = (venue) => {
  if (venue.initialized && venue.photos && venue.installed && venue.validated)
    return true
  return false
}


let timer;
const debounce = (callback) => {
  clearTimeout(timer);
  timer = setTimeout(callback, 800);
};

const VenueInfraContext = createContext(null);

const escapeRegExp = (str = "") =>
  str.replace(/([.?*+^$[\]\\(){}|-\s])/g, "\\$1");

export const Highlight = ({ search = "", children = "" }) => {
  const parsedSearch = new RegExp(`(${escapeRegExp(search.trim())})`, "i");
  const segments = String(children).split(parsedSearch);

  if (search) {
    return segments.map((segment, index) =>
      parsedSearch.test(segment) ? <mark key={index}>{segment}</mark> : segment
    );
  } else {
    return children;
  }
};

const apiColumn = {
  // "Proposal Count": "ProposalCount",
  // "Pending Tasks": "Tasks",
  "Release": "Firmware"
};

export const RowSecondLine = (venue) => {
  let returnStr = "";

  // if(infraFocus){
  //   if(venue?.switchCount > 0){
  //     returnStr+= `${venue?.switchCount} Switch${venue?.switchCount > 1 ? "es" : ""}`
  //   }
  //   if(venue?.apCount > 0){
  //     if(returnStr.length > 0)
  //       returnStr+=", "
  //     returnStr+= `${venue?.apCount} AP${venue?.apCount > 1 ? "s" : ""}`
  //   }
  // }

  if (venue?.tickets > 0) {
    returnStr += `${venue?.tickets} Ticket${venue?.tickets > 1 ? "s" : ""}`;
  }
  if (venue?.offlineAP > 0) {
    if (returnStr.length > 0) returnStr += ", ";
    returnStr += `<span class="text-danger">${venue?.offlineAP}</span>/${venue?.apCount
      } AP${venue?.offlineAP > 1 ? "s" : ""} Offline`;
  } else if (venue?.apCount > 0) {
    if (returnStr.length > 0) returnStr += ", ";
    returnStr += `${venue?.apCount} AP${venue?.apCount > 1 ? "s" : ""}`;
  }
  if (venue?.offlineSwitch > 0) {
    if (returnStr.length > 0) returnStr += ", ";
    returnStr += `<span class="text-danger">${venue?.offlineSwitch}</span>/${venue?.switchCount
      } Switch${venue?.offlineSwitch > 1 ? "es" : ""} Offline`;
  } else if (venue?.switchCount > 0) {
    if (returnStr.length > 0) returnStr += ", ";
    returnStr += `${venue?.switchCount} Switch${venue?.switchCount > 1 ? "es" : ""
      }`;
  }

  return <div dangerouslySetInnerHTML={{ __html: returnStr }}></div>;
};

const venueLink = (venue, activeOrg, permissions) => {
  let link = null;
  if (
    (venue.orgId == activeOrg.orgId
      ? permissions?.manageVenue?.view
      : permissions?.manageHierarchy?.view) &&
    !venue.restricted
  ) {
    link = `/organization/${venue.orgId}/venues/${venue.venueId}`
  }
  return link
}

const SubList = (props) => {
  const { subList, setOptions, level, setSubList } = props;

  const {
    search,
    cols,
    mainFilter,
    filterActive,
    setDeleteModal,
    setDowngrade,
    showVenueOptions,
    setDeletingVenue,
    hideStatusIcon,
    showInfraOptions,
    associationScrn,
    infraFocus,
    listView
  } = useContext(VenueInfraContext);
  const [enableDowngrade, setEnableDowngrade] = useState(true);

  const switchOrgs = useSelector((store) => store.rbac.data.orgs)?.map(
    (it) => it.orgId
  );
  const infraType = useSelector((store) => store.infraTypes.data);
  const activeOrg = useSelector((store) => store.activeOrg.data);
  const view = useSelector((store) => store.identity.meta.view);
  const permissions = useSelector((store) => store.rbac.permissions);

  const navigate = useNavigate();



  return (
    <>
      {subList.map((venue, index) => {
        return (
          <ExpRow
            key={venue.infraItemId ?? venue.venueId}
            leafNode={!venue.leafNode}
            orgId={venue.orgId}
            venueId={venue.venueId}
            level={level}
            venue={venue}
            parentLast={
              props.parentLast
                ? [...props.parentLast, index == subList.length - 1]
                : [index == subList.length - 1]
            }
            setOptions={setOptions}
            parentIds={props.parentIds ?? []}
            className={
              (venue.venueType == SITE && venue.infraItemId == null ? "site-bg " : "") +
              ((venue.highlighted != null ? !venue.highlighted : (search.length > 0 || mainFilter != "All" || filterActive) && listView != "flat")
                ? "opacity-50"
                : "")
            }
          >
            {listView === 'flat' && cols.Status ? (
              <ExpCell
                width="100px"
                showLines={false}
                last={index == subList.length - 1}
                parentLast={
                  props.parentLast
                    ? [...props.parentLast, index == subList.length - 1]
                    : []
                }
              >
                {venue.infraItemId ? (
                  <LightBadge
                    color={venue.infraItemId && !venue.enabled ? statusColor.disabled : statusColor[venue.status]}
                    notLighter={venue.infraItemId && !venue.enabled}
                    borderColor={venue.infraItemId && !venue.enabled ? statusColor.disabledBorder : null}
                  >
                    <div className="text-dark">{venue.infraItemId && !venue.enabled ? statusText.disabled : statusText[venue.status]}</div>
                  </LightBadge>
                ) : (
                  <></>
                )}
              </ExpCell>
            ) : null}
            <ExpCell
              width="500px"
              showLines={level > 1}
              last={index == subList.length - 1}
              parentLast={
                props.parentLast
                  ? [...props.parentLast, index == subList.length - 1]
                  : []
              }
            >
              {!!venue.infraItemId ? (
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    {venue?.infraCategory === 1 ? (
                      <>
                        <Suspense fallback={<></>}>
                          <APSVG width="24" height="24" />
                        </Suspense>
                      </>
                    ) : (
                      <>
                        <Suspense fallback={<></>}>
                          <SwitchSVG width="24" height="24" />
                        </Suspense>
                      </>
                    )}
                    <div className="ml-50">
                      <LinkWrapper
                        to={
                          `/organization/${activeOrg.orgId}/infra/${venue.infraItemId}/`
                        }
                      >
                        <Highlight search={search}>
                          {venue.infraName}
                        </Highlight>
                      </LinkWrapper>
                    </div>
                  </div>

                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: "0.75rem" }}
                  >
                    <div className="d-flex align-items-center">
                      <Highlight search={search}>{venue.type}</Highlight>
                    </div>
                    <div className="d-flex align-items-center">
                      &nbsp;
                      <div
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          height: "5px",
                          width: "5px",
                          content: " ",
                          borderRadius: "50%",
                          backgroundColor: "black",
                        }}
                      ></div>
                      {
                        infraType.find(
                          (it) => it.infraTypeId == venue.infraTypeId
                        )?.infraType
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex py-50"
                  >
                    {!venue.permissionHide && (
                      <div className="d-flex align-items-center ">
                        {view != BUSINESS_VIEW && !hideStatusIcon && (
                          statusIcons(venue.zone)
                        )}
                        <span>{VenueIcon[venue.venueType]}</span>
                      </div>
                    )}
                    <div
                      className={
                        "d-flex align-items-center " +
                        (view != BUSINESS_VIEW ? "ml-50" : "")
                      }
                    >
                      <div>
                        <div className="d-flex">
                          <div
                            className={`d-flex align-items-center`}
                          >
                            <LinkWrapper
                              to={venueLink(venue, activeOrg, permissions)}
                            >
                              <Highlight search={search}>
                                {venue.venueName}
                              </Highlight>
                            </LinkWrapper>
                          </div>
                          {infraFocus && (
                            <span className="online-infra-counter ml-50">
                              <LeftBracket />
                              <span
                                className={`online-infra-count normal-size text-${venue?.onlineInfras > 0 ? "success font-weight--600" : "dark"
                                  }`}
                              >
                                {venue?.onlineInfras}
                              </span>
                              <span className="normal-size">/</span>{venue?.totalInfras}
                              <RightBracket />
                            </span>
                          )}
                        </div>
                        {view != BUSINESS_VIEW && !venue.restricted && (
                          <div
                            className="font-weight-bold"
                            style={{
                              marginTop: "0.2rem",
                              minHeight: "0.5rem",
                              fontSize: "0.80rem",
                            }}
                          >
                            {(infraFocus && view == DEPLOYMENT_VIEW) ? (
                              (venue?.totalTasks ?? 0) > 0 ? (
                                <PendingTasksSubHeading
                                  pendingTasks={venue?.pendingTasks}
                                  totalTasks={venue?.totalTasks}
                                />
                              ) : "No Tasks"
                            ) : RowSecondLine(venue)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </ExpCell>
            {listView === 'tree' &&
              <ExpCell hide={venue.restricted} width="60px">
                {venue.infraItemId == null ? (
                  <div>
                    <OperationCounters
                      id={venue.venueId}
                      zone={venue.zone}
                      alarmsData={{ show: (venue.optAlarmCount ?? 0) > 0, total: venue.optAlarmCount }}
                      ticketsData={{ show: (venue.optTicketCount ?? 0) > 0, total: venue.optTicketCount }}
                      fwScheduleData={
                        {
                          show: (venue.optScheduleCount ?? 0) > 0,
                          total: venue.optScheduleCount,
                          venueScheduleId: venue.scheduleId,
                          venueSnoozedDate: venue.scheduleSnoozed,
                          venueScheduleDate: venue.scheduleDate,
                          venueTimezone: venue.venueTimezone,
                          subVenueSchedulesCount: venue.subVenueSchedulesCount,
                          subVenueSnoozedDate: venue.subVenueSnoozedDate,
                          subVenueScheduleTimezone: venue.subVenueScheduleTimezone
                        }
                      }
                      venueId={venue.venueId}
                      venueName={venue.venueName}
                      venueType={venue.venueType}
                      enableFwClick={permissions?.manageFirmware?.update &&
                        venue?.scheduleId != null &&
                        venue?.venueId != null &&
                        venue?.scheduleDate != null &&
                        venue?.scheduleSnoozed != null &&
                        venue?.venueTimezone != null
                      }
                      fwClick={() => {
                        setOptions({
                          scheduleId: venue?.scheduleId,
                          venueId: venue?.venueId,
                          scheduleDate: venue?.scheduleDate,
                          scheduleSnoozed: venue?.scheduleSnoozed,
                          timezone: venue?.venueTimezone,
                          setSubList: setSubList,
                        });
                      }}
                      setVenues={setSubList}
                      small={true}
                    />
                  </div>
                ) : null}
              </ExpCell>}
            {listView === 'tree' && cols.Status && (
              <ExpCell width="100px">
                {venue.infraItemId ? (
                  <LightBadge
                    color={venue.infraItemId && !venue.enabled ? statusColor.disabled : statusColor[venue.status]}
                    notLighter={venue.infraItemId && !venue.enabled}
                    borderColor={venue.infraItemId && !venue.enabled ? statusColor.disabledBorder : null}
                  >
                    <div className="text-dark">{venue.infraItemId && !venue.enabled ? statusText.disabled : statusText[venue.status]}</div>
                  </LightBadge>
                ) : (
                  <></>
                )}
              </ExpCell>
            )}
            {cols.Venue ? (
              <ExpCell level={level} width="250px">
                {venue.venueName == null || venue.infraItemId == null ? null : (
                  <LinkWrapper
                    to={
                      permissions?.manageHierarchy?.view
                        ? `/organization/${venue.orgId}/venues/${venue.venueId}`
                        : null
                    }
                  >
                    <div
                      className={
                        "d-flex align-items-center "}
                    >
                      {VenueIcon[venue.venueType]}
                      {venue.venueName}
                    </div>
                  </LinkWrapper>
                )}
              </ExpCell>
            ) : null}
            {cols.Tasks && (
              <ExpCell width="200px">
                {venue.infraItemId && (
                  <div className={"d-flex align-items-center notes-div "} >
                    <div className={`d-flex pl-1 ${checkAllVenueTask(venue) ? "all-task-bg" : ""}`}  >
                      {
                        checkAllVenueTask(venue) &&
                        <Success className="all-task-done-check" width={16} height={16} />
                      }

                      <InstallTooltip
                        infraId={venue.infraItemId}
                        placed={venue.initialized}
                        firstConnectedAt={venue.firstConnectedAt}
                        allTaskDone={checkAllVenueTask(venue)}
                      />
                      <ImageTooltip
                        hover={venue.photos}
                        infraId={venue.infraItemId}
                        showColor={venue.photos}
                        allTaskDone={checkAllVenueTask(venue)}

                      />
                      <LayoutTooltip
                        infra={venue}
                        venueId={venue.venueId}
                        activeOrgId={activeOrg.orgId}
                        allTaskDone={checkAllVenueTask(venue)}

                      />
                      <ValidateTooltip
                        infraId={venue.infraItemId}
                        speedTestResult={venue.speedTestResult}
                        allTaskDone={checkAllVenueTask(venue)}

                      />
                    </div>
                  </div>
                )}
              </ExpCell>
            )}
            {cols.Room && (
              <ExpCell width="150px">
                <div className="ellipsed-text long-text-div" title={venue?.location ?? ""}>
                  <Highlight search={search}>{venue.room ?? ""}</Highlight>
                </div>
              </ExpCell>
            )}
            {cols.Location && (
              <ExpCell hide={venue.restricted} width="120px">
                <div className="ellipsed-text long-text-div" title={venue?.location ?? ""}>
                  <Highlight search={search}>{venue?.location ?? ""}</Highlight>
                </div>
              </ExpCell>
            )}
            {cols["MAC Address"] && (
              <ExpCell width="170px">
                {venue.macAddress
                  ? MAC_ADDRESS(venue.macAddress, true)
                  : ""}
              </ExpCell>
            )}
            {cols["Private IP"] && <ExpCell width="110px">
              {ipFormater(venue.privateIP?.split("/")[0] ?? "")}
            </ExpCell>}
            {cols["Public IP"] && <ExpCell width="110px">
              {venue.publicIP ?? ""}
            </ExpCell>}
            {cols["AP Profile"] && <ExpCell width="150px">
              <LinkWrapper
                to={`/organization/${activeOrg.orgId}/profiles/${venue.apProfileId}?infraType=${venue.infraTypeId}`}
              >
                {venue.apProfile ?? ""}
              </LinkWrapper>
            </ExpCell>}
            {cols["Switch Profile"] && <ExpCell width="150px">
              <LinkWrapper
                to={`/organization/${activeOrg.orgId}/profiles/${venue.switchProfileId}?infraType=${venue.infraTypeId}`}
              >
                {venue.switchProfile ?? ""}
              </LinkWrapper>
            </ExpCell>}
            {cols["RF Profile"] && <ExpCell width="150px">
              <LinkWrapper
                to={`/organization/${activeOrg.orgId}/profiles/${venue.rfProfileId}?infraType=${venue.infraTypeId}`}
              >
                {venue.rfProfile ?? ""}
              </LinkWrapper>
            </ExpCell>}
            {cols["Radio 2G"] && <ExpCell width="210px">
              {venue.radio2G ? radioFormater(venue.radio2G) : ""}
            </ExpCell>}
            {cols["Radio 5G"] && <ExpCell width="210px">
              {venue.radio5G ? radioFormater(venue.radio5G) : ""}
            </ExpCell>}
            {cols["Radio 6G"] && <ExpCell width="210px">
              {venue.radio6G ? radioFormater(venue.radio6G) : ""}
            </ExpCell>}
            {cols.Traffic && (
              <ExpCell hide={venue.restricted} width="120px">
                {(associationScrn ? (venue.infraItemId != null && venue.status != "demo") : (venue.infraItemId == null || venue.status != "demo")) && <ApexChartsTrendLine
                  color={["#B347E6", "#F5A742"]}
                  seriesTitles={["uplink", "downlink"]}
                  series={
                    formatSingleNetworkCardData({
                      uplink: venue?.uplink,
                      downlink: venue?.downlink,
                    }, true)}
                />}
              </ExpCell>
            )}
            {cols.Clients && (
              <ExpCell hide={venue.restricted} width="120px">
                {(associationScrn ? (venue.infraItemId != null && venue.status != "demo") : (venue.infraItemId == null || venue.status != "demo")) &&  <ApexChartsTrendLine
                  color={["#5279CE"]}
                  seriesTitles={["client"]}
                  series={venue?.clients?.map((it) => { return { client: Number(it.device_count) ?? 0 } })}
                />}
              </ExpCell>
            )}
            {cols["UpTime"] && (
              <ExpCell hide={venue.restricted} width="120px">
                <span id={`uptimeValue${venue.infraItemId}`}>
                  {venue.infraItemId
                    ? venue.status == "connected"
                      ? UPTIME(venue?.uptimeReported, true, false, true)
                      : "-"
                    : ""}
                </span>
                <UncontrolledTooltip
                  target={`#uptimeValue${venue.infraItemId}`}
                >
                  {venue.infraItemId
                    ? venue.status == "connected"
                      ? UPTIME(venue?.uptimeReported, false, false)
                      : "-"
                    : ""}
                </UncontrolledTooltip>
              </ExpCell>
            )}
            {cols.Release && (
              <ExpCell hide={venue.restricted} className="cursor-pointer" width="100px">
                {((infraFocus && venue.infraItemId != null) || !infraFocus) ?
                  <FirmwarePopup
                    tooltipId={venue.venueId}
                    data={venue?.firmwareCount ?? {}}
                  >
                    <LightBadge
                      color={versionColors[venue?.firmwareStatus] ?? "secondary"}
                    >
                      {venue?.firmwareVersion?.replace("Unknown", ``) ?? ""}
                    </LightBadge>
                  </FirmwarePopup>
                  : null}
              </ExpCell>
            )}
            {cols.Operations && (
              <ExpCell level={level} width="150px">
                {(venue.infraItemId != null) ? (
                  <Suspense fallback={<></>}>
                    <OperationCounters
                      id={venue.infraItemId}
                      zone={venue.zone}
                      alarmsData={{
                        show: (venue.optAlarmCount ?? 0) > 0,
                        total: venue.optAlarmCount,
                      }}
                      ticketsData={{
                        show: (venue.optTicketCount ?? 0) > 0,
                        total: venue.optTicketCount,
                      }}
                      fwScheduleData={{
                        show: (venue.optScheduleCount ?? 0) > 0,
                        total: venue.optScheduleCount,
                        venueScheduleId: venue.scheduleId,
                        venueSnoozedDate: venue.scheduleSnoozed,
                        venueScheduleDate: venue.scheduleDate,
                        venueTimezone: venue.venueTimezone,
                        subVenueSchedulesCount: venue.subVenueSchedulesCount,
                        subVenueSnoozedDate: venue.subVenueSnoozedDate,
                        subVenueScheduleTimezone: venue.subVenueScheduleTimezone,
                        infraScheduleSnoozed: venue.scheduleSnoozed,
                      }}
                      isInfra={venue.infraItemId != null}
                      infraName={venue.infraName}
                      infraItemId={venue.infraItemId}
                      orgId={venue.orgId}
                      venueId={venue.venueId}
                      venueName={venue.venueName}
                      venueType={venue.venueType}
                      enableFwClick={
                        venue?.canReschedule &&
                        venue?.firmwareStatus == "Update-available"
                      }
                      fwClick={() => {
                        if (venue.infraItemId != null) {
                          setOptions({
                            scheduleId: venue?.scheduleId,
                            venueId: venue?.venueId,
                            infraItemId: venue?.infraItemId,
                            scheduleDate: venue?.scheduleDate,
                            scheduleSnoozed: venue?.scheduleSnoozed,
                            timezone: venue?.venueTimezone,
                            rescheduleInfra: true,
                            setSubList: setSubList
                          });
                        }
                        else {
                          setOptions({
                            scheduleId: venue?.scheduleId,
                            venueId: venue?.venueId,
                            scheduleDate: venue?.scheduleDate,
                            scheduleSnoozed: venue?.scheduleSnoozed,
                            timezone: venue?.venueTimezone,
                            setSubList: setSubList,
                          });
                        }
                      }}
                      setInfras={setSubList}
                      setVenues={setSubList}
                    />
                  </Suspense>
                ) : null}
              </ExpCell>
            )}
            {cols["Serial Number"] ? (
              <ExpCell width="170px">
                {venue?.serialNumber?.length > 0 ?
                  <EllipsedText
                    text={venue.serialNumber}
                  /> : ''}
              </ExpCell>
            ) : null}
            {cols["Asset Tag"] ? (
              <ExpCell level={level} width="170px">
                {venue?.assetTag?.length > 0 ?
                  <EllipsedText text={venue?.assetTag} /> : ''}
              </ExpCell>
            ) : null}
            <ExpCell hide={venue.restricted} className="justify-content-end">
              <UncontrolledDropdown
                className={!showVenueOptions ? "d-none " : ""}
                direction="down"
                disabled={
                  !(
                    switchOrgs.includes(venue.orgId) ||
                    permissions.manageVenue.update ||
                    permissions?.manageFirmware?.create
                  )
                }
              >
                <DropdownToggle color="white" className="w-0 p-0">
                  <span
                    className={
                      "material-symbols-outlined cursor-pointer " +
                      (!(
                        switchOrgs.includes(venue.orgId) ||
                        permissions.manageVenue.update ||
                        permissions?.manageFirmware?.create
                      )
                        ? "text-secondary"
                        : "text-primary")
                    }
                    title="Open Dashboard"
                  >
                    more_vert
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="w-100"
                    onClick={() => {
                      if (venue.orgId == activeOrg.orgId) {
                        if (permissions?.manageVenue?.update)
                          navigate(
                            `/organization/${venue.orgId}/venues/${venue.venueId}/`
                          );
                      } else if (permissions?.manageHierarchy?.update)
                        navigate(
                          `/organization/${venue.orgId}/venues/${venue.venueId}/`
                        );
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className="w-100"
                    onClick={() => {
                      setDeleteModal({
                        type: deleteTypes.INFRA,
                        infraIds: new Set([venue.infraItemId])
                      })
                    }}
                  >
                    Delete
                  </DropdownItem>
                  {permissions?.manageFirmware?.update &&
                    !!venue?.firmwareVersion &&
                    venue?.firmwareVersion !== venue?.previousVersion &&
                    enableDowngrade && (
                      <DropdownItem
                        className="cursor-pointer w-100"
                        disabled={!venue?.previousVersion}
                        onClick={() =>
                          setDowngrade({
                            venue: venue,
                            downgrading: false,
                            onSuccess: () => setEnableDowngrade(false),
                          })
                        }
                      >
                        Revert Update
                      </DropdownItem>
                    )}
                  {permissions?.manageFirmware?.update &&
                    venue.firmwareStatus == "Update-available" && (
                      <DropdownItem
                        className="w-100"
                        onClick={() => {
                          setOptions({
                            scheduleId: venue?.scheduleId,
                            venueId: venue?.venueId,
                            scheduleDate: venue?.scheduleDate,
                            scheduleSnoozed: venue?.scheduleSnoozed,
                            timezone: venue?.venueTimezone,
                            setSubList: setSubList,
                          });
                        }}
                      >
                        Reschedule Update
                      </DropdownItem>
                    )}
                  {permissions?.manageVenue?.create && (
                    <DropdownItem
                      className="w-100"
                      onClick={() => {
                        navigate(
                          `/organization/${venue.orgId}/venues/${venue.venueId}/add/`
                        );
                      }}
                    >
                      Add Child
                    </DropdownItem>
                  )}
                  {/* {!venue.defaultVenue && permissions.manageVenue.delete && (
                    <DropdownItem
                      className="w-100"
                      onClick={() => {
                        setDeleteModal({
                          type: deleteTypes.VENUE,
                          infraItemId: venue.infraItemId
                        });
                        setDeletingVenue(venue.infraItemId)
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )} */}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown
                className={
                  !(showInfraOptions && venue.infraItemId) ? "d-none " : ""
                }
                direction="down"
                disabled={
                  !(
                    switchOrgs.includes(venue.orgId) ||
                    permissions.manageVenue.update
                  )
                }
              >
                <DropdownToggle color="white" className="w-0 p-0">
                  <span
                    className={
                      "material-symbols-outlined cursor-pointer " +
                      (!(
                        switchOrgs.includes(venue.orgId) ||
                        permissions.manageVenue.update
                      )
                        ? "text-secondary"
                        : "text-primary")
                    }
                    title="Open Dashboard"
                  >
                    more_vert
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="w-100"
                    onClick={() => {
                      if (venue.orgId == activeOrg.orgId) {
                        if (permissions?.manageVenue?.update)
                          navigate(
                            `/organization/${venue.orgId}/infra/${venue.infraItemId}/`
                          );
                      } else if (permissions?.manageHierarchy?.update)
                        navigate(
                          `/organization/${venue.orgId}/infra/${venue.infraItemId}/`
                        );
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className="w-100"
                    onClick={() => {
                      setDeleteModal({
                        type: deleteTypes.INFRA,
                        infraIds: new Set([venue.infraItemId])
                      })
                    }}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ExpCell>
          </ExpRow>
        );
      })}
    </>
  );
};

const ExpRow = (props) => {
  const view = useSelector((store) => store.identity.meta.view);
  const permissions = useSelector((store) => store.rbac.permissions);
  const venuePermissions = useSelector(
    (store) => store?.rbac?.venuePermissions
  );
  const checkbox = useRef(null);

  const {
    isSelector,
    selections,
    setSelections,
    cols,
    mainFilter,
    sort,
    deletingVenue,
    setDeletingVenue,
    subListClose,
    infraFocus,
    infraAPIs,
    search,
    associationScrn,
    treeStruct,
    setVenueSelection,
    filterActive,
    showAP,
    profileScrn,
    showSWITCH,
    disableCheck
  } = useContext(VenueInfraContext);

  const [expand, setExpand] = useState(props?.venue?.children?.length > 0);
  const [subList, setSubList] = useState(props?.venue?.children ?? []);
  const [hideExpand, setHideExpand] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [childIds, setChildIds] = useState(new Set(treeStruct?.venue[props.venueId]?.children));
  const [infraIds, setInfraIds] = useState(new Set(treeStruct?.venue[props.venueId]?.infrastructure))
  let level = props.level ?? 1;
  let subCount = 0;

  useEffect(() => {
    if (subListClose) {
      setExpand(false);
      if (subList.length == 1 && subList[0]?.venueId == deletingVenue)
        setHideExpand(true);
    } else setDeletingVenue(null);
  }, [subListClose]);

  const getList = (offset = 0) => {
    const columns = Object.keys(cols ?? {})
      .map((col) => (cols[col] ? (apiColumn[col] ?? col) : null))
      .filter((it) => it);

    const { controller, run } = createRequest(
      services.telemetry.GET_VENUE_CHILDREN,
      [
        props.venueId,
        offset,
        20,
        "",
        view,
        columns,
        "All",
        sort,
        associationScrn ? { infraCategory: (showAP ? "AP" : (showSWITCH ? "SWITCH" : "ALL")) } : {},
        (infraAPIs || associationScrn),
        ,
        associationScrn
      ]
    );
    // setSubListLoading(true);
    run()
      .then((response) => {
        // setSubListError(false);
        subCount += response.data.length
        if (offset == 0) {
          setSubList([...listFormatter(response.data, permissions, venuePermissions)]);
        } else {
          setSubList((prevState) => [...prevState, ...listFormatter(response.data, permissions, venuePermissions)]);
        }
        response.data.map(it => {
          if (it.infraItemId == null)
            setChildIds(prevState => { prevState.add(it.venueId); return prevState })
          else
            setInfraIds(prevState => { prevState.add(it.infraItemId); return prevState })
        })

        if (response.data.length == 20) getList(subCount);
        else setHasMore(false);

      })
      .catch((err) => {
        let x = new CatchedWebError(err);
        // setSubListError(x.message);
      })
      .finally(() => {
        // setSubListLoading(false);
      });

    return controller;
  };

  useEffect(() => {
    if (props?.venue?.children == null || props?.venue?.children?.length == 0) {
      if (expand) {
        let controller = getList(0);

        return () => {
          controller?.abort();
        };
      } else {
        setSubList([]);
        setHasMore(true);
        subCount = 0;
      }
    }
    else setHasMore(false);
  }, [expand, props.orgId, props.venueId]);

  useEffect(() => {
    setSubList(props?.venue?.children ?? []);
  }, [props?.venue?.children]);

  // useEffect(() => {
  //   setTimeout(() => console.log(props.venueId,"--->", props.parentIds, childIds), 1000);
  // }, [childIds])

  useEffect(() => {
    // console.log(props.venueId,"--->", selections[props.venueId])
    if (isSelector && checkbox.current) {
      //Checking if change in their state
      if (props.venue.infraItemId == null) {
        //If row is a venue
        if (selections.venues[props.venueId] == 2) {
          checkbox.current.checked = true;
          checkbox.current.indeterminate = false;
        }
        else if (selections.venues[props.venueId] == 1) {
          if (
            expand &&
            search.length == 0 &&
            !hasMore &&
            Array.from(childIds).every((it) => selections.venues[it] == 2) &&
            Array.from(infraIds).every((it) => selections.infras[it] == 2)
          ) {
            //check if it should move from partial to full
            setSelections((prevState) => {
              return {
                ...prevState,
                venues: { ...prevState.venues, [props.venueId]: 2 },
              };
            });
            setVenueSelection((prevState) => {
              let sel = { ...prevState };
              sel.venues.add(props.venueId);
              sel.venueDelete.delete(props.venueId);

              treeStruct.venue[props.venueId].infrastructure.map(infra => {
                if (profileScrn)
                  sel.infras.add(infra);
                else
                  sel.infras.delete(infra);
                sel.infraDelete.delete(infra);
              })

              return { ...sel }
            });
          } else if (
            expand &&
            search.length == 0 &&
            !hasMore &&
            Array.from(childIds).every((it) => selections.venues[it] == 0) &&
            Array.from(infraIds).every((it) => selections.infras[it] == 0)
          ) {
            setSelections((prevState) => {
              return {
                ...prevState,
                venues: { ...prevState.venues, [props.venueId]: 0 },
              };
            });
          }
          checkbox.current.indeterminate = true;
          checkbox.current.checked = false;
        } else {
          checkbox.current.indeterminate = false;
          checkbox.current.checked = false;
        }
      }
      else {
        if (selections.infras[props.venue.infraItemId] == 2) {
          checkbox.current.checked = true;
          checkbox.current.indeterminate = false;
        }
        else {
          checkbox.current.checked = false;
          checkbox.current.indeterminate = false;
        }
      }
    }
  }, [selections, hasMore, isSelector]);

  const rippleVal = (venueId, val) => {
    setSelections(prevState => {
      let sel = { ...prevState }
      treeStruct.venue[venueId].children.forEach((it) => {
        sel.venues[it] = val
      })
      treeStruct.venue[venueId].infrastructure.forEach(it => {
        sel.infras[it] = val;
      })
      return { ...sel };
    })

    if (val == 0) {
      setVenueSelection(prevState => {
        let sel = { ...prevState }
        treeStruct.venue[venueId].children.forEach(it => {
          // if(sel.venues.has(it))
          sel.venueDelete.add(it);
          sel.venues.delete(it);
        })
        treeStruct.venue[venueId].infrastructure.forEach(it => {
          if (profileScrn) {
            sel.infraDelete.add(it);
            sel.infras.delete(it)
          }
          else {
            sel.infras.delete(it);
            sel.infraDelete.add(it);
          }
        })
        return { ...sel }
      })
    }
    else {
      setVenueSelection(prevState => {
        let sel = { ...prevState }
        treeStruct.venue[venueId].children.forEach(it => {
          sel.venues.add(it);
          sel.venueDelete.delete(it);
        })
        treeStruct.venue[venueId].infrastructure.forEach(it => {
          if (profileScrn) {
            sel.infras.add(it);
            sel.infraDelete.delete(it);
          }
          else {
            sel.infraDelete.delete(it);
            sel.infras.delete(it);
          }
        })
        return { ...sel }
      })
    }

    treeStruct.venue[venueId].children.forEach(it => {
      rippleVal(it, val)
    })

  }


  //Handle check
  const handleCheck = (id, type, infraId) => {
    if (profileScrn)
      setVenueSelection((prevState) => {
        return { ...prevState, applyToOrg: false };
      });


    if (type == "venue") {
      setExpand(true);
      //If row is a venue
      if (selections.venues[id] == 2) {
        //If the venue was selected
        setSelections((prevState) => {
          return { ...prevState, venues: { ...prevState.venues, [id]: 0 } };
        });
        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          // if(sel.venues.has(id))
          sel.venueDelete.add(id);
          sel.venues.delete(id);
          return { ...sel };
        });
        if (props.parentIds.length > 0) {
          //Move parent to (partial) state
          props.parentIds.map((parent) => {
            if (selections.venues[parent] != 1)
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [parent]: 1 },
                };
              });
            //Remove parent and add infra
            setVenueSelection((prevState) => {
              let sel = { ...prevState };
              // if(sel.venues.has(parent))
              sel.venueDelete.add(parent);
              sel.venues.delete(parent);

              treeStruct.venue[parent].infrastructure.forEach((infra) => {
                if (selections.infras[infra] == 2) {
                  sel.infras.add(infra);
                  sel.infraDelete.delete(infra);
                }
              });

              return { ...sel }
            });

          });
        }
        //unselect the children
        //Unselect infras
        rippleVal(id, 0);
      } else if (selections.venues[id] == 1) {
        //If the venue was partially selected

        //Unselect the venue
        setSelections((prevState) => {
          return { ...prevState, venues: { ...prevState.venues, [id]: 0 } };
        });
        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          // if(sel.venues.has(id))
          sel.venueDelete.add(id);
          sel.venues.delete(id);
          return { ...sel };
        });
        //Unselect all children
        //Unselect Infras
        rippleVal(id, 0);
      } else {
        //If the venue was unselected -- select it
        setSelections((prevState) => {
          return { ...prevState, venues: { ...prevState.venues, [id]: 2 } };
        });
        if (props.parentIds.length > 0) {
          //Move parent to (partial) state
          props.parentIds.map((parent) => {
            if (selections.venues[parent] != 1)
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [parent]: 1 },
                };
              });
            //Remove parent and add infra
            setVenueSelection((prevState) => {
              let sel = { ...prevState };
              // if(sel.venues.has(parent))
              sel.venueDelete.add(parent);
              sel.venues.delete(parent);

              treeStruct.venue[parent].infrastructure.forEach((infra) => {
                if (selections.infras[infra] == 2) {
                  sel.infras.add(infra);
                  sel.infraDelete.delete(infra);
                }
              });

              return { ...sel }
            });
          });
        }
        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          sel.venues.add(id);
          sel.venueDelete.delete(id);
          return { ...sel };
        });
        //Select all its children
        //Select all infras
        rippleVal(id, 2);

      }
    } else {
      if (selections.infras[infraId] == 2) {
        //Make its parent partial
        if (props.parentIds.length > 0) {
          //Move parent to (partial) state
          props.parentIds.map((parent) => {
            if (selections.venues[parent] != 1)
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [parent]: 1 },
                };
              });
            //Remove parent and add infra
            setVenueSelection((prevState) => {
              let sel = { ...prevState };
              // if(sel.venues.has(parent))
              sel.venueDelete.add(parent);
              sel.venues.delete(parent);

              treeStruct.venue[parent].infrastructure.forEach((infra) => {
                if (selections.infras[infra] == 2 && infra != infraId) {
                  sel.infras.add(infra);
                  sel.infraDelete.delete(infra);
                }
              });

              return { ...sel }
            });
          });
        }

        //Unselect the infra
        setSelections((prevState) => {
          return {
            ...prevState,
            infras: { ...prevState.infras, [infraId]: 0 },
          };
        });

        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          if (sel.venues.has(id)) {
            sel.venueDelete.add(id);
            sel.venues.delete(id);
            treeStruct.venue[id].infrastructure.forEach((it) => {
              if (it != infraId) sel.infras.add(it);
            });
          } else {
            sel.infraDelete.add(infraId);
            sel.infras.delete(infraId);
          }

          return { ...sel };
        });
      } else {

        if (props.parentIds.length > 0) {
          //Move parent to (partial) state
          props.parentIds.map((parent) => {
            if (selections.venues[parent] != 1)
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [parent]: 1 },
                };
              });
            //Remove parent and add infra
            setVenueSelection((prevState) => {
              let sel = { ...prevState };
              // if(sel.venues.has(parent))
              sel.venueDelete.add(parent);
              sel.venues.delete(parent);

              treeStruct.venue[parent].infrastructure.forEach((infra) => {
                if (selections.infras[infra] == 2 && infra != infraId) {
                  sel.infras.add(infra);
                  sel.infraDelete.delete(infra);
                }
              });

              return { ...sel }
            });
          });
        }

        setSelections((prevState) => {
          return {
            ...prevState,
            infras: { ...prevState.infras, [infraId]: 2 },
          };
        });

        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          sel.infras.add(infraId);
          sel.infraDelete.delete(infraId);

          return { ...sel };
        });
      }
    }
  };

  const firstCell = props.children[0] ?? props.children[1];
  const newChildren = props?.children?.map((element) =>
    element ? React.cloneElement(element, { level: level }) : element
  );
  return (
    <>
      <div className={"ExpRow " + props.className}>
        <ExpCell
          {...firstCell.props}
          expand={expand}
          level={level}
          hasExpandIcon={props.leafNode}
        >
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-start position-relative">
              {props.expand && expand && (
                <div
                  className={`${firstCell.props.last || firstCell.props.level == undefined
                    ? "fixed-line"
                    : "fixed-line-inner"
                    }`}
                />
              )}
              {props.leafNode && !hideExpand && !props.venue.infraItemId ? (
                expand ? (
                  <CollapseIcon
                    style={{
                      marginRight: "4px",
                      minWidth: "2.2rem",
                      cursor: "pointer",
                    }}
                    width={14}
                    height={14}
                    onClick={() => setExpand(!expand)}
                  />
                ) : (
                  <ExpandIcon
                    style={{
                      marginRight: "4px",
                      minWidth: "2.2rem",
                      cursor: "pointer",
                    }}
                    width={14}
                    height={14}
                    onClick={() => setExpand(!expand)}
                  />
                )
              ) : props.venue.infraItemId != null ? null : (
                <div className="mr-1" style={{ minWidth: "1.5rem" }}>
                  &nbsp;
                </div>
              )}
              {isSelector &&
                ((props.venue.highlighted == null ? (search.length == 0 && mainFilter == "All" && !filterActive) : props.venue.highlighted)) && (
                  <input
                    className={`mr-50 ${disableCheck || props.venue.restricted ? "cursor-not-allowed" : "cursor-pointer"}`}
                    type="checkbox"
                    id={`checkbox-${props.venue.infraId ?? props.venue.venueId ?? props.venue.orgId}`}
                    ref={checkbox}
                    disabled={disableCheck || props.venue.restricted}
                    onClick={() => handleCheck(props.venueId, props.venue.infraItemId == null ? "venue" : "inrfa", props.venue.infraItemId)}
                  />
                )}
              {firstCell.props.children}
            </div>
          </div>

          {(profileScrn && disableCheck) ? <Tooltip
            anchorSelect={`#checkbox-${props.venue.infraId ?? props.venue.venueId ?? props.venue.orgId}`}
            variant="light"
            clickable
            place="bottom"
            border="solid 2px #EAEAEA"
            opacity={1}
            style={{ boxShadow: "2px 2px 15px #EAEAEA", zIndex: 1 }}
            delayShow={100}
          >
            {DEFAULT_ASSOCIATION}
          </Tooltip> : <></>}
        </ExpCell>
        {newChildren[0] == null
          ? newChildren.slice(2)
          : newChildren.slice(1)}
      </div>
      {expand && (
        <>
          <SubList
            {...props}
            subList={subList}
            setSubList={setSubList}
            level={level + 1}
            parentIds={[...(props.parentIds ?? []), props.venueId]}
          />
        </>
      )}
    </>
  );
};

const AllInput = (props) => {
  const {
    applyToOrg,
    disableCheck,
    profileScrn,
    setVenueSelection,
    setSelections,
    venueSelection,
    treeStruct
  } = props;

  const allCheckbox = useRef();

  useEffect(() => {
    if(applyToOrg && !!treeStruct){
      allCheckbox.current.checked = true;
      allCheckbox.current.indeterminate = false;
    }
    else if(venueSelection.venues.size>0 || venueSelection.infras.size>0) {
      allCheckbox.current.checked = false;
      allCheckbox.current.indeterminate = true;
    }
    else {
      allCheckbox.current.checked = false;
      allCheckbox.current.indeterminate = false;
    }
  },[venueSelection, applyToOrg])

  return (
    <span className="mr-1" style={{ paddingTop: "0.25rem" }}>
      <input type="checkbox" id="checkbox-all" ref={allCheckbox} disabled={disableCheck} onClick={() => {
        if (profileScrn)
          setVenueSelection((prevState) => {
            return { ...prevState, applyToOrg: !applyToOrg };
          });
        setSelections(prevState => {
          let sel = { ...prevState }
          Object.keys(sel.venues).map(venue => {
            sel.venues[venue] = applyToOrg ? 0 : 2
          })
          Object.keys(sel.infras).map(infra => {
            sel.infras[infra] = applyToOrg ? 0 : 2
          })
          return { ...sel }
        })
        setVenueSelection(prevState => {
          let sel = { ...prevState }
          if (applyToOrg) {
            sel.venues.clear();
            sel.infras.clear();
            Object.keys(treeStruct.venue).map(it => sel.venueDelete.add(Number(it)));
            if(profileScrn)
              Object.keys(treeStruct.infrastructure).map(it => sel.infraDelete.add(Number(it)));
            else
              sel.infraDelete.clear();
          }
          else {
            sel.infras.clear();
            sel.infraDelete.clear();
            sel.venueDelete.clear();
            Object.keys(treeStruct.venue).map(it => sel.venues.add(Number(it)));
          }

          return { ...sel }
        })
        // setApplyToOrg(prevState => !prevState);
      }
      }
        //for suppressing react controlled input warning
        onChange={() => { }}
        className={`${disableCheck ? "cursor-not-allowed" : "cursor-pointer"}`}
      />

      {(profileScrn && disableCheck) ? <Tooltip
        anchorSelect={`#checkbox-all`}
        variant="light"
        clickable
        place="bottom"
        border="solid 2px #EAEAEA"
        opacity={1}
        style={{ boxShadow: "2px 2px 15px #EAEAEA", zIndex: 1, fontSize: "0.85rem", fontWeight:"400", textTransform: "none" }}
        delayShow={100}
      >
        {DEFAULT_ASSOCIATION}
      </Tooltip> : <></>}

    </span>
  )
}

const listFormatter = (data, permissions = null, venuePermissions = null) => {
  let formattedData = data?.map(item => {
    let newItem = { ...item };
    if (item.infraItemId != null && permissions != null) {
      let venueId = item.venueId
      let dropdownEnabled = false,
        canEdit = permissions?.manageInfra?.update,
        canRetry = permissions?.manageFirmware?.update,
        canDelete = permissions?.manageInfra?.delete;
      let canReboot = canEdit;
      let canReschedule = canRetry;
      if (!!venueId) {
        let permi = venuePermissions[venueId];
        if (!!permi) {
          canEdit = permi?.infra?.update;
          canReboot = canEdit;
          canDelete = permi?.infra?.delete;
        }
      }
      if (canEdit || canRetry || canDelete) dropdownEnabled = true;
      newItem = {
        ...newItem,
        dropdownEnabled: dropdownEnabled,
        canEdit: canEdit,
        canRetry: canRetry,
        canDelete: canDelete,
        canReboot: canReboot,
        canReschedule: canReschedule,
      };

    }
    if (newItem.children?.length > 0) {
      return { ...newItem, children: listFormatter(item.children, permissions, venuePermissions) };
    } else {
      return newItem;
    }
  })
  return formattedData;
}

const VenueInfraWidget = (props) => {
  const {
    initialColumn,
    startLevel,
    isSelectorDefault = false,
    noAllSelection = false,
    addVenueButton,
    hideStatusIcon,
    SCREEN_ID,
    showVenueOptions,
    filterReq,
    infraFocus,
    showInfraOptions,
    associationScrn,
    profileScrn,
    selections,
    setSelections,
    venueSelection,
    setVenueSelection,
    treeStruct,
    initFilter,
    showAP,
    showSWITCH,
    disableCheck
  } = props;
  const activeOrg = useSelector((store) => store.activeOrg.data);
  const permissions = useSelector((store) => store.rbac.permissions);
  const venuePermissions = useSelector(
    (store) => store?.rbac?.venuePermissions
  );
  const view = useSelector((store) => store.identity.meta.view);
  const importNotification = useSelector(store => store.identity.meta.importNotification);
  const enableNotification = useSelector(store => store.identity.meta.enableNotification);
  const disableNotification = useSelector(store => store.identity.meta.disableNotification);
  const moveNotification = useSelector(store => store.identity.meta.moveNotification);
  const deleteNotification = useSelector(store => store.identity.meta.deleteNotification);
  const identity = useSelector(store => store.identity);
  const activeVenue = useSelector(store => store.activeVenue.data);

  const [params, setParams] = useSearchParams();

  const initMount = useRef(true);
  // const [selections, setSelections] = useState({venues:{ }, infras:{ }});

  const [deleteOption, setDeleteOption] = useState(INFRA_DELETE_OPTIONS[0].value)
  const [venues, setVenues] = useState([]);
  const [applyToOrg, setApplyToOrg] = useState(false);
  const [venueLoading, setVenueLoading] = useState(false);
  const [search, setSearch] = useState(params.get("search") ?? "");
  const [hasMore, setHasMore] = useState(true);
  const [deletingVenue, setDeletingVenue] = useState(null);
  const [listView, setListView] = useState(getViewType(SCREEN_ID) ?? "tree");
  const filteredColumns = (columns) => {
    let filteredCols = { ...columns }
    if (listView === "tree") {
      delete filteredCols["Venue"];
      return filteredCols;
    }
    return columns;
  };
  const [cols, setCols] = useState(
    cmpCol(
      filteredColumns(columnObj[SCREEN_ID][view]),
      getColumns(SCREEN_ID, view)
    )
  );
  const [sort, setSort] = useState({
    orderBy: "Name",
    order: "ASC"
  });
  const [downgrade, setDowngrade] = useState();
  const [mainFilter, setMainFilter] = useState(params.get("quickFilter") ?? "All");
  const [filterCount, setFilterCount] = useState({});
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);

  const [deleteModal, setDeleteModal] = useState();
  const [subListClose, setSubListClose] = useState(false);
  const [deletionLoading, setDeletionLoading] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [filterInitial, setFilterInitial] = useState(() => {
    const filterTemp = {
      OrgFilter: "orgs",
      OperationsFilter: "operations",
      LocationFilter: "location",
      VenueStateFilter: "state",
      FWFilter: "firmwareVersion",
      CategoryFilter: "infraCategory",
      InfraTypeFilter: "infraType",
      StatusFilter: "status",
    };
    let initialFilter = initFilter ?? {}
    Object.keys(filterReq ?? {}).map((filter) => {
      if (filterReq[filter]) {
        if (filter == "CategoryFilter") {
          initialFilter[filterTemp[filter]] = "ALL"
        } else {
          initialFilter[filterTemp[filter]] = []
        }
      }
    }
    )
    return initialFilter
  })
  const [filterData, setFilterData] = useState(() => {
    let filterParam = params.get('filter');
    if (filterParam != null) {
      let parsedFilter = JSON.parse(filterParam)
      return { ...filterInitial, ...parsedFilter }
    } else {
      return filterInitial
    }
  });
  const [filterActive, setFilterActive] = useState(() => {
    return !lodash.isEqual(filterInitial, filterData)
  });
  const [filterSelection, setFilterSelection] = useState(filterData);
  const [isSelector, setIsSelector] = useState(isSelectorDefault)
  const [selectedVenue, setSelectedVenue] = useState(null)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)
  const [options, setOptions] = useState();
  const [downloadingList, setDownloadingList] = useState(false)
  const [loading, setLoading] = useState(false);
  const [exportItemHovered, setExportItemHovered] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let newParams = new URLSearchParams(window.location.search);
    newParams = Object.fromEntries(newParams);
    newParams.filter = JSON.stringify(filterData)
    setParams(newParams, { replace: true })
  }, [filterData])

  useEffect(() => {
    replaceParam(setParams, 'quickFilter', mainFilter, { replace: true });
  }, [mainFilter]);

  useEffect(() => {
    if (isSelector)
      setApplyToOrg(venueSelection?.venues?.size == Object.keys(treeStruct?.venue ?? {}).length)
    // else
    //   setApplyToOrg(venueSelection.applyToOrg);
  }, [venueSelection, treeStruct])

  useEffect(() => {
    if (!isSelector) {
      setSelections({ infras: {}, venues: {} })
      setVenueSelection({
        venues: new Set(),
        infras: new Set(),
        venueDelete: new Set(),
        infraDelete: new Set()
      })
      setSelectedVenue(null)
    }
  }, [isSelector])

  const fetchVenue = useCallback((offset, mainFilter, filterData, newCols = cols, newListView = listView) => {
    if (offset === 0) setVenues([]);
    const columns = Object.keys(newCols ?? {})
      .map((col) => (newCols[col] ? (apiColumn[col] ?? col) : null))
      .filter((it) => it);
    setVenueLoading(true);
    if (!props.infraAPIs) {
      const { controller, run } = createRequest(
        services.telemetry.GET_INFRA_HIERARCHY_LIST,
        [
          activeOrg.orgId,
          offset,
          20,
          columns,
          view,
          mainFilter,
          sort,
          encodeURIComponent(search),
          { ...filterData, orgId: filterData.orgs?.map(org => org.orgId) },
          'Tree',
          associationScrn,
          undefined,
          capitalizeWord(newListView)
        ]
      );
      run()
        .then((response) => {
          if (response.data.length < 20) {
            setHasMore(false);
          }
          if (offset == 0) setVenues(listFormatter(response.data, permissions, venuePermissions));
          else setVenues((prevState) => [...prevState, ...listFormatter(response.data, permissions, venuePermissions)]);
        })
        .catch((err) => {
          let x = new CatchedWebError(err);
          make_custom_toast("error", "Venue", x.message);
          setHasMore(false);
        })
        .finally(() => {
          setVenueLoading(false);
        });
    } else {
      const { controller, run } = createRequest(
        services.telemetry.GET_VENUE_CHILDREN,
        [
          props.venueId,
          offset,
          20,
          encodeURIComponent(search),
          view,
          columns,
          mainFilter,
          sort,
          filterData,
          true,
          offset == 0,
          undefined,
          capitalizeWord(newListView)
        ]
      );
      run()
        .then((response) => {
          if (response.data.length == 0 || response?.data[0]?.children?.length < 20) {
            setHasMore(false);
          }
          if (offset == 0) setVenues(listFormatter(response.data, permissions, venuePermissions));
          else setVenues((prevState) => [...prevState, ...listFormatter(response.data, permissions, venuePermissions)]);
        })
        .catch((err) => {
          let x = new CatchedWebError(err);
          make_custom_toast("error", "Venue", x.message);
          setHasMore(false);
        })
        .finally(() => {
          setVenueLoading(false);
        });
    }

    // return controller;
  }, [activeOrg.orgId, associationScrn, cols, props.infraAPIs, props.venueId, search, sort, view]);

  const fetchCount = () => {
    const columns = Object.keys(cols ?? {})
      .map((col) => (cols[col] ? (apiColumn[col] ?? col) : null))
      .filter((it) => it);
    // (orgId, columns, viewType, search, filterData, forAssociation)
    if (associationScrn) {
      const { controller, run } = createRequest(
        services.telemetry.GET_INFRA_HIERARCHY_LIST_COUNT,
        [
          activeOrg.orgId,
          columns,
          view,
          encodeURIComponent(search),
          associationScrn ? { ...initFilter, orgs: [activeOrg.orgId] } : {},
          true
        ]
      );

      run().then((response) => {
        setFilterCount(response.data);
      });
    }
    else {
      const { controller, run } = createRequest(
        services.telemetry.GET_VENUE_CHILDREN_COUNT,
        [
          props.venueId,
          columns,
          encodeURIComponent(search),
          view,
          associationScrn ? { ...initFilter, orgs: [activeOrg.orgId] } : {},
        ]
      );

      run().then((response) => {
        setFilterCount(response.data);
      });
    }

  };

  const deleteInfra = useCallback(
    (deleteInfraId) => {
      const UUID = uuidv4();
      const { context, run } = deleteInfraId?.length > 1 ?
        createRequest(services.infra.BULK_DELETE, [
          activeOrg.orgId,
          UUID,
          deleteOption == 2
        ],
          deleteInfraId
        )
        : createRequest(services.infra.DELETE, [
          deleteInfraId,
          deleteOption == 2
        ])

      setDeletionLoading(true);
      run()
        .then((response) => {
          if (deleteInfraId?.length > 1)
            dispatch(identityActions.setDeleteUUID(UUID))
          else {
            fetchVenue(0, mainFilter, filterData)
            fetchCount()
          }
          setVenues((currList) =>
            currList?.filter((item) => item.infraItemId != deleteInfraId)
          );
          setSubListClose(true);
          setDeleteModal(null);
          setIsSelector(false);
          make_custom_toast(
            'success',
            'Delete Infrastructure',
            'Deleting Infrastructure'
          )
        })
        .catch((err) => {
          let x = new CatchedWebError(err);
          const apiContext = createErrorContext(
            context,
            "Deleting Infrastructure",
            TICKET_CATEGORY.NETWORK,
            err
          );
          // setDeletionError(x.message);
          make_custom_toast(
            "error",
            "Venue",
            x.message,
            true,
            "Create Ticket",
            () => {
              navigate(
                `/organization/${activeOrg.orgId}/support/createticket/${apiContext.action}/${apiContext.category}`,
                {
                  state: {
                    ticketContext: apiContext,
                  },
                }
              );
            }
          );
        })
        .finally(() => {
          setDeletionLoading(false);
          setDeleteModal(null);
          // setTimeout(() => setSubListClose(false), 1000);
        });
    },
    [navigate, activeOrg, filterData, mainFilter, fetchVenue]
  );

  const handleDownload = () => {
    const allowedColumns = [
      "Status",
      "Operations",
      "Organization-Venue",
      "Private IP",
      "Public IP",
      "AP Profile",
      "Switch Profile",
      "RF Profile",
      "Radio 2G",
      "Radio 5G",
      "Radio 6G",
      "Tasks",
      "Room",
      "Location",
      "Traffic",
      "Clients",
      "UpTime",
      "Firmware",
      "MAC Address",
      "Serial Number",
      "Asset Tag",
      // "Speedtest",
    ];
    const formattedColumns = Object.keys(cols ?? {})
      .map((col) => (cols[col] ? (apiColumn[col] ?? col) : null))
      .filter((it) => it);
    const filteredColumns = allowedColumns.filter(col => formattedColumns.includes(col))
    const apiURL = services.telemetry.DOWNLOAD_VENUE_INFRA_LIST.urlBuilder(
      ...[
        activeVenue?.venueId,
        'Tree',
        view,
        filteredColumns,
        mainFilter,
        sort,
        search,
        filterData
      ]
    )
    const fileName = `Venue Infrastructure List${!!mainFilter && !filterActive ? ('-(' + capitalizeSentence(mainFilter) + ')') : ''}${activeVenue?.venueId != null ? ('-(' + activeVenue?.venueId + ')') : ''}${activeOrg?.orgId != null ? ('-(' + activeOrg.orgId + ')') : ''}.xlsx`
    setDownloadingList(true)
    downloadSpreadsheet(apiURL, fileName, identity.meta.token)
      .catch(err => {
        make_custom_toast('error', `Export Venue Infrastructure List`, (new CatchedWebError(err)).message)
      })
      .finally(() => {
        setDownloadingList(false)
      })
  }

  useEffect(() => {
    let actionCompleted = importNotification?.bulkActionStatus == 'Completed' ||
      enableNotification?.bulkActionStatus == 'Completed' ||
      disableNotification?.bulkActionStatus == 'Completed' ||
      moveNotification?.bulkActionStatus == 'Completed' ||
      deleteNotification?.bulkActionStatus == 'Completed'
    if (actionCompleted) {
      fetchVenue(0, mainFilter, filterData)
      fetchCount()
    }
  }, [importNotification, enableNotification, disableNotification, moveNotification, deleteNotification])


  useEffect(() => {
    let controller;

    if (Object.keys(cols).length > 0) {
      setVenues([]);
      controller = fetchVenue(0, mainFilter, filterData);
      fetchCount(mainFilter, filterData);
      setHasMore(true);
    }

    return () => {
      controller?.abort();
    };
  }, [activeOrg.orgId, sort, search]);


  const onMainFilter = (filter) => {
    if (!venueLoading) {
      setVenues([]);
      setHasMore(true);
      setMainFilter(filter);
      fetchCount(filter, filterInitial);
      fetchVenue(0, filter, filterInitial);
      setFilterSelection(filterInitial);
      setFilterData(filterInitial);
      setFilterActive(false);
    }
  };

  // useEffect(() => {
  //   if (initMount.current) initMount.current = false;
  //   else if(initialColumn != null) {
  //     console.log("Changed--->", initialColumn)
  //     setCols(initialColumn);
  //   }
  // }, [initialColumn]);

  useEffect(() => {
    if (initMount.current) initMount.current = false;
    else {
      setMainFilter("All");
      let newCols = cmpCol(filteredColumns(columnObj[SCREEN_ID][view]), getColumns(SCREEN_ID, view))
      setCols(newCols)
      fetchVenue(0, "All", filterData, newCols)
    }
  }, [view]);

  return (
    <div className="VenueInfraWidget" data-testid="VenueInfraWidget">
      <InfraStatusChangeModal
        isOpen={isStatusModalOpen}
        setIsOpen={setIsStatusModalOpen}
        infraIds={venueSelection?.infras}
        disabled={false}
        orgId={activeOrg.orgId}
        onSuccess={() => {
          setIsSelector(false);
          setIsStatusModalOpen(false);
          setSelectedVenue(null);
        }}
      />
      <MoveInfraModal
        isOpen={isMoveModalOpen}
        setIsOpen={setIsMoveModalOpen}
        selectedVenue={selectedVenue}
        setSelectedVenue={setSelectedVenue}
        infraIds={venueSelection?.infras}
        onSuccess={() => {
          setIsSelector(false);
          setIsMoveModalOpen(false);
          setSelectedVenue(null)
        }}
        onCancel={() => {
          // setIsSelector(false);
          // setVenueSelection({
          //   venues:new Set(),
          //   infras:new Set(),
          //   venueDelete:new Set(),
          //   infraDelete:new Set()
          // });
          setSelectedVenue(null)
          setIsMoveModalOpen(false);
        }}
        orgId={activeOrg.orgId}
      />
      <div>
        <DowngradeModal downgrade={downgrade} setDowngrade={setDowngrade} />
        {options != null && (
          <FirmwareReschedule
            options={options}
            setModal={setOptions}
            updateSnoozeDate={(snzDate, itemId, rescheduleInfra) => {
              if (rescheduleInfra) {
                if (options.setSubList) {
                  options.setSubList((prevState) => {
                    let index = prevState.findIndex((it) => it.infraItemId == itemId);
                    let newList = [...prevState];
                    if (index >= 0) newList[index].scheduleSnoozed = snzDate;
                    return [...newList];
                  });
                }
                else {
                  setVenues((prevState) => {
                    let index = prevState.findIndex((it) => it.infraItemId == itemId);
                    let newList = [...prevState];
                    if (index >= 0) newList[index].scheduleSnoozed = snzDate;
                    return [...newList];
                  });
                }
                return
              }
              if (options.setSubList) {
                options.setSubList((prevState) => {
                  let index = prevState.findIndex((it) => it.venueId == itemId);
                  let newList = [...prevState];
                  if (index >= 0) newList[index].scheduleSnoozed = snzDate;
                  return [...newList];
                });
              } else {
                setVenues((prevState) => {
                  let index = prevState.findIndex((it) => it.venueId == itemId);
                  let newList = [...prevState];
                  if (index >= 0) newList[index].scheduleSnoozed = snzDate;
                  return [...newList];
                });
              }
            }}
          />
        )}
        <FilterSetter
          // disabled={!Object.keys(filterSelection).length}
          showFilter={showFilter}
          elements={() => [
            <OrgFilter
              hide={!filterReq.OrgFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <OperationsFilter
              hide={!filterReq.OperationsFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <LocationFilter
              hide={!filterReq.LocationFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <VenueStateFilter
              hide={!filterReq.VenueStateFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <CategoryFilter
              hide={!filterReq.CategoryFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <InfraTypeFilter
              hide={!filterReq.InfraTypeFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <ConnectedFilter
              hide={!filterReq.StatusFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <FWFilter
              hide={!filterReq.FWFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
          ]}
          handleApplyClick={() => {
            setMainFilter("All");
            setHasMore(true);
            fetchVenue(0, "All", filterSelection);
            setVenues([]);
            fetchCount("All", filterInitial);
            setFilterData(filterSelection);
            if (lodash.isEqual(filterSelection, filterInitial))
              setFilterActive(false);
            else setFilterActive(true);
            setShowFilter(false);
          }}
          handleClearAll={() => {
            // setMainFilter('All');
            setHasMore(true);
            setFilterActive(false);
            setFilterSelection(filterInitial);
            setFilterData(filterInitial);
            fetchVenue(0, mainFilter, filterInitial);
            setVenues([]);
            fetchCount(mainFilter, filterInitial);
            setShowFilter(false);
          }}
          setShowFilter={setShowFilter}
        />
        <div className="d-flex justify-content-between mb-1">
          <div className="d-flex align-items-start">
            <InputGroup
              className="input-group-merge"
              style={{ width: "310px" }}
            >
              <Input
                autoFocus
                type="text"
                placeholder="Search"
                style={{ height: "2.4rem" }}
                defaultValue={search}
                onChange={(e) => {
                  if (
                    e.target.value.trim().length > 1 ||
                    e.target.value.trim().length === 0
                  ) {
                    debounce(() => {
                      setSearch(e.target.value);
                      setHasMore(true);
                    });
                  }
                  else {
                    debounce(() => {
                      setSearch("");
                      setHasMore(true);
                    });
                  }
                }}
              />
              <SearchIconAddon />
            </InputGroup>
            <div className="ml-1">
              <GroupButton>
                {filterObj[SCREEN_ID] &&
                  filterObj[SCREEN_ID][view].map((filter, key) => {
                    return (
                      <div
                        className={
                          "grp-btn-custom " +
                          (mainFilter == filter.filter && !filterActive
                            ? "active"
                            : "")
                        }
                        onClick={() => {
                          onMainFilter(filter.filter);
                        }}
                        key={`${key}-venue-infra`}
                      >
                        {filter.filter} ({filterCount[filter?.count] ?? 0})
                      </div>
                    );
                  })}
              </GroupButton>
            </div>
            <FilterButton
              className="ml-50"
              size={22}
              active={filterActive}
              onClick={() => setShowFilter(true)}
              style={{ padding: "0.36rem" }}
            />
          </div>
          <div className="d-flex align-items-center h-100">
            {!associationScrn ?
              <>
                <GroupButton className={`align-items-center ${isSelector ? 'mr-1' : ''}`}>
                  <div
                    className={
                      "grp-btn-custom grp-btn-icon-div d-flex align-items-center justify-content-center " +
                      (listView === "tree" ? "active " : "")
                    }
                    data-clickDisabled={isSelector && listView !== "tree"}
                    onClick={() => {
                      if (!loading && !isSelector) {
                        setListView("tree");
                        setCols((prevState) => {
                          if (
                            prevState["Venue"] != null
                          ) {
                            const newCols = { ...prevState };
                            delete newCols["Venue"];
                            fetchVenue(0, mainFilter, filterData, newCols, 'tree')
                            return newCols;
                          } else {
                            fetchVenue(0, mainFilter, filterData, cols, 'tree')
                          }
                          return prevState;
                        });
                        setViewType(SCREEN_ID, "tree");
                      }
                    }}
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "1.4rem" }}
                    >
                      account_tree
                    </span>
                    {/* tree */}
                  </div>
                  <div
                    className={
                      "grp-btn-custom grp-btn-icon-div d-flex align-items-center justify-content-center " +
                      (listView === "flat" ? "active" : "")
                    }
                    data-clickDisabled={isSelector && listView !== 'flat'}
                    onClick={() => {
                      if (!loading && !isSelector) {
                        setListView("flat");
                        setCols((prevState) => {
                          if (prevState["Venue"] == null) {
                            const newCols = { ...prevState };
                            let getColValue = getColumns(SCREEN_ID, view) ?? {}
                            newCols["Venue"] =
                              getColValue["Venue"] ??
                              columnObj[SCREEN_ID][view]["Venue"];
                            fetchVenue(0, mainFilter, filterData, newCols, 'flat')
                            return newCols;
                          }
                          return prevState;
                        });
                        setViewType(SCREEN_ID, "flat");
                      }
                    }}
                  >
                    <List height='1.4rem' width='1.4rem' className="list-icon" />
                    {/* <List height= '2.4rem' width='2.14rem' className="list-icon" /> */}
                    {/* flat */}
                  </div>
                </GroupButton>
                {isSelector ?
                  <BulkActions
                    selectionLength={venueSelection.infras.size}
                    onStatusClick={() => {
                      if (venueSelection.infras.size == 0)
                        return;
                      setIsStatusModalOpen(true);
                    }}
                    onMoveClick={() => {
                      if (venueSelection.infras.size == 0)
                        return
                      setIsMoveModalOpen(true)
                    }}
                    onDeleteClick={() => {
                      if (venueSelection.infras.size == 0)
                        return
                      setDeleteModal({
                        type: deleteTypes.INFRAS,
                        infraIds: venueSelection?.infras
                      })
                    }}
                    onCancel={() => {
                      setIsSelector(false);
                    }}
                  />
                  :
                  <UncontrolledDropdown
                    className="top-dropdown h-100"
                    direction="down"
                  >
                    <DropdownToggle
                      color="white"
                      className="p-0 h-100"
                      size="sm"
                    >
                      <span className="material-symbols-outlined text-primary">more_vert</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className="w-100 d-flex align-items-center"
                        disabled={downloadingList}
                        onClick={handleDownload}
                        onMouseEnter={() => setExportItemHovered(true)}
                        onMouseLeave={() => setExportItemHovered(false)}
                        title={`Export List\n(maximum limit: 5000)`}
                      >
                        <DynamicComponent
                          components={[CsvIcon, CsvIconGrey]}
                          condition={exportItemHovered}
                          height={18}
                          width={24}
                          className={`mr-50 ${downloadingList
                            ? "list-download-icon--disabled"
                            : "cursor-pointer"
                            }`}
                          disabled={downloadingList}
                        />
                        Export
                      </DropdownItem>
                      {(permissions?.manageInfra?.update || permissions?.manageInfra?.delete) ?
                        <DropdownItem
                          className="w-100 d-flex align-items-center"
                          disabled={!!importNotification || !!moveNotification || !!deleteNotification}
                          onClick={() => {
                            setIsSelector(true)
                          }}
                        >
                          <span className="material-symbols-outlined">stacks</span>
                          Bulk Edit
                        </DropdownItem>
                        : null}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                }
              </>
              : null}
          </div>
        </div>

        <SidePanel
          isOpen={isColumnSelectorOpen}
          setIsOpen={setIsColumnSelectorOpen}
        >
          <ColSelector
            cols={cols}
            setCols={setCols}
            setIsOpen={setIsColumnSelectorOpen}
            onApply={(newCol) => {
              setColumns(SCREEN_ID, newCol, view);
              setHasMore(true);
              fetchVenue(0, mainFilter, filterData, newCol);
            }}
          />
        </SidePanel>

        <InfiniteScroll
          dataLength={props.infraAPIs ? venues[0]?.children?.length ?? 0 : venues.length}
          next={() => {
            if (props.infraAPIs) {
              const columns = Object.keys(cols ?? {})
                .map((col) => (cols[col] ? (apiColumn[col] ?? col) : null))
                .filter((it) => it);

              const { run } = createRequest(
                services.telemetry.GET_VENUE_CHILDREN,
                [
                  props.venueId,
                  venues[0]?.children?.length ?? 0,
                  20,
                  encodeURIComponent(search),
                  view,
                  columns,
                  mainFilter,
                  sort,
                  filterData,
                  true,
                  false,
                ]
              );
              run()
                .then((response) => {
                  if (response.data.length < 20) {
                    setHasMore(false);
                  }
                  setVenues((prevState) => {
                    let venue = [...prevState];
                    if (venue.length > 0)
                      venue[0].children = [
                        ...(venue[0]?.children ?? []),
                        ...response.data,
                      ];
                    return [...venue];
                  });
                })
                .catch((err) => {
                  let x = new CatchedWebError(err);
                  make_custom_toast("error", "Venue", x.message);
                  setHasMore(false);
                })
                .finally(() => {
                  setVenueLoading(false);
                });
            } else {
              fetchVenue(venues.length, mainFilter, filterData);
            }
          }}
          hasMore={hasMore}
          loader={<></>}
          endMessage={<></>}
          scrollableTarget="venue-dash-table"
          scrollThreshold={0.9}
        >
          {/* <ExpandableVenueList isRoot list={props.venues} listSetter={props.setVenues} setExpVenue={props.setExpVenue} subListClose={props.subListClose} expVenue={props.expVenue} sort={props.sort} /> */}
          <div id="venue-dash-table">
            <VenueInfraContext.Provider
              value={{
                startLevel: startLevel,
                initialColumn: initialColumn,
                isSelector: isSelector ?? false,
                selections: selections,
                subListClose: subListClose,
                cols: cols,
                search: search,
                sort: sort,
                filterActive: filterActive,
                mainFilter: mainFilter,
                filterData: filterData,
                showVenueOptions: showVenueOptions,
                showInfraOptions: showInfraOptions,
                showAP: showAP,
                showSWITCH: showSWITCH,
                hideStatusIcon: hideStatusIcon,
                associationScrn: associationScrn,
                profileScrn: profileScrn,
                infraFocus: infraFocus,
                infraAPIs: props.infraAPIs,
                treeStruct: treeStruct,
                setSelections: setSelections,
                disableCheck: disableCheck,
                deletingVenue: deletingVenue,
                setDeletingVenue: setDeletingVenue,
                setDowngrade: setDowngrade,
                setDeleteModal: setDeleteModal,
                setVenueSelection: setVenueSelection,
                listView: listView
              }}
            >
              <Table className="table-view fixed-header">
                <thead>
                  <tr>
                    {listView === 'flat' && cols.Status && (
                      <th style={{ minWidth: "150px" }}>
                        <div className="d-flex">
                          {!noAllSelection && isSelector && mainFilter == 'All' && !filterActive &&
                            <AllInput
                              applyToOrg={applyToOrg}
                              disableCheck={disableCheck}
                              profileScrn={profileScrn}
                              setVenueSelection={setVenueSelection}
                              setSelections={setSelections}
                              treeStruct={treeStruct}
                            />
                          }
                          <ColumnHeader
                            header="Status"
                            attribute={"Status"}
                            setter={setSort}
                            sort={sort}
                          />
                        </div>
                      </th>
                    )}
                    <th style={{ minWidth: "500px" }}>
                      <div className="d-flex">
                        {!noAllSelection && isSelector && (listView === 'tree' || !cols.Status) && mainFilter == 'All' && !filterActive &&
                          <AllInput
                            applyToOrg={applyToOrg}
                            disableCheck={disableCheck}
                            profileScrn={profileScrn}
                            setVenueSelection={setVenueSelection}
                            setSelections={setSelections}
                            venueSelection={venueSelection}
                            treeStruct={treeStruct}
                          />
                        }
                        <ColumnHeader
                          header="Name"
                          attribute={"Name"}
                          setter={setSort}
                          sort={sort}
                        />
                      </div>
                    </th>
                    {listView === 'tree' && <th style={{ minWidth: "60px" }}></th>}
                    {listView === 'tree' && cols.Status && (
                      <th style={{ minWidth: "150px" }}>
                        <ColumnHeader
                          header="Status"
                          attribute={"Status"}
                          setter={setSort}
                          sort={sort}
                        />
                      </th>
                    )}
                    {cols.Venue && (
                      <th style={{ minWidth: "250px" }}>
                        <ColumnHeader
                          header="Venue"
                          attribute={"Venue"}
                          setter={setSort}
                          sort={sort}
                        />
                      </th>
                    )}
                    {cols.Tasks && (
                      <th style={{ minWidth: "200px" }}>
                        <div className="pl-1">
                          <ColumnHeader header="Tasks" />
                        </div>
                      </th>
                    )}
                    {cols.Room && (
                      <th style={{ minWidth: "120px" }}>
                        <ColumnHeader header="Room" attribute="Room" setter={setSort} sort={sort} />
                      </th>
                    )}
                    {cols.Location && (
                      <th style={{ minWidth: "120px" }}>
                        <ColumnHeader
                          header="Location"
                          attribute={"Location"}
                          setter={setSort}
                          sort={sort}
                        />
                      </th>
                    )}
                    {cols["MAC Address"] && (
                      <th style={{ minWidth: "170px" }}>
                        <ColumnHeader header="MAC Address" />
                      </th>
                    )}
                    {cols["Private IP"] && <th style={{ minWidth: "130px" }}>
                      <ColumnHeader header="Private IP" attribute="Private IP" setter={setSort} sort={sort} />
                    </th>}
                    {cols["Public IP"] && <th style={{ minWidth: "130px" }}>
                      <ColumnHeader header="Public IP" attribute="Public IP" setter={setSort} sort={sort} />
                    </th>}
                    {cols["AP Profile"] && <th style={{ minWidth: "170px" }}>
                      <ColumnHeader header="AP Profile" attribute="AP Profile" setter={setSort} sort={sort} />
                    </th>}
                    {cols["Switch Profile"] && <th style={{ minWidth: "170px" }}>
                      <ColumnHeader header="Switch Profile" attribute="Switch Profile" setter={setSort} sort={sort} />
                    </th>}
                    {cols["RF Profile"] && <th style={{ minWidth: "170px" }}>
                      <ColumnHeader header="RF Profile" attribute="RF Profile" setter={setSort} sort={sort} />
                    </th>}
                    {cols["Radio 2G"] && <th style={{ minWidth: "210px" }}>
                      <ColumnHeader header="2G Radio" />
                    </th>}
                    {cols["Radio 5G"] && <th style={{ minWidth: "210px" }}>
                      <ColumnHeader header="5G Radio" />
                    </th>}
                    {cols["Radio 6G"] && <th style={{ minWidth: "210px" }}>
                      <ColumnHeader header="6G Radio" />
                    </th>}
                    {cols.Traffic && (
                      <th style={{ minWidth: "120px" }}>
                        <div style={{ marginTop: "2px" }}>Traffic</div>
                      </th>
                    )}
                    {cols.Clients && (
                      <th style={{ minWidth: "120px" }}>
                        <div style={{ marginTop: "2px" }}>Clients</div>
                      </th>
                    )}
                    {cols["UpTime"] && (
                      <th style={{ minWidth: "120px" }}>
                        <ColumnHeader
                          header="Up Time"
                          attribute={"UpTime"}
                          setter={setSort}
                          sort={sort}
                        />
                      </th>
                    )}
                    {cols.Release && (
                      <th style={{ minWidth: "100px" }}>
                        <div style={{ marginTop: "1.5px" }}>Release</div>
                      </th>
                    )}
                    {cols.Operations && (
                      <th style={{ minWidth: "150px" }}>
                        <ColumnHeader header="Operations" />
                      </th>
                    )}
                    {cols["Serial Number"] && (
                      <th style={{ minWidth: "150px" }}>
                        <ColumnHeader header="Serial Number" />
                      </th>
                    )}
                    {cols["Asset Tag"] && (
                      <th style={{ minWidth: "150px" }}>
                        <ColumnHeader header="Asset Tag" />
                      </th>
                    )}
                    {
                      <th className="text-right">
                        <span
                          className="material-symbols-outlined cursor-pointer"
                          onClick={() =>
                            setIsColumnSelectorOpen((prevState) => !prevState)
                          }
                        >
                          settings
                        </span>
                      </th>
                    }
                  </tr>
                </thead>
                {venues && venues?.length > 0 ? (
                  <SubList
                    level={startLevel ?? 1}
                    cols={cols}
                    subList={venues}
                    setSubList={setVenues}
                    setOptions={setOptions}
                  />
                ) : venueLoading ? (
                  <tr>
                    <td
                      className="text-center p-3"
                      style={{ height: "45vh", backgroundColor: "white" }}
                      colSpan={20}
                    >
                      <Spinner color="primary" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td
                      className="text-center p-3"
                      style={{ height: "40vh", backgroundColor: "white" }}
                      colSpan={
                        Object.keys(cols)?.length + 2 > 0
                          ? Object.keys(cols)?.length + 2
                          : 10
                      }
                    >
                      No {(infraFocus) ? "Venue/Infra" : "Venue"} Found
                    </td>
                  </tr>
                )}
              </Table>
            </VenueInfraContext.Provider>
          </div>
        </InfiniteScroll>

        {/* <DeleteModal
          isOpen={!!deleteModal}
          toggle={setDeleteModal}
          heading="Are You Sure?"
          info="Any Infrastructure on this venue will be moved to Default Venue."
          icon={
            deletionLoading ? (
              <Spinner className="me-25 m-1" size="lg" />
            ) : (
              "error"
            )
          }
        >
          <Button
            color="primary"
            onClick={() => {
              setDeleteModal(null);
            }}
          >
            Cancel
          </Button>
          <Button
            color="outline-danger"
            onClick={() => {
              if(deleteModal.type === deleteTypes.venue)
                deleteVenue(deleteModal.venueId);
            }}
          >
            Yes, Delete it
          </Button>
        </DeleteModal> */}
        <DeleteInfraModal
          option={deleteOption}
          setOption={setDeleteOption}
          isOpen={deleteModal}
          setIsOpen={setDeleteModal}
          onDelete={() => {
            deleteInfra(Array.from(deleteModal?.infraIds ?? {}))
          }}
          infraIds={deleteModal?.infraIds}
          disabled={deletionLoading}
          orgId={activeOrg.orgId}
        />
      </div>
    </div>
  );
};

VenueInfraWidget.propTypes = {};

VenueInfraWidget.defaultProps = {
  showVenueOptions: false,
  showInfraOptions: false,
  addVenueButton: false,
  infraAPIs: false,
  hideStatusIcon: false,
  disableCheck: false,
  associationScrn: false,
  profileScrn: false
};

export default VenueInfraWidget;
