/***
 *
 * Controller class for user.
 * @file DynamicComponent.js
 * @description DynamicComponent component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./DynamicComponent.scss";

const DynamicComponent = ({components, condition, ...props}) => {
  const ComponentOnTrue = components[0]
  const ComponentOnFalse = components[1]
  return condition
    ? <ComponentOnTrue data-testid="DynamicComponent" {...props}/>
    : <ComponentOnFalse data-testid="DynamicComponent" {...props}/>
};

DynamicComponent.propTypes = {};

DynamicComponent.defaultProps = {};

export default DynamicComponent;
