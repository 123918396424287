/***
 *
 * Controller class for user.
 * @file InfraCell.js
 * @description InfraCell component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./InfraCell.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SWITCH } from "../../utility/constants";
import { displayPort } from "../../pages/Devices/DeviceList";
import { ReactComponent as SwitchColored } from "../../assets/images/icons/SwitchColored.svg";
import { ReactComponent as APColored } from "../../assets/images/icons/APColored.svg";
import { serial_mac } from "../../helpers/macHelper";
import LinkWrapper from "../LinkWrapper";
import { Tooltip } from "react-tooltip";

const InfraCell = (props) => {
  const {
    macAddress,
    infraId,
    infraName,
    switches,
    clientMac,
    infraCategory,
  } = props;
  const activeOrgId = useSelector((store) => store.activeOrg.data.orgId);
  const [data, setData] = useState(null);
  const [mode, setMode] = useState("AP"); //AP, Switch
  const [formattedSwitches, setFormattedSwitches] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setMode(
      infraCategory == SWITCH &&
        !!switches &&
        Array.isArray(switches) &&
        switches.length > 0
        ? "Switch"
        : "AP"
    );
  }, [switches]);

  useEffect(() => {
    if (!!switches && Array.isArray(switches)) {
      let formattedData = [...switches];
      for (let i = 0; i < formattedData.length; i++) {
        if (!!formattedData[i]) {
          formattedData[i] = {
            ...formattedData[i],
            port: displayPort(formattedData[i].port),
          };
        }
      }
      setFormattedSwitches(formattedData);
    }
  }, [switches]);

  useEffect(() => {
    let CorrectSwitchObjPresent =
      !!switches && Array.isArray(switches) && switches.length > 0;
    if (infraCategory == SWITCH && CorrectSwitchObjPresent) {
      setData({
        icon: <SwitchColored />,
        count: switches.length,
        id: `tooltip-${(switches[0]?.infra_id ?? "") + serial_mac(clientMac ?? "-")
          }`,
        enableClick: !!switches[0]?.infra_id ? true : false,
        link: !!switches[0]?.infra_id
          ? `/organization/${activeOrgId}/infra/${switches[0]?.infra_id}/`
          : null,
        name: switches[0]?.name,
        enableTooltip: true,
      });
    } else {
      setData({
        icon: <APColored />,
        id: CorrectSwitchObjPresent
          ? `tooltip-${(switches[0]?.infra_id ?? "") +
          serial_mac(clientMac ?? "-") +
          (macAddress ?? "-")
          }`
          : null,
        enableClick: !!infraId,
        link: !!infraId
          ? `/organization/${activeOrgId}/infra/${infraId}/`
          : null,
        name: infraName,
        enableTooltip: CorrectSwitchObjPresent,
      });
    }
  }, [activeOrgId, infraId, infraName, clientMac, navigate, switches]);

  return !!data ? (
    <>
      <div
        className="InfraCell" data-testid="InfraCell"
        onMouseOver={() => {
          if (!!data.onMouseOver) data.onMouseOver();
        }}
        onMouseLeave={() => {
          if (!!data.onMouseLeave) data.onMouseLeave();
        }}
      >
        <div className="d-flex align-items-center">
          <span
            className={`mr-50 ${data.count > 1 ? "position-relative" : ""}`}
          >
            {data.icon}
            {data.count > 1 ? (
              <div className="infra-count d-flex align-items-center justify-content-center">
                {data.count < 10 ? data.count : "9+"}
              </div>
            ) : null}
          </span>
          <span
            id={data.id}
            className={`overflow-ellipsis`}
          >
            <LinkWrapper
              to={data.enableClick ? data.link : null}
            >
            {data.name ?? "-"}
            </LinkWrapper>
            {data.enableTooltip ? (
              <Tooltip
                anchorSelect={`#${data.id}`}
                clickable
                noArrow
                place="bottom"
                variant="light"
                border="solid 2px #D9D9D9"
                opacity={1}
                style={{ padding:"0 0 0.5rem 0", borderTopLeftRadius:"5px", borderTopRightRadius:"5px", boxShadow: "2px 2px 15px #EAEAEA", zIndex: 40 }}
                delayShow={200}
              >
                <div
                  className="w-100"
                  style={{
                    backgroundColor: "#D9D9D9",
                    height: "25px",
                    borderTopLeftRadius: "0.1rem",
                    borderTopRightRadius: "0.1rem",
                  }}
                ></div>
                <table className="client-infra-tooltip">
                  <tbody>
                    <tr>
                      <td className="text-left tooltip-headers">Switch</td>
                      <td className="text-left tooltip-headers">Port</td>
                      <td className="text-right tooltip-headers">VLAN</td>
                    </tr>
                    {formattedSwitches?.map((item, key) => {
                      return (
                        <tr className="py-50" key={key}>
                          <td style={{ maxWidth: "18rem" }}>
                            {item.name ?? "-"}
                          </td>
                          <td className="text-right">{item.port ?? "-"}</td>
                          <td className="text-right">
                            {!!item.vlan ? item.vlan.join(",") : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Tooltip>
            ) : null}
          </span>
        </div>
      </div>
    </>
  ) : (
    "-"
  );
};

InfraCell.propTypes = {};

InfraCell.defaultProps = {};

export default InfraCell;
