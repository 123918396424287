/***
 *
 * Controller class for user.
 * @file LinkWrapper.js
 * @description LinkWrapper component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./LinkWrapper.scss";
import { Link } from "react-router-dom";

const LinkWrapper = (props) => {
  const {
    to,
    state,
    children,
    linkClasses
  } = props;

  return (
    // <div className="LinkWrapper" data-testid="LinkWrapper">
    // </div>
    to != null ? (
      <Link
        to={to}
        state={state ?? undefined}
        className={`RouterLink ${linkClasses??''}`}
      >
        {children}
      </Link>
    ) : children
  );
};

LinkWrapper.propTypes = {};

LinkWrapper.defaultProps = {};

export default LinkWrapper;
