/***
 *
 * Controller class for user.
 * @file MapModal.js
 * @description MapModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useCallback, useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./MapModal.scss";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { X } from "react-feather";
import { AlarmOrg } from "../OrgWidget";
import VenueStateGroup from "../VenueStateGroup";
import ApexChartsTrendLine from "../ApexChartsTrendLine";
import { versionColors } from "../../pages/Venue/VenueTree";
import LightBadge from "../LightBadge";
import { RowSecondLine } from "../VenueInfraWidget";
import { useNavigate } from "react-router-dom";
import createRequest, { services } from "../../services";
import LinkWrapper from "../LinkWrapper";
import { venueOnlineInfraLink } from "../VenueBasicDetails";

const offsetY = (y, height) => {
  // const screenHeight = document.getElementById("portal").clientHeight;
  const screenHeight = document.getElementById("map-widget")?.getBoundingClientRect();

  return screenHeight?.top;
  // if(y > (height/2) && y<(screenHeight-(height/2)))
  //   return y - (height/2)
  // return y - (height * (y/screenHeight));

}

const offsetX = (x, width) => {
  // const screenWidth = document.getElementById("portal").clientWidth;
  const screenWidth = document.getElementById("map-widget")?.getBoundingClientRect();

  return screenWidth?.left
//   if( x > (screenWidth/2))
//     return x- width;
//   return x;
}

export const getVenueStreetView = (orgId, venueId, onResponse) => {
  const {run} = createRequest(services.organization.VENUE_STREETVIEW, [orgId, venueId]);

  run().then(response => {
    if(!!onResponse)
      onResponse(response.data);
  }) 
}

const MapModal = (props) => {
  const {
    site,
    openModal,
    setOpenModal
  } = props;
  const [map, setMap] = useState();
  const [active, setActive] = useState(0);
  const [viewPano, setViewPano] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setActive(0);
  },[site]);

  useEffect(() => {
    if(site != null && active<site?.length){
      getVenueStreetView(site[active].orgId, site[active].venueId, (response) => {
        setViewPano(response)
      } )
    }
  },[site, active])

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);


  return (
    openModal && (
      <div className="MapModal main">
        {/* <div className="backDrop" onClick={() => {setOpenModal(null); setViewPano(null);}}></div> */}
        {active<site?.length && <div
          style={{
            position: "absolute",
            width: "320px",
            height: "98%",
            maxHeight: "620px", 
            fontWeight: "400",
            zIndex: 1,
            top: "1%",
            left: "1%",
            padding: "1rem",
            backgroundColor: "white",
            borderRadius: "3px",
          }}
        >
          <div style={{
            height:"42px"
          }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <LinkWrapper
                to={`/organization/${site[active].orgId}/venues/${site[active].venueId}`}
              >
                {site[active].venueName}
              </LinkWrapper>
              <span
                style={{
                  width: "10%",
                  textAlign: "end",
                  cursor: "pointer",
                }}
                onClick={() => {setOpenModal(null); setViewPano(null);}}
              >
                <X />
              </span>
            </div>

            <div className="text-small" style={{height:"0.875rem"}}>
              {RowSecondLine(site[active])}
            </div>
          </div>

          <div style={{
            height: "calc( 90% - 42px )",
            overflow: "auto",
            margin: "0.5rem 0",
            paddingRight: "0.5rem" 
          }}>
            <div className="d-flex align-items-center justify-content-between height-map">
              <span>Alarms</span>
              <span style={{minWidth:"100px"}}>
                <AlarmOrg critical={site[active].criticalAlarm} major={site[active].majorAlarm} displayNone
                  venues={!!site[active].venueId ? [site[active].venueId, ...site[active].criticalAlarmVenues??[], ...site[active].majorAlarmVenues??[]] : []}
                />
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between height-map">
              <span>State</span>
              <span>
                <VenueStateGroup active={site[active].state}/>
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between height-map">
              <span>Infrastructure</span>
              <span>
                <LinkWrapper
                  to={venueOnlineInfraLink((site[active]?.onlineInfras??0),site[active]?.orgId,site[active]?.venueId)}
                >
                <span
                  className={`${
                    (site[active]?.onlineInfras ?? 0) > 0 &&
                    (site[active]?.totalInfras ?? 0) > 0
                      ? "text-success font-weight--600 cursor-pointer"
                      : ""
                  } font-size--larger`}
                >
                  {site[active]?.onlineInfras != null ? site[active]?.onlineInfras : "0"}
                </span>
                </LinkWrapper>
                &nbsp;/&nbsp;
                <LinkWrapper
                  to={(site[active]?.totalInfras ?? 0) <= 0 ? null :
                    `/organization/${site[active].orgId}/venues/${site[active]?.venueId}/infrastructure`
                  }
                >
                  {site[active]?.totalInfras != null ? site[active]?.totalInfras : "0"}
                </LinkWrapper>
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between height-map">
              <div>Traffic Trend</div>
              <ApexChartsTrendLine
                color={["#B347E6", "#F5A742"]}
                seriesTitles={["uplink", "downlink"]}
                series={site[active]?.trafficData}
              />
            </div>

            <div className="d-flex align-items-center justify-content-between height-map">
              <div>Client Trend</div>
              <ApexChartsTrendLine
                color={["#5279CE"]}
                seriesTitles={["client"]}
                series={site[active]?.clientData}
              />
            </div>

            <div className="d-flex align-items-center justify-content-between height-map">
              <div>Release</div>
              <LightBadge
                color={versionColors[site[active].firmwareStatus] ?? "secondary"}
              >
                {site[active]?.firmwareVersion?.replace("Unknown", ``) ?? "-"}
              </LightBadge>
            </div>
            <div style={{width:"100%"}} className="my-1 text-center">
              {!!viewPano ? <img src={viewPano}
                className="ap-img"
                onError={() => setViewPano(null)}
              />
              :<GoogleMap
                mapContainerStyle={{
                  height:"170px",
                  width:"100%"
                }}
                options={{
                  disableDefaultUI: true,
                  keyboardShortcuts: false,
                  minZoom: 5
                }}
                center={{ lat: site[active].lat, lng: site[active].lng }}
                zoom={13}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
              <Marker
                position={{
                  lat: site[active].lat,
                  lng: site[active].lng,
                }}
              ></Marker>
              </GoogleMap>}
            </div>
            <div className="ellipsis-text font-address">
              {site[active]?.venueAddress?.addressLine}
            </div>
          </div>

          <div>
            {site.length>1 && <div className="d-flex align-items-center justify-content-center">
              <span className="material-symbols-outlined cursor-pointer" onClick={() => {
                if(active>0)
                  setActive(prevState => prevState-1)
              }} style={{ fontSize: "1.5rem" }}>chevron_left</span>
              <span style={{paddingBottom: "0.2rem"}}>{active+1}/{site.length}</span>
              <span className="material-symbols-outlined cursor-pointer" onClick={() => {
                if(active < site?.length-1)
                  setActive(prevState => prevState+1)
              }}  style={{ fontSize: "1.5rem" }}>chevron_right</span>
            </div>}
          </div>
        </div>}
      </div>
    )
  );
};

MapModal.propTypes = {};

MapModal.defaultProps = {};

export default MapModal;
