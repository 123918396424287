/***
 *
 * Controller class for user.
 * @file VenueTree.js
 * @description VenueTree component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useState, useCallback, createContext, useContext } from "react";
import { ColumnHeader, FilterSetter, SearchIconAddon, Toggle } from "../../../components";
import DeleteModal from "../../../components/DeleteModal"
import "./VenueTree.scss";
import { Row, Col, Input, Spinner, Button, InputGroup, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Table, Modal, ModalBody, ModalHeader } from "reactstrap";
import createRequest, { services } from "../../../services";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ApexChartsTrendLine from "../../../components/ApexChartsTrendLine";
import store from "../../../redux/store";
import { ReactComponent as Site } from "../../../assets/images/icons/Site.svg";
import { ReactComponent as Building } from "../../../assets/images/icons/Building.svg";
import { ReactComponent as Floor } from "../../../assets/images/icons/Floor.svg";
import { ReactComponent as Unit } from "../../../assets/images/icons/Unit.svg";
import { breadcrumbActions } from "../../../redux/slices";
import { CatchedWebError } from "../../../configs";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { ExpCell } from "../../../components/ExpTable";
import {ReactComponent as Success} from "../../../assets/images/icons/Success.svg"
import {ReactComponent as Error} from "../../../assets/images/icons/emergency-siren.svg"
import {ReactComponent as Warning} from "../../../assets/images/icons/WarningWhiteExclamation.svg"
import {ReactComponent as WarningOrange} from "../../../assets/images/icons/OrangeWarning.svg"
import LightBadge from "../../../components/LightBadge";
import { BUSINESS, BUSINESS_VIEW, DEPLOYMENT_VIEW, MANAGE, MSP, NETWORK_VIEW, SCROLL_MORE_TEXT, VENUE_STATUS } from "../../../utility/constants";
import blazer from "../../../services/blazer.service";
import Blazer from "../../../redux/slices/blazer.slice";
import useTimedCaller from "../../Dashboard/NewDashboard/useTimedCaller";
import ChartError from "../../Dashboard/Graphs/ChartError";
import VenueChart from "../../Dashboard/Graphs/VenueChart";
import { formatNetworkCardData, formatVenueAlertData, formatVenueCardData, formatVenueTasksCardData } from "../../Dashboard/NewDashboard/BlazerUtil";
import { isoDate } from "../../Dashboard/Graphs/Utils";
import ColSelector from "../../../components/ColSelector";
import VenueAlarmChart from "../../Dashboard/Graphs/VenueAlarmsChart";
import GroupButton from "../../../components/GroupButton";
import FirmwarePopup from "../../../components/FirmwarePopup";
import { CollapseIcon, ExpandIcon } from "../../../assets/images/icons/Icons";
import { make_custom_toast } from "../../../helpers/toasts";
import { Operations } from "../../Organization/OrgList";
import FilterButton from "../../../components/FilterButton";
import { FWFilter, LocationFilter, OperationsFilter, OrgFilter, VenueStateFilter } from "../../Filters/filters";
import lodash from "lodash-es"
import FirmwareReschedule from "../../../components/FirmwareRescheduleVenue";
import moment from "moment";
import { VenueSelectionContext } from "../../Networks/Activation";
import SidePanel from "../../../components/SidePanel";
import VenueInfraWidget from "../../../components/VenueInfraWidget";
import { getColumns } from "../../../utility/colSaver";
import VenueTasksCard from "../../Dashboard/Graphs/VenueTasksCard";
import OrgWidget from "../../../components/OrgWidget";
import { LIST_TYPES } from "../../../components/OrgWidget/utils";

export const statusIcons = (venueStatus) => {
  if (venueStatus == "RED_ZONE")
    return <Error className="pr-50" width={28} height={28}/>;
  if (venueStatus == "ORANGE_ZONE")
    return <WarningOrange className="pr-50" width={28} height={28}/>;
  if (venueStatus == "YELLOW_ZONE")
    return <Warning className="pr-50" width={28} height={28}/>;
  if (venueStatus == "GREEN_ZONE")
    return <Success className="pr-50" width={28} height={28}/>;
  return <></>;
};

export const VenueIcon = {
  1: <Site width="100%" height="100%" style={{ height: "22px", width: "22px" }} className="icon  mr-50" />,
  // 2: <Building width="100%" height="100%" style={{ height: "22px", width: "22px" }} className="icon mr-50" />,
  2: <Floor width="100%" height="100%" style={{ height: "22px", width: "22px" }} className="icon mr-50" />,
  // 4: <Unit width="100%" height="100%" style={{ height: "22px", width: "22px" }} className="icon mr-50" />
}

// const SubVenueList = (props) => {
//   const { index, venue, subList, switchOrgs, setDeletingVenue, level, view, expand, setExpand } = props;

//   const { isSelector } = useContext(ListContext)
//   const selectionData = useContext(VenueSelectionContext)
//   const { selectedVenues, setSelectedVenues, selectedVenueNames, setSelectedVenueNames } = selectionData ?? {}

//   const permissions = useSelector(store => store.rbac.permissions);
//   const activeOrgId = useSelector(state => state.activeOrg.data.orgId)
//   const venuePer = useSelector(store => store?.rbac?.data?.venues?.find(it => it?.venueId == props?.item?.venueId)?.permissions ?? null)

//   const navigate = useNavigate();
//   return (
//     <ExpVenueList setOptions={props.setOptions} latestVersion={props.latestVersion} key={venue.venueId} cols={props.cols} search={props.search}
//       permissions={permissions} setDeleteModal={props.setDeleteModal} subListClose={props.subListClose} level={level + 1} expand={!venue.leafNode}
//       orgId={venue.orgId} venueId={venue.venueId} sort={props.sort} columnMap={props.columnMap}
//       parentLast={index == (subList.length - 1) ? props.parentLast ? props.parentLast + 1 : 1 : null} filterData={props.filterData}>
//       <ExpCell width="450px" showLines last={index == (subList.length - 1)} parentLast={props.parentLast + 1}>
//         <div className="d-flex align-items-center justify-content-between">
//           <div className="d-flex py-50" onClick={() => {
//             if (venue.orgId == activeOrgId) {
//               if (permissions?.manageVenue?.view)
//                 navigate(`/organization/${venue.orgId}/venues/${venue.venueId}`)
//             }
//             else if (permissions?.manageHierarchy?.view)
//               navigate(`/organization/${venue.orgId}/venues/${venue.venueId}`)
//           }}>
//             {view != BUSINESS_VIEW && <div>
//               <img src={statusIcons(venue.onlineInfras, venue.totalInfras)} />
//             </div>}
//             <div className={"d-flex align-items-center " + (view != BUSINESS_VIEW ? "ml-1" : "")}>
//               <div>
//                 <div className={`${(venue.orgId == activeOrgId ? permissions?.manageVenue?.view : permissions?.manageHierarchy?.view) ? "table-link" : ""} d-flex align-items-center`}>
//                   {VenueIcon[venue.venueType]}
//                   {venue.venueName}
//                 </div>
//                 {view != BUSINESS_VIEW && <div className="font-weight-bold" style={{ marginTop: "0.5rem", minHeight: "0.5rem", fontSize: "0.75rem" }}>
//                   {venue?.tickets > 0 ? `${venue?.tickets} Ticket${venue?.tickets > 1 ? "s" : ""}` : ""}
//                   {(venue?.tickets > 0 && (venue?.offlineAP > 0 || venue?.offlineSwitch > 0)) ? ", " : ""}
//                   {venue?.offlineAP > 0 ? `${venue?.offlineAP} AP${venue?.offlineAP > 1 ? "s" : ""} Offline` : ""}
//                   {(venue?.offlineAP > 0 && venue?.offlineSwitch > 0) ? ", " : ""}
//                   {venue?.offlineSwitch > 0 ? `${venue?.offlineSwitch} Switch${venue?.offlineSwitch > 1 ? "es" : ""} Offline` : ""}
//                 </div>}
//               </div>
//             </div>
//           </div>
//           {(isSelector &&
//             (venuePer == null || venuePer?.network == null || venuePer.network == MANAGE))
//             ? <div>
//               <Toggle displayText={false} value={selectedVenues.includes(venue.venueId)} onClick={() => {
//                 const isSelected = selectedVenues.includes(venue.venueId)
//                 if (isSelected) {
//                   setSelectedVenues((prevState) => { return prevState.filter((ins) => ins !== venue.venueId) })
//                   setSelectedVenueNames((prevState) => { return prevState.filter((ins) => ins.venueId !== venue.venueId) })
//                 }
//                 else {
//                   if (!expand && !props.item.leafNode)
//                     setExpand(true);
//                   setSelectedVenues((prevState) => { return [...prevState, venue.venueId] });
//                   setSelectedVenueNames((prevState) => { return [...prevState, { venueName: venue.venueName, venueId: venue.venueId, isActive: true }] })
//                 }
//               }} />
//             </div>
//             : null}
//         </div>
//       </ExpCell>
//       {props.cols.State && <ExpCell width="100px">{VENUE_STATUS[venue.state]}</ExpCell>}
//       {props.cols.Location && <ExpCell width="100px"><div className="d-flex align-items-center"><div className="flag-icon mr-50" style={{ backgroundImage: `url( https://flagcdn.com/${venue?.location?.toLowerCase()}.svg )` }}>&nbsp;</div>{venue?.location}</div></ExpCell>}
//       {props.cols.Organization && <ExpCell width="180px"></ExpCell>}
//       {props.cols.Operations && <ExpCell width="150px">{Operations(venue.ticketStatus, venue.alarmStatus, venue.scheduleStatus)}</ExpCell>}
//       {props.cols.Infrastructure && <ExpCell className="justify-content-end" width="100px">
//         <div className="d-flex">
//           <span>{venue?.onlineInfras}/{venue?.totalInfras}</span>
//           <span style={{ width: '1.1rem' }} />
//         </div>

//       </ExpCell>}
//       {props.cols.Traffic && <ExpCell width="100px"><ApexChartsTrendLine color={["#B347E6", "#F5A742"]} series={[formatNetworkCardData({ uplink: venue?.uplink, downlink: venue?.downlink }).series1, formatNetworkCardData({ uplink: venue?.uplink, downlink: venue?.downlink }).series2]} /></ExpCell>}
//       {props.cols.Clients && <ExpCell width="100px"><ApexChartsTrendLine color={["#5279CE"]} series={[{
//         name: "Connected Clients",
//         data: venue?.clients?.map(it => Number(it.device_count)) ?? [],
//       }]} /></ExpCell>}
//       {props.cols["In-Service"] && <ExpCell width="140px">{venue.inService}</ExpCell>}
//       {props.cols.Cost && <ExpCell className="justify-content-end pr-3" width="100px">${venue.cost ?? "-"}</ExpCell>}
//       {props.cols.Proposed && <ExpCell className="justify-content-end pr-50" width="100px">${venue.quoted}</ExpCell>}
//       {props.cols.Firmware && <ExpCell className="cursor-pointer" width="100px">
//         <FirmwarePopup tooltipId={venue.venueId} data={venue?.firmwareCount}>
//           <LightBadge color={versionColors[venue?.firmwareStatus] ?? "secondary"}>{venue?.firmwareVersion?.replace("Unknown", ``) ?? "-"}</LightBadge>
//         </FirmwarePopup>
//       </ExpCell>}
//       <ExpCell className="justify-content-end">
//         {<UncontrolledDropdown direction="down" disabled={!(switchOrgs.includes(venue.orgId) || permissions.manageVenue.update || permissions?.manageFirmware?.create)}>
//           <DropdownToggle color='white' className="w-0 p-0">
//             <span className={"material-symbols-outlined cursor-pointer " + ((permissions?.manageHierarchy?.create || switchOrgs.includes(venue.orgId)) ? "text-primary" : "text-secondary")} title="Options">more_vert</span>
//           </DropdownToggle>
//           <DropdownMenu>
//             <DropdownItem className="w-100" onClick={() => {
//               if (venue.orgId == activeOrgId) {
//                 if (permissions?.manageVenue?.update)
//                   navigate(`/organization/${venue.orgId}/venues/${venue.venueId}`)
//               }
//               else if (permissions?.manageHierarchy?.update)
//                 navigate(`/organization/${venue.orgId}/venues/${venue.venueId}/settings/`)
//             }}>
//               Edit
//             </DropdownItem>
//             {(permissions?.manageFirmware?.update && venue?.previousVersion !== null && venue?.firmwareVersion != venue?.previousVersion) &&
//               <DropdownItem className='cursor-pointer w-100' onClick={() => props.setDowngrade({ venue: venue, downgrading: false })}>
//                 Revert Update
//               </DropdownItem>
//             }
//             {permissions?.manageFirmware?.update && venue.firmwareStatus == "Update-available" &&
//               <DropdownItem className="w-100" onClick={() => {
//                 props.setOptions({
//                   scheduleId: venue?.scheduleId,
//                   venueId: venue?.venueId,
//                   scheduleDate: venue?.scheduleDate,
//                   scheduleSnoozed: venue?.scheduleSnoozed,
//                   timezone: venue?.venueTimezone
//                 })
//               }}>
//                 Reschedule Update
//               </DropdownItem>
//             }
//             {permissions?.manageVenue?.create &&
//               <DropdownItem className="w-100" onClick={() => {
//                 navigate(`/organization/${venue.orgId}/venues/${venue.venueId}/add/`)
//               }}>
//                 Add Child
//               </DropdownItem>
//             }
//             {(!venue.defaultVenue && permissions.manageVenue.delete) &&
//               <DropdownItem className="w-100" onClick={() => {
//                 props.setDeleteModal(venue.venueId)
//                 setDeletingVenue(venue.venueId);
//               }}>
//                 Delete
//               </DropdownItem>
//             }
//           </DropdownMenu>
//         </UncontrolledDropdown>}
//       </ExpCell>
//     </ExpVenueList>
//   )
// }

// const ExpVenueRow = (props) => {
//   const activeOrgId = useSelector(state => state.activeOrg.data.orgId)
//   const view = useSelector(store => store.identity.meta.view);
//   const switchOrgs = useSelector(store => store.rbac.data.orgs)?.map(it => it.orgId);
//   const permissions = useSelector(store => store.rbac.permissions);
//   const { isSelector } = useContext(ListContext)

//   const [expand, setExpand] = useState(false);
//   const [subList, setSubList] = useState([]);
//   const [hideExpand, setHideExpand] = useState(false);
//   const [deletingVenue, setDeletingVenue] = useState(null)
//   let level = props.level ?? 1;
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (props.subListClose) {
//       setExpand(false);
//       if (subList.length == 1 && subList[0].venueId == deletingVenue)
//         setHideExpand(true);
//     }
//     else
//       setDeletingVenue(null);
//   }, [props.subListClose])

//   useEffect(() => {
//     if (expand) {
//       const columns = Object.keys(props.cols ?? {}).map(col => props.cols[col] ? props.columnMap[col]??col : null).filter(it => it)
//       const { controller, run } = createRequest(services.venue.LIST_DASHBOARD, [props.orgId, 0, 10000, "", view, props.venueId, columns, props.sort]);
//       // setSubListLoading(true);
//       run()
//         .then(response => {
//           // setSubListError(false);
//           setSubList(response.data);
//         })
//         .catch(err => {
//           let x = new CatchedWebError(err);
//           // setSubListError(x.message);
//         })
//         .finally(() => {
//           // setSubListLoading(false);
//         });

//       return () => {
//         controller.abort();
//       };
//     }
//     else
//       setSubList([])
//   }, [expand, props.orgId, props.venueId]);
//   const firstCell = React.cloneElement(props.children[0], { expand });

//   return (
//     <>
//       <div className="ExpRow">
//         {/* {firstCell} */}
//         <ExpCell {...firstCell.props} hasExpandIcon={props.expand}>
//           <div className="d-flex align-items-center">
//             <div className="d-flex align-items-center justify-content-start position-relative">
//               {props.expand && expand && <div className={`${(firstCell.props.last || firstCell.props.level == undefined) ?
//                 "fixed-line" : "fixed-line-inner"}`} />}
//               {(props.expand && !hideExpand) ?
//                 expand ?
//                   <CollapseIcon style={{ marginLeft: "-0.55rem", marginRight: '4px', minWidth: "3rem", cursor: 'pointer' }} width={14} height={14}
//                     onClick={() => setExpand(!expand)} />
//                   : <ExpandIcon style={{ marginLeft: "-0.55rem", marginRight: '4px', minWidth: "3rem", cursor: 'pointer' }} width={14} height={14}
//                     onClick={() => setExpand(!expand)} />
//                 : <div className="mr-1" style={{ minWidth: level == 1 ? "1.8rem" : "0rem" }}>&nbsp;</div>}
//               {firstCell.props.children}
//             </div>
//           </div>
//         </ExpCell>
//         {props.children.slice(1)}
//       </div>
//       {
//         expand &&
//         <>
//           {subList?.map((venue, index) => {
//             return <SubVenueList index={index} venue={venue} subList={subList}
//               switchOrgs={switchOrgs} setDeletingVenue={setDeletingVenue}
//               level={level} view={view} expand={expand} setExpand={setExpand} {...props} />
//           })
//           }
//         </>

//       }
//     </>)
// }
// const ExpVenueList = (props) => {
//   const newChildren = props?.children?.map(element => element ? React.cloneElement(element, { level: props.level }) : element)
//   // console.log("Old Children--->", props.children);
//   // console.log("New Children--->", newChildren);
//   return (
//     <ExpVenueRow setOptions={props.setOptions} latestVersion={props.latestVersion} level={props.level} search={props.search} setDeleteModal={props.setDeleteModal} cols={props.cols} expand={props.expand}
//       orgId={props.orgId} venueId={props.venueId} sort={props.sort} parentLast={props.parentLast} subListClose={props.subListClose} filterData={props.filterData} columnMap={props.columnMap}>
//       {newChildren}
//       {/* {props.children} */}
//     </ExpVenueRow>)
// }

// const debounce = (callback) => {
//   clearTimeout(timer);
//   timer = setTimeout(callback, 800);
// }

const downgradeVenue = (venue, downgrade, setDowngrade) => {
  const prevVersion = venue.previousVersion
  const currVersion = venue.firmwareVersion
  setDowngrade(prevState => {
    return {
      ...prevState,
      downgrading: true
    }
  })
  const { run } = createRequest(services.fwupgrade.NEW_FWUPGRADE, [], {
    "orgs": [
      {
        "orgId": venue.orgId,
        "venues": [
          {
            "venueId": venue.venueId,
            "scheduleDate": moment.utc().add(1, 'minute').format("YYYY-MM-DDTHH:mm:ss") + 'Z',
            "infras": []
          }
        ]
      }
    ],
    "firmwareVersion": prevVersion,
    "scheduleName": "Revert Update",
    "description": `Revert the update(${currVersion} to ${prevVersion})`,
    "force": true,
    "defaultSchedule": true
  });
  run().then(() => {
    if (!!downgrade?.onSuccess) {
      downgrade.onSuccess()
    }
    make_custom_toast("success", "Revert Update", "Update revert in progress.")
  })
    .catch(err => {
      make_custom_toast("error", "Unable to revert update", (new CatchedWebError(err).message));
    }).finally(() => setDowngrade(null));
}

export const DowngradeModal = (props) => {
  const { downgrade, setDowngrade } = props
  return (
    <Modal centered isOpen={!!downgrade} toggle={() => setDowngrade(null)}>
      <ModalHeader className="bg-white" toggle={() => { setDowngrade(null) }}></ModalHeader>
      <ModalBody className="p-1 pb-0">
        <div className="text-center">
          <h3 className="mb-1">Are you sure?</h3>
          {/* <img src={Warning} width={64} height={64} /> */}
          <span className="material-symbols-outlined text-warning display-2">error</span>
          <br />
          <div className="mt-2">
            The venue is currently on Release <b>{downgrade?.venue?.firmwareVersion}</b>.
            This action will move it to Release <b>{downgrade?.venue?.previousVersion}</b>.
            Are you sure you want to proceed?
          </div>
        </div>
        <div className="text-center mt-2">
          <Button.Ripple disabled={downgrade?.downgrading} outline color="primary" onClick={() => setDowngrade(null)}>Cancel</Button.Ripple>
          <Button.Ripple disabled={downgrade?.downgrading} className="ml-1" color="primary" onClick={() => downgradeVenue(downgrade?.venue, downgrade, setDowngrade)}>Yes</Button.Ripple>
        </div>
      </ModalBody>
    </Modal>
  )
}

// const TreeView = (props) => {
//   const view = useSelector(store => store.identity.meta.view);
//   const switchOrgs = useSelector(store => store.rbac.data.orgs)?.map(it => it.orgId);
//   const activeOrgId = useSelector(store => store.activeOrg.data.orgId)
//   const permissions = useSelector(store => store.rbac.permissions);

//   const selectionData = useContext(VenueSelectionContext)
//   const { selectedVenues, setSelectedVenues, selectedVenueNames, setSelectedVenueNames } = selectionData ?? {}
//   const { isSelector } = useContext(ListContext)

//   const [options, setOptions] = useState(null);
//   const navigate = useNavigate();

//   const [downgrade, setDowngrade] = useState(null);

//   const [enableDowngrade, setEnableDowngrade] = useState(true)
//   const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false)

//   return (
//     <div>
//       <DowngradeModal downgrade={downgrade} setDowngrade={setDowngrade} />
//       <SidePanel
//         isOpen={isColumnSelectorOpen}
//         setIsOpen={setIsColumnSelectorOpen}>
//         <ColSelector cols={props.cols} setCols={props.setCols} setIsOpen={setIsColumnSelectorOpen} />
//       </SidePanel>
//       {options != null && <FirmwareReschedule options={options} setModal={setOptions} updateSnoozeDate={(snzDate, venueId) => {
//         props.setVenues(prevState => {
//           let index = prevState.findIndex(it => it.venueId == venueId)
//           let newList = [...prevState]
//           if (index >= 0)
//             newList[index].scheduleSnoozed = snzDate
//           return [...newList]
//         })
//       }} />}

//       {!props.venueError && <div>
//         <InfiniteScroll
//           dataLength={props?.venues?.length ?? 0}
//           next={() => {
//             const columns = Object.keys(props.cols ?? {}).map(col => props.cols[col] ? props.columnMap[col]??col : null).filter(it => it)
//             const { run } = createRequest(services.venue.LIST_DASHBOARD, [props.orgId, props.venues.length, 20, encodeURIComponent(props.search),
//               view, , columns, props.sort, props.mainFilter, {...props.filterData, orgId: props.filterData.orgs.map(org => org.orgId)}]);
//             if (!props.venueLoading)
//               run()
//                 .then(response => {
//                   if (response.data.length < 20 || props.filterData?.firmwareVersion?.length > 0) {
//                     props.setHasMore(false);
//                   }
//                   props.setVenues(currVenues => [...currVenues, ...response.data]);
//                 })
//                 .catch(err => {
//                   let x = new CatchedWebError(err);
//                   // make_custom_toast()
//                   props.setHasMore(false);
//                 })
//           }}
//           hasMore={props.hasMore}
//           loader={<div>{SCROLL_MORE_TEXT}</div>}
//           endMessage={<span>{`Showing ${props?.venues?.length} results.`}</span>}
//           scrollableTarget="venue-dash-table"
//           scrollThreshold={0.7}
//         >
//           {/* <ExpandableVenueList isRoot list={props.venues} listSetter={props.setVenues} setExpVenue={props.setExpVenue} subListClose={props.subListClose} expVenue={props.expVenue} sort={props.sort} /> */}
//           <div id="venue-dash-table">
//             <Table className="table-view fixed-header">
//               <thead>
//                 <tr>
//                   <th style={{ width: "450px" }}>
//                     <ColumnHeader header="Name" attribute={"Name"} setter={props.setSort} sort={props.sort} />
//                   </th>
//                   {props.cols.State && <th style={{ width: "100px" }}><ColumnHeader header="State" attribute={"Status"} setter={props.setSort} sort={props.sort} /></th>}
//                   {props.cols.Location && <th style={{ width: "100px" }}><ColumnHeader header="Location" attribute={"Location"} setter={props.setSort} sort={props.sort} /></th>}
//                   {props.cols.Organization && <th style={{ width: "180px" }}><ColumnHeader header="Organization" attribute={"Organization"} setter={props.setSort} sort={props.sort} /></th>}
//                   {props.cols.Operations && <th style={{ width: "150px" }}><div style={{ marginTop: '2px' }}>Operations</div></th>}
//                   {props.cols.Infrastructure && <th className='pr-0' style={{ width: "100px" }}><ColumnHeader header="Infrastructure" attribute={"Infrastructure"} setter={props.setSort} sort={props.sort} /></th>}
//                   {props.cols.Traffic && <th style={{ width: "100px" }}><div style={{ marginTop: '2px' }}>Traffic</div></th>}
//                   {props.cols.Clients && <th style={{ width: "100px" }}><div style={{ marginTop: '2px' }}>Clients</div></th>}
//                   {props.cols["In-Service"] && <th style={{ width: "140px" }}><ColumnHeader width="110px" header="In-Service" attribute={"In-Service"} setter={props.setSort} sort={props.sort} /></th>}
//                   {props.cols.Cost && <th style={{ width: "100px" }}><div style={{ marginTop: '1.6px' }}>Cost</div></th>}
//                   {props.cols.Proposed && <th style={{ width: "100px" }}><div style={{ marginTop: '1.5px' }}>Proposed</div></th>}
//                   {props.cols.Firmware && <th style={{ width: "100px" }}><div style={{ marginTop: '1.5px' }}>Firmware</div></th>}
//                   {<th className="text-right" style={isSelector ? { width: "50px" } : {}}>
//                     <span
//                       className="material-symbols-outlined cursor-pointer"
//                       onClick={() =>
//                         setIsColumnSelectorOpen(prevState => !prevState)}>
//                       settings
//                     </span>
//                   </th>}
//                 </tr>
//               </thead>
//               {(props.venueLoading) ? <tr><td className="text-center p-3" style={{ height: '45vh', backgroundColor: 'white' }} colSpan={20}><Spinner color="primary" /></td></tr> :
//                 (props?.venues && props?.venues?.length > 0) ?
//                   props?.venues?.map((venue) => {
//                     return (
//                       <ExpVenueRow setOptions={setOptions} latestVersion={props.latestVersion} setDowngrade={setDowngrade} key={venue.venueId} cols={props.cols} permissions={permissions} setDeleteModal={props.setDeleteModal} expand={!venue.leafNode}
//                         orgId={venue.orgId} venueId={venue.venueId} sort={props.sort} subListClose={props.subListClose} search={props.search} filterData={props.filterData} columnMap={props.columnMap}>
//                         <ExpCell width="450px">
//                           <div className="d-flex align-items-center justify-content-between">
//                             <div className="d-flex py-50" onClick={() => {
//                               if (venue.orgId == activeOrgId) {
//                                 if (permissions?.manageVenue?.view)
//                                   navigate(`/organization/${venue.orgId}/venues/${venue.venueId}`)
//                               }
//                               else if (permissions?.manageHierarchy?.view)
//                                 navigate(`/organization/${venue.orgId}/venues/${venue.venueId}`)
//                             }}>

//                               {view != BUSINESS_VIEW && <div className="d-flex align-items-start">
//                                 <img src={statusIcons(venue.onlineInfras, venue.totalInfras)} />
//                               </div>}
//                               <div className={"d-flex align-items-center " + (view != BUSINESS_VIEW ? "ml-1" : "")}>
//                                 <div>
//                                   <div className={`${(venue.orgId == activeOrgId ? permissions?.manageVenue?.view : permissions?.manageHierarchy?.view) ? "table-link" : ""} d-flex align-items-center`}>
//                                     {VenueIcon[venue.venueType]}
//                                     {venue.venueName}
//                                   </div>
//                                   {view != BUSINESS_VIEW && <div className="font-weight-bold" style={{ marginTop: "0.5rem", minHeight: "0.5rem", fontSize: "0.75rem" }}>
//                                     {venue?.tickets > 0 ? `${venue?.tickets} Ticket${venue?.tickets > 1 ? "s" : ""}` : ""}
//                                     {(venue?.tickets > 0 && (venue?.offlineAP > 0 || venue?.offlineSwitch > 0)) ? ", " : ""}
//                                     {venue?.offlineAP > 0 ? `${venue?.offlineAP} AP${venue?.offlineAP > 1 ? "s" : ""} Offline` : ""}
//                                     {(venue?.offlineAP > 0 && venue?.offlineSwitch > 0) ? ", " : ""}
//                                     {venue?.offlineSwitch > 0 ? `${venue?.offlineSwitch} Switch${venue?.offlineSwitch > 1 ? "es" : ""} Offline` : ""}
//                                   </div>}
//                                 </div>
//                               </div>
//                             </div>
//                             {isSelector
//                               ? <div>
//                                 <Toggle displayText={false} value={selectedVenues.includes(venue.venueId)} onClick={() => {
//                                   const isSelected = selectedVenues.includes(venue.venueId)
//                                   if (isSelected) {
//                                     setSelectedVenues((prevState) => { return prevState.filter((ins) => ins !== venue.venueId) })
//                                     setSelectedVenueNames((prevState) => { return prevState.filter((ins) => ins.venueId !== venue.venueId) })
//                                   }
//                                   else {
//                                     // if(!expand && !venue.leafNode)
//                                     //     setExpand(true);
//                                     setSelectedVenues((prevState) => { return [...prevState, venue.venueId] });
//                                     setSelectedVenueNames((prevState) => { return [...prevState, { venueName: venue.venueName, venueId: venue.venueId, isActive: true }] })
//                                   }
//                                 }} />
//                               </div>
//                               : null}
//                           </div>
//                         </ExpCell>
//                         {props.cols.State && <ExpCell width="100px"><span style={{ paddingLeft: '0.4rem' }}>{VENUE_STATUS[venue.state]}</span></ExpCell>}
//                         {props.cols.Location && <ExpCell width="100px"><div className="d-flex align-items-center"><div className="flag-icon mr-50" style={{ backgroundImage: `url( https://flagcdn.com/${venue?.location?.toLowerCase()}.svg )` }}>&nbsp;</div>{venue?.location}</div></ExpCell>}
//                         {props.cols.Organization && <ExpCell width="180px" ><div style={{ paddingLeft: '0.5rem' }}>{venue.orgName}</div></ExpCell>}
//                         {props.cols.Operations && <ExpCell width="150px">{Operations(venue.ticketStatus, venue.alarmStatus, venue.scheduleStatus)}</ExpCell>}
//                         {props.cols.Infrastructure && <ExpCell className="justify-content-end" width="100px">
//                           <div className="d-flex">
//                             <span>{venue?.onlineInfras}/{venue?.totalInfras}</span>
//                             <span style={{ width: '1.1rem' }} />
//                           </div>
//                         </ExpCell>}
//                         {props.cols.Traffic && <ExpCell width="100px"><ApexChartsTrendLine color={["#B347E6", "#F5A742"]} series={[formatNetworkCardData({ uplink: venue?.uplink, downlink: venue?.downlink }).series1, formatNetworkCardData({ uplink: venue?.uplink, downlink: venue?.downlink }).series2]} /></ExpCell>}
//                         {props.cols.Clients && <ExpCell width="100px"><ApexChartsTrendLine color={["#5279CE"]} series={[{
//                           name: "Connected Clients",
//                           data: venue?.clients?.map(it => Number(it.device_count)) ?? [],
//                         }]} /></ExpCell>}
//                         {props.cols["In-Service"] && <ExpCell width="140px"><span style={{ paddingLeft: '0.3rem' }}>{venue.inService}</span></ExpCell>}
//                         {props.cols.Cost && <ExpCell className="justify-content-end pr-3" width="100px">${venue.cost ?? "-"}</ExpCell>}
//                         {props.cols.Proposed && <ExpCell className="justify-content-end pr-50" width="100px">${venue.quoted}</ExpCell>}
//                         {props.cols.Firmware && <ExpCell className="cursor-pointer" width="100px">
//                           <FirmwarePopup tooltipId={venue.venueId} data={venue?.firmwareCount ?? {}}>
//                             <LightBadge color={versionColors[venue?.firmwareStatus] ?? "secondary"}>{venue?.firmwareVersion?.replace("Unknown", ``) ?? "-"}</LightBadge>
//                           </FirmwarePopup>
//                         </ExpCell>}
//                         <ExpCell className="justify-content-end">
//                           {!isSelector
//                             ? <UncontrolledDropdown direction="down" disabled={!(switchOrgs.includes(venue.orgId) || permissions.manageVenue.update || permissions?.manageFirmware?.create)}>
//                               <DropdownToggle color='white' className="w-0 p-0">
//                                 <span className={"material-symbols-outlined cursor-pointer " + ((!(switchOrgs.includes(venue.orgId) || permissions.manageVenue.update || permissions?.manageFirmware?.create)) ? "text-secondary" : "text-primary")} title="Open Dashboard">more_vert</span>
//                               </DropdownToggle>
//                               <DropdownMenu>
//                                 <DropdownItem className="w-100" onClick={() => {
//                                   if (venue.orgId == activeOrgId) {
//                                     if (permissions?.manageVenue?.update)
//                                       navigate(`/organization/${venue.orgId}/venues/${venue.venueId}/settings/`)
//                                   }
//                                   else if (permissions?.manageHierarchy?.update)
//                                     navigate(`/organization/${venue.orgId}/venues/${venue.venueId}/settings/`)
//                                 }}>
//                                   Edit
//                                 </DropdownItem>
//                                 {(permissions?.manageFirmware?.update && !!venue?.previousVersion &&
//                                   !!venue?.firmwareVersion && venue?.firmwareVersion !== venue?.previousVersion && enableDowngrade) &&
//                                   <DropdownItem className='cursor-pointer w-100' onClick={() => setDowngrade({ venue: venue, downgrading: false, onSuccess: () => setEnableDowngrade(false) })}>
//                                     Revert Update
//                                   </DropdownItem>
//                                 }
//                                 {permissions?.manageFirmware?.update && venue.firmwareStatus == "Update-available" &&
//                                   <DropdownItem className="w-100" onClick={() => {
//                                     setOptions({
//                                       scheduleId: venue?.scheduleId,
//                                       venueId: venue?.venueId,
//                                       scheduleDate: venue?.scheduleDate,
//                                       scheduleSnoozed: venue?.scheduleSnoozed,
//                                       timezone: venue?.venueTimezone
//                                     })
//                                   }}>
//                                     Reschedule Update
//                                   </DropdownItem>
//                                 }
//                                 {permissions?.manageVenue?.create &&
//                                   <DropdownItem className="w-100" onClick={() => {
//                                     navigate(`/organization/${venue.orgId}/venues/${venue.venueId}/add/`)
//                                   }}>
//                                     Add Child
//                                   </DropdownItem>
//                                 }
//                                 {!venue.defaultVenue && permissions.manageVenue.delete &&
//                                   <DropdownItem className="w-100" onClick={() => {
//                                     props.setDeleteModal(venue.venueId)
//                                   }}>
//                                     Delete
//                                   </DropdownItem>
//                                 }
//                               </DropdownMenu>
//                             </UncontrolledDropdown> : null}
//                         </ExpCell>
//                       </ExpVenueRow>
//                     )
//                   })
//                   : <tr><td className="text-center p-3" style={{ height: '46vh', backgroundColor: 'white' }} colSpan={(Object.keys(props.cols)?.length) + 2 > 0 ? (Object.keys(props.cols)?.length) + 2 : 10}>No Venue Found</td></tr>
//               }
//             </Table>
//           </div>
//         </InfiniteScroll>
//       </div>}
//     </div>)
// }

// const sortOpn = [
//   { value: { order: "ASC", orderBy: "venueId" }, label: "None" },
//   { value: { order: "ASC", orderBy: "venueName" }, label: "Name (A-Z)" },
//   { value: { order: "DESC", orderBy: "venueName" }, label: "Name (Z-A)" },
//   { value: { order: "DESC", orderBy: "infrastructureCount" }, label: "Infra (High-Low)" },
//   { value: { order: "ASC", orderBy: "infrastructureCount" }, label: "Infra (Low-High)" },
// ];

export const versionColors = {
  "Up to Date": "#32C997",
  "In Progress": "#FFB240",
  "Failed": "#E02B1D",
  "Update-available": "#5279CE",
  "Out of Date": "#6B32C9"
}

const VenueGraphs = (props) => {
  const {activeOrgId} = props
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  const venueRef = useSelector(state => state.venueDash_venue);
  const venueTasksRef = useSelector(state => state.venueDash_tasks);
  const venueAlarmRef = useSelector(state => state.venueDash_alarms);
  const view = useSelector(store => store.identity.meta.view);

  useTimedCaller({ service: blazer.ORG_VENUE, params: [activeOrgId, true, isoDate(), new Date().getTimezoneOffset()], data: {} }, 0, activeOrgId, state => state.venueDash_venue, Blazer.venueDash_venue.actions);
  useTimedCaller({ service: blazer.ORG_VENUE_TASKS, params: [activeOrgId, true], data: {} }, 0, activeOrgId, state => state.venueDash_tasks, Blazer.venueDash_tasks.actions);
  useTimedCaller({ service: blazer.ORG_VENUE_ALARMS, params: [activeOrgId, isoDate(range), isoDate(), true, new Date().getTimezoneOffset()], data: {} }, range, activeOrgId, state => state.venueDash_alarms, Blazer.venueDash_alarms.actions);

  return (
      <Row className="mb-1">
        {view != DEPLOYMENT_VIEW?
        <Col xl={4} className="pr-0 col-xxl-3">
          {venueRef.isError ?
            <ChartError title="Venue" value={venueRef.isError} /> :
            <VenueChart height={"160px"} series={formatVenueCardData(JSON.parse(venueRef.data)).series} total={formatVenueCardData(JSON.parse(venueRef.data)).total} trend={formatVenueCardData(JSON.parse(venueRef.data)).trend} isLoading={venueRef.isLoading || (!venueRef.isError && !venueRef.data)} main={venueRef.active} setMain={(val) => store.dispatch(Blazer.venueDash_venue.actions.setActive(val))} />}
        </Col>:null}
        <Col xl={4} className="col-xxl-3">
          {view == DEPLOYMENT_VIEW?
          venueRef.isError ?
          <ChartError title="Tasks" value={venueTasksRef.isError} /> :
          <div style={{marginBottom: '6px'}}>
            <VenueTasksCard height={"160px"} series={formatVenueTasksCardData(JSON.parse(venueTasksRef.data)).series} 
            total={formatVenueTasksCardData(JSON.parse(venueTasksRef.data)).total} 
            isLoading={venueTasksRef.isLoading || (!venueTasksRef.isError && !venueTasksRef.data)} 
            main={venueTasksRef.active} 
            setMain={(val) => store.dispatch(Blazer.venueDash_tasks.actions.setActive(val))} />
          </div>
          :venueAlarmRef.isError ?
            <ChartError title="Venue with Active Alarms" value={venueAlarmRef.isError} /> :
            <VenueAlarmChart
              height={"125px"}
              ticks={3}
              categories={formatVenueAlertData(JSON.parse(venueAlarmRef.data)).labels}
              colors={["#9CDAED"]}
              series={formatVenueAlertData(JSON.parse(venueAlarmRef.data)).series}
              isLoading={venueAlarmRef.isLoading || (!venueAlarmRef.isError && !venueAlarmRef.data)}
            />
          }
        </Col>
      </Row>
    )
} 

const VenueTree = () => {
  const { state } = useLocation();
  const SCREEN_ID = "venue-dashboard";
  const view = useSelector(store => store.identity.meta.view);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeOrgType = useSelector(store => store.activeOrg.data.orgTypeId);

  const breadCrumbs = [{
    path: `/organization/${activeOrgId}/venues/`,
    text: "Venues",
    active: true,
  }];

  useEffect(() => {
    // if (permissions?.manageFirmware?.update) {
    //   const { run } = createRequest(services.fwupgrade.GET_LATEST_VERSION, [])
    //   run().then(response => setLatestVersion(response.data));
    // }
    store.dispatch(breadcrumbActions.setData(breadCrumbs))
  }, []);

  return (
    <div className="VenueTree fadable mt-1" data-testid="VenueTree">
      <VenueGraphs activeOrgId={activeOrgId}/>
      <OrgWidget
        SCREEN_ID={SCREEN_ID}
        listType={LIST_TYPES.VENUE_LIST}
        disableCollapseRoot
        filterReq={{
          OrgFilter: activeOrgType != BUSINESS,
          OperationsFilter: true,
          LocationFilter: true,
          VenueStateFilter: true,
          FWFilter: true,
        }}
        showVenueOptions
        />
    </div>
  );
};

// export { MapView }
export default VenueTree;