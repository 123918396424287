import Service from "./Service";
import { TICKET } from "../helpers";
import { UPLOAD } from "../helpers/urlBuilder";
import { BUSINESS_VIEW, DEPLOYMENT_VIEW, NETWORK_VIEW, VIEW_MAPPING } from "../utility/constants";



/** Services related to identity management */
const ticket = {

    // GET Request
    GET_TICKETS: new Service(() => TICKET(`ticket`), "GET"),
    GET_ALL_TICKETS: new Service((orgId, offset, limit, sort, filter, searchTerm) => TICKET(`ticket/organization/${orgId}?offset=${offset}&limit=${limit}&order=${sort.order}` + (searchTerm ? `&search=${searchTerm}` : '') + (sort.orderBy.length > 0 ? `&orderkey=${sort.orderBy}` : '') + (filter?.priorities?.length ? `&severity=${filter.priorities}` : '') + (filter?.statuses?.length ? `&status=${filter.statuses}` : '') + (filter?.infraId ? `&infraId=${filter.infraId}` : '') + (filter?.networkId ? `&networkId=${filter.networkId}` : '')), "GET"),
    GET_TICKET_NOTIFICATION: new Service((orgId) => TICKET(`ticket/notification/count?orgId=${orgId}`), "GET"),
    GET_OPEN_TICKETS: new Service((orgId, offset, limit, mainFilter = "All", sort, filter, searchTerm, cols = {}, venueId, view) => TICKET(`ticket/organization/${orgId}?offset=${offset}&limit=${limit}&type=Bug&filter=${mainFilter}&order=${sort.order}&columns=${cols}` + (searchTerm ? `&search=${searchTerm}` : '') + (venueId ? `&venueId=${venueId}` : '') + (view ? `&view=${VIEW_MAPPING[view]}` : '') + (sort.orderBy.length > 0 ? `&orderkey=${sort.orderBy}` : '')
        + (filter?.priorities?.length ? `&severity=${filter.priorities}` : '')
        + (filter?.statuses?.length ? `&status=${filter.statuses}` : '')
        + (filter?.category?.length ? `&category=${filter.category}` : '')
        + (filter?.subCategories?.length ? `&subCategory=${filter.subCategories}` : '')
        + (filter?.infraId ? `&infraId=${filter.infraId}` : '')
        + (filter?.venueId ? `&venueId=${filter.venueId}` : '')
        + (filter?.networkId ? `&networkId=${filter.networkId}` : '')), "GET"),
    GET_FEATURE_REQUEST: new Service((orgId, offset, limit, mainFilter = "All", sort, filter, searchTerm, cols = {}, venueId, view) => TICKET(`ticket/organization/${orgId}?offset=${offset}&limit=${limit}&type=New Feature&filter=${mainFilter}&order=${sort.order}&columns=${cols}` + (searchTerm ? `&search=${searchTerm}` : '') + (venueId ? `&venueId=${venueId}` : '') + (view ? `&view=${VIEW_MAPPING[view]}` : '') + (sort.orderBy.length > 0 ? `&orderkey=${sort.orderBy}` : '') + (filter?.priorities?.length ? `&severity=${filter.priorities}` : '') + (filter?.statuses?.length ? `&status=${filter.statuses}` : '') + (filter?.categories?.length ? `&category=${filter.categories}` : '') + (filter?.infraId ? `&infraId=${filter.infraId}` : '') + (filter?.venueId ? `&venueId=${filter.venueId}` : '') + (filter?.networkId ? `&networkId=${filter.networkId}` : '')), "GET"),
    GET_CRITICAL_TICKETS: new Service((orgId, offset, limit, sort, filter, searchTerm, venueId, view) => TICKET(`ticket/organization/${orgId}?open=true&severity=3&offset=${offset}&limit=${limit}&order=${sort.order}` + (searchTerm ? `&search=${searchTerm}` : '') + (venueId ? `&venueId=${venueId}` : '') + (view ? `&view=${VIEW_MAPPING[view]}` : '') + (sort.orderBy.length > 0 ? `&orderkey=${sort.orderBy}` : '') + (filter?.priorities?.length ? `&severity=${filter.priorities}` : '') + (filter?.statuses?.length ? `&status=${filter.statuses}` : '') + (filter?.categories?.length ? `&category=${filter.categories}` : '')), "GET"),
    GET_CLOSED_TICKETS: new Service((orgId, offset, limit, sort, filter, searchTerm, venueId, view) => TICKET(`ticket/organization/${orgId}?status=5&open=false&offset=${offset}&limit=${limit}&order=${sort.order}` + (searchTerm ? `&search=${searchTerm}` : '') + (venueId ? `&venueId=${venueId}` : '') + (view ? `&view=${VIEW_MAPPING[view]}` : '') + (sort.orderBy.length > 0 ? `&orderkey=${sort.orderBy}` : '') + (filter?.priorities?.length ? `&severity=${filter.priorities}` : '') + (filter?.statuses?.length ? `&status=${filter.statuses}` : '') + (filter?.categories?.length ? `&category=${filter.categories}` : '')), "GET"),

    GET_SINGLE_TICKET: new Service((ticketId) => TICKET(`ticket/${ticketId}`), "GET"),
    GET_TICKET_COMMENTS: new Service((ticketId) => TICKET(`ticket/${ticketId}/conversations`), "GET"),
    GET_ALL_TICKET_COUNT: new Service((orgId, search, venueId, view) => TICKET(`ticket/organization/${orgId}/count?search=${search}` + (venueId ? `&venueId=${venueId}` : '') + (view ? `&view=${VIEW_MAPPING[view]}` : '')), "GET"),

    GET_TICKET_LAST_COMMENTS: new Service((ticketId) => TICKET(`ticket/${ticketId}/comment`), "GET"),
    UPLOAD_FILE: new Service((orgId, extension, filename) => UPLOAD(`organization/${orgId}/upload?purpose=put&extenstion=${extension}&folder=ticket&filename=${filename}`), "GET"),

    // ----------------------------------------------------------------------

    //  POST Request
    CREATE_TICKET: new Service(() => TICKET(`ticket`), "POST"),
    ADD_COMMENT: new Service((ticketId) => TICKET(`ticket/${ticketId}/reply`), "POST"),
    // ----------------------------------------------------------------------

    // PUT Request 
    UPDATE_TICKET: new Service((ticketId) => TICKET(`ticket/${ticketId}`), "PUT"),
    // ----------------------------------------------------------------------

    // DELETE Request
    DELETE_TICKET: new Service((ticketId) => TICKET(`ticket/${ticketId}`), "DELETE"),
    REMOVE_ATTACHMENT: new Service((orgId, fileName, extension) => TICKET(`ticket/attachment?orgid=${orgId}&extenstion=${extension}&filename=${fileName}`), "DELETE"),
    // ----------------------------------------------------------------------


    // #####################################--- SOLUTIONS ----###############################

    // GET Request

    SEARCH_SOLUTION: new Service((term) => TICKET(`solutions/search/${term}`), "GET"),
    GET_CATEGORY_LIST: new Service(() => TICKET(`solutions/categories`), "GET"),
    GET_CATEGORY_FOLDER: new Service((categoryId) => TICKET(`solutions/categories/${categoryId}/folders`), `GET`),
    GET_ARTICLE_BY_ID: new Service((articleId) => TICKET(`solutions/articles/${articleId}`), "GET"),
    GET_ARTICLES_BY_FOLDER: new Service((folderId) => TICKET(`solutions/folders/${folderId}/articles`), `GET`),
    GET_ARTICLES_BY_CATEGORY: new Service((category) => TICKET(`solutions/categories/${category}/articles`), `GET`)

};

export default ticket;
