import Service from "./Service";
import { N } from "../helpers";

/** Services related to networks */
const networks = {
    GET_NET: new Service((id, offset = 0, limit = 100, searchText = "", order = "ASC", orderBy = "networkId", filterData = {}, children = false, columns=[]) =>
        N(`network/organization/${id}/?offset=${offset}&limit=${limit}&search=${searchText}&order=${order}&orderBy=${orderBy}&children=${children}`
            + (columns?.length>0 ? `&columns=${columns}` : ``)
            + (filterData?.bands?.length ? `&bandType=${filterData.bands}` : ``)
            + (filterData?.networkType?.length ? `&networkType=${filterData.networkType}` : ``)
            + (filterData?.orgs?.length>0 ? `&orgIds=${filterData.orgs.map(it => it.orgId)}` : ``)), "GET"),
    NEW_NET: new Service(() => N(`network`), "POST"),
    GET_NETWORK: new Service((id, orgId) => N(`network/${id}?orgId=${orgId}`), "GET"),
    UPDATE_NETWORK: new Service((id) => N(`network/${id}`), "PUT"),
    ACTIVATE: new Service((id, venueId) => N(`network/${id}/venue/${venueId}`), "POST"),
    DEACTIVATE: new Service((id, venueId) => N(`network/${id}/venue/${venueId}`), "DELETE"),
    DELETE: new Service((id) => N(`network/${id}`), "DELETE"),
    GET_BY_VENUE: new Service((venueId, order = "ASC", orderBy = "networkId", filterData = {}, search = "", children = false) =>
        N(`network/venue/${venueId}?order=${order}&orderBy=${orderBy}&children=${children}`
            + (search.length>0 ? `&search=${search}` : ``)
            + (filterData?.bands?.length ? `&bandType=${filterData.bands}` : ``)
            + (filterData?.networkType?.length ? `&networkType=${filterData.networkType}` : ``)
            + (filterData?.venues?.length>0 ? `&venueIds=${filterData.venues}` : ``)), "GET"),
    GET_BY_INFRA: new Service((infraId) => N(`network/infrastructure/${infraId}`), "GET"),
    GET_FAILEDOPS: new Service((orgId, offset, limit) => N(`failedoperation/organization/${orgId}/?offset=${offset}&limit=${limit}`), "GET"),
    SWITCHCONFIG_GET: new Service((infraItemId, orgId) => N(`inherit/switch/${infraItemId}?orgId=${orgId}`), "GET"),
    SWITCHCONFIG_SET: new Service((infraItemId, orgId) => N(`inherit/switch/${infraItemId}?orgId=${orgId}`), "PUT"),
    SWITCHCONFIG_PATCH: new Service((infraItemId, orgId) => N(`inherit/switch/${infraItemId}?orgId=${orgId}`), "PATCH"),
    SFPCONFIG_SET: new Service(infraItemId => N(`switchconfig/uplink/${infraItemId}`), "PUT"),
    GET_CONFIG: new Service(infraId => N(`configaudit/infrastructure/${infraId}`), "GET"),
    GET_RRM: new Service(orgId => N(`rrm?orgId=${orgId}`), "GET"),
    PUT_RRM: new Service(orgId => N(`rrm/${orgId}`), "PUT"),
    GET_PROFILES: new Service((orgId, search="" ,offset,limit=20, sort={},filterData={}) => N(`profiles?orgId=${orgId}&offset=${offset}&limit=${limit}&children=true&search=${search}&order=${sort.order}&orderBy=${sort.orderBy}`
    + (filterData?.profileType ? `&profileType=${filterData.profileType}` : ``)
    + (filterData?.orgs?.length>0 ? `&orgIds=${filterData.orgs.map(it => it.orgId)}` : ``)),"GET"),
    GET_VENUE_PROFILES: new Service((orgId, venueId, search="" ,offset,limit=20, sort={},filterData={}) => N(`venues/${venueId}/profiles?orgId=${orgId}&offset=${offset}&limit=${limit}&children=true&search=${search}&order=${sort.order}&orderBy=${sort.orderBy}`
    + (filterData?.profileType ? `&profileType=${filterData.profileType}` : ``)
    + (filterData?.venues?.length>0 ? `&venueIds=${filterData.venues}` : ``)),"GET"),
    NEW_PROFILE: new Service(() => N(`profiles`), "POST"),
    GET_PROFILE: new Service((id) => N(`profiles/${id}`),"GET"),
    UPDATE_PROFILE: new Service((id) => N(`profiles/${id}`),"PUT"),
    PATCH_PROFILE: new Service((id) => N(`profiles/${id}`),"PATCH"),
    AP_DEFAULT: new Service((id) => N(`profiles/infratype`),"GET"),
    PROFILE_COUNT: new Service((id, search, navVenue = 0) => N(`profiles/count?orgId=${id}&search=${search}&children=true`
    + (navVenue > 0 ? `&venueId=${navVenue}` : ``)),"GET"),
    DELETE_PROFILE: new Service((id) => N(`profiles/${id}`),"DELETE"),
    GET_PORT_INFRA: new Service((id) => N(`infrastructure/${id}/ports`),"GET"),
    GET_PROFILE_INFRA: new Service((id, orgId) => N(`inherit/ap/${id}?orgId=${orgId}`),"GET"),
    UPDATE_PROFILE_INFRA: new Service((id, orgId) => N(`inherit/ap/${id}?orgId=${orgId}`),"PUT"),
    PATCH_PROFILE_INFRA: new Service((id, orgId) => N(`inherit/ap/${id}?orgId=${orgId}`),"PATCH"),
    SWITCH_STATS: new Service((infraItemId, columns, interval = 0, filter="All", search="", count = false, sort=null) => N(`infrastructure/${infraItemId}/switchstatistics?getCount=${count}&columns=${columns}&filter=${filter}&search=${search}` + 
        (sort !=null ? `&orderBy=${sort?.orderBy}&order=${sort?.order}`:``) + 
        (interval > 0 ? `&interval=${interval}`:``)
    ), "GET"),

    GET_VXLAN: new Service((infraId) => N(`infrastructure/${infraId}/vxlan`), "GET"),
    PUT_VXLAN: new Service((infraId) => N(`infrastructure/${infraId}/vxlan`), "PUT"),

    BANNED_RADIO: new Service((infraId) => N(`infrastructure/${infraId}/radios`), "GET"),
    VERIFY_BANNED: new Service(() => N(`blockClients/verify`),"POST"),

    GET_BAN_CLIENT: new Service((orgId, offset, limit, search = "", sort) => N(`blockClients?orgId=${orgId}&offset=${offset}&limit=${limit}&orderBy=${sort?.orderBy}&order=${sort?.order}`+(search?.length>0?`&search=${search}`:``)), "GET"),
    GET_BAN_CLIENT_NET: new Service((networkId, offset, limit, search = "", sort) => N(`blockClients/network/${networkId}?&offset=${offset}&limit=${limit}&orderBy=${sort?.orderBy}&order=${sort?.order}`+(search?.length>0?`&search=${search}`:``)), "GET"),
    BAN_CLIENT: new Service(() => N(`blockClients`), "POST"),
    GET_CLIENT: new Service((blockId, orgId) => N(`blockClients/${blockId}?orgId=${orgId}`), "GET"),
    REMOVE_BAN_CLIENT: new Service((clientId) => N(`blockClients/${clientId}/removenetwork`), "PUT"),
    ADD_BAN_CLIENT: new Service((networkId) => N(`blockClients/network/${networkId}`), "POST"),
    GET_BANNED_COUNT: new Service((orgId, connectionType, search='') => N(`blockClients/count?orgId=${orgId}` + (['wired', 'wireless'].includes(connectionType)?`&clientType=${connectionType}`:'')+(search?.length>0?`&search=${search}`:``)), "GET"),

    GET_ASSOCIATION: new Service((orgId, networkId=0, profileId=0, profileTypeId=0) => N(`organization/${orgId}/association?networkId=${networkId}&profileId=${profileId}&profileTypeId=${profileTypeId}`))
};

export default networks;