const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

});

export const usdCurrencyFormatter = (amount) => {
    if (amount) {
        let formattedCurrency = currencyFormatter.format(amount)
        if (formattedCurrency.slice(-3) === '.00') {
            formattedCurrency = formattedCurrency.slice(0, -3)
        }
        else if (formattedCurrency.at(-1) === '0') {
            // formattedCurrency = formattedCurrency.slice(0, -1)
            formattedCurrency = formattedCurrency
        }

        return formattedCurrency
    }
    return '$0'

}

export const INPUT_LIMIT = {
    QUOTE_NAME: 80,
    PROJECT_NAME: 35,
    END_CUSTOMER: 35,
    ADDRESS: 75,
    EMAIL: 50,
    ATTENTION: 30,
    NOTES: 128,
    COMMENT: 256,
    PRICE_BOOK_NAME: 35,
    PRICE_BOOK_DESCRIPTION: 200
}

export const PRODUCT_CALL_LIMIT = 40

export const phoneRegEx = new RegExp(/^(\+\d{1,3})?\(?\d{3}\)?\d{3}\d{4}$/)
// export const phoneRegEx = new RegExp(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/)

