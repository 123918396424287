/***
 *
 * Controller class for user.
 * @file NewOverview.js
 * @description NewOverview component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { Suspense, lazy, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, ModalHeader, Progress, Row, UncontrolledButtonDropdown, UncontrolledDropdown, UncontrolledTooltip, Spinner } from "reactstrap";
import { activeVenueActions, breadcrumbActions, infraListActions } from "../../../redux/slices";
import Blazer from "../../../redux/slices/blazer.slice";
import REDUX_WORKER from "../../../redux/workers";
import blazer from "../../../services/blazer.service";
import { formatApRebootData, formatAverageHealthData, formatDevicesCardData, formatInfraCardData, formatNetworkCardData, formatNetworkThreshholdCardData } from "../../Dashboard/NewDashboard/BlazerUtil";
import useTimedCaller from "../../Dashboard/NewDashboard/useTimedCaller";
import Header from "../_builder/Header";
import { isoDate } from "../../Dashboard/Graphs/Utils";
import "./NewOverview.scss";

import { ReactComponent as UpdateIcon } from "../../../assets/images/icons/UpdateIcon.svg";
import createRequest, { services } from "../../../services";
import { ReactComponent as WarnIcon } from "../../../assets/images/icons/exclamation.svg";
import moment from 'moment-timezone';
import { VenueContext } from "../SingleVenue";
import { DowngradeModal, VenueIcon } from "../VenueTree";
import * as Yup from "yup";
import AutoComplete from "react-google-autocomplete";
import { ReactComponent as TicketIcon } from '../../../assets/images/icons/ticket-icon-col.svg'
import { ReactComponent as Historical } from '../../../assets/images/icons/Historical Trend.svg'
import { ReactComponent as ApIcon } from '../../../assets/images/icons/AP.svg'
import { ReactComponent as WarningIcon } from '../../../assets/images/icons/OrangeWarning.svg'
import { ReactComponent as CriticalIcon } from '../../../assets/images/icons/emergency-siren.svg'
import { ReactComponent as MinorIcon } from '../../../assets/images/icons/WarningWhiteExclamation.svg'
import { ReactComponent as GreenTick } from '../../../assets/images/icons/Success.svg'
import { ReactComponent as InstallIcon } from '../../../assets/images/icons/install_cloud_blue.svg'
import { ReactComponent as PhotoIcon } from '../../../assets/images/icons/camera-icon.svg'
import { ReactComponent as PlacementIcon } from '../../../assets/images/icons/placement_blue.svg'
import { ReactComponent as ValidateIcon } from '../../../assets/images/icons/validate-icon_blue.svg'
import { ReactComponent as Design } from "../../../assets/images/icons/BusinessView.svg";
import { ReactComponent as Live } from "../../../assets/images/icons/NetworkView.svg";
import { ReactComponent as Deployed } from "../../../assets/images/icons/ProjectView.svg";
import { DEPLOYMENT_VIEW, VENUE_STATE, VENUE_STATE_LABEL, VENUE_STATE_MAP, VENUE_TYPE_LABEL, VENUE_TYPE_MAPPING, SITE, NONE } from "../../../utility/constants";
import { make_custom_toast } from "../../../helpers/toasts";
import { CatchedWebError } from "../../../configs";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode"
import LayoutListItem from "../FloorPlan2/LayoutListItem";
import { UPTIME } from "../../Infrastructure/Details";
import { Formik } from "formik";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { useNavigate } from "react-router";
import FloorplanSlideView from "../FloorPlan2/components/FloorplanSlideView";
import { FAKE_WALL_DATA, LAYOUT_TYPES } from "../FloorPlan2/constants";
import VenueStateDropdown from "../../../components/VenueStateDropdown";
import AlertVenueTable from "../../../components/AlertVenueTable";
import { dateLeftFinder } from "../../../utility/Utils";
import APSVG from "../../Infrastructure/InfraList/APSVG";
import SwitchSVG from "../../Infrastructure/InfraList/SwitchSVG";
import LightBadge from "../../../components/LightBadge";
import VenueBasicDetails from "../../../components/VenueBasicDetails";
import LinkWrapper from "../../../components/LinkWrapper";
import NetworkThresholdChart from "../../Dashboard/Graphs/NetworkThresholdChart";

const ApRebootChart = lazy(() => import("../../Dashboard/Graphs/ApRebootChart"));
const AvgHealthChart = lazy(() => import("../../Dashboard/Graphs/AvgHealthChart"));
const ChartCard = lazy(() => import("../../Dashboard/Graphs/ChartCard"));
const ChartError = lazy(() => import("../../Dashboard/Graphs/ChartError"));
const DateRangeSelector = lazy(() => import("../../Dashboard/Graphs/DateRangeSelector"));
const DevicesChart = lazy(() => import("../../Dashboard/Graphs/DevicesChart"));
const InfraChart = lazy(() => import("../../Dashboard/Graphs/InfraChart"));
const NetworkChart = lazy(() => import("../../Dashboard/Graphs/NetworkChart"));
const RefreshButton = lazy(() => import("../../Dashboard/Graphs/RefreshButton"));

export const NewoverviewWrapper = () => {
  const activeOrgId = useSelector(state => state.activeOrg.data.orgId);
  const range = useSelector(state => state.activeOrg.meta.dateRange);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const activeVenue = useSelector(store => store.activeVenue.data);

  const [venueId, setVenueId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeVenue != null) {
      setVenueId(activeVenue.venueId);
    }
  }, [activeVenue])


  useEffect(() => {


    if (venuecrumb.venueId !== activeVenue.venueId) {
      REDUX_WORKER.getVenueParent(activeVenue.venueId, activeOrgId);
    }

    if (venuecrumb.venueId === activeVenue.venueId && activeVenue.venueId === Number(venueId))
      dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
      {
        text: activeVenue?.venueName,
        active: false,
      },
      {
        path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/overview/`,
        text: "Overview",
        active: true,
      },
      ]));

  }, [activeVenue, venuecrumb, venueId]);

  return (
    <>
      {activeOrgId && activeVenue.venueId && <NewOverview activeOrgId={activeOrgId} activeVenue={activeVenue} range={range} />}
    </>
  );
};

export const Venue_State_Icons = {
  [VENUE_STATE.DESIGN]: <Design width="100%" height="100%" style={{ height: "22px", width: "22px" }} className="icon  mr-50" />,
  [VENUE_STATE.LIVE]: <Live width="100%" height="100%" style={{ height: "22px", width: "22px" }} className="icon  mr-50" />,
  [VENUE_STATE.DEPLOYMENT]: <Deployed width="100%" height="100%" style={{ height: "22px", width: "22px" }} className="icon  mr-50" />
}

const alarmLink = (venueInfoCount, orgPermissions, activeOrgId) => {
  let link = null;
  if (
    ((venueInfoCount?.alarms?.totalAlarms ?? 0) > 0) &&
    orgPermissions?.manageNotificationstats?.view
  ) {
    link = `/organization/${activeOrgId}/notification`;
    let filter = {
      venues: (venueInfoCount?.alarms?.totalAlarmVenues ?? []).map(id => { return { venueId: id, venueName: '' } }),
      alertType: ['alarm']
    }
    link += `?filter=${JSON.stringify(filter)}`
  }
  return link;
}

const NewOverview = ({ activeOrgId, activeVenue, range, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()


  const infraRef = useSelector(state => state.venue_infra);
  const devicesRef = useSelector(state => state.venue_devices);
  const networkRef = useSelector(state => state.venue_network);
  const view = useSelector(store => store.identity.meta.view);

  const permissions = useSelector(store => store?.rbac?.permissions);


  const healthRef = useSelector(state => state.venue_ap_health);
  const apRebootRef = useSelector(state => state.venue_ap_reboot);

  const infraCumRef = useSelector(state => state.venue_cum_infra);
  const devicesCumRef = useSelector(state => state.venue_cum_devices);
  const networkCumRef = useSelector(state => state.venue_cum_network);
  const healthCumRef = useSelector(state => state.venue_cum_ap_health);
  const apRebootCumRef = useSelector(state => state.venue_cum_ap_reboot);


  // const { venueInfo } = useContext(VenueContext);

  const [scheduleDate, setScheduleDate] = useState(null);
  const [timezone, setTimezone] = useState(null);

  const venueAdd = useRef();
  const formikRef = useRef()
  const [forceRefresh, setForceRefresh] = useState(false);
  const [cumulative, setCumulative] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [enableDowngrade, setEnableDowngrade] = useState(false)
  const [downgrade, setDowngrade] = useState(null)
  const [previousVersion, setPreviousVersion] = useState(null)
  const [currentVersion, setCurrentVersion] = useState(null)
  const [venueInfoCount, setVenueInfoCount] = useState({
    tickets: 0,
    alarms: 0,
    lastStatusAt: 0
  })
  const [venueTasks, setVenueTasks] = useState({
    pendingTasks: 0,
    totalTasks: 0,
    notInstalled: 0,
    notPlaced: 0,
    notPhotos: 0,
    notValidated: 0
  })
  const [venueAddressAlert, setVenueAddressAlert] = useState(false);
  const [address, setAddress] = useState({ venue: activeVenue?.venueAddress?.addressLine });
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [submitError, setSubmitError] = useState(null);


  const [showStateDropdown, setShowStateDropdown] = useState(false)
  // const [showReasonModal, setShowReasonModal] = useState(false)
  const [selectedVenueState, setSelectedVenueState] = useState(activeVenue.state)
  const [venueReason, setVenueReason] = useState(activeVenue.reason)
  const [showTypeDropdown, setShowTypeDropdown] = useState(false)
  const [venueName, setVenueName] = useState(activeVenue?.venueName || '')
  const [venueAddress, setVenueAddress] = useState(activeVenue?.venueAddress?.addressLine || '')
  const [map, setMap] = useState(null)
  const [venues, setVenues] = useState([])
  const [pos, setPos] = useState(null)
  const [floorplanList, setFloorplanList] = useState([])
  const [floorplanLoading, setFloorplanLoading] = useState(false)
  const activeOrgTypeId = useSelector(store => store.activeOrg.data.orgTypeId);

  const activeOrg = useSelector(store => store.activeOrg.data);
  const rbacData = useSelector(store => store?.rbac?.data);

  const [basicVenueDetails, setBasicVenueDetails] = useState({})
  const [basicVenueDetailsLoading, setBasicVenueDetailsLoading] = useState(false)

  Geocode.setApiKey(process.env.REACT_APP_MAP)
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({ lat: 0, lng: 0 });
    map.fitBounds(bounds);
    setMap(map);
  }, [map])

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, [])

  useEffect(() => {
    setTimeout(() =>
      setVenues([
        activeVenue?.shippingAddress?.latitude === undefined ? { lat: 0, lng: 0 } : { lat: activeVenue?.shippingAddress?.latitude, lng: activeVenue?.shippingAddress?.longitude, icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png' }
        , { lat: activeVenue?.venueAddress.latitude, lng: activeVenue?.venueAddress.longitude, icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' }
      ]), 500);
  }, [activeVenue]);


  const saveInput = () => {
    if (formikRef.current) {

      formikRef.current.handleSubmit();
    }
  };


  const getVenueCountData = () => {
    const { run, controller } = createRequest(services.telemetry.GET_VENUE_INFO_COUNT, [activeVenue.venueId])
    run()
      .then(res => {
        setVenueInfoCount(res.data)
      })
      .catch(err => {
        make_custom_toast('error', 'Venue', (new CatchedWebError(err)).message)
      })
  }
  const getVenueTasks = () => {
    const { run, controller } = createRequest(services.telemetry.GET_VENUE_TASKS, [activeVenue.venueId, "AP"])
    run()
      .then(res => {
        setVenueTasks(res.data)
      })
      .catch(err => {
        make_custom_toast('error', 'Venue', (new CatchedWebError(err)).message)
      })
  }

  const getFloorPlanList = () => {
    setFloorplanLoading(true)
    const { run, controller } = createRequest(services.wre.GET_FLOORPLAN_LIST, [activeVenue.venueId])
    run()
      .then(res => {
        const tempData = res.data.sort((a, b) => {
          if (a.layoutJson)
            return -1
          else
            return 1
          return 0
        }

        )
        for (let i in tempData) {
          if (tempData[i].layoutJson == null) {
            tempData[i].layoutJson = FAKE_WALL_DATA
            tempData[i].layoutType = LAYOUT_TYPES.IMAGE
          }
        }
        // setFloorplanList(tempData.filter((floorplan) => floorplan.layoutJson != null))

        setFloorplanList(tempData)
        setFloorplanLoading(false)
      })
      .catch(err => {
        setFloorplanLoading(false)
        make_custom_toast('error', 'Floorplan', (new CatchedWebError(err)).message)
      })
    return controller.abort()
  }

  useEffect(() => {
    getVenueCountData()
    getVenueTasks()
    getFloorPlanList()
    // calling venue details in below  useEffect
  }, [])

  useEffect(() => {

    if (activeVenue?.venueId) {
      const { run } = createRequest(services.infra.GET_ERROR, [activeVenue?.venueId]);
      run()
        .then((response) => {
          setErrorArray(response?.data);
        })
        .catch((err) => {
          //do nothing
        })
    }

    // getVenueBasicDetails()
  }, [activeVenue?.venueId])



  const getVenueBasicDetails = () => {
    setBasicVenueDetailsLoading(true)
    const { run } = createRequest(services.telemetry.GET_VENUE_DETAILS, [activeVenue?.venueId])
    run()
      .then(res => {
        setBasicVenueDetailsLoading(false)
        setBasicVenueDetails(res.data)
      })
  }

  useTimedCaller({ service: blazer.VENUE_INFRA, params: [activeVenue.venueId, cumulative], data: {} }, range, activeVenue.venueId, cumulative ? state => state.venue_cum_infra : state => state.venue_infra, cumulative ? Blazer.venue_cum_infra.actions : Blazer.venue_infra.actions, forceRefresh, setForceRefresh);
  useTimedCaller({ service: blazer.VENUE_DEVICES, params: [activeVenue.venueId, isoDate(range), isoDate(), cumulative, new Date().getTimezoneOffset()], data: {} }, range, activeVenue.venueId, cumulative ? state => state.venue_cum_devices : state => state.venue_devices, cumulative ? Blazer.venue_cum_devices.actions : Blazer.venue_devices.actions, forceRefresh, setForceRefresh);
  useTimedCaller({ service: blazer.VENUE_NETWORK, params: [activeVenue.venueId, isoDate(range), isoDate(), cumulative, new Date().getTimezoneOffset()], data: {} }, range, activeVenue.venueId, cumulative ? state => state.venue_cum_network : state => state.venue_network, cumulative ? Blazer.venue_cum_network.actions : Blazer.venue_network.actions, forceRefresh, setForceRefresh);
  useTimedCaller({ service: blazer.AP_HEALTH_VENUE, params: [activeVenue.venueId, isoDate(range), isoDate(), cumulative, new Date().getTimezoneOffset()], data: {} }, range, activeVenue.venueId, cumulative ? state => state.venue_cum_ap_health : state => state.venue_ap_health, cumulative ? Blazer.venue_cum_ap_health.actions : Blazer.venue_ap_health.actions, forceRefresh, setForceRefresh);
  useTimedCaller({ service: blazer.AP_REBOOT_VENUE, params: [activeVenue.venueId, isoDate(range), isoDate(), cumulative, new Date().getTimezoneOffset()], data: {} }, range, activeVenue.venueId, cumulative ? state => state.venue_cum_ap_reboot : state => state.venue_ap_reboot, cumulative ? Blazer.venue_cum_ap_reboot.actions : Blazer.venue_ap_reboot.actions, forceRefresh, setForceRefresh);



  const infraSetRef = cumulative ? infraCumRef : infraRef;
  const devicesSetRef = cumulative ? devicesCumRef : devicesRef;
  const networkSetRef = cumulative ? networkCumRef : networkRef;
  const healthSetRef = cumulative ? healthCumRef : healthRef;
  const apRebootSetRef = cumulative ? apRebootCumRef : apRebootRef;

  useEffect(() => {
    if (activeVenue.venueId) {
      const { run } = createRequest(services.fwupgrade.GET_VENUE_SCHEDULE, [activeVenue.venueId]);
      run()
        .then(response => {
          let prevVersion = response?.data?.previousVersion
          let currVersion = response?.data?.currentVersion
          setEnableDowngrade((permissions?.manageFirmware?.update &&
            !!currVersion &&
            currVersion !== prevVersion))
          setPreviousVersion(prevVersion)
          setCurrentVersion(currVersion)

          if (response.data?.status === 'Scheduled' && !(response.data?.completedDate)) {
            // setScheduleDate(response.data?.scheduleDate);
            if (response.data?.scheduleDate && !!moment.tz.zone(response.data?.timezone)) {
              const scheduleDateObj = (moment.utc(response?.data?.scheduleDate));
              setScheduleDate(((scheduleDateObj.tz(response?.data?.timezone)).format('YYYY-MM-DDTHH:mm:ss.000')));
              setTimezone(response.data?.timezone);
            }
          }
        })
        .catch(err => {
          //not showing this error on UI
          // setError((new CatchedWebError(err)).message);
        })
    }
  }, [activeVenue])


  const DeploymentVenueData = () => {
    return (
      <div className="bg-white rounded " style={{ height: '8.4em' }}>
        <div className="fw-600 mb-1">
          Pending Tasks {`(${basicVenueDetails?.pendingTasks}/${basicVenueDetails?.totalTasks})`}
        </div>

        <div >
          <Row>
            <Col>
              <div className="pending-task-row d-flex align-items-center mb-50"><InstallIcon width={22} height={22} /> Initialize ({basicVenueDetails?.initializePending})</div>
            </Col>
            <Col>
              <div className="pending-task-row d-flex align-items-center mb-50"><PhotoIcon width={22} height={22} /> Photo ({basicVenueDetails?.photosPending})</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="pending-task-row d-flex align-items-center mb-50"><PlacementIcon width={22} height={22} /> Physical Install ({basicVenueDetails?.installPending})</div>
            </Col>
            <Col>
              <div className="pending-task-row d-flex align-items-center mb-50"><ValidateIcon width={22} height={22} /> Validate ({basicVenueDetails?.validatePending})</div>
            </Col>
          </Row>
        </div>
        <div className="mt-50">
          <Progress value={(1 - (venueTasks.pendingTasks / venueTasks.totalTasks)) * 100} color="success" style={{ fontSize: "0.75rem" }}>
            {((1 - (venueTasks.pendingTasks / venueTasks.totalTasks)) > 0.2) ? `${Math.floor((1 - (venueTasks.pendingTasks / venueTasks.totalTasks)) * 100)}%` : ""}
          </Progress>
        </div>

      </div>
    )
  }

  const NetworkViewData = () => {
    const venuePermissions = useContext(VenueContext)
    const orgPermissions = useSelector(store => store?.rbac?.permissions)
    return <Col xs={2} className="d-flex flex-column justify-content-center px-2 rounded bg-white">
      <div className={`d-flex align-items-center justify-content-center noti-pill rounded border  px-1 py-custom mb-1 ${(venueInfoCount?.tickets ?? 0) > 0 && (venuePermissions?.venue > NONE || (venuePermissions?.venue == null && orgPermissions?.manageVenue?.view)) ? 'table-link' : ''}`}
        onClick={() => {
          if ((venuePermissions?.venue > NONE || (venuePermissions?.venue == null &&
            orgPermissions?.manageVenue?.view)) &&
            orgPermissions?.manageTicket?.view && venueInfoCount?.tickets > 0)
            navigate(`/organization/${activeOrgId}/venues/${activeVenue.venueId}/admin`)
        }}>
        <TicketIcon height={24} className="mr-1" />
        <span>
          {venueInfoCount?.tickets} {(venueInfoCount?.tickets ?? 0) == 1 ? "Ticket" : "Tickets"}
        </span>
      </div>
      <div className={`d-flex  align-items-center justify-content-center noti-pill rounded border  px-1 py-custom ${(venueInfoCount?.alarms?.totalAlarms ?? 0) > 0 && orgPermissions?.manageNotificationstats?.view ? "table-link" : ""}`}
      >
        {Number(venueInfoCount?.alarms?.totalAlarms) === 0 ? <GreenTick height={24} className="mr-1" /> : <WarningIcon height={24} className="mr-1" />}
        <LinkWrapper
          to={alarmLink(venueInfoCount, orgPermissions, activeOrgId)}
        >
          {venueInfoCount?.alarms?.totalAlarms} {(venueInfoCount?.alarms?.totalAlarms ?? 0) == 1 ? "Alarm" : "Alarms"}
        </LinkWrapper>
      </div>

      {/* <div className="d-flex align-items-center bg-white rounded py-custom  pl-3 mb-50">
        <ApIcon height={24} className="mr-1" />
        <span id={'uptimeId'} >
          {venueInfoCount.lastStatusAt ? UPTIME(venueInfoCount.lastStatusAt, true, true) : '-'}
        </span>
        <UncontrolledTooltip target={'#uptimeId'}>  {venueInfoCount.lastStatusAt ? UPTIME(venueInfoCount.lastStatusAt, false, true) : '-'}</UncontrolledTooltip>
      </div> */}
    </Col>
  }


  const BasicVenueDetails = () => {
    return (
      <div style={{ width: '60%', height: '8.42em' }} className="d-flex flex-column justify-content-between">
        <div className="d-flex mb-50 justify-content-between">
          <span>
            Alarms
          </span>
          <span className="d-flex">
            {
              (basicVenueDetails?.criticalAlarm > 0) &&
              <span className="d-flex">
                <CriticalIcon width="18px" className="mr-50 ml-1" />
                {basicVenueDetails?.criticalAlarm}
              </span>
            }

            {
              (basicVenueDetails?.majorAlarm > 0) &&
              <span className="d-flex"> <WarningIcon width="18px" className="mr-50 ml-1" /> {basicVenueDetails?.criticalAlarm}</span>

            }
            {
              (basicVenueDetails?.minorAlarm > 0) &&
              <span className="d-flex"> <MinorIcon width="18px" className="mr-50 ml-1" /> {basicVenueDetails?.criticalAlarm}</span>
            }
            {
              (basicVenueDetails?.criticalAlarm < 1 && basicVenueDetails?.majorAlarm < 1 && basicVenueDetails?.minorAlarm < 1) &&
              <span>0</span>
            }
          </span>
        </div>
        <div className="d-flex mb-1 justify-content-between align-items-end">
          <span>
            Infrastructure
          </span>
          <span ><span style={{ fontSize: '20px' }} className="text-success">{basicVenueDetails?.onlineInfras ?? 0}</span>/{basicVenueDetails?.totalInfras ?? 0}</span>
        </div>
        <div className="d-flex justify-content-between">
          <span>
            Release
          </span>
          <LightBadge className="m-0" color="success">{basicVenueDetails?.firmwareVersion}</LightBadge>
        </div>
      </div>)
  }

  return (
    <div className="NewOverview" data-testid="NewOverview">
      {/* <Modal centered isOpen={showReasonModal}>
        <ModalHeader className="bg-white" toggle={() => { setShowReasonModal(false); setVenueReason('') }}><h5>Reason for {VENUE_STATE_LABEL[selectedVenueState]} Status<span className="text-danger">*</span></h5></ModalHeader>
        <ModalBody className="py-0">
          <div className="pb-1">
            <Input
              type="textarea"
              value={venueReason} onChange={(e) => {
                setVenueReason(e.target.value);
              }} />
            <div className="d-flex align-items-center justify-content-end mt-50">
              <Button color="primary" className="small-add-button" onClick={() => {
                setShowReasonModal(false);
              }}>Save</Button>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
      <Row className="p-0 m-0">
        <Col className="p-0 m-0" xs={11}>
          {props.hideHeader ? <div className="mb-1"></div> : <Header heading={activeVenue.venueName} />}
        </Col>
        <Col className="p-0 m-0" xs={1}>
          {/* <Button color="primary" className="small-add-button mt-50" onClick={saveInput}>Update</Button> */}
        </Col>
      </Row>
      <DowngradeModal downgrade={downgrade} setDowngrade={setDowngrade} />
      {/* --- Formik removed from here --- */}

      {/* New View */}
      <VenueBasicDetails linkName={props.hideHeader} />
      {/* <div>

        <span className="d-flex py-1">
          {
            basicVenueDetails?.scheduledAt &&
            <span className="px-1 py-50 yellow-info-badge shadow-sm rounded mr-1">
              <UpdateIcon width={24} height={24} />
              Venue firmware upgrade in &nbsp; <span className="text-primary cursor-pointer">{dateLeftFinder(basicVenueDetails?.scheduledAt)}</span>
            </span>
          }
          {
            basicVenueDetails?.awaitingTickets > 0 &&
            <span className="d-flex align-items-center px-1 py-50 yellow-info-badge shadow-sm rounded">
              <TicketIcon width={22} height={24} />
              <span className="text-primary cursor-pointer ml-50">1 Ticket</span> &nbsp; awaiting customer feedback
            </span>
          }
        </span>
        <Row className="p-0 m-0">
          <Col xs="5" className="p-0 ">
            <div className="bg-white rounded p-2 d-flex flex-column justify-content-between" style={{ height: '160px' }}>
              <div className="d-flex justify-content-between">
                <div>
                  {Number(venueInfoCount?.alarms?.totalAlarms) === 0 ? <GreenTick height={24} className="mr-1" /> : <WarningIcon height={24} className="mr-1" />}
                  {VenueIcon[activeVenue.venueType]}
                  <span className="text-primary">
                    {activeVenue.venueName}
                  </span>
                </div>

                <div className="d-flex align-items-center">
                  <span className="mr-50">
                    State
                  </span>
                  <div className="venueStateGrp">
                    <div className={"stateSelect " + (selectedVenueState == VENUE_STATE.DESIGN ? "design-white bg-primary" : "design-black")}><Design width={18} height={18} /></div>
                    <div className={"stateSelect " + (selectedVenueState == VENUE_STATE.DEPLOYMENT ? "deployed-white bg-primary" : "deployed-black")}><Deployed width={18} height={18} /></div>
                    <div className={"stateSelect " + (selectedVenueState == VENUE_STATE.LIVE ? "live-white bg-primary" : "live-black")}><Live width={18} height={18} /></div>
                  </div>

                </div>
              </div>
              <div className="d-flex mt-1">
                <APSVG width="22px" className="mr-1" />
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.Ceiling ?? 0} x Ceiling
                </span>
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.["Wall Plate"] ?? 0}  x Wall Plate
                </span>
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.Outdoor ?? 0}  x Outdoor
                </span>
              </div>

              <div className="d-flex mt-1">
                <SwitchSVG className="mr-1" />
                <span className="mr-1">
                  {basicVenueDetails?.infraTypeCount?.["48 Port Switch"] ?? 0}  x 48 Ports
                </span>
              </div>
            </div>
          </Col>
          <Col xs="4" className="p-0 pl-1">
            <div className="bg-white rounded p-2" >
              {
                view != DEPLOYMENT_VIEW ?
                  <BasicVenueDetails />
                  : <DeploymentVenueData />
              }
            </div>
          </Col>
          <Col xs="3" >
            <div className="bg-white rounded p-1 pt-0">
              <div title={activeVenue?.venueAddress?.addressLine} className="address-title mb-50">{activeVenue?.venueAddress?.addressLine}</div>

              {<GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: '7.45em'
                }}

                zoom={14}
                onLoad={onLoad}
                options={{
                  disableDefaultUI: true,
                }}
                onUnmount={onUnmount}
              >
                {
                  <div>
                    {venues && venues.map((item, index) => {
                      if (item.lat === undefined)
                        return <React.Fragment key={index}></React.Fragment>
                      map?.panTo({ lat: item.lat, lng: item.lng })
                      map?.setZoom(10);
                      return <Marker key={index} position={{ lat: item.lat, lng: item.lng }}
                        icon={item?.icon} />
                    }
                    )}
                    {(!!pos) && <Marker position={pos} />}{!!(pos) && map?.panTo(pos)}
                  </div>
                }
              </GoogleMap>}
            </div>
          </Col>
        </Row>
      </div> */}
      {/* ------------------------------------------------------------------------ */}

      <div className="d-flex justify-content-end align-items-center">
        <div className='d-flex justify-content-end'>
          {/* {errorArray?.length > 0 &&
            <div className='d-flex align-items-center'>
              <WarnIcon id="venueOverviewError" className='infra-error-info mr-2' height='32' width='32' />
              <UncontrolledTooltip target="venueOverviewError" placement="down">
                {errorArray?.map((errorItem, key) => {
                  return <div key={key}>For {errorItem?.count} Infrastructure(s): {errorItem?.errorCode}</div>
                })}
              </UncontrolledTooltip>
            </div>
          } */}
          {/* } */}

          {/* {
            view != DEPLOYMENT_VIEW &&
           
          } */}
        </div>
      </div>
      {

        view != DEPLOYMENT_VIEW ?
          <>

            <div className="bg-white mt-1 pt-1 rounded">
              <div className="d-flex justify-content-between align-items-center">
                <span className="row-heading d-flex align-items-center">
                  <Historical className="mr-50 ml-1" width={24} height={24} />
                  Historical Trends
                </span>
                <div className="d-flex">
                  <Suspense fallback={<></>}>
                    <DateRangeSelector />
                  </Suspense>
                  <Suspense fallback={<></>}>
                    <RefreshButton clickState={forceRefresh} clickHandler={setForceRefresh} />
                  </Suspense>
                  {enableDowngrade
                    ? <UncontrolledDropdown direction="left">
                      <DropdownToggle color='white' size='sm' className="w-0 p-0">
                        <span className="material-symbols-outlined cursor-pointer text-primary " style={{ marginTop: '4px' }}>more_vert</span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem className="w-100"
                          disabled={!previousVersion}
                          onClick={() =>
                            setDowngrade(
                              {
                                venue: {
                                  orgId: activeVenue.orgId, venueId: activeVenue.venueId, previousVersion: previousVersion,
                                  firmwareVersion: currentVersion
                                },
                                downgrading: false, onSuccess: () => setEnableDowngrade(false)
                              })}>
                          Revert Update
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    : null}
                </div>
              </div>
              <Row className="p-0 m-0 mt-1">

                <Col xs={3} className=" ">
                  {healthSetRef.isError ?
                    <Suspense fallback={<></>}>
                      <ChartError title="Average Health" value={healthRef.isError} />
                    </Suspense> :
                    <Suspense fallback={<></>}>
                      <AvgHealthChart
                        height={"160px"}
                        // width={"110%"}
                        ticks={3}
                        showPercentage={true}
                        categories={formatAverageHealthData(JSON.parse(healthRef.data)).labels}
                        colors={["#9CDAED"]}
                        series={formatAverageHealthData(JSON.parse(healthRef.data)).series}
                        isLoading={healthRef.isLoading || (!healthRef.isError && !healthRef.data)}
                      />
                    </Suspense>
                  }
                </Col>
                <Col xs={3} className="">
                  {apRebootSetRef.isError ?
                    <Suspense fallback={<></>}>
                      <ChartError title="AP REBOOT" value={apRebootRef.isError} />
                    </Suspense> :
                    <Suspense fallback={<></>}>
                      <ApRebootChart
                        height={"160px"}
                        // width={"110%"}
                        ticks={3}
                        categories={formatApRebootData(JSON.parse(apRebootRef.data)).labels}
                        colors={["#ED9F9C"]}
                        series={formatApRebootData(JSON.parse(apRebootRef.data)).series}
                        isLoading={apRebootRef.isLoading || (!apRebootRef.isError && !apRebootRef.data)}
                      />
                    </Suspense>}
                </Col>

                <Col xs={3} className="">
                  {networkSetRef.isError ?
                    <Suspense fallback={<></>}>
                      <ChartError title="NETWORK TRAFFIC" value={networkSetRef.isError} />
                    </Suspense> :
                    <Suspense fallback={<></>}>
                      <NetworkThresholdChart
                        height={"160px"}
                        // width={"110%"}
                        up={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).up}
                        down={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).down}
                        total={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).total}
                        categories={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).labels}
                        ticks={3}
                        series1={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).series1}
                        series2={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).series2}
                        series3={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).series3}
                        series4={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).series4}
                        series5={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).series5}
                        series6={formatNetworkThreshholdCardData(JSON.parse(networkSetRef.data)).series6}
                        typeSeriesTrafficDownlink={formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).typeSeriesTrafficDownlink}
                        typeSeriesTrafficUplink={formatNetworkThreshholdCardData(JSON.parse(networkRef.data)).typeSeriesTrafficUplink}
                        isLoading={networkSetRef.isLoading || (!networkSetRef.isError && !networkSetRef.data)}

                      />
                    </Suspense>}
                </Col>
                <Col xs={3} className="">
                  {devicesSetRef.isError ?
                    <Suspense fallback={<></>}><ChartError title="Connected Wireless Clients" value={devicesSetRef.isError} /></Suspense> :
                    <Suspense fallback={<></>}>
                      <DevicesChart currentlyConnected={formatDevicesCardData(JSON.parse(devicesSetRef.data)).currentlyConnected} categories={formatDevicesCardData(JSON.parse(devicesSetRef.data)).labels} colors={["#5279CE"]} series={
                        formatDevicesCardData(JSON.parse(devicesSetRef.data)).series
                      }
                        height={"160px"}
                        // width={"110%"}
                        ticks={3}
                        isLoading={devicesSetRef.isLoading || (!devicesSetRef.isError && !devicesSetRef.data)} />
                    </Suspense>}
                </Col>
              </Row>

            </div>
            <AlertVenueTable venueId={activeVenue.venueId} />
          </>
          :
          <div>
            {/* --- deployment view --- */}
            {
              floorplanLoading ?
                <div className="center-div bg-white p-3 rounded mt-2 " style={{ height: '200px' }}>
                  <Spinner color="primary" />
                </div>
                : floorplanList.length > 0 ?
                  <FloorplanSlide floorplanList={floorplanList} venueId={activeVenue.venueId} />
                  :
                  <div className="center-div bg-white p-3 rounded mt-2 " style={{ height: '200px' }}>
                    No Floorplan Available
                  </div>
            }
          </div>
      }

    </div >
  );
};

const FloorplanSlide = ({ floorplanList, venueId }) => {
  const [floorplanIndex, setFloorplanIndex] = useState(0)
  return (

    <div className=" p-1 bg-white rounded mt-1 mr-1">
      <div className="slide-container " >
        <FloorplanSlideView
          layout={floorplanList[floorplanIndex]}
          hideDropdown={true}
          onDelete={() => { }}
          isDesignLayout={true}
          hideBackGrid={true}
          onHeaderClick={() => { }}
          showAP={true}
          showRealAp={true}
          showCableDrop={true}
          venueId={venueId}
        />

      </div >

      <div className="d-flex w-100 align-items-center justify-content-center">
        {
          floorplanList.length > 1 &&
          <div
            className={" center-div  " + (floorplanList.length > 1 ? 'slide-button ' : 'slide-button-disabled ') + (floorplanIndex > 0 ? 'cursor-pointer' : 'cursor-not-allowed')}
            onClick={() => {
              if (floorplanIndex > 0)
                setFloorplanIndex(floorplan => floorplan - 1)
            }}>
            <span class="material-symbols-outlined">
              chevron_left
            </span>
          </div>
        }

        <div className="  mx-50">
          <div className="d-flex center-div mx-2">
            {
              floorplanList.length > 1 && floorplanList.map((plan, index) => {
                return <div className={index == floorplanIndex ? "slide-grey-dot-selected cursor-pointer" : "slide-grey-dot cursor-pointer"}
                  onClick={() => setFloorplanIndex(index)} />
              })
            }
          </div>
        </div>

        {
          floorplanList.length > 1 &&
          <div
            className={"center-div  " + (floorplanList.length > 1 ? 'slide-button ' : 'slide-button-disabled ') + (floorplanIndex < floorplanList.length - 1 ? 'cursor-pointer' : 'cursor-not-allowed')}
            onClick={() => {
              if (floorplanIndex < floorplanList.length - 1)
                setFloorplanIndex(floorplan => floorplan + 1)
            }}>
            <span class="material-symbols-outlined">
              chevron_right
            </span>
          </div>
        }
      </div>

    </div >

  )
}

export default NewOverview;
