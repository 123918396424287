/***
 *
 * Controller class for user.
 * @file InfraInsightCharts.js
 * @description InfraInsightCharts component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import Blazer from "../../redux/slices/blazer.slice";
import blazer from "../../services/blazer.service";
import { isoDate } from "../../pages/Dashboard/Graphs/Utils";
import "./InfraInsightCharts.scss";
import { ReactComponent as Historical } from '../../assets/images/icons/Historical Trend.svg'

import DateRangeSelector from "../../pages/Dashboard/Graphs/DateRangeSelector";
import RefreshButton from "../../pages/Dashboard/Graphs/RefreshButton";
import useTimedCaller from "../../pages/Dashboard/NewDashboard/useTimedCaller";
import OneGraph from "../../pages/Infrastructure/Insight/OneGraph";

const InfraInsightCharts = () => {
    const infraName = useSelector(store => store.oneInfra.data.infraDisplayName);
    const infraSerial = useSelector(store => store.oneInfra.data.macAddress);
    const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
    const infra = useSelector(store => store.oneInfra.data);
    const infraData = useSelector(store => store.oneInfra.data);
    const range = useSelector(state => state.activeOrg.meta.dateRange);
    const activeId = useSelector(state => state.activeOrg.data.orgId);
    const deviceTypes = useSelector(store => store.infraTypes.data);
    const [forceRefresh, setForceRefresh] = useState(false);
    // const [range, setRange] = useState(0.25);

    const dispatch = useDispatch();

    // useEffect(() => {
    //   dispatch(activeOrgActions.setDateRange(0.25));
    //   return () => {
    //     // console.log("return executed");
    //     if(range===0.25) dispatch(activeOrgActions.setDateRange(6));
    //   }
    // },[])




    // useEffect(() => {
    //   console.log("range: ",range);
    // }, [range])

    useTimedCaller({ service: blazer.INFRA_LOAD, params: [infraSerial, isoDate(range), isoDate(), new Date().getTimezoneOffset()], data: {} }, range, infraSerial, state => state.infra_load, Blazer.infra_load.actions, forceRefresh, setForceRefresh);
    useTimedCaller({ service: blazer.INFRA_CHANNEL_UTILIZATION, params: [infraSerial, "2G", isoDate(range), isoDate(), new Date().getTimezoneOffset()], data: {} }, range, infraSerial, state => state.channel_utilization_2g, Blazer.channel_utilization_2g.actions, forceRefresh, setForceRefresh);
    useTimedCaller({ service: blazer.INFRA_CHANNEL_UTILIZATION, params: [infraSerial, "5G", isoDate(range), isoDate(), new Date().getTimezoneOffset()], data: {} }, range, infraSerial, state => state.channel_utilization_5g, Blazer.channel_utilization_5g.actions, forceRefresh, setForceRefresh);
    useTimedCaller({ service: blazer.INFRA_CHANNEL_UTILIZATION, params: [infraSerial, "6G", isoDate(range), isoDate(), new Date().getTimezoneOffset()], data: {} }, range, infraSerial, state => state.channel_utilization_6g, Blazer.channel_utilization_6g.actions, forceRefresh, setForceRefresh);
    useTimedCaller({ service: blazer.INFRA_MEMORY, params: [infraSerial, isoDate(range), isoDate(), new Date().getTimezoneOffset()], data: {} }, range, infraSerial, state => state.infra_memory, Blazer.infra_memory.actions, forceRefresh, setForceRefresh);
    useTimedCaller({ service: blazer.INFRA_DEVICES, params: [infraSerial, isoDate(range), isoDate(), new Date().getTimezoneOffset()], data: {} }, range, infraSerial, state => state.infra_devices, Blazer.infra_devices.actions, forceRefresh, setForceRefresh);
    useTimedCaller({ service: blazer.INFRA_NETWORK, params: [infraSerial, isoDate(range), isoDate(), new Date().getTimezoneOffset()], data: {} }, range, infraSerial, state => state.infra_network, Blazer.infra_network.actions, forceRefresh, setForceRefresh);

    const GRAPH_HEIGHT = "150px"

    return (
        <div className="InfraInsightCharts rounded bg-white my-1 p-1" data-testid="InfraInsightCharts">
            <div className="d-flex justify-content-between mb-1 ">
                <span className="row-heading d-flex align-items-center">
                    <Historical className="mr-50" width={24} height={24} />
                    Historical Trends
                </span>

                <span className="d-flex">
                    <DateRangeSelector />
                    <RefreshButton clickState={forceRefresh} clickHandler={setForceRefresh} />
                </span>
            </div>

            <Row>

                <Col xs={3}>
                    <OneGraph chartType='LOAD' range={range} height={GRAPH_HEIGHT} ticks={3} />
                </Col>
                <Col xs={3}>
                    <OneGraph chartType='MEMORY' range={range} height={GRAPH_HEIGHT} ticks={3} />
                </Col>
                <Col xs={3}>
                    <OneGraph chartType='Connected Wireless Clients' range={range} height={GRAPH_HEIGHT} ticks={3} />
                </Col>
                <Col xs={3}>
                    <OneGraph chartType='THRESHOLD' range={range} height={GRAPH_HEIGHT} ticks={3} noMarker={true} />
                </Col>
            </Row>
            <Row className="mt-1">
                <Col xs={3}>
                    <OneGraph chartType='TRAFFIC' range={range} height={GRAPH_HEIGHT} ticks={3} noMarker={true} />
                </Col>
                {
                    infraData?.radio2G.supported &&
                    <Col xs={3}>
                        <OneGraph chartType='CHANNEL_2G' range={range} height={GRAPH_HEIGHT} ticks={3} />
                    </Col>
                }
                {
                    infraData?.radio5G.supported &&
                    <Col xs={3}>
                        <OneGraph chartType='CHANNEL_5G' range={range} height={GRAPH_HEIGHT} ticks={3} />
                    </Col>
                }
                {
                    infraData?.radio6G.supported &&
                    <Col xs={3}>
                        <OneGraph chartType='CHANNEL_6G' range={range} height={GRAPH_HEIGHT} ticks={3} />
                    </Col>
                }

            </Row>
        </div>
    );
};

export default InfraInsightCharts;
