/**
 * Convert Infra API response to Chart-compatible data
 * :: BACKEND =====> VASHU__TELEMETRY
 * @param {Response} apiResponse - APIResponse
 * @returns Appropriate graph component format bundled in a javascript object
 */
export function formatInfraCardData(apiResponse) {
  let cardData = {
    series: [apiResponse?.connected ?? 0, apiResponse?.disconnected ?? 0, apiResponse?.pending ?? 0, apiResponse?.demo ?? 0],
    trend: [apiResponse?.connectedInfraTrend ?? 0, apiResponse?.disconnectedInfraTrend ?? 0, apiResponse?.pendingInfraTrend ?? 0, apiResponse?.demoInfraTrend ?? 0]
  };
  return cardData;
}

/**
 * Convert Firmware Version API response to Chart-compatible data
 * :: BACKEND =====> SNEHA_FIRMWARE
 * @param {Response} apiResponse - APIResponse
 * @returns Appropriate graph component format bundled in a javascript object
 */
export function formatFirmwareVerisonCardData(apiResponse) {
  let cardData = {
    series: [apiResponse?.['Up to date'] ?? 0, apiResponse?.['Others'] ?? 0, apiResponse?.Failed ?? 0, apiResponse?.["Update-available"] ?? 0],
    total: apiResponse?.totalInfras
  };
  return cardData;
}


/**
 * Convert Venue API to Chart-compatible data
 * :: BACKEND =====> VASHU__TELEMETRY
 * @param {Response} apiResponse - APIResponse
 * @returns Appropriate graph component format bundled in a javascript object
 */
export function formatVenueCardData(apiResponse) {
  let cardData = {
    series: [apiResponse?.venuesWithAlarm ?? 0, apiResponse?.onlineVenues ?? 0, apiResponse?.offlineVenues ?? 0, apiResponse?.inactiveVenues ?? 0],
    trend: [apiResponse?.venuesWithAlarmTrend ?? 0, apiResponse?.onlineVenuesTrend ?? 0, apiResponse?.offlineVenuesTrend ?? 0, apiResponse?.inactiveVenuesTrend ?? 0],
    total: apiResponse?.totalVenues
  };
  return cardData;
}
export function formatVenueTasksCardData(apiResponse) {
  let cardData = {
    series: [apiResponse?.pendingTasks ?? 0, apiResponse?.completedTasks ?? 0],
    total: apiResponse?.totalTasks
  };
  return cardData;
}

export function formatWebhookCardData(apiResponse) {
  let cardData = {
    series: [
      apiResponse?.success ?? 0,
      apiResponse?.failures ?? 0,
      apiResponse?.series400 ?? 0,
      apiResponse?.series500 ?? 0,
      apiResponse?.rateLimit ?? 0,
    ],
    total: apiResponse?.total,
  };
  return cardData;
}

export function formatClientCardData(apiResponse) {
  let cardData = {
    series: [apiResponse?.wired ?? 0, apiResponse?.wireless ?? 0],
    total: apiResponse?.all ?? 0,
  };
  return cardData;
}

/**
 * Convert Support API to Chart-compatible data
 * :: BACKEND =====> SNEHA__SUPPORT
 * @param {Response} apiResponse - APIResponse
 * @returns Appropriate graph component format bundled in a javascript object
 */
export function formatSupportCardData(apiResponse) {
  let cardData = {
    ticketsData: {
      open: apiResponse?.openTickets ?? 0,
      awaiting: apiResponse?.waitingTickets ?? 0,
      top: apiResponse?.tickets.map(it => ({ name: it.subject, id: it.freshworksTicketId })) ?? []
    }
  };
  return cardData;
}

/**
 * Convert Devices API to Chart-compatible data
 * :: BACKEND =====> VASHU__TELEMETRY
 * @param {Response} apiResponse - APIResponse
 * @returns Appropriate graph component format bundled in a javascript object
 */
export function formatDevicesCardData(apiResponse) {
  let cardData = {
    currentlyConnected: apiResponse?.connected ?? 0,
    series: {
      name: "Connected Clients",
      data: apiResponse?.userdeviceTrend?.map(it => Number(it.device_count)) ?? [],
    },
    labels: apiResponse?.userdeviceTrend?.map(it => it.label) ?? []
  };
  return cardData;
}

export function formatAverageHealthData(apiResponse) {
  let cardData = {
    series: {
      name: "Average Uptime",
      data: apiResponse?.uptimeTrend?.map(it => Number(it.avg_uptime)) ?? [],
      // data: apiResponse?.uptimeTrend?.map(it => Number(it.avg_uptime * 4.16666666667).toFixed(1)) ?? [], // here we are converting the value of hours to percentage by multiplying with 4.166... to show percentage on graphs
    },
    labels: apiResponse?.uptimeTrend?.map(it => it.label) ?? []
  };
  return cardData;
}

export function formatOnlineInfraData(apiResponse) {
  let cardData = {
    series: {
      name: "Online Infra",
      data: apiResponse?.infrastructureTrend?.map(it => Number(it.infra_count)) ?? [],
      // data: apiResponse?.uptimeTrend?.map(it => Number(it.avg_uptime * 4.16666666667).toFixed(1)) ?? [], // here we are converting the value of hours to percentage by multiplying with 4.166... to show percentage on graphs
    },
    labels: apiResponse?.infrastructureTrend?.map(it => it.label) ?? []
  };
  return cardData;
}

export function formatVenueAlertData(apiResponse) {
  let cardData = {
    series: {
      name: "Venues",
      data: apiResponse?.alarmsTrend?.map(it => Number(it.alerts_count)) ?? [],
      // data: apiResponse?.uptimeTrend?.map(it => Number(it.avg_uptime * 4.16666666667).toFixed(1)) ?? [], // here we are converting the value of hours to percentage by multiplying with 4.166... to show percentage on graphs
    },
    labels: apiResponse?.alarmsTrend?.map(it => it.label) ?? []
  };
  return cardData;
}

const NumParser = (value) => {
  if (value == null) return null
  let parsedValue = Number(value)
  if (isNaN(parsedValue)) return null
  else return parsedValue
}

export function formatWebhookTrendData(apiResponse) {
  let cardData = {
    series: {
      name: "Webhook Success",
      data: apiResponse?.map(it => NumParser(it.success)) ?? [],
    },
    labels: apiResponse?.map(it => it.label) ?? []
  };
  return cardData;
}

export function formatWebhookErrorTrendData(apiResponse) {
  let cardData = {
    series1: {
      name: "400 Series",
      data: apiResponse?.map(it => NumParser(it.series400)) ?? [],
    },
    series2: {
      name: "500 Series",
      data: apiResponse?.map(it => NumParser(it.series500)) ?? [],
    },
    series3: {
      name: "Exceeds Limit",
      data: apiResponse?.map(it => NumParser(it.rate_limit)) ?? [],
    },
    labels: apiResponse?.map(it => it.label) ?? []
  };
  return cardData;
}

export function formatApRebootData(apiResponse) {
  let cardData = {
    series: {
      name: "Total Reboots",
      data: apiResponse?.uptimeTrend?.map(it => Number(it.total_reboot)) ?? [],
    },
    labels: apiResponse?.uptimeTrend?.map(it => it.label) ?? []
  };
  return cardData;
}

export function formatLoadData(apiResponse) {
  let cardData = {
    series: {
      name: "Avg",
      data: apiResponse?.loadTrend?.map(it => Number(it.load)) ?? []
    },
    seriesMin: {
      name: "Min",
      data: apiResponse?.loadTrend?.map(it => Number(it.min_load)) ?? []
    },
    seriesMax: {
      name: "Max",
      data: apiResponse?.loadTrend?.map(it => Number(it.max_load)) ?? []
    },
    labels: apiResponse?.loadTrend?.map(it => it.label) ?? []
  };
  return cardData;
}

export function formatMemoryData(apiResponse) {
  let cardData = {
    series: {
      name: "Memory Usage",
      data: apiResponse?.memoryTrend?.map(it => Number(it.used_memory)) ?? []
    },
    labels: apiResponse?.memoryTrend?.map(it => it.label) ?? []
  };
  return cardData;
}

const indexCalc = (seriesData) => {
  const size = 1024;
  // const types = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = 0;
  for (let i = 0; i < seriesData.length; i++) {
    let currIndex = seriesData[i] < 1 ? 0 : Math.floor(Math.log(seriesData[i]) / Math.log(size));
    if (currIndex > 6) currIndex = 6;
    if (index < currIndex) index = currIndex;
  }
  return index;
}
export const convertToCorrectUnit = (seriesData, index) => {
  const size = 1024;
  let tempData = [];
  for (let i = 0; i < seriesData.length; i++) {
    // tempData.push(Math.round(seriesData[i] / Math.pow(size, index)));
    tempData.push(seriesData[i] / Math.pow(size, index));
  }
  return tempData;
}

const smartConverter = (value = 0) => {
  try {
    const size = 1024;
    const types = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let index = value < 1 ? 0 : Math.floor(Math.log(value) / Math.log(size));
    if (index > 6) index = 6;
    if (index < 0) return '0 MB';
    return Math.ceil(value / Math.pow(size, index)) + ' ' + (types[index] ?? "MB");
  } catch (error) {
    return '0 MB';
  }
}
const converter = (value) => {
  try {
    value = parseFloat(value);
    if (isNaN(value)) {
      value = '-';
    }
    else if (value < 0) {
      value = smartConverter(-1 * value);
      if (value !== '0.0 MB')
        value = '-' + value; //converting negative value back to negative
    }
    else if (value === 0) {
      return '0 MB';
    }
    else if (value > 0) {
      value = smartConverter(value);
    }
  } catch (error) {
    value = '0 MB';
  }
  return value;
}

/**
 * Convert Network API to Chart-compatible data
 * :: BACKEND =====> VASHU__TELEMETRY
 * @param {Response} apiResponse - APIResponse
 * @returns Appropriate graph component format bundled in a javascript object
 */
export function formatNetworkCardData(apiResponse, shortData = false) {
  let trafficData = apiResponse;
  let seriesDataTrafficUplink = [];

  let seriesDataTrafficDownlink = [];

  let categoriesData = [];
  let typeSeriesTrafficUplink = "";
  let typeSeriesTrafficDownlink = "";

  try {
    if (trafficData?.traffic) {
      for (let i = 0; i < trafficData?.traffic?.length; i++) {
        let upLink = !isNaN(Number(trafficData.traffic[i].total_rx_data)) ? Number(trafficData.traffic[i].total_rx_data) : 0

        let downLink = !isNaN(Number(trafficData.traffic[i].total_tx_data)) ? Number(trafficData.traffic[i].total_tx_data) : 0


        seriesDataTrafficUplink.push(upLink);

        seriesDataTrafficDownlink.push(downLink);

        categoriesData.push(trafficData.traffic[i].label);
      }
    }
    else {
      for (let i = 0; i < trafficData?.uplink?.length; i++) {
        let downRx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].total_rx_data))
          ? Number(trafficData.downlink[i].total_rx_data)
          : 0
        let upRx = !isNaN(Number(trafficData.uplink[i].total_rx_data)) ? Number(trafficData.uplink[i].total_rx_data) : 0
        let upLink = downRx + upRx;






        let downTx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].total_tx_data))
          ? Number(trafficData.downlink[i].total_tx_data)
          : 0
        let upTx = !isNaN(Number(trafficData.uplink[i].total_tx_data)) ? Number(trafficData.uplink[i].total_tx_data) : 0
        let downLink = downTx + upTx;




        downLink = !isNaN(Number(downLink.toFixed(2))) ? Number(downLink.toFixed(2)) : 0;

        seriesDataTrafficUplink.push(upLink);

        seriesDataTrafficDownlink.push(downLink);


        categoriesData.push(trafficData.uplink[i].label);
      }
    }
    let index = indexCalc([...seriesDataTrafficUplink, ...seriesDataTrafficDownlink]);
    const types = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    seriesDataTrafficUplink = convertToCorrectUnit(seriesDataTrafficUplink, index) ?? [];

    typeSeriesTrafficUplink = (index > 0 && index <= 6) ? types[index] : 'MB';

    seriesDataTrafficDownlink = convertToCorrectUnit(seriesDataTrafficDownlink, index) ?? [];

    typeSeriesTrafficDownlink = typeSeriesTrafficUplink;
  } catch (err) {
  }
  if (shortData) {
    return seriesDataTrafficUplink?.map((it, ind) => { return { uplink: it, downlink: seriesDataTrafficDownlink[ind] } })
  }
  let cardData = {
    up: 0,
    down: 0,
    total: 0,
    labels: categoriesData,
    series1:
    {
      name: "Upload",
      data: seriesDataTrafficUplink
    },
    series2:
    {
      name: "Download",
      data: seriesDataTrafficDownlink
    },

    typeSeriesTrafficDownlink: typeSeriesTrafficDownlink,
    typeSeriesTrafficUplink: typeSeriesTrafficUplink
  };
  // console.log("rdebug network card data:", cardData)
  return cardData;
}

export function formatSingleNetworkCardData(apiResponse, shortData = false) {
  let trafficData = apiResponse;
  let seriesDataTrafficUplink = [];
  let seriesDataTrafficDownlink = [];
  let categoriesData = [];
  let typeSeriesTrafficUplink = "";
  let typeSeriesTrafficDownlink = "";

  try {
    if (trafficData?.traffic) {
      for (let i = 0; i < trafficData?.traffic?.length; i++) {
        let upLink = !isNaN(Number(trafficData.traffic[i].rx_bytes)) ? Number(trafficData.traffic[i].rx_bytes) : 0
        let downLink = !isNaN(Number(trafficData.traffic[i].tx_bytes)) ? Number(trafficData.traffic[i].tx_bytes) : 0
        seriesDataTrafficUplink.push(upLink);
        seriesDataTrafficDownlink.push(downLink);
        categoriesData.push(trafficData.traffic[i].label);
      }
    }
    else {
      for (let i = 0; i < trafficData?.uplink?.length; i++) {
        let downRx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].rx_bytes))
          ? Number(trafficData.downlink[i].rx_bytes)
          : 0
        let upRx = !isNaN(Number(trafficData.uplink[i].rx_bytes)) ? Number(trafficData.uplink[i].rx_bytes) : 0
        let upLink = downRx + upRx;
        let downTx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].tx_bytes))
          ? Number(trafficData.downlink[i].tx_bytes)
          : 0
        let upTx = !isNaN(Number(trafficData.uplink[i].tx_bytes)) ? Number(trafficData.uplink[i].tx_bytes) : 0
        let downLink = downTx + upTx;
        downLink = !isNaN(Number(downLink.toFixed(2))) ? Number(downLink.toFixed(2)) : 0;
        seriesDataTrafficUplink.push(upLink);
        seriesDataTrafficDownlink.push(downLink);
        categoriesData.push(trafficData.uplink[i].label);
      }
    }
    let index = indexCalc([...seriesDataTrafficUplink, ...seriesDataTrafficDownlink]);
    const types = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    seriesDataTrafficUplink = convertToCorrectUnit(seriesDataTrafficUplink, index) ?? [];
    typeSeriesTrafficUplink = (index > 0 && index <= 6) ? types[index] : 'MB';

    seriesDataTrafficDownlink = convertToCorrectUnit(seriesDataTrafficDownlink, index) ?? [];
    typeSeriesTrafficDownlink = typeSeriesTrafficUplink;
  } catch (err) {
  }
  if (shortData) {
    return seriesDataTrafficUplink?.map((it, ind) => { return { uplink: it, downlink: seriesDataTrafficDownlink[ind] } })
  }
  let cardData = {
    up: 0,
    down: 0,
    total: 0,
    labels: categoriesData,
    series1: {
      name: "Upload",
      data: seriesDataTrafficUplink
    },
    series2: {
      name: "Download",
      data: seriesDataTrafficDownlink
    },
    typeSeriesTrafficDownlink: typeSeriesTrafficDownlink,
    typeSeriesTrafficUplink: typeSeriesTrafficUplink
  };
  return cardData;
}
// /**
//  * Convert Network API to Chart-compatible data
//  * :: BACKEND =====> VASHU__TELEMETRY
//  * @param {Response} apiResponse - APIResponse
//  * @returns Appropriate graph component format bundled in a javascript object
//  */
// export function formatNetworkCardData(apiResponse, shortData = false) {
//   let trafficData = apiResponse;
//   let seriesDataTrafficUplink = [];
//   let minSeriesDataTrafficUplink = [];
//   let maxSeriesDataTrafficUplink = [];
//   let seriesDataTrafficDownlink = [];
//   let minSeriesDataTrafficDownlink = [];
//   let maxSeriesDataTrafficDownlink = [];
//   let categoriesData = [];
//   let typeSeriesTrafficUplink = "";
//   let typeSeriesTrafficDownlink = "";

//   try {
//     if (trafficData?.traffic) {
//       for (let i = 0; i < trafficData?.traffic?.length; i++) {
//         let upLink = !isNaN(Number(trafficData.traffic[i].rx_bytes)) ? Number(trafficData.traffic[i].rx_bytes) : 0
//         let minUpLink = !isNaN(Number(trafficData.traffic[i].min_rx_bytes)) ? Number(trafficData.traffic[i].min_rx_bytes) : 0
//         let maxUpLink = !isNaN(Number(trafficData.traffic[i].max_rx_bytes)) ? Number(trafficData.traffic[i].max_rx_bytes) : 0

//         let downLink = !isNaN(Number(trafficData.traffic[i].tx_bytes)) ? Number(trafficData.traffic[i].tx_bytes) : 0
//         let minDownLink = !isNaN(Number(trafficData.traffic[i].min_tx_bytes)) ? Number(trafficData.traffic[i].min_tx_bytes) : 0
//         let maxDownLink = !isNaN(Number(trafficData.traffic[i].max_tx_bytes)) ? Number(trafficData.traffic[i].max_tx_bytes) : 0



//         seriesDataTrafficUplink.push(upLink);
//         minSeriesDataTrafficUplink.push(minUpLink)
//         maxSeriesDataTrafficUplink.push(maxUpLink)

//         seriesDataTrafficDownlink.push(downLink);
//         minSeriesDataTrafficDownlink.push(minDownLink);
//         maxSeriesDataTrafficDownlink.push(maxDownLink)

//         categoriesData.push(trafficData.traffic[i].label);
//       }
//     }
//     else {
//       for (let i = 0; i < trafficData?.uplink?.length; i++) {
//         let downRx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].rx_bytes))
//           ? Number(trafficData.downlink[i].rx_bytes)
//           : 0
//         let upRx = !isNaN(Number(trafficData.uplink[i].rx_bytes)) ? Number(trafficData.uplink[i].rx_bytes) : 0
//         let upLink = downRx + upRx;

//         // min_rx_byte
//         let minDownRx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].min_rx_bytes))
//           ? Number(trafficData.downlink[i].min_rx_bytes)
//           : 0
//         let minUpRx = !isNaN(Number(trafficData.uplink[i].min_rx_bytes)) ? Number(trafficData.uplink[i].min_rx_bytes) : 0
//         let minUpLink = minDownRx + minUpRx;

//         // max_rx-byte
//         let maxDownRx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].max_rx_bytes))
//           ? Number(trafficData.downlink[i].max_rx_bytes)
//           : 0
//         let maxUpRx = !isNaN(Number(trafficData.uplink[i].max_rx_bytes)) ? Number(trafficData.uplink[i].max_rx_bytes) : 0
//         let maxUpLink = maxDownRx + maxUpRx;


//         let downTx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].tx_bytes))
//           ? Number(trafficData.downlink[i].tx_bytes)
//           : 0
//         let upTx = !isNaN(Number(trafficData.uplink[i].tx_bytes)) ? Number(trafficData.uplink[i].tx_bytes) : 0
//         let downLink = downTx + upTx;

//         // min_tx_byte
//         let minDownTx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].min_tx_bytes))
//           ? Number(trafficData.downlink[i].min_tx_bytes)
//           : 0
//         let minUpTx = !isNaN(Number(trafficData.uplink[i].min_tx_bytes)) ? Number(trafficData.uplink[i].min_tx_bytes) : 0
//         let minDownLink = minDownTx + minUpTx;

//         // max_tx_byte
//         let maxDownTx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].max_tx_bytes))
//           ? Number(trafficData.downlink[i].max_tx_bytes)
//           : 0
//         let maxUpTx = !isNaN(Number(trafficData.uplink[i].max_tx_bytes)) ? Number(trafficData.uplink[i].max_tx_bytes) : 0
//         let maxDownLink = maxDownTx + maxUpTx;

//         downLink = !isNaN(Number(downLink.toFixed(2))) ? Number(downLink.toFixed(2)) : 0;
//         minDownLink = !isNaN(Number(minDownLink.toFixed(2))) ? Number(minDownLink.toFixed(2)) : 0;
//         maxDownLink = !isNaN(Number(maxDownLink.toFixed(2))) ? Number(maxDownLink.toFixed(2)) : 0;


//         seriesDataTrafficUplink.push(upLink);
//         minSeriesDataTrafficUplink.push(minUpLink)
//         maxSeriesDataTrafficUplink.push(maxUpLink)

//         seriesDataTrafficDownlink.push(downLink);
//         minSeriesDataTrafficDownlink.push(minDownLink);
//         maxSeriesDataTrafficDownlink.push(maxDownLink)

//         categoriesData.push(trafficData.uplink[i].label);
//       }
//     }
//     let index = indexCalc([...seriesDataTrafficUplink, ...seriesDataTrafficDownlink]);
//     const types = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
//     seriesDataTrafficUplink = convertToCorrectUnit(seriesDataTrafficUplink, index) ?? [];
//     minSeriesDataTrafficUplink = convertToCorrectUnit(minSeriesDataTrafficUplink, index) ?? [];
//     maxSeriesDataTrafficUplink = convertToCorrectUnit(maxSeriesDataTrafficUplink, index) ?? [];
//     typeSeriesTrafficUplink = (index > 0 && index <= 6) ? types[index] : 'MB';

//     seriesDataTrafficDownlink = convertToCorrectUnit(seriesDataTrafficDownlink, index) ?? [];
//     minSeriesDataTrafficDownlink = convertToCorrectUnit(minSeriesDataTrafficDownlink, index) ?? [];
//     maxSeriesDataTrafficDownlink = convertToCorrectUnit(maxSeriesDataTrafficDownlink, index) ?? [];
//     typeSeriesTrafficDownlink = typeSeriesTrafficUplink;
//   } catch (err) {
//   }
//   if (shortData) {
//     return seriesDataTrafficUplink?.map((it, ind) => { return { uplink: it, downlink: seriesDataTrafficDownlink[ind] } })
//   }
//   let cardData = {
//     up: 0,
//     down: 0,
//     total: 0,
//     labels: categoriesData,
//     series1:
//     {
//       name: "Up Max",
//       data: maxSeriesDataTrafficUplink
//     },
//     series2: {
//       name: "Up Avg",
//       data: seriesDataTrafficUplink
//     },
//     series3: {
//       name: "Up Min",
//       data: minSeriesDataTrafficUplink
//     },
//     series4:
//     {
//       name: "Down Max",
//       data: maxSeriesDataTrafficDownlink
//     },
//     series5:
//     {
//       name: "Down Avg",
//       data: seriesDataTrafficDownlink
//     },
//     series6: {
//       name: "Down Min",
//       data: minSeriesDataTrafficDownlink
//     },
//     typeSeriesTrafficDownlink: typeSeriesTrafficDownlink,
//     typeSeriesTrafficUplink: typeSeriesTrafficUplink
//   };
//   console.log("rdebug network card data:", cardData)
//   return cardData;
// }
export function formatNetworkThreshholdCardData(apiResponse, shortData = false) {
  let trafficData = apiResponse;
  let seriesDataTrafficUplink = [];
  let minSeriesDataTrafficUplink = [];
  let maxSeriesDataTrafficUplink = [];
  let seriesDataTrafficDownlink = [];
  let minSeriesDataTrafficDownlink = [];
  let maxSeriesDataTrafficDownlink = [];
  let categoriesData = [];
  let typeSeriesTrafficUplink = "";
  let typeSeriesTrafficDownlink = "";

  try {
    if (trafficData?.traffic) {
      for (let i = 0; i < trafficData?.traffic?.length; i++) {
        let upLink = !isNaN(Number(trafficData.traffic[i].avg_rx_rate)) ? Number(trafficData.traffic[i].avg_rx_rate) : 0
        let minUpLink = !isNaN(Number(trafficData.traffic[i].min_rx_rate)) ? Number(trafficData.traffic[i].min_rx_rate) : 0
        let maxUpLink = !isNaN(Number(trafficData.traffic[i].max_rx_rate)) ? Number(trafficData.traffic[i].max_rx_rate) : 0

        let downLink = !isNaN(Number(trafficData.traffic[i].avg_tx_rate)) ? Number(trafficData.traffic[i].avg_tx_rate) : 0
        let minDownLink = !isNaN(Number(trafficData.traffic[i].min_tx_rate)) ? Number(trafficData.traffic[i].min_tx_rate) : 0
        let maxDownLink = !isNaN(Number(trafficData.traffic[i].max_tx_rate)) ? Number(trafficData.traffic[i].max_tx_rate) : 0



        seriesDataTrafficUplink.push(upLink);
        minSeriesDataTrafficUplink.push(minUpLink)
        maxSeriesDataTrafficUplink.push(maxUpLink)

        seriesDataTrafficDownlink.push(downLink);
        minSeriesDataTrafficDownlink.push(minDownLink);
        maxSeriesDataTrafficDownlink.push(maxDownLink)

        categoriesData.push(trafficData.traffic[i].label);
      }
    }
    else {
      for (let i = 0; i < trafficData?.uplink?.length; i++) {
        let downRx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].avg_rx_rate))
          ? Number(trafficData.downlink[i].avg_rx_rate)
          : 0
        let upRx = !isNaN(Number(trafficData.uplink[i].avg_rx_rate)) ? Number(trafficData.uplink[i].avg_rx_rate) : 0
        let upLink = downRx + upRx;

        // min_rx_byte
        let minDownRx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].min_rx_rate))
          ? Number(trafficData.downlink[i].min_rx_rate)
          : 0
        let minUpRx = !isNaN(Number(trafficData.uplink[i].min_rx_rate)) ? Number(trafficData.uplink[i].min_rx_rate) : 0
        let minUpLink = minDownRx + minUpRx;

        // max_rx-byte
        let maxDownRx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].max_rx_rate))
          ? Number(trafficData.downlink[i].max_rx_rate)
          : 0
        let maxUpRx = !isNaN(Number(trafficData.uplink[i].max_rx_rate)) ? Number(trafficData.uplink[i].max_rx_rate) : 0
        let maxUpLink = maxDownRx + maxUpRx;


        let downTx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].avg_tx_rate))
          ? Number(trafficData.downlink[i].avg_tx_rate)
          : 0
        let upTx = !isNaN(Number(trafficData.uplink[i].avg_tx_rate)) ? Number(trafficData.uplink[i].avg_tx_rate) : 0
        let downLink = downTx + upTx;

        // min_tx_byte
        let minDownTx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].min_tx_rate))
          ? Number(trafficData.downlink[i].min_tx_rate)
          : 0
        let minUpTx = !isNaN(Number(trafficData.uplink[i].min_tx_rate)) ? Number(trafficData.uplink[i].min_tx_rate) : 0
        let minDownLink = minDownTx + minUpTx;

        // max_tx_byte
        let maxDownTx = !!trafficData?.downlink[i] && !isNaN(Number(trafficData.downlink[i].max_tx_rate))
          ? Number(trafficData.downlink[i].max_tx_rate)
          : 0
        let maxUpTx = !isNaN(Number(trafficData.uplink[i].max_tx_rate)) ? Number(trafficData.uplink[i].max_tx_rate) : 0
        let maxDownLink = maxDownTx + maxUpTx;

        downLink = !isNaN(Number(downLink.toFixed(2))) ? Number(downLink.toFixed(2)) : 0;
        minDownLink = !isNaN(Number(minDownLink.toFixed(2))) ? Number(minDownLink.toFixed(2)) : 0;
        maxDownLink = !isNaN(Number(maxDownLink.toFixed(2))) ? Number(maxDownLink.toFixed(2)) : 0;


        seriesDataTrafficUplink.push(upLink);
        minSeriesDataTrafficUplink.push(minUpLink)
        maxSeriesDataTrafficUplink.push(maxUpLink)

        seriesDataTrafficDownlink.push(downLink);
        minSeriesDataTrafficDownlink.push(minDownLink);
        maxSeriesDataTrafficDownlink.push(maxDownLink)

        categoriesData.push(trafficData.uplink[i].label);
      }
    }
    let index = indexCalc([...seriesDataTrafficUplink, ...seriesDataTrafficDownlink]);
    const types = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    seriesDataTrafficUplink = convertToCorrectUnit(seriesDataTrafficUplink, index) ?? [];
    minSeriesDataTrafficUplink = convertToCorrectUnit(minSeriesDataTrafficUplink, index) ?? [];
    maxSeriesDataTrafficUplink = convertToCorrectUnit(maxSeriesDataTrafficUplink, index) ?? [];
    // typeSeriesTrafficUplink = (index > 0 && index <= 6) ? types[index] : 'MB';
    typeSeriesTrafficUplink = 'Mbps';

    seriesDataTrafficDownlink = convertToCorrectUnit(seriesDataTrafficDownlink, index) ?? [];
    minSeriesDataTrafficDownlink = convertToCorrectUnit(minSeriesDataTrafficDownlink, index) ?? [];
    maxSeriesDataTrafficDownlink = convertToCorrectUnit(maxSeriesDataTrafficDownlink, index) ?? [];
    typeSeriesTrafficDownlink = typeSeriesTrafficUplink;
  } catch (err) {
  }
  if (shortData) {
    return seriesDataTrafficUplink?.map((it, ind) => { return { uplink: it, downlink: seriesDataTrafficDownlink[ind] } })
  }
  let cardData = {
    up: 0,
    down: 0,
    total: 0,
    labels: categoriesData,
    series1:
    {
      name: "Up Max",
      data: maxSeriesDataTrafficUplink
    },
    series2: {
      name: "Up Avg",
      data: seriesDataTrafficUplink
    },
    series3: {
      name: "Up Min",
      data: minSeriesDataTrafficUplink
    },
    series4:
    {
      name: "Down Max",
      data: maxSeriesDataTrafficDownlink
    },
    series5:
    {
      name: "Down Avg",
      data: seriesDataTrafficDownlink
    },
    series6: {
      name: "Down Min",
      data: minSeriesDataTrafficDownlink
    },
    typeSeriesTrafficDownlink: typeSeriesTrafficDownlink,
    typeSeriesTrafficUplink: typeSeriesTrafficUplink
  };
  return cardData;
}

/**
 * Convert  Channel Utilization API to Chart-compatible data
 * :: BACKEND =====> VASHU__TELEMETRY
 * @param {Response} apiResponse - APIResponse
 * @returns Appropriate graph component format bundled in a javascript object
 */
export function formatChannelUtilizationData(apiResponse, shortData = false) {
  let trafficData = apiResponse;
  let seriesDataTrafficUplink = [];
  let seriesDataTrafficDownlink = [];
  let categoriesData = [];
  let typeSeriesTrafficUplink = "";
  let typeSeriesTrafficDownlink = "";

  let total_utilization = []
  let ap_utilization = []
  let channels = []
  try {
    for (let i in apiResponse) {
      let dataPoint = apiResponse[i]
      let totalUtilization = !isNaN(Number(dataPoint.total_util_pct)) ? Number(dataPoint.total_util_pct) : 0
      let apUtilization = !isNaN(Number(dataPoint.ap_util_pct)) ? Number(dataPoint.ap_util_pct) : 0
      let channel = dataPoint.channels
      total_utilization.push(totalUtilization);
      ap_utilization.push(apUtilization);
      categoriesData.push(dataPoint.label);
      channels.push(channel)
    }

    let index = indexCalc([...seriesDataTrafficUplink, ...seriesDataTrafficDownlink]);
    const types = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    seriesDataTrafficUplink = convertToCorrectUnit(seriesDataTrafficUplink, index) ?? [];
    typeSeriesTrafficUplink = (index > 0 && index <= 6) ? types[index] : 'MB';

    seriesDataTrafficDownlink = convertToCorrectUnit(seriesDataTrafficDownlink, index) ?? [];
    typeSeriesTrafficDownlink = typeSeriesTrafficUplink;
  } catch (err) {
  }
  if (shortData) {
    return seriesDataTrafficUplink?.map((it, ind) => { return { uplink: it, downlink: seriesDataTrafficDownlink[ind] } })
  }
  let cardData = {
    up: 0,
    down: 0,
    total: 0,
    labels: categoriesData,
    series1: {
      name: "Total Utilization",
      data: total_utilization
    },
    series2: {
      name: "AP Utilization",
      data: ap_utilization
    },
    channels: {
      name: "Channel(s)",
      data: channels
    },
    totalUtilizationUnit: "%",
    apUtilizationUnit: "%"
  };
  return cardData;
}

export function formatPhyRateCardData(apiResponse) {
  // [
  //   {
  //     "label": "string",
  //     "tx_phy_avg": 0,
  //     "rx_phy_avg": 0,
  //     "properties": {}
  //   }
  // ]



  let labels = [], rxData = [], txData = [];
  let minRxData = [], maxRxData = [];
  let minTxData = [], maxTxData = [];
  let size = 1000
  let types = ['Kbps', 'Mbps', 'Gbps', 'Tbps']
  let index = 0

  if (Array.isArray(apiResponse)) {
    for (let i = 0; i < apiResponse.length; i++) {
      let item = apiResponse[i]
      if (!!item?.label && !isNaN(item?.rx_phy_avg) && !isNaN(item?.tx_phy_avg)) {
        labels.push(item.label)
        rxData.push(item.rx_phy_avg)
        txData.push(item.tx_phy_avg)
      }
      if (!isNaN(item?.min_rx_phyrate) && !isNaN(item?.max_rx_phyrate)) {
        minRxData.push(item.min_rx_phyrate)
        maxRxData.push(item.max_rx_phyrate)
      }
      if (!isNaN(item?.min_tx_phyrate) && !isNaN(item?.max_tx_phyrate)) {
        minTxData.push(item.min_tx_phyrate)
        maxTxData.push(item.max_tx_phyrate)
      }
    }
    const convertToUnit = (value, index) => {
      value = value / Math.pow(size, index);
      value = Math.round(value * 10) / 10;
      return isNaN(value) ? 0 : value;
    };
    for (let i = 0; i < rxData.length; i++) {
      let rx = rxData[i]
      let rxIndex = rx < 1 ? 0 : Math.floor(Math.log(rx) / Math.log(size))
      let maxIndex = Math.max(index, rxIndex)
      index = !isNaN(maxIndex) ? maxIndex : index
    }
    for (let i = 0; i < minRxData.length; i++) {
      let rx = minRxData[i]
      let rxIndex = rx < 1 ? 0 : Math.floor(Math.log(rx) / Math.log(size))
      let maxIndex = Math.max(index, rxIndex)
      index = !isNaN(maxIndex) ? maxIndex : index
    }
    for (let i = 0; i < maxRxData.length; i++) {
      let rx = maxRxData[i]
      let rxIndex = rx < 1 ? 0 : Math.floor(Math.log(rx) / Math.log(size))
      let maxIndex = Math.max(index, rxIndex)
      index = !isNaN(maxIndex) ? maxIndex : index
    }

    for (let i = 0; i < txData.length; i++) {
      let tx = txData[i]
      let txIndex = tx < 1 ? 0 : Math.floor(Math.log(tx) / Math.log(size))
      let maxIndex = Math.max(index, txIndex)
      index = !isNaN(maxIndex) ? maxIndex : index
    }
    for (let i = 0; i < minTxData.length; i++) {
      let tx = minTxData[i]
      let txIndex = tx < 1 ? 0 : Math.floor(Math.log(tx) / Math.log(size))
      let maxIndex = Math.max(index, txIndex)
      index = !isNaN(maxIndex) ? maxIndex : index
    }
    for (let i = 0; i < maxTxData.length; i++) {
      let tx = maxTxData[i]
      let txIndex = tx < 1 ? 0 : Math.floor(Math.log(tx) / Math.log(size))
      let maxIndex = Math.max(index, txIndex)
      index = !isNaN(maxIndex) ? maxIndex : index
    }



    for (let i = 0; i < rxData.length; i++) {
      rxData[i] = convertToUnit(rxData[i], index)
    }
    for (let i = 0; i < minRxData.length; i++) {
      minRxData[i] = convertToUnit(minRxData[i], index)
    }
    for (let i = 0; i < maxRxData.length; i++) {
      maxRxData[i] = convertToUnit(maxRxData[i], index)
    }



    for (let i = 0; i < txData.length; i++) {
      txData[i] = convertToUnit(txData[i], index)
    }
    for (let i = 0; i < minTxData.length; i++) {
      minTxData[i] = convertToUnit(minTxData[i], index)
    }
    for (let i = 0; i < maxTxData.length; i++) {
      maxTxData[i] = convertToUnit(maxTxData[i], index)
    }
  }
  let cardData = {
    up: 0,
    down: 0,
    total: 0,
    labels: labels,
    series1: {
      name: "Rx Max",
      data: maxRxData
    },
    series2: {
      name: "Rx Avg",
      data: rxData
    },
    series3: {
      name: "Rx Min",
      data: minRxData
    },

    series4: {
      name: "Tx Max",
      data: maxTxData
    },
    series5: {
      name: "Tx Avg",
      data: txData
    },
    series6: {
      name: "Tx Min",
      data: minTxData
    },
    unit: types[index]
  };
  return cardData
}

export function formatRssiCardData(apiResponse) {
  // [
  //   {
  //     "label": "string",
  //     "rssi_avg": 0
  //   }
  // ]
  let labels = [], data = [], max = -40;
  let minData = [];
  let maxData = [];
  if (Array.isArray(apiResponse)) {
    for (let i = 0; i < apiResponse.length; i++) {
      let item = apiResponse[i]
      labels.push(item.label)
      let RoundedRssiAvg = Math.round(item.rssi_avg);
      let RoundedMinRssiAvg = Math.round(item.min_rssi);
      let RoundedMaxRssiAvg = Math.round(item.max_rssi);
      if (isNaN(RoundedRssiAvg))
        RoundedRssiAvg = 0
      if (isNaN(RoundedMinRssiAvg))
        RoundedMinRssiAvg = 0
      if (isNaN(RoundedMaxRssiAvg))
        RoundedMaxRssiAvg = 0

      RoundedRssiAvg = Math.max(RoundedRssiAvg, -100)
      RoundedMinRssiAvg = Math.max(RoundedMinRssiAvg, -100)
      RoundedMaxRssiAvg = Math.max(RoundedMaxRssiAvg, -100)

      data.push(RoundedRssiAvg === 0 ? null : RoundedRssiAvg)
      minData.push(RoundedMinRssiAvg === 0 ? null : RoundedMinRssiAvg)
      maxData.push(RoundedMaxRssiAvg === 0 ? null : RoundedMaxRssiAvg)
      if (RoundedMaxRssiAvg !== 0 && RoundedMaxRssiAvg > max)
        max = RoundedMaxRssiAvg
    }
  }
  let cardData = {
    // currentlyConnected: apiResponse?.connected ?? 0,
    labels: labels,
    series: {
      name: "Avg",
      data: data,
    },
    seriesMin: {
      name: "Min",
      data: minData,
    },
    seriesMax: {
      name: "Max",
      data: maxData,
    },

    max: max
  };
  return cardData;
}