/***
 *
 * Controller class for user.
 * @file PlusButton.js
 * @description PlusButton component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./PlusButton.scss";
import { AddIcon } from "../../assets/images/icons/Icons";

const PlusButton = (props) => {
  const {disabled, onClick} = props;
  return (
    <div
      data-testid="PlusButton"
      tabIndex="0"
      className={`PlusButton rounded ${
        disabled
          ? "cursor-not-allowed disable"
          : "cursor-pointer"
      }`}
      onKeyUp={(event) => {
        if (
          !disabled &&
          (event.key === " " || event.key === "Enter")
        )
          onClick();
      }}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <AddIcon
        className={disabled ? "disable" : "active"}
        height={19}
        width={19}
      />
    </div>
  );
};

PlusButton.propTypes = {};

PlusButton.defaultProps = {};

export default PlusButton;
