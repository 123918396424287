import Service from "./Service";
import { MARKETPLACE } from "../helpers";

/** Services related to Marketplace */

const marketplace = {

    //######################################################################################################################################################
    //-------------------------GET Request-------------------------------------- 

    GET_ALL_PRODUCTS: new Service((orgId, offset, limit, forceAll) => MARKETPLACE(`products?orgId=${orgId}&offset=${offset}&limit=${limit}` + (forceAll ? "&forceAll=true" : "")), "GET"),
    GET_PRODUCT_BY_ID: new Service((productId, orgId) => MARKETPLACE(`products/${productId}?orgId=${orgId}`), "GET"),

    CHECK_CART_FOR_ORGANIZATION: new Service((orgId) => MARKETPLACE(`carts/organization/${orgId}`), "GET"),
    GET_CART_ITEMS_BY_CART_ID: new Service((cartId, offset, limit) => MARKETPLACE(`carts/${cartId}/cartitems?offset=${offset}&limit=${limit}`), "GET"),

    GET_QUOTES: new Service((orgId, offset, limit, sort, filter, searchTerm) => MARKETPLACE(`quotes/organization/${orgId}?offset=${offset}&limit=${limit}` + (sort?.order ? `&order=${sort?.order}` : '') + (sort?.orderBy?.length > 0 ? `&orderBy=${sort?.orderBy}` : '') + (filter?.statuses?.length ? `&status=${filter.statuses}` : '') + (searchTerm ? `&search=${searchTerm}` : '')), "GET"),
    GET_QUOTES_VENUE: new Service((venueId, offset, limit, sort, filter, searchTerm) => MARKETPLACE(`quotes/venue/${venueId}?offset=${offset}&limit=${limit}` + (sort?.order ? `&order=${sort?.order}` : '') + (sort?.orderBy?.length > 0 ? `&orderBy=${sort?.orderBy}` : '') + (filter?.statuses?.length ? `&status=${filter.statuses}` : '') + (searchTerm ? `&search=${searchTerm}` : '')), "GET"),
    GET_QUOTE_DETAILS: new Service((quoteId) => MARKETPLACE(`quotes/${quoteId}`), "GET"),
    GET_QUOTE_LINE_ITEMS: new Service((quoteId, offset, limit) => MARKETPLACE(`quotes/${quoteId}/quoteline?offset=${offset}&limit=${limit}`), "GET"),


    GET_QUOTE_PDF: new Service((quoteId) => MARKETPLACE(`quotes/pdf/${quoteId}`), "GET"),
    GET_QUOTE_CSV: new Service((quoteId) => MARKETPLACE(`quotes/excel/${quoteId}`), "GET"),

    GET_ORDERS: new Service((orgId, offset, limit) => MARKETPLACE(`orders?orgId=${orgId}&offset=${offset}&limit=${limit}`), "GET"),
    GET_ORDER_DETAILS: new Service((orderId) => MARKETPLACE(`orders/${orderId}`), "GET"),

    GET_SUBSCRIPTIONS: new Service((orgId, offset, limit) => MARKETPLACE(`subscriptions?orgId=${orgId}&offset=${offset}&limit=${limit}`), "GET"),
    GET_QUOTE_STATUS_COUNT: new Service((orgId) => MARKETPLACE(`quotes/status/count?orgId=${orgId}`), "GET"),

    GET_QUOTE_COMMENT: new Service((quoteId, offset, limit) => MARKETPLACE(`quotes/${quoteId}/comments?offset=${offset}&limit=${limit}`), "GET"),

    GET_TOP_QUOTES: new Service((orgId) => MARKETPLACE(`quotes/expensive?orgId=${orgId}`), "GET"),

    CHECK_PRODUCT_PRICE_UPDATES: new Service((quoteId, productId) => MARKETPLACE(`quotes/${quoteId}/price-compare?productIds=${productId}`, "GET")),

    GET_PRICEBOOKS: new Service((orgId, isPAdmin, search, offset, limit, sort) => MARKETPLACE(`organization/${orgId}/pricebooks?getAll=${isPAdmin}&search=${search}&offset=${offset}&limit=${limit}` + (sort?.order ? `&order=${sort?.order}` : '') + (sort?.orderBy?.length > 0 ? `&orderBy=${sort?.orderBy}` : '')), "GET"),

    GET_PRICEBOOK: new Service((priceBookId) => MARKETPLACE(`pricebooks/${priceBookId}`), "GET"),
    GET_DEFAULT_PRICEBOOK_PRICE: new Service(() => MARKETPLACE(`pricebooks/default`), "GET"),
    EXPORT_MARKETPLACE_PRODUCTS: new Service((orgId) => MARKETPLACE(` organization/${orgId}/pricebooks/export`), "GET"),




    //######################################################################################################################################################
    // --------------------------POST Request-------------------------------------

    CREATE_NEW_CART: new Service((identityId) => MARKETPLACE(`carts`), "POST"),
    CREATE_QUOTE: new Service((identityId) => MARKETPLACE(`quotes`), "POST"),
    CONVERT_QUOTE: new Service((quoteId) => MARKETPLACE(`quotes/${quoteId}/subscription`), "POST"),

    ADD_QUOTE_COMMENT: new Service((quoteId) => MARKETPLACE(`quotes/${quoteId}/comments`), "POST"),

    CREATE_ORDER: new Service((invoiceId) => MARKETPLACE(`invoice/${invoiceId}/order`), "POST"),

    DUPLICATE_QUOTE: new Service((quoteId) => MARKETPLACE(`quotes/${quoteId}/duplicate`), "POST"),
    SEND_EMAIL: new Service((quoteId, emailList) => MARKETPLACE(`quotes/${quoteId}/email?recipients=${emailList}`), "POST"),

    CREATE_PRICEBOOK: new Service(() => MARKETPLACE(`pricebooks`), "POST"),



    //######################################################################################################################################################
    //-------------------------- PUT Request ----------------------------------

    UPDATE_CART: new Service((cartId) => MARKETPLACE(`carts/${cartId}`), "PUT"),
    UPDATE_QUOTE: new Service((quoteId, forceUpdate = false) => MARKETPLACE(`quotes/${quoteId}?forceUpdate=${forceUpdate}`), "PUT"),
    UPDATE_QUOTE_STATUS: new Service((quoteId) => MARKETPLACE(`quotes/${quoteId}/status`), "PUT"),
    UPDATE_PRODUCT_PRICE: new Service((productId) => MARKETPLACE(`products/${productId}`), "PUT"),
    UPDATE_QUOTE_ITEMS: new Service((quoteId) => MARKETPLACE(`quotes/${quoteId}/quoteline`), "PUT"),
    UPDATE_PRODUCT_STATUS: new Service((productId) => MARKETPLACE(`products/${productId}/status`), "PUT"),
    UPDATE_PRICEBOOK: new Service((priceBookId) => MARKETPLACE(`pricebooks/${priceBookId}`), "PUT"),
    UPDATE_ORG_PRICEBOOK: new Service((orgId) => MARKETPLACE(`organization/${orgId}/pricebooks`), "PUT"),




    //######################################################################################################################################################
    //-------------------------- DELETE Request ----------------------------------

    DELETE_QUOTE: new Service((quoteId) => MARKETPLACE(`quotes/${quoteId}`), "DELETE"),
    EMPTY_CART: new Service((cartId) => MARKETPLACE(`carts/${cartId}/cartitems`), "DELETE"),
    DELETE_PRICEBOOK: new Service((priceBookId) => MARKETPLACE(`pricebooks/${priceBookId}`), "DELETE"),



    //######################################################################################################################################################
    //-------------------------- PATCH Request ----------------------------------
    MAKE_PRICEBOOK_DEFAULT: new Service((priceBookId) => MARKETPLACE(`pricebooks/${priceBookId}`), "PATCH"),
    UPDATE_PRICEBOOK_PRODUCT_STATUS: new Service((priceBookId) => MARKETPLACE(`pricebooks/${priceBookId}/status`), "PATCH"),


};

export default marketplace;
