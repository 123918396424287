import ChartCard from "./ChartCard";
import AreaChartSkeleton from "./AreaChartSkeleton";
import AreaApexChartWrapper from "./AreaApexChartWrapper";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const ChartCardWithAreaCharts = ({ showBorders, title, categories, series, channels, colors, isLoading, range, AddAction, SearchAction, isTraffic, typeSeriesTrafficDownlink, typeSeriesTrafficUplink, showPercentage, height, width, ticks, xlabel, ylabel, noStyle, noCardBorder, chartOptions, autoSkeleton, selectorComponent = null, timeInterval = null, noMarker, borderColorArray, showChannel, isRssi }) => {
  return (
    <ChartCard AddAction={AddAction} SearchAction={SearchAction} title={title} typeSeriesTrafficDownlink={typeSeriesTrafficDownlink} isTraffic={isTraffic} isLoading={isLoading} noStyle={noStyle} noCardBorder={noCardBorder}
      series={series} xlabel={xlabel} ylabel={ylabel} selectorComponent={selectorComponent} colors={colors} range={range} showPercentage={showPercentage} timeInterval={timeInterval} showBorders={showBorders} categories={categories} typeSeriesTrafficUplink={typeSeriesTrafficUplink} chartOptions={chartOptions} noMarker={noMarker} borderColorArray={borderColorArray} isRssi={isRssi} showChannel={showChannel} channels={channels}
    >
      {isLoading ?
        autoSkeleton && !!height ? <div style={{ lineHeight: 1 }}><Skeleton height={height} /></div> : <AreaChartSkeleton legendCount={colors.length} />
        :
        <>
          <AreaApexChartWrapper
            range={range}
            showPercentage={showPercentage}
            showBorders={showBorders}
            categories={categories}
            series={series}
            channels={channels}
            height={height}
            width={width}
            ticks={ticks}
            colors={colors}
            typeSeriesTrafficUplink={typeSeriesTrafficUplink}
            isTraffic={isTraffic}
            typeSeriesTrafficDownlink={typeSeriesTrafficDownlink}
            showLegend={false}
            chartOptions={chartOptions}
            timeInterval={timeInterval}
            noMarker={noMarker}
            borderColorArray={borderColorArray}
            showChannel={showChannel}
            isRssi={isRssi}
          />
        </>
      }
    </ChartCard>
  );
};

ChartCardWithAreaCharts.defaultProps = {
  title: "AREACHART", Pill: null, categories: [0, 1, 2, 3, 4, 5, 6].map(i => `Day ${i}`), series: [{
    name: "Connected Devices",
    data: [300, 400, 200, 700, 200, 100, 500]
  }], isLoading: true, showLegend: true
}

export default ChartCardWithAreaCharts;