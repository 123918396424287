/***
 *
 * Controller class for user.
 * @file OrgStateGroup.js
 * @description OrgStateGroup component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./OrgStateGroup.scss";
import { ReactComponent as Design } from "../../assets/images/icons/BusinessView.svg";
import { ReactComponent as Live } from "../../assets/images/icons/NetworkView.svg";
import { ReactComponent as Deployed } from "../../assets/images/icons/ProjectView.svg";
import LightBadge from "../LightBadge";
import { ORG_STATE, VENUE_STATE } from "../../utility/constants";

const OrgStateGroup = (props) => {
  const { active, clickable = false, selectedSize = 24, size = 18 } = props;
  return (
    <div className="OrgStateGroup" data-testid="OrgStateGroup">
      {
        active == ORG_STATE.DISABLED
          ? <div>
            <LightBadge color="secondary">Disabled</LightBadge>
          </div>
          : <div className="d-flex align-items-end" style={{ gap: "0.15rem" }}>
            <div className={"live-black " + (active == ORG_STATE.OPPORTUNITY ? "active live-green " : "") + (clickable ? "cursor-pointer" : "")}>
              <Design width={active == ORG_STATE.OPPORTUNITY ? selectedSize : size} height={active == ORG_STATE.OPPORTUNITY ? selectedSize : size} />
            </div>
            <div className={"deployed-black " + (active == ORG_STATE.ONBOARDING ? "active deployed-green " : "") + (clickable ? "cursor-pointer" : "")}>
              <Deployed width={active == ORG_STATE.ONBOARDING ? selectedSize : size} height={active == ORG_STATE.ONBOARDING ? selectedSize : size} />
            </div>
            <div className={"devolping-black " + (active == ORG_STATE.COMMERCIAL ? "active devolping-green " : "") + (clickable ? "cursor-pointer" : "")}>
              <Live width={active == ORG_STATE.COMMERCIAL ? selectedSize : size} height={active == ORG_STATE.COMMERCIAL ? selectedSize : size} />
            </div>
            {
              clickable
                ? <div className={"devolping-black " + (active == ORG_STATE.DISABLED ? "active devolping-white " : "") + (clickable ? "cursor-pointer" : "")}>Disabled</div>
                : <></>
            }
          </div>
      }
    </div>
  );
};

OrgStateGroup.propTypes = {};

OrgStateGroup.defaultProps = {};

export default OrgStateGroup;
