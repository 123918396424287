/**
 * Infrastructure Service
 * @file infra.service.js
 * @description API Endpoints related to infrastructure
 * @author Utkarsh Gupta
 * @since 26 Jul 2022
 */

import Service from "./Service";
import { I } from "../helpers";

export function onedaytimestamps() {
  let ts = Math.round(new Date().getTime() / 1000);
  let tsYesterday = ts - (24 * 3600);
  return [ts, tsYesterday];
}

/** Services related to infrastructure management */
const infra = {

  /** Get list of infrastructure by venueId
   * @example
   * params = [venueId: number, offset?: number = 0, limit?: number = 0, search?: string = ""]
   * data = {}
   */
  GET_BY_VENUE: new Service((venueId, offset = 0, limit = 10, search = "", sort = { order: "ASC", orderBy: "infraItemId" }, filter = { infraTag: "ALL" }) =>
    I(`infrastructure/venue/${venueId}?offset=${offset}&limit=${limit}&search=${search}&order=${sort.order}&orderBy=${sort.orderBy}`
      + (filter?.category ? `&infracategory=${filter.category}` : "")
      + (filter?.infraType?.length ? `&infraType=${filter.infraType}` : "")
      + (filter?.infraTag ? `&infraTag=${filter.infraTag}` : "")
      + (filter?.status ? `&status=${filter.status}` : "")
    ), "GET"),

  /** Create a new infrastructure 
   * @example
   * params = []
   * data = {
   *   venueId: number,
   *   infraTypeId: number,
   *   macAddress: string,
   *   infraDisplayName: string,
   *   orgId: number
   * }
  */
  CREATE: new Service(() => I("infrastructure"), "POST"),

  /** Delete an infrastructure by its id
   * @example
   * params = [infraId: number]
   * data = {}
   */
  DELETE: new Service((infraId, deleteInfra=false) => I(`infrastructure/${infraId}?deleteInfra=${deleteInfra}`), "DELETE"),
  BULK_DELETE: new Service((orgId,UUID, deleteInfra=false) => I(`infrastructure?orgId=${orgId}&uuid=${UUID}&deleteInfra=${deleteInfra}`), "PUT"),

  /** Get details of one infrastructure by its id
   * @example
   * params = [infraId: number]
   * data = {}
   */
  GET: new Service((infraId) => I(`infrastructure/${infraId}`), "GET"),

  /** Modify details of one infrastructure by its id 
   * @example
   * params = [infraId: number]
   * data = {
   *   venueId: number,
   *   infraTypeId: number,
   *   macAddress: string,
   *   infraDisplayName: string,
   *   orgId: number
   * } 
   */
  MODIFY: new Service((infraId) => I(`infrastructure/${infraId}`), "PUT"),

  /** Get list of Unassigned infrastructure 
   * @example
   * params = []
   * data = {}
  */
  GET_UNASSIGNED: new Service((orgId, offset, limit, search, sort = {}) => I(`infrastructure/organization/${orgId}/unassigned?offset=${offset}&limit=${limit}&search=${search}&order=${sort?.order}&orderBy=${sort?.orderBy}`), "GET"),

  /** Get list of infrastructure by organization id 
   * @example
   * params = [orgId: number, offset?: number = 0, limit?: number = 10, search?: string = ""]
   * data = {}
   */
  GET_BY_ORGANIZATION: new Service((orgId, offset = 0, limit = 10, search = "", orderBy = "infraItemId", order = "DESC", filter = {}) =>
    I(`infrastructure/organization/${orgId}?offset=${offset}&limit=${limit}&search=${search}&order=${order}&orderBy=${orderBy}`
      + (filter?.category ? `&infracategory=${filter.category}` : "")
      + (filter?.infraType?.length ? `&infraType=${filter.infraType}` : "")
      + (filter?.venues?.length ? `&venueId=${filter.venues}` : "")
      + (filter?.status?.length ? `&status=${filter.status}` : "")
    ), "GET"),

  /** Get list of types of infrastructure 
   * @example
   * params = [orgId: number]
   * data = {}
  */
  GET_TYPES: new Service((orgId, admin=false) => I(`infrastructure/infratype?orgId=${orgId}` + (admin?`&admin=${admin}`:'')), "GET"),

  /** Run a command on the AP
   * @example
   * params = [infraId: number | string]
   * data = {
   *   cmd: "reboot" | "blink" | "reset" | "upgrade",
   *   uri?: string (only required for "upgrade")
   * }
   */
  RUN: new Service((infraId) => I(`infrastructure/${infraId}/command`), "POST"),

  /** Get list of available firmware for an AP
   * @example
   * params = [infraId: number | string]
   * data = {}
   */
  FIRMWARE: new Service((infraId) => I(`infrastructure/${infraId}/firmware`), "GET"),

  /** Get the details of currently installed firmware
   * @example
   * params = [infraId: number | string]
   * data = {}
   */

  CREATE_TYPE: new Service(() => I(`infrastructure/infratype`),"POST"),
  GET_TYPE: new Service((id) => I(`infrastructure/infratype/${id}`),"GET"),
  PUT_TYPE: new Service((id) => I(`infrastructure/infratype/${id}`),"PUT"),
  DELETE_TYPE: new Service((id) => I(`infrastructure/infratype/${id}`),"DELETE"),
  TYPE_IMG: new Service((infraId, purpose, imgId) => I(`infrastructure/infratype/${infraId}/images?purpose=${purpose}&imageId=${imgId}`), "GET"),
  TYPE_IMG_DELETE: new Service((infraId, imgId) => I(`infrastructure/infratype/${infraId}/images?imageId=${imgId}`), "DELETE"),
  DETAILS: new Service((infraId) => I(`infrastructure/${infraId}/details`), "GET"),
  IMPORT: new Service((orgId, preview, UUID) => I(`infrastructure/import?orgId=${orgId}&preview=${preview}&uuid=${UUID}`), "POST"),
  TEMPLATE: new Service((orgId) => I(`infrastructure/template/${orgId}`), "GET"),
  STATUS: new Service((infraId) => I(`infrastructure/${infraId}/status`), "GET"),
  MOVE: new Service((venueId, originalOrgId, UUID) => I(`infrastructure/move/venue/${venueId}?orgId=${originalOrgId}&uuid=${UUID}`), "PUT"),
  CONNECT: new Service((infraId) => I(`infrastructure/${infraId}/rtty`),"GET"),
  INSTALL_IMG: new Service((infraId, purpose="get", imageCount=0, thumbnail=false) => I(`infrastructure/${infraId}/installationimage?purpose=${purpose}&imageCount=${imageCount}&thumbnail=${thumbnail}`),"GET"),
  INSTALL_IMG_DELETE: new Service((infraId, imageCount=0) => I(`infrastructure/${infraId}/installationimage?imageCount=${imageCount}`),"DELETE"),
  UTILITY: new Service((infraId) => I(`infrastructure/${infraId}/trigger`),"POST"),
  GET_ERROR: new Service((venueId) => I(`infrastructure/aperror?venueId=${venueId}`),"GET"),
  GET_GRAPH: new Service((infraId,startDate,endDate,countOnly=false,offset,limit) => I(`infrastructure/${infraId}/graph?startdate=${startDate}&enddate=${endDate}` + (countOnly?`&countOnly=${countOnly}`:``) + (limit?`&limit=${limit}`:``) + (offset?`&offset=${offset}`:``)),"GET"),
  INFRA_OFFLINE_ALARM_COUNT: new Service((orgId) => I(`infrastructure/alarm/count?orgId=${orgId}`), "GET"),
  BULK_STATUS_CHANGE: new Service((orgId, UUID, state, waitUntilDone=false) => I(`infrastructure/state?orgId=${orgId}&uuid=${UUID}&state=${state}&waitUntilDone=${waitUntilDone}`), "PUT")
};

export default infra;
