/***
 *
 * Controller class for user.
 * @file AddVenue.js
 * @description AddVenue component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import "./AddVenue.scss";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import TextInput from "../../../components/TextInput";
import VenueStateDropdown from "../../../components/VenueStateDropdown";
import { Button, Col, Dropdown, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, Row } from "reactstrap";
import Geocode from "react-geocode";
import createRequest, { services } from "../../../services";
import { GoogleMap, Marker } from "@react-google-maps/api";
import AutoComplete from "react-google-autocomplete";
import store from "../../../redux/store";
import { activeVenueActions, breadcrumbActions } from "../../../redux/slices";
import { CatchedWebError } from "../../../configs";
import Select from "react-select";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { make_custom_toast } from "../../../helpers/toasts";
import { ReactComponent as StreetViewIcon } from "../../../assets/images/icons/map_view/street_view_icon.svg";
import { ReactComponent as MapViewIcon } from "../../../assets/images/icons/map_view/map_view_icon.svg";
import { VENUE_STATE, VENUE_TYPE_LABEL, VENUE_TYPE_MAPPING, reactselectTheme } from "../../../utility/constants";
import HeightAnimator from "../../../components/HeightAnimator";
import { VenueIcon } from "../VenueTree";
import axios from "axios";
import { saveAs } from "file-saver";
import { GOOGLE_MAP } from "../../../components/VenueBasicDetails/helper";


const addVenueForm = {
  initialValues: (props, activeOrgAddress, activeVenueAddress) => {
    return {
      name: "",
      state: VENUE_STATE.LIVE,
      reason: '',
      addressLine: "",
      type: props.rootVenue ? 1 : 2,
      lat: (props.rootVenue ? activeOrgAddress.latitude : activeVenueAddress.latitude ? activeVenueAddress.latitude : 0),
      lng: (props.rootVenue ? activeOrgAddress.longitude : activeVenueAddress.longitude ? activeVenueAddress.longitude : 0)
    };
  },
  validationSchema: () => Yup.object({
    name: Yup.string().required("Required")
      .min(3, "Minimum 3 Characters are Required")
      .max(45, "Maximum 45 Characters are Allowed")
      .matches(/^[a-zA-Z0-9_#'\- &]*$/, "Only alphanumeric characters and ( _, ', -, #, & ) are allowed"),
    addressLine: Yup.string(),
    reason: Yup.string().when('state', ([state], schema) => {
      if (state == VENUE_STATE.DISABLED) {
        return schema.required("Required")
      }
      else return schema.notRequired()
    })
  }),
  extractSubmitData: (values, props, activeOrgId, venueId) => {
    const submitData = {
      orgId: activeOrgId,
      parentVenueId: (props.rootVenue ? 0 : Number(venueId)),
      venueType: values.type,
      venueName: values.name,
      state: values.state,
      venueAddress: {
        addressLine: values.addressLine,
      },
      shippingAddress: {
        addressLine: values.addressLine,
      }
    };
    if (values.state == VENUE_STATE.DISABLED)
      submitData.reason = values.reason
    if (values.lat && values.lng) {
      submitData.venueAddress.latitude = values.lat;
      submitData.venueAddress.longitude = values.lng;
      submitData.shippingAddress.latitude = values.lat;
      submitData.shippingAddress.longitude = values.lng;
    }
    return submitData;
  }
};


export const streetviewHandler = (orgId, venueId, lat, lng) => {
  axios({
    method: 'get',
    url: `https://maps.googleapis.com/maps/api/streetview?size=1024x320&location=${lat},${lng}&return_error_code=true&fov=120&pitch=10&key=${process.env.REACT_APP_STREET_VIEW}`,
    responseType: 'arraybuffer',
  }).then(response => {
    // console.log("Response Image--->", response);
    const { run } = createRequest(services.organization.VENUE_STREETVIEW, [orgId, venueId, "put"]);
    const blob = new Blob([response.data]);
    const file = new File([blob], "streetview.jpeg");
    // saveAs(file);
    run()
      .then(url => {
        // console.log("URL--->", url.data)
        axios.put(url.data, file)
      })

  })
    .catch(err => {
      // console.log(err);
    });

}

/// setup state variables and init functions for 
const useAddVenue = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeOrgAddress = useSelector(store => store.activeOrg.data.orgAddress);
  const activeVenueAddress = useSelector(store => store.activeVenue.data.venueAddress);
  const permissions = useSelector(store => store.permissions.data);
  const { venueId } = useParams();
  const navigate = useNavigate();
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [submitErrorContext, setSubmitErrorContext] = useState(null);
  const [map, setMap] = useState(null);
  const [pos, setPos] = useState({
    lat: (props.rootVenue ? activeOrgAddress.latitude : activeVenueAddress.latitude ? activeVenueAddress.latitude : 0),
    lng: (props.rootVenue ? activeOrgAddress.longitude : activeVenueAddress.longitude ? activeVenueAddress.longitude : 0)
  });
  const [streetView, setStreetView] = useState(null);
  const [address, setAddress] = useState("");
  const [addressAlert, setAddressAlert] = useState(null);

  useEffect(() => {
    if (!!pos) {
      setStreetView(`https://maps.googleapis.com/maps/api/streetview?size=1024x320&location=${pos.lat},${pos.lng}&return_error_code=true
      &fov=120&pitch=10&key=${process.env.REACT_APP_STREET_VIEW}`)
    }
  }, [pos])

  Geocode.setApiKey(process.env.REACT_APP_MAP);
  /// Set up breadcrumb
  useEffect(() => {
    store.dispatch(breadcrumbActions.setData([{
      path: `/organization/${activeOrgId}/venues/`,
      text: "Venues",
      active: false
    },
    {
      path: `/organization/${activeOrgId}/venues/add`,
      text: "New Venue",
      active: true
    }]))
  }, [activeOrgId]);

  /// Load and set lat, long bounds on map
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(pos);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  /// Set map state to null on unmount
  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  /// Call API to create new venue
  function onAddVenueFormSubmit(values, { setSubmitting }) {
    const submitData = addVenueForm.extractSubmitData(values, props, activeOrgId, venueId);
    const { context, run } = createRequest(services.venue.POST, [], submitData);
    async function execute() {
      run()
        .then((response) => {
          navigate(`/organization/${activeOrgId}/venues/${response.data.venueId}`)
          store.dispatch(activeVenueActions.resetInfra());
          streetviewHandler(activeOrgId, response.data.venueId,
            response.data.venueAddress.latitude, response.data.venueAddress.longitude)
        })
        .catch((err) => {
          let x = new CatchedWebError(err);
          const apiContext = createErrorContext(context, 'CREATE VENUE', TICKET_CATEGORY.VENUE, err)
          // setSubmitError(x.message);
          make_custom_toast('error', 'Venue', x.message, true, 'Create Ticket', () => {
            navigate(
              `/organization/${activeOrgId}/support/createticket/${apiContext.action}/${apiContext.category}`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          })
        })
        .finally(() => {
          setSubmitting(false);
        })
    }
    if (!submitData.venueAddress.latitude)
      Geocode.fromAddress(submitData.venueAddress.addressLine).then((response) => {
        submitData.venueAddress.latitude = response?.results[0]?.geometry?.location?.lat;
        submitData.venueAddress.longitude = response?.results[0]?.geometry?.location?.lng;
        setPos({ lat: response?.results[0]?.geometry?.location?.lat, lng: response?.results[0]?.geometry?.location?.lng })
        execute();
      }).catch(() => { execute() })
    else
      execute();
  }

  /// Pre-Validate address field before submitting to Formik's onSubmit
  function onAddressPreValidation(event, handleSubmit, setFieldValue) {
    event.preventDefault();
    let addressValue = document.getElementById("venue-address").value
    if (address === "") {
      setFieldValue("addressLine", addressValue);
    } else {
      setFieldValue("addressLine", address);
    }
    let addressLength = addressValue;
    if (addressLength === 0) {
      setAddressAlert("Required");
    } else if (addressLength < 3) {
      setAddressAlert("Minimum 3 Characters are Required");
    } else if (addressLength > 250) {
      setAddressAlert("Maximum 250 Characters are Allowed");
    } else {
      handleSubmit();
    }
  }

  return { activeOrgId, onAddVenueFormSubmit, onAddressPreValidation, onLoad, onUnmount, activeOrgAddress, activeVenueAddress, permissions, venueId, navigate, submitSuccess, submitError, submitErrorContext, setSubmitSuccess, setSubmitError, map, setMap, streetView, setStreetView, pos, setPos, address, setAddress, addressAlert, setAddressAlert };
};

const ReasonInput = () => {
  return (
    <TextInput
      name="reason"
      label="Reason for disabled state"
      isrequired
      placeholder="Reason"
      // overallClasses={`mt-2 min-height--reason-input`}
      noBottomMargin
    />
  )
}

const AddVenue = (props) => {
  const { activeOrgId, onAddVenueFormSubmit, onAddressPreValidation, onLoad, onUnmount, activeOrgAddress, activeVenueAddress, streetView, setStreetView, navigate, submitSuccess, submitError, submitErrorContext, setSubmitError, map, pos, setPos, setAddress, addressAlert, setAddressAlert } = useAddVenue(props);

  const [googleMapView, setGoogleMapView] = useState(GOOGLE_MAP.MAP_VIEW);

  return (
    <div className="AddVenue" data-testid="AddVenue">
      {/* <Heading text="Add Venue" /> */}
      {(activeOrgAddress || activeOrgAddress) &&
        <div className="rounded bg-white shadow-sm border py-2 px-50 mt-1 addVenueBlock">
          <Formik
            initialValues={addVenueForm.initialValues(props, activeOrgAddress, activeVenueAddress)}
            validationSchema={addVenueForm.validationSchema()}
            onSubmit={onAddVenueFormSubmit}>
            {({ values, isSubmitting, handleSubmit, setFieldValue, setSubmitting }) => (
              <Form onSubmit={event => { onAddressPreValidation(event, handleSubmit, setFieldValue) }} className=" d-flex flex-column justify-content-between">
                {/* <ReportAlert
                  error={submitError}
                  setError={setSubmitError}
                  context={submitErrorContext}
                /> */}
                {/* <AlertBox color="danger" isOpen={submitError} toggle={() => { setSubmitError(null) }}>{submitError}</AlertBox> */}

                {/* New Venue created success modal */}
                <Modal centered isOpen={!!submitSuccess} className="p-5">
                  <ModalBody className="d-flex flex-column align-items-center p-4">
                    <div className="font-weight-bolder">A new venue is created successfully.</div>
                    <div className="pt-3">
                      <Button className="mx-1" color="outline-warning rx-1" onClick={() => { navigate(`/organization/${activeOrgId}/venues/`) }}>
                        All Venues
                      </Button>
                    </div>
                    <div className="mt-2">
                      <Button className="mx-1" color="outline-primary" onClick={() => { navigate(`/organization/${activeOrgId}/venues/${submitSuccess.venueId}/`); }}>
                        Go to new Venue
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>

                {/* Add Venue Form */}
                <div className="px-1">
                  <Row>
                    <Col xs={12} md={5}>
                      <div className="min-height--input">
                        <TextInput
                          type="text"
                          name="name"
                          label="Name"
                          isrequired={"true"}
                          placeholder="Name"
                          noBottomMargin
                        />
                      </div>

                      <div className='min-height--input'>
                        <label className="labelfont">State</label>
                        <VenueStateDropdown
                          selectedVenueState={values.state}
                          onChange={option => {
                            setFieldValue("state", Number(option))
                          }}
                        />
                      </div>
                    </Col>

                    <Col xd={12} md={4}>
                      <div className="min-height--address-input">
                        <Label className="autocomplete-label">
                          Venue Address
                        </Label>
                        <span className="text-danger">*</span>
                        {/* Address Autocomplete */}
                        <AutoComplete className={"form-control autocomplete-input " + (addressAlert && "invalid")}
                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                          // onBlur={(e) => {
                          //   Geocode.fromAddress(e.target.value).then((response) => {
                          //     setFieldValue('lat',response?.results[0]?.geometry?.location?.lat);
                          //     setFieldValue('lng',response?.results[0]?.geometry?.location?.lng);
                          //     setPos({lat:response?.results[0]?.geometry?.location?.lat,lng:response?.results[0]?.geometry?.location?.lng});
                          //   })
                          // }}
                          onChange={() => {
                            setFieldValue('lat', null);
                            setFieldValue('lng', null);
                            setAddressAlert(null);
                            setAddress("");
                          }}
                          id="venue-address"
                          options={{ types: [] }}
                          defaultValue={(props.rootVenue ? activeOrgAddress.addressLine : activeVenueAddress.addressLine) || ""}
                          placeholder="Address"
                          onPlaceSelected={
                            place => {
                              const selectLat = place?.geometry?.location?.lat() || 0;
                              const selectLng = place?.geometry?.location?.lng() || 0;
                              setAddress(place?.formatted_address || place.name);
                              setFieldValue('lat', selectLat);
                              setFieldValue('lng', selectLng);
                              setPos({ lat: selectLat, lng: selectLng });
                            }
                          }
                        />
                        {addressAlert && <span className="text-danger error-field">{addressAlert}</span>}
                      </div>
                      {
                        values.state == VENUE_STATE.DISABLED &&
                        <ReasonInput />
                      }
                      {/* <HeightAnimator heightAuto={values.state == VENUE_STATE.DISABLED} transitionTime='200ms'>
                      </HeightAnimator> */}
                      {/* <Select
                      defaultValue={VENUE_TYPE_MAPPING[props.rootVenue?0:1]}
                      styles={reactselectTheme}
                      isDisabled
                      options={VENUE_TYPE_MAPPING}
                      onChange={(e) => setFieldValue("type", Number(e.value))}
                    /> */}
                    </Col>
                    <Col xs={12} md={3}>
                      <div className="google-map-container">
                        {googleMapView == GOOGLE_MAP.MAP_VIEW ? <GoogleMap
                          mapContainerStyle={{ width: '100%', height: '12em' }}
                          defaultCenter={pos}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                          zoom={14}
                          options={{
                            disableDefaultUI: true,
                            keyboardShortcuts: false,
                            minZoom: 5
                          }}
                        >
                          {map?.panTo(pos)}
                          <Marker position={pos} />
                          {setTimeout(() => map?.setZoom(12), 10)}
                        </GoogleMap> :
                          <div className="text-center" style={{ height: "12em" }}>
                            {streetView != null ?
                              <img className="ap-img" alt={"No streetview"} src={streetView} onError={() => setStreetView(null)} />
                              : <div className="pt-2">
                                No Streetview found
                              </div>}
                          </div>
                        }
                        <div className="map-switch-container d-flex ">
                          <div className={`cursor-pointer map-switch-button d-flex leftsideborder ${googleMapView == GOOGLE_MAP.STREET_VIEW ? 'active' : ''}`} onClick={() => setGoogleMapView(GOOGLE_MAP.STREET_VIEW)}>
                            <StreetViewIcon
                              className={`street-icon ${googleMapView == GOOGLE_MAP.STREET_VIEW ? 'active' : ''}`}
                              width={12}
                              height={12} />
                          </div>
                          <div className={`cursor-pointer map-switch-button d-flex rightsideborder ${googleMapView == GOOGLE_MAP.MAP_VIEW ? 'active' : ''}`} onClick={() => setGoogleMapView(GOOGLE_MAP.MAP_VIEW)}>
                            <MapViewIcon
                              className={`map-icon ${googleMapView == GOOGLE_MAP.MAP_VIEW ? 'active' : ''}`}
                              width={12}
                              height={12} />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Form Submit/Discard Buttons */}
                <div className="mt-5 px-1 d-flex justify-content-end align-items-center">
                  <Button.Ripple colour="primary" className="mr-2 small-add-button" disabled={isSubmitting} outline onClick={() => { navigate(`/organization/${activeOrgId}/venues`) }}>Discard</Button.Ripple>
                  <Button.Ripple type="submit" color="primary" className="small-add-button" disabled={isSubmitting}>Add</Button.Ripple>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    </div>
  );
};

AddVenue.propTypes = {
  rootVenue: PropTypes.bool
};

AddVenue.defaultProps = {
  rootVenue: false
};

export default AddVenue;
