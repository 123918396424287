import { createSlice } from "@reduxjs/toolkit";

const activeOrgInitialState = {
  status: {
    loading: false,
    error: null
  },
  data: {
    orgId: null,
    parentOrgId: null,
    orgDisplayName: null,
    orgTypeId: null,
    createdAt: null,
    updatedAt: null,
    OLSEnable: null,
    marketplaceEnable: null,
    lidarScan: null,
    orgAddress: {
      addressLine: null,
      state: null,
      city: null,
      zipCode: null
    },
    reason: null
  },
  meta: {
    roles: [],
    dateRange: 6,
    cumulative: false,
    navVenue: 0,
    navVenueName: ""
  },
  logo: null
};

const activeOrgSlice = createSlice({
  name: "activeOrg",
  initialState: activeOrgInitialState,
  reducers: {
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    },
    setActiveOrg: (state, action) => {
      state.data = action.payload;
    },
    setOLSEnabled: (state, action) => {
      state.data.OLSEnable = action.payload;
    },
    setMarketplaceEnabled: (state, action) => {
      state.data.marketplaceEnable = action.payload;
    },
    setVxLANEnabled: (state, action) => {
      state.data.vxLAN = action.payload;
    },
    setThirdPartyEnabled: (state, action) => {
      state.data.thirdPartyEnable = action.payload;
    },
    setRoles: (state, action) => {
      state.meta.roles = [...(action.payload)];
    },
    resetActiveOrg: (state) => {
      state.logo = null;
      state = { ...activeOrgInitialState };
    },
    setActiveOrgLogo: (state, action) => {
      state.logo = action.payload;
    },
    setDateRange: (state, action) => {
      state.meta.dateRange = action.payload;
    },
    setCumulative: (state, action) => {
      state.meta.cumulative = action.payload;
    },
    setNavVenue: (state, action) => {
      state.meta.navVenue = action.payload;
    },
    setNavVenueName: (state, action) => {
      state.meta.navVenueName = action.payload;
    },
    resetNavVenue: (state, action) => {
      state.meta.navVenue = 0;
      state.meta.navVenueName = "";
    },
    resetActiveOrgLogo: (state, action) => {
      state.logo = null;
    },
    setLidarScan: (state, action) => {
      state.data.lidarScan = action.payload;
    }
  }
});

export const activeOrgReducer = activeOrgSlice.reducer;
const activeOrgActions = activeOrgSlice.actions;
export default activeOrgActions;

