/***
 *
 * Controller class for user.
 * @file TextEditor.js
 * @description TextEditor component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */
import React, { Suspense, lazy } from "react";
// import PropTypes from 'prop-types';
import "./TextEditor.scss";

const JoditEditor = lazy(() => import("jodit-react"));

const TextEditor = (props) => {
  const { ref, value, config, tabIndex, onBlur, onChange } = props
  return (
    <div className="TextEditor" onPaste={(e) => {
    }}>
      <Suspense fallback={<>Loading Editor...</>}>
        <JoditEditor
          ref={ref}
          value={props?.value}
          config={config}
          tabIndex={tabIndex}
          onBlur={onBlur}
          onChange={onChange}
        />
      </Suspense>
    </div>
  );
};

TextEditor.propTypes = {};

TextEditor.defaultProps = {};

export default TextEditor;
