/***
 *
 * Controller class for user.
 * @file InputBox.js
 * @description InputBox component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./InputBox.scss";

const InputBox = (props) => {
  const inputProps = {...props}
  delete inputProps.divClassNames
  return (
    <div className={"InputBox " + (props.divClassNames??"")} data-testid="InputBox">
      <input className={"InputBox " + props.className??""}{...inputProps}/>
    </div>
  );
};

InputBox.propTypes = {};

InputBox.defaultProps = {};

export default InputBox;