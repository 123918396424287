/***
 *
 * Controller class for user.
 * @file DummySwitch.js
 * @description DummySwitch component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useCallback, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import { Button, Col, Container, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { AlertBox, Toggle } from "../../../../components";
import { CatchedWebError } from "../../../../configs";
import createRequest, { services } from "../../../../services";
import { make_toast } from "../../../../helpers";
import "./DummySwitch.scss"
import Select from "react-select";
import Creatable from "react-select/creatable";
import { UncontrolledTooltip } from "reactstrap/lib";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isRange } from "../../OneL2Vlan";
import { DEFAULT_VLAN, commaSeparatedList } from "../../L2Vlan";
import { TRUNK_LABEL } from "../../../../utility/tooltip-config-global";
import { portSpeed } from "../../../Profiles/ModelSetting";
import { ReactComponent as CableTest } from "../../../../assets/images/icons/CableTest.svg";
import { ReactComponent as EthernetCable } from "../../../../assets/images/icons/EthernetCable.svg";
import { ReactComponent as SfpCable } from "../../../../assets/images/icons/SfpCable.svg";
import { ReactComponent as Monitor } from "../../../../assets/images/icons/Monitor.svg";
import APSVG from "../../InfraList/APSVG";
import { ReactComponent as PowerCycle } from "../../../../assets/images/icons/PowerCycle.svg";
import { ReactComponent as ResultWire } from "../../../../assets/images/icons/ResultWire.svg";
import { make_custom_toast } from "../../../../helpers/toasts";
import { ReactComponent as EmergencySirenIcon } from "../../../../assets/images/icons/emergency-siren.svg";
import { ReactComponent as Warning } from "../../../../assets/images/icons/Warning.svg";
import { ReactComponent as PoE } from "../../../../assets/images/icons/poe-shadow.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/images/icons/Success.svg";
import { ReactComponent as EthPort } from "../../../../assets/images/icons/EthPort.svg";
import { ReactComponent as SFPPort } from "../../../../assets/images/icons/SFPPort.svg";
import { meterToFeet } from "../../../../utility/Utils";
import ChartCardWithAreaCharts from "../../../Dashboard/Graphs/ChartCardWithAreaCharts";
import { oneInfraActions } from "../../../../redux/slices";
import RefreshButton from "../../../Dashboard/Graphs/RefreshButton";
import { convertToCorrectUnit } from "../../../Dashboard/NewDashboard/BlazerUtil";
import { useNavigate, useSearchParams } from "react-router-dom";
import lodash from "lodash-es"
import deepEqual from "deep-equal";

export const TRUNK = 'Trunk'
export const ACCESS = 'Access'

export const GREEN_COLOR = "#289A71";
export const GREY_COLOR = "#808080";
export const BLACK_COLOR = "#000";
const BLUE_COLOR = "#0E4D92";
const YELLOW_COLOR = "#FFBE40"
const TRUNK_COLOR = "#7A0303"
const ACCESS_COLOR = "#7367F0"
const PURPLE_COLOR = "#E218CE"

export const IntervalOptions = [{ value: 30, label: "30 Minutes" }, { value: 60, label: "1 Hour" }, { value: 1440, label: "24 Hours" },]

export const vl = (str) => ({ value: str, label: str == "inherit" ? "Inherit" : str });

export function isInteger(value) {
    return /^[0-9]+$/.test(value);
}

export const typesFromBytes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const typesFromBits = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
export const indexCalc = (seriesData) => {
    const size = 1024;
    // const types = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let index = 0;
    for (let i = 0; i < seriesData.length; i++) {
        let currIndex = seriesData[i] < 1 ? 0 : Math.floor(Math.log(seriesData[i]) / Math.log(size));
        if (currIndex > 8) currIndex = 8;
        if (index < currIndex) index = currIndex;
    }
    return index;
}

export function parseVlanSyntax(portsString) {
    let ports = new Set();
    let fragments = portsString.split(",");
    for (let uncleanfragment of fragments) {
        let fragment = uncleanfragment.trim();
        if (fragment === "") {
            continue
        } else if (isInteger(fragment)) {
            const num = Number(fragment);
            if (num >= 1 && num <= 4094) {
                ports.add(num);
            } else {
                return [[], "Enter value[1-4094]"]
            }
        } else if (isRange((fragment))) {
            const [start, end] = fragment.split("-").map(item => Number(item));
            if (start <= end) {
                for (let i = start; i <= end; i++) {
                    if (i >= 1 && i <= 4094) {
                        ports.add(i);
                    } else {
                        return [[...ports.keys()], "Enter value[1-4094]"]
                    }
                }
            } else {
                return [[], "Invalid"]
            }
        } else {
            return [[], "Invalid"]
        }
    }
    return [[...ports.keys()], null];
}

export function portBgColor(status) {
    if (status === 'Blocked') {
        return "fill-black";
    }
    else if (status === 'Warning') {
        return "fill-yellow"
    }
    else if (status === 'Connected') {
        return "fill-green"
    }
    else return "fill-grey"
    // if (!enabled) {
    //     return BLACK_COLOR;
    // } 
    // else if((!!maxSpeed && !!speed && Number(speed)<Number(maxSpeed)) || duplex !== 'full') {
    //     return YELLOW_COLOR
    // }
    // else if (!online || !connected) {
    //     return GREY_COLOR;
    // } 
    // else {
    //     return GREEN_COLOR;
    // }
}

export const portBorderColor = (borderType) => {
    return borderType === TRUNK
        ? "border-trunk"
        : borderType === ACCESS
            ? "border-access"
            : borderType === '8021x'
                ? "border-802"
                : borderType === `LAG`
                    ? "border-lag"
                    : "border-none"
}

let timeout;

const useAPName = (props) => {
    const [apName, setApName] = useState('')
    const { lldp } = props;
    useEffect(() => {
        const apNameMatch = lldp?.match(/Shasta AP - Name: (.*?),/);
        setApName(apNameMatch ? apNameMatch[1] : '')
    }, [lldp])

    return apName
}
const statusMapping = {
    OK: "Cable is a correctly terminated, good cable, good pair.",
    ON: "Cable is a Open pair, and has no link partner. This means that one (or more) pair has “no pin contact”. If length is returned, it could indicate the position of the opening.",
    ST: "A short is detected on the cable. Cable length is shorter than expected. If length is returned, it could indicate the position of the short.",
    IE: "The terminating impedance is not in the reference range.",
    NC: "No cable detected.",
    NT: "Not Tested",
    NS: "Gigabit Ethernet ports linked up at a speed lower than 1000 Mbps.",
    UN: "Unknown status/error"
}
const statusTitle = {
    ON: "Open Pair",
    ST: "Shorted Pair",
    IE: "Impedance Error",
    NS: "Not Supported",
}
const ResultPill = (props) => {
    const { length, status, id, small, index } = props;
    return (
        <div className={`ResultPill position-absolute bg-white rounded border ${small ? 'px-50' : 'p-50'}`}
            style={{ zIndex: 4 - index }}
        >
            {/* {!['OK'].includes(status) ? ( */}
            {/* <> */}
            <EmergencySirenIcon className='ResultIcon' id={id} />
            {statusMapping[status] != null ?
                <Tooltip anchorSelect={`#${id}`} place={'bottom'}
                    variant="light" border="2px solid #EAEAEA" opacity={1}
                >
                    <div className="wire-tooltip-status">
                        {statusTitle[status] ? <span className="wire-tooltip-status-title">{statusTitle[status]}:&nbsp;</span> : null}{statusMapping[status]}
                    </div>
                </Tooltip> : null}
            {/* </> */}
            {/* ) : (
                <SuccessIcon className='ResultIcon'/>
            )} */}
            {length ?? 0}
            &nbsp;
            ft
            {/* <div className="d-flex align-items-center justify-content-between">
            </div> */}
        </div>
    )
}

const Wire = (props) => {
    const { wireNo, color, slash } = props;
    return (
        <div className="d-flex WireDiv">
            <div className="WireNo">{wireNo}</div>
            <ResultWire
                className="ResultWire d-block"
                data-color={color}
                data-slash={slash}
            // data-no-top-bottom='true'
            />
            <div className="WireNo">{wireNo}</div>
        </div>
    )
}

export const includeProperty = (obj, properties) => {
    let returnObj = {};
    properties.map(it => { if (obj[it] != null) returnObj[it] = obj[it] });

    return returnObj;
}

const roundVal = (val) => {
    return !isNaN(Number(val)) ? Math.round(val * 100) / 100 : null
}

const temperatureVal = (val) => {
    let returnVal = roundVal(val)
    if (returnVal != null) {
        returnVal = `${returnVal} ºC`
    }
    return returnVal
}

const dBmVal = (val) => {
    let returnVal = roundVal(val)
    if (returnVal != null) {
        returnVal = `${returnVal} dBm`
    }
    return returnVal
}

export const PortModal = (props) => {
    const features = useSelector(store => store?.oneInfra?.features);
    const orgId = useSelector(store => store.activeOrg.data.orgId);
    const infra = useSelector(store => store.oneInfra.data.macAddress);
    const interval = useSelector(store => store.oneInfra.meta.interval);
    const [traffic, setTraffic] = useState({ isLoading: true, data: [] });
    const [packets, setPackets] = useState({ isLoading: true, data: [] });
    const [drop, setDrop] = useState({ isLoading: true, data: [] });
    const [error, setError] = useState({ isLoading: true, data: [] });

    const dispatch = useDispatch();

    const TimeSelector = () => {
        return <Select
            className="daterange-select"
            styles={{
                container: (provided, state) => ({
                    ...provided,
                    // maxWidth: '140px',
                    minWidth: '9rem'
                }),
                control: (provided, state) => ({
                    ...provided,
                    minHeight: '32px',
                    height: '32px',
                }),
                valueContainer: (provided, state) => ({
                    ...provided,
                    height: '32px',
                    display: "flex",
                    alignItems: "center"
                }),
                menu: (provided, state) => ({
                    ...provided,
                    zIndex: 10,
                }),
                input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                }),
                indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }),
            }}
            isClearable={false} isSearchable={false} defaultValue={IntervalOptions.find(it => it.value == interval)}
            options={IntervalOptions}
            onChange={(event) => { dispatch(oneInfraActions.setInterval(event.value)); getStatistics(event.value) }} />
    }

    const {
        editModal,
        setEditModal,
        modalTab,
        setModalTab,
        actualToShow,
        index,
        isPutting,
        portData,
        warning,
        setWarning,
        portStats,
        trunkVlans,
        cableTesting,
        cableTestResult,
        lldp,
        infraId,
        setCableTesting,
        setCableTestResult,
        borderType,
        sfp,
        children
    } = props;

    const apName = useAPName({ lldp: lldp });

    const getStatistics = (_interval = interval) => {
        const { run: getTraffic } = createRequest(services.telemetry.GET_PORT_TRAFFIC, [infra, orgId, `ethernet${index}`, _interval])
        const { run: getPackets } = createRequest(services.telemetry.GET_PORT_PACKETS, [infra, orgId, `ethernet${index}`, _interval])
        const { run: getDrops } = createRequest(services.telemetry.GET_PORT_DROP, [infra, orgId, `ethernet${index}`, _interval])
        const { run: getErros } = createRequest(services.telemetry.GET_PORT_ERROR, [infra, orgId, `ethernet${index}`, _interval])

        setTraffic({ isLoading: true })
        setPackets({ isLoading: true })
        setDrop({ isLoading: true })
        setError({ isLoading: true })

        getTraffic().then((response) => {
            let cat = response.data.map(it => it?.label);
            let unit = indexCalc([...response.data.map(it => it?.tx_bytes), ...response.data.map(it => it?.rx_bytes)]);
            // unit = unit>0 ? unit : 1;
            let series1 = { name: "Tx", data: convertToCorrectUnit(response.data.map(it => it?.tx_bytes), unit) }
            let series2 = { name: "Rx", data: convertToCorrectUnit(response.data.map(it => it?.rx_bytes), unit) }
            setTraffic({
                isLoading: false,
                categories: cat,
                series: [series1, series2],
                convertType: typesFromBytes[unit]
            })
        })

        getPackets().then((response) => {
            let cat = response.data.map(it => it?.label);
            let series1 = { name: "Tx", data: response.data.map(it => it?.tx_packets) }
            let series2 = { name: "Rx", data: response.data.map(it => it?.rx_packets) }
            setPackets({
                isLoading: false,
                categories: cat,
                series: [series1, series2]
            })
        })

        getDrops().then((response) => {
            let cat = response.data.map(it => it?.label);
            let series1 = { name: "Tx", data: response.data.map(it => it?.tx_dropped) }
            let series2 = { name: "Rx", data: response.data.map(it => it?.rx_dropped) }
            setDrop({
                isLoading: false,
                categories: cat,
                series: [series1, series2]
            })
        })

        getErros().then((response) => {
            let cat = response.data.map(it => it?.label);
            let series1 = { name: "Tx", data: response.data.map(it => it?.tx_error) }
            let series2 = { name: "Rx", data: response.data.map(it => it?.rx_error) }
            setError({
                isLoading: false,
                categories: cat,
                series: [series1, series2]
            })
        })


    }

    const handleCableTest = () => {
        const { run } = createRequest(services.infra.RUN, [infraId], {
            "cmd": "cable-diagnostics",
            "cablePorts": [`Ethernet${index}`]
        }
        )
        setCableTesting(true)
        run()
            .then(response => {
                // setCableTestResult(response.data)
                // console.log(response.data)
                make_custom_toast('success', sfp ? 'SFP Info Collection' : 'Cable Test', sfp ? 'SFP Info Collection' : 'Cable Test completed')
                let result = response?.data?.result?.text[`Ethernet${index}`] ?? {}
                // let result = {
                //     "type": "RJ45",
                //     "link-status": "UP",
                //     "pair-A": {
                //         "status": "ON",
                //         "meters": 8
                //     },
                //     "pair-B": {
                //         "status": "ST",
                //         "meters": 8
                //     },
                //     "pair-C": {
                //         "status": "IE",
                //         "meters": 8
                //     },
                //     "pair-D": {
                //         "status": "NC",
                //         "meters": 8
                //     }
                // }

                // {
                //     "Ethernet24": {
                //         "form-factor": "",
                //         "part-number": "",
                //         "revision": "",
                //         "rx-optical-power": 0,
                //         "serial-number": "",
                //         "temperature": 0,
                //         "tx-optical-power": 0,
                //         "type": "SFP",
                //         "vendor-name": ""
                //     }
                // }
                if (sfp) {
                    // console.log(result)
                    setCableTestResult(result)
                    return
                }
                const totalObj = {}
                if (result['pair-A'] != null && result['pair-B'] != null && result['pair-C'] != null && result['pair-D'] != null) {
                    if (
                        ['OK'].includes(result['pair-A'].status) &&
                        ['OK'].includes(result['pair-B'].status) &&
                        ['OK'].includes(result['pair-C'].status) &&
                        ['OK'].includes(result['pair-D'].status)
                    )
                        totalObj.status = 'OK'
                    else totalObj.status = 'ERROR'
                    let lengthA = !isNaN(Number(result['pair-A'].meters)) ? result['pair-A'].meters : 0
                    let lengthB = !isNaN(Number(result['pair-B'].meters)) ? result['pair-B'].meters : 0
                    let lengthC = !isNaN(Number(result['pair-C'].meters)) ? result['pair-C'].meters : 0
                    let lengthD = !isNaN(Number(result['pair-D'].meters)) ? result['pair-D'].meters : 0
                    totalObj.length = Math.max(lengthA, lengthB, lengthC, lengthD)
                    // totalObj.length = !isNaN(Number(result['pair-A'].meters)) ? result['pair-A'].meters : 0
                    // totalObj.length = !isNaN(Number(result['pair-B'].meters)) ? totalObj.length + result['pair-B'].meters : totalObj.length
                    // totalObj.length = !isNaN(Number(result['pair-C'].meters)) ? totalObj.length + result['pair-C'].meters : totalObj.length
                    // totalObj.length = !isNaN(Number(result['pair-D'].meters)) ? totalObj.length + result['pair-D'].meters : totalObj.length
                    totalObj.lengthFloored = !isNaN(Math.floor(totalObj.length)) ? Math.floor(totalObj.length) : totalObj.length
                }
                else totalObj.status = 'ERROR'

                setCableTestResult(
                    {
                        green: resultObj(result, 'pair-A'),
                        orange: resultObj(result, 'pair-B'),
                        blue: resultObj(result, 'pair-C'),
                        brown: resultObj(result, 'pair-D'),
                        total: totalObj
                    }
                )
            })
            .catch(err => {
                make_custom_toast('error', 'Cable Test', (new CatchedWebError(err)).message)
            })
            .finally(() => {
                setCableTesting(false);
            })
    }

    const handlePowerCycle = () => {
        const { run } = createRequest(services.infra.RUN, [infraId], {
            "cmd": "powercycle",
            "powerCyclePorts": [{ name: `Ethernet${index}`, cycle: 1000 }]
        })
        run()
            .then(response => {
                make_custom_toast('success', 'Power Cycle', 'Power Cycling the port.')
            })
            .catch(err => {
                make_custom_toast('error', 'Power Cycle', (new CatchedWebError(err)).message)
            })
        // .finally(() => {
        //     setCableTesting(false);
        // })
    }

    const resultObj = (result, property) => {
        let obj = result[property] ? {
            length: meterToFeet(result[property] != null && result[property].meters != null
                ? result[property].meters
                : '-'),
            status: result[property] != null ? result[property].status : null
        } : {
            length: '-',
            status: ''
        }
        return obj

    }

    useEffect(() => {
        if (editModal) {
            getStatistics();
        }
    }, [infraId, editModal])

    useEffect(() => {
        // console.log("cableTestResult: ",cableTestResult)
        if (cableTestResult != null) {
            setWarning(false)
        }
    }, [cableTestResult])

    return (
        <Modal size="lg" centered isOpen={editModal} toggle={() => { if (!isPutting) setEditModal(false); }}
            className="PortModal"
        >
            <ModalHeader className="bg-white pb-0" toggle={() => { if (!isPutting) setEditModal(false); }}>
                <h4 className="font-weight-bolder">{`Port ${actualToShow[index]}`} {portData?.name?.length > 0 ? ` ${portData.name}` : ""}</h4>
            </ModalHeader>
            <ModalBody>
                <div className={`d-flex tabs-div mb-2`}>
                    <div data-active={modalTab === 'overview'} onClick={() => setModalTab('overview')} className="cursor-pointer">Overview</div>
                    <div data-active={modalTab === 'config'} onClick={() => setModalTab('config')} className="cursor-pointer">Config</div>
                    <div data-active={modalTab === 'stats'} onClick={() => setModalTab('stats')} className="cursor-pointer">Statistics</div>
                </div>
                {modalTab === 'config' ? children
                    : modalTab == 'overview' ?
                        <div className="PortOverviewDiv d-flex justify-content-between flex-column">
                            <div>
                                <div className="TopDiv">
                                    <div className="SimplePortDiv"
                                    // style={{
                                    //     backgroundColor: portBgColor(portStats?.status ?? ""),
                                    //     border: borderType ? `0.4px solid ${portBorderColor(borderType)}` : null
                                    // }}
                                    >
                                        <div className="position-relative">
                                            <div className="port-number" data-sfp={sfp}>{actualToShow[index]}</div>
                                            {sfp
                                                ? <SFPPort height={46} width={46}
                                                    className={`${portBgColor(portStats?.status ?? "")} ${!!borderType ? "port-border" : "port-border-none"} ${portBorderColor(borderType)}`} />
                                                : <EthPort height={46} width={46}
                                                    className={`${portBgColor(portStats?.status ?? "")} ${!!borderType ? "port-border" : "port-border-none"} ${portBorderColor(borderType)}`} />}
                                        </div>
                                    </div>
                                    <div className="CableDiv">
                                        {sfp ? <SfpCable className="w-100" /> : <EthernetCable className="w-100" />}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {apName?.length > 0 ? <APSVG height='50' width='50' /> : <Monitor height='50' width='50' />}
                                    </div>
                                    <div className="d-flex align-items-center font-weight--600 color--shasta-black"><div className="port-name">{portData?.name ?? ""}</div></div>
                                    <div>
                                        {!features?.cableDiagnosticSupport ? null :
                                            <Button.Ripple
                                                className="CableTestDiv"
                                                color='primary'
                                                disabled={cableTesting}
                                                onClick={() => {
                                                    if (sfp) {
                                                        handleCableTest()
                                                    }
                                                    else {
                                                        setWarning(true)
                                                    }
                                                }}
                                            >
                                                {sfp ? (
                                                    !cableTesting ? (
                                                        'Collect SFP Info'
                                                    ) : <Spinner size='sm' />
                                                ) : (
                                                    <><CableTest />Cable Test</>
                                                )
                                                }
                                            </Button.Ripple>}
                                    </div>
                                    <div className="d-flex align-items-center font-weight--600 color--shasta-black">{apName}</div>
                                </div>
                                <div className="BottomDiv">
                                    <div className="d-block">
                                        <div className="font-weight--500 color--shasta-black">Operational Info:</div>
                                        <ul className="OperationalInfoList">
                                            <li>
                                                <span className="OpInfoKey">Status :&nbsp;</span>
                                                &nbsp;
                                                <span>{portStats?.status ?? 'Unknown'}</span>
                                            </li>
                                            <li>
                                                <span className="OpInfoKey">Speed :&nbsp;</span>
                                                &nbsp;
                                                <span>{portSpeed.find(it => it.value == portStats?.speed)?.label ?? 'Unknown'}</span>
                                            </li>
                                            <li>
                                                <span className="OpInfoKey">Duplex :&nbsp;</span>
                                                &nbsp;
                                                <span className="capitalize">{portStats?.duplex ?? 'Unknown'}</span>
                                            </li>
                                            <li className={sfp ? "d-none" : ""}>
                                                <span className="OpInfoKey">PoE power draw :&nbsp;</span>
                                                &nbsp;
                                                <span>
                                                    {
                                                        portStats?.poe != null ? (
                                                            (portStats?.poe / 1000).toFixed(3) + ' W'
                                                        ) : "Unknown"
                                                    }
                                                </span>
                                            </li>
                                            <li>
                                                <span className="OpInfoKey">Native VLAN :&nbsp;</span>
                                                &nbsp;
                                                <span>{portData?.vlan?.inherit ? (portData?.vlanProfileValue?.untaggedVlan ?? "-") : (portData?.vlan?.untaggedVlan ?? "-")}</span>
                                            </li>
                                            <li>
                                                <span className="OpInfoKey">Trunk VLANs :&nbsp;</span>
                                                &nbsp;
                                                <span>{trunkVlans ?? '-'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="ResultContainer">
                                        {!features?.cableDiagnosticSupport ? null :
                                            sfp ? cableTesting || cableTestResult == null ? null : (
                                                <div className="ResultsDiv">
                                                    <div className="d-block color--shasta-black">SFP Operational Info:</div>
                                                    <ul className="SfpOpList">
                                                        <li>
                                                            <span className="SfpInfoKey">Vendor :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{cableTestResult["vendor-name"] ?? 'Unknown'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="SfpInfoKey">Form Factor :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{cableTestResult["form-factor"] ?? 'Unknown'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="SfpInfoKey">Part Number :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{cableTestResult["part-number"] ?? 'Unknown'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="SfpInfoKey">Serial number :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{cableTestResult["serial-number"] ?? 'Unknown'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="SfpInfoKey">Revision :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{cableTestResult["revision"] ?? 'Unknown'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="SfpInfoKey">Temperature :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{temperatureVal(cableTestResult["temperature"]) ?? 'Unknown'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="SfpInfoKey">Vcc :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{cableTestResult["vcc"] ?? 'Unknown'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="SfpInfoKey">Current :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{cableTestResult["tx-bias-current"] ?? 'Unknown'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="SfpInfoKey">Tx Power :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{dBmVal(cableTestResult["tx-optical-power"]) ?? 'Unknown'}</span>
                                                        </li>
                                                        <li>
                                                            <span className="SfpInfoKey">Rx Power :&nbsp;</span>
                                                            &nbsp;
                                                            <span>{dBmVal(cableTestResult["rx-optical-power"]) ?? 'Unknown'}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ) : warning || cableTesting || cableTestResult ? <div className="ResultsDiv" data-warning={!!warning}>
                                                {warning ? (
                                                    <div className="CTWarningDiv">
                                                        <div className="font-weight--500">
                                                            Do you want to Cable test?
                                                        </div>
                                                        <div>This is a disruptive process</div>
                                                        <div className="gap--10 d-flex justify-content-end">
                                                            <Button.Ripple color='primary' outline size='sm'
                                                                disabled={cableTesting}
                                                                onClick={() => setWarning(false)}
                                                            >Cancel</Button.Ripple>
                                                            <Button.Ripple color='primary' size='sm'
                                                                disabled={cableTesting}
                                                                onClick={handleCableTest}
                                                            >{cableTesting ? <Spinner size='sm' /> : 'Confirm'}</Button.Ripple>
                                                        </div>
                                                    </div>
                                                ) : cableTestResult != null ? (
                                                    <>
                                                        <div className="text-center mb-1">{['OK'].includes(cableTestResult.total.status) ? <SuccessIcon /> : null}{meterToFeet(cableTestResult.total.length)}&nbsp;ft&nbsp;({cableTestResult.total.lengthFloored}&nbsp;{cableTestResult.total.lengthFloored === 1 ? 'meter' : 'meters'})</div>
                                                        <div className="position-relative">
                                                            <div>
                                                                <div className="d-flex WireDiv">
                                                                    <Wire wireNo={1} color='green' />
                                                                </div>
                                                                <div className="d-flex WireDiv">
                                                                    <Wire wireNo={2} color='green' slash={false} />
                                                                </div>
                                                            </div>
                                                            {['OK'].includes(cableTestResult['green'].status) ? null : (
                                                                <ResultPill
                                                                    length={cableTestResult['green'].length}
                                                                    status={cableTestResult['green'].status}
                                                                    id='wire-green'
                                                                    index={0}
                                                                />
                                                            )
                                                            }
                                                        </div>
                                                        <div className="d-flex WireDiv position-relative">
                                                            <Wire wireNo={3} color='orange' />
                                                            {['OK'].includes(cableTestResult['orange'].status) ? null : (
                                                                <ResultPill
                                                                    length={cableTestResult['orange'].length}
                                                                    status={cableTestResult['orange'].status}
                                                                    id='wire-orange'
                                                                    small={true}
                                                                    index={1}
                                                                />
                                                            )
                                                            }
                                                        </div>
                                                        <div className="position-relative">
                                                            <div className="d-flex WireDiv">
                                                                <Wire wireNo={4} color='blue' slash={false} />
                                                            </div>
                                                            <div className="d-flex WireDiv">
                                                                <Wire wireNo={5} color='blue' />
                                                            </div>
                                                            {['OK'].includes(cableTestResult['blue'].status) ? null : (
                                                                <ResultPill
                                                                    length={cableTestResult['blue'].length}
                                                                    status={cableTestResult['blue'].status}
                                                                    id='wire-blue'
                                                                    index={2}
                                                                />
                                                            )
                                                            }
                                                        </div>
                                                        <div className="d-flex WireDiv position-relative">
                                                            <Wire wireNo={6} color='orange' slash={false} />
                                                            {['OK'].includes(cableTestResult['orange'].status) ? null : (
                                                                <ResultPill
                                                                    length={cableTestResult['orange'].length}
                                                                    status={cableTestResult['orange'].status}
                                                                    id='wire-orange-second'
                                                                    small={true}
                                                                    index={3}
                                                                />
                                                            )
                                                            }
                                                        </div>
                                                        <div className="position-relative">
                                                            <div>
                                                                <div className="d-flex WireDiv">
                                                                    <Wire wireNo={7} color='brown' />
                                                                </div>
                                                                <div className="d-flex WireDiv">
                                                                    <Wire wireNo={8} color='brown' slash={false} />
                                                                </div>
                                                            </div>
                                                            {['OK'].includes(cableTestResult['brown'].status) ? null : (
                                                                <ResultPill
                                                                    length={cableTestResult['brown'].length}
                                                                    status={cableTestResult['brown'].status}
                                                                    id='wire-brown'
                                                                    index={4}
                                                                />
                                                            )}
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                                : null}
                                    </div>
                                    <div className="d-block">
                                        <div className="font-weight--500 color--shasta-black">
                                            LLDP Info:
                                        </div>
                                        <div className="mt-50">
                                            {lldp}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {sfp || !features?.poeSupport?.powerCycle ? null :
                                <div>
                                    <Button.Ripple
                                        color='primary'
                                        className='d-flex align-items-center mb-1'
                                        onClick={handlePowerCycle}
                                    >
                                        <PowerCycle className="mr-50" />Power Cycle
                                    </Button.Ripple>
                                </div>}
                        </div>
                        : <div className="StatisticsDiv">
                            <div className="d-flex align-items-center justify-content-end mr-1 mb-50">
                                <TimeSelector />
                                <RefreshButton clickHandler={() => { }} clickFunc={getStatistics} />
                            </div>
                            <Row className="m-0 mb-2">
                                <Col xs={6}>
                                    <ChartCardWithAreaCharts
                                        title={"Network Traffic"}
                                        categories={traffic?.categories}
                                        series={traffic?.series}
                                        colors={["#B347E6", "#F5A742"]}
                                        height={"160px"}
                                        width={"108%"}
                                        isTraffic={true}
                                        ticks={5}
                                        selectorComponent={TimeSelector}
                                        xlabel={`Traffic (${traffic?.convertType})`}
                                        ylabel={"Time"}
                                        typeSeriesTrafficUplink={traffic?.convertType}
                                        typeSeriesTrafficDownlink={traffic?.convertType}
                                        isLoading={traffic?.isLoading}
                                        showBorders={true}
                                        timeInterval={5}
                                        noStyle={true}
                                        noCardBorder={false} />
                                </Col>
                                <Col xs={6}>
                                    <ChartCardWithAreaCharts
                                        title={"Packet Drop"}
                                        categories={drop?.categories}
                                        series={drop?.series}
                                        colors={["#B347E6", "#F5A742"]}
                                        height={"160px"}
                                        width={"108%"}
                                        ticks={5}
                                        selectorComponent={TimeSelector}
                                        xlabel={`Packets`}
                                        ylabel={"Time"}
                                        // typeSeriesTrafficUplink={"MB"}
                                        // typeSeriesTrafficDownlink={"MB"}
                                        isLoading={drop?.isLoading}
                                        showBorders={true}
                                        timeInterval={5}
                                        noStyle={true}
                                        noCardBorder={false} />
                                </Col>
                            </Row>

                            <Row className="m-0">
                                <Col xs={6}>
                                    <ChartCardWithAreaCharts
                                        title={"Packets"}
                                        categories={packets?.categories}
                                        series={packets?.series}
                                        colors={["#B347E6", "#F5A742"]}
                                        height={"160px"}
                                        width={"108%"}
                                        // isTraffic={true}
                                        ticks={5}
                                        selectorComponent={TimeSelector}
                                        xlabel={`Packets`}
                                        ylabel={"Time"}
                                        // typeSeriesTrafficUplink={"MB"}
                                        // typeSeriesTrafficDownlink={"MB"}
                                        isLoading={traffic?.isLoading}
                                        timeInterval={5}
                                        showBorders={true}
                                        noStyle={true}
                                        noCardBorder={false} />
                                </Col>
                                <Col xs={6}>
                                    <ChartCardWithAreaCharts
                                        title={"Errors"}
                                        categories={error?.categories}
                                        series={error?.series}
                                        colors={["#B347E6", "#F5A742"]}
                                        height={"160px"}
                                        width={"108%"}
                                        ticks={5}
                                        selectorComponent={TimeSelector}
                                        xlabel={`Errors`}
                                        ylabel={"Time"}
                                        // typeSeriesTrafficUplink={"MB"}
                                        // typeSeriesTrafficDownlink={"MB"}
                                        isLoading={traffic?.isLoading}
                                        timeInterval={5}
                                        showBorders={true}
                                        noStyle={true}
                                        noCardBorder={false} />
                                </Col>
                            </Row>
                        </div>}
            </ModalBody>
        </Modal>
    )
}

const Port = ({ index, portData, fullConfig, portStats, infraId, infraTypeId, connected, onSubmit, x, y, dim, maxSpeed, orgId, entireConfig, lldp, profileName, profileURL, onClick, vlanId, mode, selectedPorts = new Set(), disabled = false, setTooltip }) => {

    const features = useSelector(store => store?.oneInfra?.features)
    const actualToShow = useSelector(store => store?.oneInfra?.actualToShow)
    const [editModal, setEditModal] = useState(false);
    const [isPutting, setIsPutting] = useState(false);
    const [isError, setIsError] = useState(false);
    const [borderType, setBorderType] = useState(null)
    const [trunkVlans, setTrunkVlans] = useState("")
    const [modalTab, setModalTab] = useState('overview')
    const [warning, setWarning] = useState(false)
    const [cableTesting, setCableTesting] = useState(false)
    const [cableTestResult, setCableTestResult] = useState(null)

    // useEffect(() => {
    //   setCableTestResult({
    //     "green": {
    //         "length": 26,
    //         "status": "OK"
    //     },
    //     "orange": {
    //         "length": 26,
    //         "status": "ON"
    //     },
    //     "blue": {
    //         "length": 26,
    //         "status": "ST"
    //     },
    //     "brown": {
    //         "length": 26,
    //         "status": "IE"
    //     },
    //     "total": {
    //         length: 26*4,
    //         status: "NC"
    //     }
    // })
    // }, [])

    useEffect(() => {
        let trunkVlansString = portData?.vlan?.inherit ? commaSeparatedList(portData?.vlanProfileValue?.taggedVlans ?? []) : commaSeparatedList(portData?.vlan?.taggedVlans ?? []);
        setTrunkVlans(trunkVlansString !== "" ? trunkVlansString : "-")
    }, [portData])


    useEffect(() => {
        const vlanConfig = portData?.vlan
        if (!!vlanConfig) {
            if (mode == 'vlan') {
                if (!!vlanId && vlanConfig?.taggedVlans?.includes(vlanId)) {
                    setBorderType(TRUNK)
                }
                else if (!!vlanId && vlanConfig?.untaggedVlan == vlanId) {
                    setBorderType(ACCESS)
                }
                else setBorderType(null)
            }
            else if (mode == '8021x') {
                if (selectedPorts.has(index))
                    setBorderType('8021x')
                else setBorderType(null)
            }
        }
    }, [portData, index, mode, selectedPorts, vlanId])


    const setPortData = useCallback((data) => {
        setIsError(false);
        if (infraId) {
            setIsPutting(true);
            let newConfig = JSON.parse(JSON.stringify(entireConfig))
            let vlans = {}
            newConfig.configuration.portsConfiguration = data.configuration
            // Object.keys(newConfig.configuration.portsConfiguration).map(port => {
            //     delete newConfig.configuration.portsConfiguration[port].vlan
            // })
            Object.keys(newConfig.configuration.vlans ?? {}).map(vlan => {
                if (!!newConfig.configuration.vlans[vlan]?.ipv4?.subnet && !!newConfig.configuration.vlans[vlan]?.ipv4?.subnet[0]?.prefix) {
                    vlans[vlan] = {
                        "subnet": newConfig.configuration.vlans[vlan]?.ipv4?.subnet
                    }
                }
            })

            const { run } = createRequest(services.networks.SWITCHCONFIG_SET, [infraId, orgId], {
                orgId: orgId,
                configuration: {
                    portsConfiguration: newConfig.configuration.portsConfiguration,
                    vlans: vlans
                }
            });
            run()
                .then(response => {
                    setEditModal(false);
                    make_toast("success", "Port settings updated", true);
                    onSubmit();
                    // REDUX_WORKER.getInfra(infraId);
                })
                .catch((err) => {
                    const x = new CatchedWebError(err);
                    setIsError(x.message);
                })
                .finally(() => {
                    setIsPutting(false);
                })
        }
    }, [infraId, orgId]);

    return (<g className="cursor-pointer" id={`Port${index}`}>
        {/* <rect x={x} y={y} rx={1} width={dim} height={dim}  fill={portBgColor(portStats?.status ?? "")}
            stroke-width={!!borderType ? 0.4 : 0}
            stroke={portBorderColor(borderType)}
        /> */}
        <g transform={`translate(${x}, ${y})`}>
            <EthPort height={dim} width={dim}
                className={`${portBgColor(portStats?.status ?? "")} ${!!borderType ? "port-border" : "port-border-none"} ${portBorderColor(borderType)}`} />
        </g>
        {(portStats.poe > 0) ? <g transform={`translate(${x + (dim / 2)}, ${y - 1})`}>
            <PoE height={4} width={4} />
        </g> : null}
        {/* <text x= y=fill="white" class="font-small">{i+1}</text> */}
        <text x={x + (dim / 2)} y={y + (dim - 3.5)} className="font-small" fill="white" dominant-baseline="middle" text-anchor="middle">{actualToShow[index]}</text>
        {/* <Port index={item} key={item} connected={stats.EthernetStatus[`Ethernet${item}`]} portData={config[`Ethernet${item}`]} fullConfig={config} infraId={infraId} onSubmit={onSubmit}/> */}
        {/* <div style={{backgroundColor:"red",height:"100%", width:"100%"}}></div> */}
        {/* <ToolTip active={isTooltipActive} position="bottom" arrow="center" parent={`#Port${index}`}>
        </ToolTip> */}
        <PortModal
            editModal={editModal}
            setEditModal={setEditModal}
            modalTab={modalTab}
            setModalTab={setModalTab}
            actualToShow={actualToShow}
            index={index}
            isPutting={isPutting}
            portData={portData}
            setPortData={setPortData}
            fullConfig={fullConfig}
            warning={warning}
            setWarning={setWarning}
            portStats={portStats}
            trunkVlans={trunkVlans}
            cableTesting={cableTesting}
            cableTestResult={cableTestResult}
            lldp={lldp}
            infraId={infraId}
            setCableTesting={setCableTesting}
            setCableTestResult={setCableTestResult}
            borderType={borderType}
        >
            <Container className="PortConfigTabContainer d-flex flex-column">
                {/* <div className="ConfigHeading mb-50">Ethernet&nbsp;{actualToShow[index]}</div> */}
                {/* {isPutting?<p className="text-center text-primary"><strong>{isPutting && "Saving..."}</strong></p>:<></>} */}
                <AlertBox color="danger" isOpen={!!isError} toggle={() => { setIsError(false); }}>
                    {isError}
                </AlertBox>
                <PortForm hidePOE={features?.poe === false} infraTypeId={infraTypeId} isPutting={isPutting} setPortData={setPortData} index={index} portData={portData} profileName={profileName} profileURL={profileURL} fullConfig={fullConfig} infraId={infraId} connected={connected} x={x} y={y} dim={dim} onCancel={() => { if (!isPutting) setEditModal(false); }} maxSpeed={maxSpeed} />
            </Container>
        </PortModal>
        <foreignObject x={x} y={y} width={dim} height={dim} onMouseOver={() => {
            const div = document.getElementById(`Port${index}`);
            const rect = div.getBoundingClientRect();
            clearTimeout(timeout)
            timeout = setTimeout(() => setTooltip({
                show: true, x: (rect.left + (rect.width / 2)), y: rect.bottom, data: {
                    index: index,
                    connected: connected,
                    portData: portData,
                    sfpPort: false,
                    trunkVlans: trunkVlans,
                    portStats: portStats,
                    lldp: lldp
                }
            }), 500);
        }}
            onMouseLeave={() => {
                clearTimeout(timeout)
                setTooltip(prevState => { return { ...prevState, show: false } })
            }}
            onClick={() => {
                if (!disabled) {
                    if (!onClick) {
                        if (portData)
                            setEditModal(true)
                    }
                    else {
                        // setIsTooltipActive(false)
                        onClick(index)
                    }
                }
            }}>
        </foreignObject>
    </g>)
};

export const SingleVal = (props) => {
    const { data } = props;
    if (data.value == "inherit")
        return (<div style={props.getStyles("singleValue", props)}>
            {data.inherit} <span className="ml-50 inherit-tag">Inherited</span>
        </div>)

    return (<div style={props.getStyles("singleValue", props)}>
        {data.label}
    </div>)
}

const getInitialLldpData = (portData) => {
    return {
        enabled: portData?.lldp ? portData?.lldp?.enabled : false,
        "8023TLV": portData?.lldp ? portData?.lldp["8023TLV"] : false,
        "8021TLV": portData?.lldp ? portData?.lldp["8021TLV"] : false,
        MEDTLV: portData?.lldp ? portData?.lldp?.MEDTLV : false
    }
}
const getInitialPoeData = (portData) => {
    return {
        "admin-mode": portData?.poe?.hasOwnProperty("admin-mode") ? portData?.poe["admin-mode"] : true,
        detection: portData?.poe?.detection || "",
        "power-limit": portData?.poe?.hasOwnProperty("power-limit") ? portData?.poe["power-limit"] : 99900,
        priority: portData?.poe?.priority || "high"
    }
}



/// PORT FORM ===============================>
export const PortForm = (props) => {
    const { isPutting, index, portData, fullConfig, infraTypeId, connected, onSubmit, profileURL = "", profileName = "", onCancel, setPortData, hidePOE = false, hideDuplex = false, maxSpeed = "1000", minSpeed = "0", profile = false, disable802, lagArray, showWarning, setShowWarning } = props;
    const [params, setParams] = useSearchParams();
    const [duplex, setDuplex] = useState(portData?.duplex ?? "full");
    const [name, setName] = useState(portData?.name ?? "")
    const infraTypes = useSelector(store => store.infraTypes.data);
    const features = useSelector(store => store.infraTypes.data.find(it => it.infraTypeId == infraTypeId).ports.capabilities)
    const [enabled, setEnabled] = useState(portData?.enabled);
    const [speed, setSpeed] = useState(portData?.speed ?? 100);

    const [mode, setMode] = useState(portData?.vlan?.inherit ? "inherit" : (portData?.vlan?.mode ?? "Access"));
    const [untaggedVlan, setUntaggedVlan] = useState(portData?.vlan?.inherit ? portData?.vlanProfileValue?.untaggedVlan : (portData?.vlan?.untaggedVlan ?? null));
    const [taggedVlans, setTaggedVlans] = useState(portData?.vlan?.inherit ? commaSeparatedList(portData?.vlanProfileValue?.taggedVlans ?? []) : commaSeparatedList(portData?.vlan?.taggedVlans ?? []));

    const [adminMode, setAdminMode] = useState(portData?.poe?.hasOwnProperty("admin-mode") ? portData?.poe["admin-mode"] : true); // fix this
    const [detection, setDetection] = useState(portData?.poe?.detection || "");
    const [doReset, setDoReset] = useState(portData?.poe?.hasOwnProperty("do-reset") ? portData?.poe["do-reset"] : false);          // fix this
    const [powerLimit, setPowerLimit] = useState(portData?.poe?.hasOwnProperty("power-limit") ? portData?.poe["power-limit"] : 99900);  // fix this
    const [priority, setPriority] = useState(portData?.poe?.priority || "high");
    const [ieee, setIeee] = useState(Object.keys(portData?.ieee8021x ?? {}).length > 0);

    const [lldpEnabled, setLldpEnabled] = useState(portData?.lldp ? portData?.lldp?.enabled : false);
    const [lldp8021, setLldp8021] = useState(portData?.lldp ? portData?.lldp["8021TLV"] : false);
    const [lldp8023, setLldp8023] = useState(portData?.lldp ? portData?.lldp["8023TLV"] : false);
    const [lldpMED, setLldpMED] = useState(portData?.lldp ? portData?.lldp?.MEDTLV : false);

    const [switchDuplex, setSwitchDuplex] = useState([{ label: "Half", value: "half" }, { label: "Full", value: "full" }]);
    const [switchEnable, setSwitchEnable] = useState([{ label: "Yes", value: true }, { label: "No", value: false }]);
    const [switchPriority, setSwitchPriority] = useState([{ label: "Critical", value: "critical" }, { label: "High", value: "high" },
    { label: "Medium", value: "medium" }, { label: "Low", value: "low" }]);
    const [switchPoePower, setSwitchPoePower] = useState([{ label: "On", value: true }, { label: "Off", value: false }]);
    const [switchPoeLimit, setSwitchPoeLimit] = useState([{ label: "PoE Class-1 (4W)", value: 4000 }, { label: "PoE Class-2 (7W)", value: 7000 }, { label: "PoE Class-5 (15.4W)", value: 15400 },
    { label: "PoE+ Class-4 (30W)", value: 30000 }, { label: "PoE++ Class-5 (45W)", value: 45000 }, { label: "PoE++ Class-6 (60W)", value: 60000 }, { label: "PoE++ Class-7 (75W)", value: 75000 },
    { label: "PoE++ Class-8 (90W)", value: 90000 },
    ])
    const [switchSpeed, setSwitchSpeed] = useState([...portSpeed.filter(it => ((Number(it.value) <= Number(maxSpeed)) && (Number(it.value) >= Number(minSpeed))))]);
    const [switchLLDPEnable, setSwitchLLDPEnable] = useState([{ label: "On", value: true }, { label: "Off", value: false }]);
    const [switch8021, setSwitch8021] = useState([{ label: "On", value: true }, { label: "Off", value: false }]);
    const [switch8023, setSwitch8023] = useState([{ label: "On", value: true }, { label: "Off", value: false }]);
    const [switchMED, setSwitchMED] = useState([{ label: "On", value: true }, { label: "Off", value: false }]);
    const [switchVLAN, setSwitchVLAN] = useState([vl("Access"), vl("Trunk")]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!profile) {
            let inheritSwitchDuplex = switchDuplex.find(it => it.value == portData?.duplexProfileValue)?.label;
            let inheritSwitchEnable = switchEnable.find(it => it.value == portData?.enabledProfileValue)?.label;
            let inheritSwitchPriority = switchPriority.find(it => it.value == portData?.poeProfileValue?.priority)?.label;
            let inheritSwitchPoePower = switchPoePower.find(it => it.value == (!!portData?.poeProfileValue ? portData?.poeProfileValue["admin-mode"] : false))?.label;
            let inheritSwitchSpeed = switchSpeed.find(it => it.value == portData?.speedProfileValue)?.label;
            let inheritSwitchVLAN = switchVLAN.find(it => it.value == (portData?.vlanProfileValue ? portData?.vlanProfileValue?.mode : "Access"))?.label;
            let inheritSwitchLLDPEnable = switchLLDPEnable.find(it => it.value == (portData?.lldpProfileValue ? portData?.lldpProfileValue?.enabled : false))?.label;
            let inheritSwitch8021 = switch8021.find(it => it.value == (portData?.lldpProfileValue ? portData?.lldpProfileValue["8021TLV"] : false))?.label;
            let inheritSwitch8023 = switch8023.find(it => it.value == (portData?.lldpProfileValue ? portData?.lldpProfileValue["8023TLV"] : false))?.label;
            let inheritSwitchMED = switchMED.find(it => it.value == (portData?.lldpProfileValue ? portData?.lldpProfileValue["MEDTLV"] : false))?.label;
            let inheritSwitchPoeLimit = switchPoeLimit.find(it => it.value == (!!portData?.poeProfileValue ? portData?.poeProfileValue["power-limit"] : 0))?.label;


            setSwitchDuplex(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitchDuplex ? `(${inheritSwitchDuplex})` : ""}`, inherit: inheritSwitchDuplex }, ...prevState]);
            setSwitchEnable(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitchEnable ? `(${inheritSwitchEnable})` : ""}`, inherit: inheritSwitchEnable }, ...prevState]);
            setSwitchPriority(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitchPriority ? `(${inheritSwitchPriority})` : ""}`, inherit: inheritSwitchPriority }, ...prevState]);
            setSwitchPoePower(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitchPoePower ? `(${inheritSwitchPoePower})` : ""}`, inherit: inheritSwitchPoePower }, ...prevState]);
            setSwitchPoeLimit(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitchPoeLimit ? `(${inheritSwitchPoeLimit})` : ""}`, inherit: inheritSwitchPoeLimit }, ...prevState]);
            setSwitchSpeed(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitchSpeed ? `(${inheritSwitchSpeed})` : ""}`, inherit: inheritSwitchSpeed }, ...prevState]);
            setSwitchVLAN(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitchVLAN ? `(${inheritSwitchVLAN})` : ""}`, inherit: inheritSwitchVLAN }, ...prevState]);
            setSwitchLLDPEnable(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitchLLDPEnable ? `(${inheritSwitchLLDPEnable})` : ""}`, inherit: inheritSwitchLLDPEnable }, ...prevState]);
            setSwitch8021(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitch8021 ? `(${inheritSwitch8021})` : ""}`, inherit: inheritSwitch8021 }, ...prevState]);
            setSwitch8023(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitch8023 ? `(${inheritSwitch8023})` : ""}`, inherit: inheritSwitch8023 }, ...prevState]);
            setSwitchMED(prevState => [{ value: "inherit", label: `Inherit ${!!inheritSwitchMED ? `(${inheritSwitchMED})` : ""}`, inherit: inheritSwitchMED }, ...prevState]);
        }
    }, [index])

    const compareNewData = (prev, newData, portData) => {
        let prevData = prev

        Object.keys(prevData).map(((key, index) => {
            if (prevData[key].vlan?.inherit != true) {
                prevData[key].vlan = includeProperty(prevData[key].vlan, ["mode", "taggedVlans", "untaggedVlan"])
            }
        }))

        if (newData?.Ethernet0.hasOwnProperty('LAG')) {
            Object.keys(prevData).map((key => {
                prevData[key].LAG = undefined
            }))
        }
        Object.keys(newData).map(((key, index) => {
            if (newData[key].hasOwnProperty("name")) {
                prevData[key].name = undefined
            }
        }))
        Object.keys(newData).map(((key, index) => {
            if (newData[key].hasOwnProperty("poe")) {
                if (newData[key].poe == undefined)
                    prevData[key].poe = undefined
                else
                    prevData[key].poe = getInitialPoeData(portData)
            }
        }))
        Object.keys(newData).map(((key, index) => {
            if (newData[key].hasOwnProperty("lldp")) {
                prevData[key].lldp = getInitialLldpData(portData)
            }
        }))

        // console.log("rdebug comp old:", JSON.parse(JSON.stringify(
        //     prevData,
        //     function (k, v) { return v === undefined ? "undefined" : v; }
        // )))
        // console.log("rdebug comp new:", JSON.parse(JSON.stringify(
        //     newData,
        //     function (k, v) { return v === undefined ? "undefined" : v; }
        // )))
        // console.log("rdebug comp isEqual:", lodash.isEqual(prevData, newData))
        // console.log("rdebug comp setShowWarning:", setShowWarning)

        if (!!setShowWarning)
            setShowWarning(!lodash.isEqual(prevData, newData))

    }

    const [error, setError] = useState({
        taggedVlans: "",
        untaggedVlans: "",
        powerLimit: "",
        name: ""
    })

    const form = useMemo(() => {
        return {
            duplex: duplex,
            enabled: enabled,
            speed: speed,
            vlan: {
                mode: mode,
                taggedVlans: parseVlanSyntax(taggedVlans)[0],
                untaggedVlan: untaggedVlan
            },
            poe: {
                "admin-mode": adminMode,
                "detection": detection,
                "do-reset": doReset,
                "power-limit": powerLimit,
                "priority": priority
            }
        };
    }, [duplex, enabled, speed, mode, untaggedVlan, taggedVlans, adminMode, detection, doReset, powerLimit, priority]);

    return (
        <form
            className={`${profile ? '' : 'switch-config-form d-flex flex-column justify-content-between'}`}
            onSubmit={(e) => {
                e.preventDefault()
                let formFinalised = {
                    duplex: duplex,
                    enabled: enabled,
                    speed: speed,
                    lldp: {
                        enabled: lldpEnabled,
                        "8023TLV": lldp8023,
                        "8021TLV": lldp8021,
                        MEDTLV: lldpMED
                    },
                    vlan: {
                        mode: mode
                    },
                };

                if (portData?.lagId != null) {
                    formFinalised.lagId = portData?.lagId
                }

                if (!hidePOE) {
                    formFinalised.poe = {
                        "admin-mode": adminMode,
                        detection: detection,
                        "power-limit": powerLimit,
                        priority: priority
                    }
                    if (doReset)
                        formFinalised.poe["do-reset"] = doReset;
                }

                if (mode == "inherit") {
                    formFinalised.vlan = "inherit"
                }
                else {
                    if (form.vlan.untaggedVlan) {
                        formFinalised.vlan.untaggedVlan = form.vlan.untaggedVlan
                    }
                    else {
                        formFinalised.vlan.untaggedVlan = DEFAULT_VLAN
                    }

                    if (formFinalised.vlan.mode === "Trunk") {
                        formFinalised.vlan.taggedVlans = form.vlan.taggedVlans;
                    }
                }


                if (profile) {
                    if (ieee) {
                        formFinalised.ieee8021x = {
                            "is-authenticator": true,
                            "authentication-mode": "auto",
                            "host-mode": "multi-auth"
                        }
                    }
                }
                else {
                    formFinalised.name = name ?? ""
                }

                let key = `Ethernet${index}`;
                let newFullConfig = {};
                let filteredConfig = {};
                Object.keys(fullConfig ?? {}).forEach(it => {
                    filteredConfig[it] = includeProperty(fullConfig[it], ["speed", "enabled", "poe", "duplex", "lldp", "vlan", "name", "lagId"])
                    if (filteredConfig[it]?.vlan?.inherit == true)
                        filteredConfig[it].vlan = "inherit";
                    else
                        filteredConfig[it].vlan = includeProperty(filteredConfig[it].vlan, ["mode", "taggedVlans", "untaggedVlan"])
                })
                newFullConfig.configuration = { ...filteredConfig, [key]: formFinalised }
                compareNewData(fullConfig, newFullConfig.configuration, portData)
                setPortData(newFullConfig);
                if (onSubmit)
                    onSubmit();
            }}>
            <div>
                {profile ? <h5 className="headings">Link Settings</h5>
                    : <div className="ConfigSubHeading mb-50">Link Settings</div>}
                <Row>
                    <Col className={profile ? "d-none" : ""} xs={8}>
                        <div className="cInput mb-1">
                            <Label>Name</Label>
                            <Input value={name} invalid={error.name.length > 0} placeholder="Port Name" onChange={(e) => {
                                setName(e.target.value);
                                if (e.target.value.length > 30)
                                    setError(prevState => { return { ...prevState, name: "Maximum 30 characters allowed" } })
                                else
                                    setError(prevState => { return { ...prevState, name: "" } })
                            }} />
                            <FormFeedback>{error.name}</FormFeedback>
                        </div>
                    </Col>
                    <Col className={profile ? "d-none" : ""} xs={4}></Col>
                    <Col xs={4}>
                        <div className="cInput">
                            <label>Enabled</label>
                            <Select
                                value={switchEnable.find(it => it.value == enabled)}
                                isDisabled={props.disabled || (portData?.lagId > 0 || portData?.lagIdProfileValue > 0)}
                                onChange={(e) => { setEnabled(e.value) }}
                                options={switchEnable}
                                components={{ SingleValue: SingleVal }}
                            />
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="cInput">
                            <label>Speed</label>
                            <Select
                                value={switchSpeed.find(it => it.value == speed)}
                                isDisabled={props.disabled}
                                onChange={(e) => { setSpeed(e.value == "inherit" ? e.value : Number.parseInt(e.value)) }}
                                options={switchSpeed}
                                components={{ SingleValue: SingleVal }}
                            />
                        </div>
                    </Col>
                    {!hideDuplex &&
                        <Col xs={4}>
                            <div className="cInput">
                                <label>Duplex</label>
                                <Select
                                    value={switchDuplex.find(it => it.value == duplex)}
                                    isDisabled={props.disabled || (portData?.lagId > 0 || portData?.lagIdProfileValue > 0)} onChange={(e) => { setDuplex(e.value); }}
                                    options={switchDuplex}
                                    components={{ SingleValue: SingleVal }}
                                />
                            </div>
                        </Col>}
                </Row>

                <div>
                    {profile ? <h5 className="headings mt-1">VLAN Settings</h5>
                        : <div className="ConfigSubHeading mt-1 mb-50">VLAN Settings</div>}
                    {(portData?.lagId > 0 || portData?.lagIdProfileValue > 0)
                        ? <div>
                            {
                                profile ?
                                    <>
                                        Managed by LAG: <span className="table-link" onClick={() => {
                                            let newParams = new URLSearchParams(window.location.search);
                                            newParams = Object.fromEntries(newParams);
                                            newParams.lag = portData?.lagId
                                            setParams(newParams, { replace: true })
                                        }}>{lagArray.find(it => it.lagId == portData?.lagId).lagName}</span>
                                    </>
                                    : <>
                                        Managed by Profile: <span className="table-link" onClick={() => {
                                            navigate(profileURL + `&lag=${portData?.lagId ?? portData?.lagIdProfileValue}`)
                                        }}>{profileName}</span>
                                    </>
                            }
                        </div>
                        : <Row>
                            <Col xs={4}>
                                <div className="cInput">
                                    <label>Port Mode</label>
                                    <Select
                                        value={switchVLAN.find(it => it.value == mode)}
                                        isDisabled={props.disabled}
                                        onChange={(e) => {
                                            if (e.value === "Access") {
                                                setTaggedVlans(commaSeparatedList(portData?.vlan?.taggedVlans ?? []));
                                                setError(er => ({ ...er, taggedVlans: "" }));
                                            }
                                            else if (e.value == "inherit") {
                                                setTaggedVlans(commaSeparatedList(portData?.vlanProfileValue?.taggedVlans ?? []));
                                                setUntaggedVlan(portData?.vlanProfileValue?.untaggedVlan ?? DEFAULT_VLAN)
                                                setError(er => ({ ...er, taggedVlans: "", untaggedVlans: "" }));
                                            }
                                            setMode(e.value)
                                        }
                                        }
                                        options={switchVLAN}
                                        components={{ SingleValue: SingleVal }}
                                    />
                                </div>
                            </Col>
                            {(mode === "Trunk" || (mode == "inherit" && portData?.vlanProfileValue?.mode == "Trunk")) &&
                                <Col xs={4}>
                                    <div className="cInput">
                                        <label className="d-inline-flex align-items-center">Trunk VLANs (1-4094) &nbsp;<span className="material-symbols-outlined cursor-pointer" style={{ fontSize: "15px", fontWeight: "bold" }} id="TRUNK_LABEL">info</span></label>
                                        <UncontrolledTooltip target="TRUNK_LABEL">{TRUNK_LABEL}</UncontrolledTooltip>
                                        <Input type="text" value={taggedVlans} invalid={error.taggedVlans.length > 0} disabled={props.disabled || mode == "inherit"} onChange={(e) => {
                                            const [vlans, err] = parseVlanSyntax(e.target.value);
                                            if (!!err) {
                                                setError(er => ({ ...er, taggedVlans: err }))
                                                setTaggedVlans(e.target.value)
                                            }
                                            else if (err == null || e.target.value === "") {
                                                if (!err && untaggedVlan && vlans?.find(it => it === Number(untaggedVlan))) {
                                                    setError(er => ({ ...er, taggedVlans: "Can't have native VLAN" }))
                                                }
                                                else
                                                    setError(er => ({ ...er, taggedVlans: "" }))
                                                setTaggedVlans(e.target.value);
                                            } else {
                                                if (err !== "error")
                                                    setError(er => ({ ...er, taggedVlans: err }))
                                                else {
                                                    if (!err && untaggedVlan && vlans?.find(it => it === Number(untaggedVlan))) {
                                                        setError(er => ({ ...er, taggedVlans: "Can't have native VLAN" }))
                                                    }
                                                    else
                                                        setError(er => ({ ...er, taggedVlans: "" }))
                                                }
                                            }
                                        }} />
                                        <FormFeedback>{error.taggedVlans}</FormFeedback>
                                    </div>
                                </Col>
                            }
                            <Col xs={4}>
                                <div className="cInput">
                                    <label>{(mode === "Trunk" || (mode == "inherit" && portData?.vlanProfileValue?.mode == "Trunk")) && <>Access</>} VLAN</label>
                                    <Input type="text" value={untaggedVlan} invalid={!!error.untaggedVlans} disabled={props.disabled || mode == "inherit"} onChange={(e) => {
                                        if (e.target.value === "") {
                                            setUntaggedVlan(null);
                                        }
                                        if (isInteger(e.target.value)) {
                                            if (e.target.value < 1 || e.target.value > 4094)
                                                setError(er => ({ ...er, untaggedVlans: "Enter value [1-4094]" }));
                                            else
                                                setError(er => ({ ...er, untaggedVlans: "" }));

                                            if (mode === "Trunk") {
                                                if (e.target.value < 1 || e.target.value > 4094)
                                                    setError(er => ({ ...er, untaggedVlans: "Enter value [1-4094]" }));
                                                else if (parseVlanSyntax(taggedVlans)[0].find(it => it === Number(e.target.value))) {
                                                    setError(er => ({ ...er, untaggedVlans: "Native VLAN can't be among Trunk VLANs" }));
                                                } else {
                                                    setError(er => ({ ...er, untaggedVlans: "" }))
                                                }
                                            }
                                            setUntaggedVlan(Number(e.target.value));
                                        } else {
                                            if (e.target.value.length === 0)
                                                setError(er => ({ ...er, untaggedVlans: "" }))
                                        }
                                    }} />
                                    <FormFeedback>{error.untaggedVlans}</FormFeedback>
                                </div>
                            </Col>
                        </Row>}
                </div>

                {profile ?
                    <h5 className={"headings mt-2 " + (hidePOE ? "d-none" : "")}>PoE Settings</h5>
                    : <div className={"ConfigSubHeading mt-2 mb-50 " + (hidePOE ? "d-none" : "")}>PoE Settings</div>}
                <Row className={"" + (hidePOE ? "d-none" : "")}>
                    <Col xs={3}>
                        <div>
                            <label>Power</label>
                            <Select
                                value={switchPoePower.find(it => (it.value == adminMode))}
                                options={switchPoePower}
                                components={{ SingleValue: SingleVal }}
                                isDisabled={props.disabled}
                                onChange={(e) => { setAdminMode(e.value) }} />
                        </div>
                    </Col>
                    {/* <Col xs={4}>
                    {(!profile && features?.poeSupport?.powerCycle) ?
                        <div className="d-flex align-items-center mt-2">
                            <Toggle
                                value={doReset}
                                displayText={false}
                                disabled={props.disabled}
                                onClick={(e) => {
                                    setDoReset(prevState => !prevState)
                                }} />
                            <span>Power Cycle</span>
                        </div> : null}
                </Col> */}
                    {/* </Row>
                <Row className={(hidePOE ? "d-none" : "")}> */}
                    {/* {(Array.isArray(features?.poeSupport?.supportedProtocols) && features?.poeSupport?.supportedProtocols?.length > 0) ?
                        <Col xs={4}>
                            <div className="cInput">
                                <label>Detection</label>
                                <Select
                                    defaultValue={vl(detection)}
                                    isDisabled={props.disabled}
                                    onChange={e => { setDetection(e.value) }}
                                    // options={[vl("2pt-dot3af"), vl("2pt-dot3af+legacy"), vl("4pt-dot3af"), vl("4pt-dot3af+legacy"), vl("legacy")]}
                                    options={!profile ? [{ value: "inherit", label: "Inherit" }, ...features?.poeSupport?.supportedProtocols?.map(item => vl(item))] : [...features?.poeSupport?.supportedProtocols?.map(item => vl(item))]}
                                />
                            </div>
                        </Col> : null} */}
                    <Col xs={5}>
                        <div className="cInput">
                            {console.log("---,", features)}
                            <label>Power Limit (mW)</label>
                            <Select
                                value={switchPoeLimit.find(it => it.value == powerLimit)}
                                components={{ SingleValue: SingleVal }}
                                menuPosition="fixed"
                                isDisabled={props.disabled}
                                onChange={(e) => {
                                    setPowerLimit(Number(e.value));
                                }}
                                options={switchPoeLimit.filter(it => (it.value <= features?.poeSupport?.POELimit || it.value == "inherit"))}
                            />
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="cInput">
                            <label>Priority</label>
                            <Select
                                value={switchPriority.find(it => it.value == priority)}
                                isDisabled={props.disabled}
                                onChange={e => { setPriority(e.value) }}
                                options={switchPriority}
                                components={{ SingleValue: SingleVal }}
                            />
                        </div>
                    </Col>
                </Row>
                {(profile && features?.ieee8021xSupport) ? <span className="d-inline-flex align-items-center mt-50">
                    <Toggle
                        value={ieee}
                        disabled={disable802 || props.disabled}
                        displayText={false}
                        onClick={(e) => {
                            setIeee(prevState => !prevState)
                        }} /> <span>Port 802.1X</span>
                </span> : null}
                {features?.lldpSupport ? <div>
                    {profile ?
                        <h5 className={"headings mt-2 "}>LLDP Settings</h5>
                        : <div className={"ConfigSubHeading mt-2 mb-50 "}>LLDP Settings</div>}

                    <Row>
                        <Col>
                            {!profile ?
                                <div className="cInput">
                                    <label>Enabled</label>
                                    <Select
                                        value={switchLLDPEnable.find(it => it.value == lldpEnabled)}
                                        isDisabled={props.disabled}
                                        onChange={e => { setLldpEnabled(e.value) }}
                                        options={switchLLDPEnable}
                                        components={{ SingleValue: SingleVal }}
                                        menuPosition="fixed"
                                    />
                                </div> : <div>
                                    <Toggle value={lldpEnabled} disabled={props.disabled} customText={{ [true]: "Enabled", [false]: "Disabled" }} onClick={() => { setLldpEnabled(prevState => !prevState) }} />
                                </div>}
                        </Col>
                        <Col>
                            {!profile ?
                                <div className={"cInput " + ((!lldpEnabled || (lldpEnabled == "inherit" && !portData?.lldpProfileValue?.enabled)) ? "d-none" : "")}>
                                    <label>802.3 TLV</label>
                                    <Select
                                        value={switch8023.find(it => it.value == lldp8023)}
                                        isDisabled={props.disabled || !lldpEnabled}
                                        onChange={e => { setLldp8023(e.value) }}
                                        options={switch8023}
                                        components={{ SingleValue: SingleVal }}
                                        menuPosition="fixed"
                                    />
                                </div> : <div>
                                    <Toggle value={lldp8023} disabled={props.disabled || !lldpEnabled} customText={{ [true]: "802.3 TLV", [false]: "802.3 TLV" }} onClick={() => { setLldp8023(prevState => !prevState) }} />
                                </div>}
                        </Col>
                        <Col>
                            {!profile ?
                                <div className={"cInput " + ((!lldpEnabled || (lldpEnabled == "inherit" && !portData?.lldpProfileValue?.enabled)) ? "d-none" : "")}>
                                    <label>802.1 TLV</label>
                                    <Select
                                        value={switch8021.find(it => it.value == lldp8021)}
                                        isDisabled={props.disabled || !lldpEnabled}
                                        onChange={e => { setLldp8021(e.value) }}
                                        options={switch8021}
                                        components={{ SingleValue: SingleVal }}
                                        menuPosition="fixed"
                                    />
                                </div> : <div>
                                    <Toggle value={lldp8021} disabled={props.disabled || !lldpEnabled} customText={{ [true]: "802.1 TLV", [false]: "802.1 TLV" }} onClick={() => { setLldp8021(prevState => !prevState) }} />
                                </div>}
                        </Col>
                        <Col>
                            {!profile ?
                                <div className={"cInput " + ((!lldpEnabled || (lldpEnabled == "inherit" && !portData?.lldpProfileValue?.enabled)) ? "d-none" : "")}>
                                    <label>MED TLV</label>
                                    <Select
                                        value={switchMED.find(it => it.value == lldpMED)}
                                        isDisabled={props.disabled || !lldpEnabled}
                                        onChange={e => { setLldpMED(e.value) }}
                                        options={switchMED}
                                        components={{ SingleValue: SingleVal }}
                                        menuPosition="fixed"
                                    />
                                </div> : <div>
                                    <Toggle value={lldpMED} disabled={props.disabled || !lldpEnabled} customText={{ [true]: "MED TLV", [false]: "MED TLV" }} onClick={() => { setLldpMED(prevState => !prevState) }} />
                                </div>}
                        </Col>
                    </Row>
                </div> : <></>}
            </div>
            {!props.disabled &&
                <div>
                    <Row className="mb-1 mt-3">
                        <Col xs={12} className="d-flex justify-content-end">
                            <Button color="outline-primary" type="reset" className="mr-1" onClick={onCancel}>Cancel</Button>
                            <button type="submit" className="btn btn-primary" disabled={error.powerLimit.length > 0 || error.taggedVlans.length > 0 || error.untaggedVlans.length > 0 || isPutting || (!profile && error.name.length > 0)}>{isPutting ? <Spinner size="sm" /> : "Ok"}</button>
                        </Col>
                    </Row>
                </div>}
        </form>
    );
};

const MngPorts = ({ con, mngt, x, y, dim }) => {
    return (
        <g className="cursor-pointer"
            onClick={() => { }}
        >
            <foreignObject x={x} y={y - 2} width={dim} height={2}>
                <div className="port-text">MGMT</div>
            </foreignObject>
            <rect x={x} y={y} rx={1} width={dim} height={dim} fill={"#808080"} />
            <rect x={x} y={y + dim + 1} rx={1} width={dim} height={dim} fill={"#808080"} />
            <foreignObject x={x} y={y + (2 * dim) + 1} width={dim} height={2}>
                <div className="port-text">CNSL</div>
            </foreignObject>
            <line x1={x + dim + 1} y1={4} x2={x + dim + 1} y2={17} stroke="#BABABA" strokeWidth={0.2} />
        </g>
    );
}

const SFPPorts = ({ index, x, y, dim, onSubmit, portData, infraTypeId, fullConfig, portStats, infraId, connected, maxSpeed, orgId, lldp, profileName, profileURL, mode, vlanId, selectedPorts = new Set(), onClick, entireConfig, disabled = false, setTooltip }) => {

    const actualToShow = useSelector(store => store?.oneInfra?.actualToShow)
    const [editModal, setEditModal] = useState(false);
    const [isPutting, setIsPutting] = useState(false);
    const [isError, setIsError] = useState(false);
    const [borderType, setBorderType] = useState(null)
    const [trunkVlans, setTrunkVlans] = useState("")
    const [modalTab, setModalTab] = useState('overview')
    const [warning, setWarning] = useState(false)
    const [cableTesting, setCableTesting] = useState(false)
    const [cableTestResult, setCableTestResult] = useState(null)

    useEffect(() => {
        let trunkVlansString = commaSeparatedList(portData?.vlan?.taggedVlans ?? [])
        setTrunkVlans(trunkVlansString !== "" ? trunkVlansString : "-")
    }, [portData?.vlan?.taggedVlans])

    useEffect(() => {
        const vlanConfig = portData?.vlan
        if (!!vlanConfig) {
            if (mode == 'vlan') {
                if (!!vlanId && vlanConfig?.taggedVlans?.includes(vlanId)) {
                    setBorderType(TRUNK)
                }
                else if (!!vlanId && vlanConfig?.untaggedVlan == vlanId) {
                    setBorderType(ACCESS)
                }
                else setBorderType(null)
            }
            else if (mode == '8021x') {
                if (selectedPorts.has(index))
                    setBorderType('8021x')
                else setBorderType(null)
            }
        }
    }, [portData, index, mode, selectedPorts, vlanId])

    const setSFPData = useCallback((data) => {
        setIsError(false);
        if (infraId) {
            setIsPutting(true);
            let newConfig = JSON.parse(JSON.stringify(entireConfig))
            let vlans = {}
            newConfig.configuration.portsConfiguration = data.configuration
            // Object.keys(newConfig.configuration.portsConfiguration).map(port => {
            //     delete newConfig.configuration.portsConfiguration[port].vlan
            // })
            Object.keys(newConfig.configuration.vlans ?? {}).map(vlan => {
                if (!!newConfig.configuration.vlans[vlan]?.ipv4?.subnet && !!newConfig.configuration.vlans[vlan]?.ipv4?.subnet[0]?.prefix) {
                    vlans[vlan] = {
                        "subnet": newConfig.configuration.vlans[vlan]?.ipv4?.subnet
                    }
                }
            })

            const { run } = createRequest(services.networks.SWITCHCONFIG_SET, [infraId, orgId], {
                orgId: orgId,
                configuration: {
                    portsConfiguration: newConfig.configuration.portsConfiguration,
                    vlans: vlans
                }
            });
            run()
                .then(response => {
                    setEditModal(false);
                    make_toast("success", "Port settings updated", true);
                    onSubmit();
                    // REDUX_WORKER.getInfra(infraId);
                })
                .catch((err) => {
                    const x = new CatchedWebError(err);
                    setIsError(x.message);
                })
                .finally(() => {
                    setIsPutting(false);
                })
        }
    }, [infraId]);

    return (<g className="cursor-pointer" id={`Port${index}`} >
        {/* <rect x={x} y={y} rx={1} width={dim + 2} height={dim} fill={portBgColor(portStats?.status ?? "")}
            stroke-width={!!borderType ? 0.4 : 0}
            stroke={portBorderColor(borderType)}
        /> */}
        <g transform={`translate(${x}, ${y})`}>
            <SFPPort height={dim} width={dim + 1}
                className={`${portBgColor(portStats?.status ?? "")} ${!!borderType ? "port-border" : "port-border-none"} ${portBorderColor(borderType)}`} />
        </g>
        <text x={x + ((dim + 1) / 2)} y={y + (dim - 3)} className="font-small" fill="white" dominant-baseline="middle" text-anchor="middle">{actualToShow[index]}</text>
        <foreignObject x={x} y={y} width={dim + 1} height={dim} onMouseOver={() => {
            const div = document.getElementById(`Port${index}`);
            const rect = div.getBoundingClientRect();
            clearTimeout(timeout)
            timeout = setTimeout(() => setTooltip({
                show: true, x: (rect.left + (rect.width / 2)), y: rect.bottom, data: {
                    index: index,
                    sfpPort: true,
                    connected: connected,
                    portData: portData,
                    trunkVlans: trunkVlans,
                    portStats: portStats,
                    lldp: lldp
                }
            }), 500);
        }}
            onMouseLeave={() => {
                clearTimeout(timeout)
                setTooltip(prevState => { return { ...prevState, show: false } })
            }}
            onClick={() => {
                if (!disabled) {
                    if (!onClick) {
                        if (portData)
                            setEditModal(true)
                    }
                    else {
                        // setIsTooltipActive(false)
                        onClick(index)
                    }
                }
            }}>
        </foreignObject>
        <PortModal
            editModal={editModal}
            setEditModal={setEditModal}
            modalTab={modalTab}
            setModalTab={setModalTab}
            actualToShow={actualToShow}
            index={index}
            isPutting={isPutting}
            portData={portData}
            fullConfig={fullConfig}
            warning={warning}
            setWarning={setWarning}
            portStats={portStats}
            trunkVlans={trunkVlans}
            cableTesting={cableTesting}
            cableTestResult={cableTestResult}
            lldp={lldp}
            infraId={infraId}
            setCableTesting={setCableTesting}
            setCableTestResult={setCableTestResult}
            borderType={borderType}
            sfp={true}
        >
            <Container className="PortConfigTabContainer d-flex flex-column">
                {/* <div className="ConfigHeading mb-50">Ethernet&nbsp;{actualToShow[index] ?? ''}</div> */}
                {/* {isPutting?<p className="text-center text-primary"><strong>{isPutting && "Saving..."}</strong></p>:<></>} */}
                <AlertBox color="danger" isOpen={!!isError} toggle={() => { setIsError(false); }}>
                    {isError}
                </AlertBox>
                <PortForm isPutting={isPutting} infraTypeId={infraTypeId} hidePOE hideDuplex={true} profileName={profileName} profileURL={profileURL} setPortData={setSFPData} index={index} portData={portData} fullConfig={fullConfig} infraId={infraId} connected={connected} x={x} y={y} dim={dim} onCancel={() => { if (!isPutting) setEditModal(false); }} maxSpeed={maxSpeed} minSpeed="10000" />
            </Container>
        </PortModal>
        {/* <text x={x+(dim/2)-1} y={11+(dim/2)} fill="white" class="font-small">{i+1}</text> */}
        {/* <Port index={item} key={item} connected={stats.EthernetStatus[`Ethernet${item}`]} portData={config[`Ethernet${item}`]} fullConfig={config} infraId={infraId} onSubmit={onSubmit}/> */}
        {/* <div style={{backgroundColor:"red",height:"100%", width:"100%"}}></div> */}
    </g>)
}

export const LegendItem = ({ name = '', bgColor = null, borderColor = null }) => {
    return (
        <span className="switch-label">
            <div className="switch-colour-label"
                style={{
                    backgroundColor: bgColor ?? '#fff',
                    border: (!!borderColor ? ('2px solid ' + borderColor) : '')
                }}></div>
            {name}
        </span>
    )
}

const Legend = ({ mode }) => {
    const legends = [
        <LegendItem name='Connected' bgColor='#1FC78F' />,
        <LegendItem name='Disconnected' bgColor='#808080' />,
        <LegendItem name='Disabled' bgColor='#000' />,
        <LegendItem name='Warning' bgColor='#FFBE40' />
    ]
    if (mode == 'default') {
        return legends;
    }
    else if (mode == 'vlan') {
        legends.push(
            <LegendItem borderColor="#7A0303" name="Trunk" />,
            <LegendItem borderColor="#7367F0" name="Access" />
        )
        return legends;
    }
    else if (mode == '8021x') {
        legends.push(
            <LegendItem borderColor="#E218CE" name="802.1x" />
        )
        return legends
    }
    else return null;
}

const DummySwitch = ({ mode = 'default', config, infraId, infraTypeId, onSubmit, dummy = false, lanPorts, sfpPorts, profileName, profileURL, entireConfig, vlanId = null, onClick, selectedPorts, disabled }) => {
    const stats = useSelector(store => store?.oneInfra?.stats);
    const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
    const actualToShow = useSelector(store => store?.oneInfra?.actualToShow)
    const [tooltip, setTooltip] = useState({ x: 0, y: 0 });
    let portsWidth = ((lanPorts.length / 2) * 6 * 1.05)
    let end = 160 - (160 - portsWidth) / 2
    let dim = 6

    const lldpFormatter = (connectedDevices, portString) => {
        if (!!connectedDevices && !!connectedDevices[portString] && Array.isArray(connectedDevices[portString])) {
            let lldp = []
            connectedDevices[portString].forEach(device => {
                if (!!device?.lldp) {
                    lldp.push(device?.lldp)
                }
            })
            return lldp.join("\n")
        }
        return "-"
    }



    return (
        <div className="" >
            <svg viewBox="0 0 200 20">
                {/* <MngPorts con mngt x={end - portsWidth - 2} dim={dim} y={4} /> */}
                {Array.from({ length: lanPorts.length }, (v, i) => lanPorts[i].portNumber).map((item, index) => {

                    let x = end - (((lanPorts.length / 2) - (Math.floor(index / 2) + 1)) * dim * 1.1) + (Math.floor(index / 12) * 1.75);
                    let y = (index % 2) ? 5 + dim : 4
                    // console.log(
                    //   "index--->", index,
                    //   "item--->", item,
                    //   "x--->", x,
                    //   "y---->", y,
                    //   "fixFactor--->", ((index/8) * 0.01),
                    // )  
                    return (
                        <g>
                            <Port entireConfig={entireConfig} index={item - 1} key={item}
                                connected={(!!stats?.EthernetStatus && (stats?.EthernetStatus[`Ethernet${item - 1}`]?.status == 'Connected' || stats?.EthernetStatus[`Ethernet${item - 1}`]?.status == "Warning"))}
                                portData={dummy ? null : config[`Ethernet${item - 1}`]} fullConfig={config}
                                portStats={!!stats?.EthernetStatus ? stats?.EthernetStatus[`Ethernet${item - 1}`] ?? {} : {}} infraId={infraId}
                                lldp={lldpFormatter(stats?.devices, `Ethernet${item - 1}`)}
                                onSubmit={onSubmit} x={x} y={y} dim={dim} maxSpeed={lanPorts[index].Speed} orgId={activeOrgId}
                                mode={mode}
                                profileName={profileName}
                                profileURL={profileURL}
                                vlanId={vlanId}
                                setTooltip={setTooltip}
                                infraTypeId={infraTypeId}
                                onClick={onClick}
                                selectedPorts={selectedPorts}
                                disabled={disabled}
                            />
                            {/*Lines Every 12 Ports */}
                            {/* {
                                (item + 1) % 12 == 0 ?
                                    <line x1={x + dim + 1} y1={4} x2={x + dim + 1} y2={17} stroke="#BABABA" strokeWidth={0.2} />
                                    : null
                            } */}
                        </g>
                    );
                })}
                {/* {
                    (lanPorts.length < 12) ?
                        <line x1={end + dim + 4} y1={4} x2={end + dim + 4} y2={17} stroke="#BABABA" strokeWidth={0.2} /> : null
                } */}
                {
                    Array.from({ length: sfpPorts.length ?? 0 }, (v, i) => sfpPorts[i].portNumber).map((item, index) => {
                        let x = end + ((Math.floor(index / 2) + 2) * (dim + 2) * 1.05) - 3.25;
                        let y = (index % 2) ? 5 + dim : 4;
                        // console.log(
                        //     "index--->", index,
                        //     "x--->", x,
                        //     "y---->", y,
                        // )
                        return (
                            <SFPPorts x={x} y={y} dim={dim} offset={lanPorts.length} fullConfig={config}
                                lldp={lldpFormatter(stats?.devices, `Ethernet${item - 1}`)}
                                index={item - 1} connected={(!!stats?.EthernetStatus && (stats?.EthernetStatus[`Ethernet${item - 1}`]?.status == "Connected" || stats?.EthernetStatus[`Ethernet${item - 1}`]?.status == "Warning"))}
                                portData={dummy ? null : config ? config[`Ethernet${item - 1}`] : null} infraId={infraId}
                                portStats={!!stats?.EthernetStatus ? stats?.EthernetStatus[`Ethernet${item - 1}`] ?? {} : {}}
                                onSubmit={onSubmit} maxSpeed={sfpPorts[index].Speed} orgId={activeOrgId}
                                mode={mode}
                                vlanId={vlanId}
                                onClick={onClick}
                                profileName={profileName}
                                profileURL={profileURL}
                                setTooltip={setTooltip}
                                infraTypeId={infraTypeId}
                                selectedPorts={selectedPorts}
                                entireConfig={entireConfig}
                                disabled={disabled}
                            />
                        );
                    })
                }
            </svg>

            <Tooltip isOpen={tooltip.show} place="bottom" opacity={1} variant="light" border="2px solid #EAEAEA" style={{ zIndex: 1 }} position={{ x: tooltip?.x, y: tooltip?.y }}>
                <div style={{ width: "410px" }}>
                    <h5 className="font-weight-bolder mb-2">Port {actualToShow[tooltip?.data?.index]} {tooltip?.data?.portData?.name ?? ""}</h5>
                    <Row>
                        <Col xs={12}>
                            <p className="d-flex mb-50">
                                <span className="firstRow">Status: </span>
                                <strong className="secondRow pl-50">
                                    {!tooltip?.data?.sfpPort
                                        ? <EthPort width={18} height={18} className={portBgColor(tooltip?.data?.portStats?.status)} />
                                        : <SFPPort width={20} height={18} className={portBgColor(tooltip?.data?.portStats?.status)} />}
                                </strong>
                            </p>
                            {tooltip?.data?.portStats?.warningReason?.length > 0
                                ? <span className="d-flex" style={{ marginLeft: "50px" }}>
                                    <Warning className="mr-1" width={20} height={20} />
                                    {tooltip?.data?.portStats?.warningReason ?? ""}
                                </span>
                                : <></>}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <p className="d-flex">
                                <span className="firstRow">Speed: </span>
                                <strong className="secondRow pl-50">{portSpeed.find(it => it.value == tooltip?.data?.portStats?.speed)?.label ?? "Unknown"}</strong>
                            </p>
                            <p className="d-flex">
                                <span className="firstRow">Native VLAN: </span>
                                <strong className="secondRow pl-50">
                                    {tooltip?.data?.portData?.vlan == "inherit"
                                        ? (tooltip?.data?.portData?.vlanProfileValue?.untaggedVlan ?? "-")
                                        : (tooltip?.data?.portData?.vlan?.untaggedVlan ?? "-")}
                                </strong>
                            </p>
                        </Col>
                        <Col xs={12} md={6}>
                            <p className="d-flex">
                                <span className="firstRow">Duplex: </span>
                                <strong className="secondRow pl-50 capitalize">{tooltip?.data?.portStats?.duplex ?? "Unknown"}</strong>
                            </p>
                            {tooltip?.data?.sfpPort ?
                                <></>
                                : <p className="d-flex">
                                    <span className="firstRow">PoE: </span>
                                    <strong className="secondRow pl-50 capitalize">{tooltip?.data?.portStats?.poe ? `${(tooltip?.data?.portStats?.poe / 1000).toFixed(2)}W` : "Unknown"}</strong>
                                </p>}
                        </Col>
                        <Col xs={12}>
                            <p className="d-flex">
                                <div className="firstRow">Trunk VLANS: </div>
                                <strong className="secondRow pl-50">{tooltip?.data?.trunkVlans}</strong>
                            </p>
                            <div className="firstRow">LLDP</div>
                            <strong className="secondRow" style={{ whiteSpace: "pre-wrap" }}>{tooltip?.data?.lldp ?? "-"}</strong>
                        </Col>
                    </Row>
                    {tooltip?.data?.sfpPort
                        ? <Row>
                            <Col xs={12}>
                                <div className="firstRow mt-50">SFP Info</div>
                                Vendor: {tooltip?.data?.portStats?.vendorName ?? "Unknown"}, Part No: {tooltip?.data?.portStats?.partNumber ?? "Unknown"}
                            </Col>
                            <Col xs={12}>
                                Temperature: {tooltip?.data?.portStats?.temperature != null ? <>{tooltip?.data?.portStats?.temperature}&nbsp;&#176;C</> : "Unknown"}, Rx Power: {tooltip?.data?.portStats?.rxPower != null ? `${tooltip?.data?.portStats?.rxPower}dBm` : "Unknown"}, Tx Power: {tooltip?.data?.portStats?.txPower != null ? `${tooltip?.data?.portStats?.txPower}dBm` : "Unknown"}
                            </Col>

                        </Row> : <></>}
                </div>
            </Tooltip>

            {mode == 'default'
                ? <div className="d-flex justify-content-center mt-2">
                    <Legend mode={mode} />
                </div>
                : <div className="d-flex justify-content-center mt-2">
                    <Legend mode={mode} />
                </div>
            }
        </div>
    );
};

DummySwitch.propTypes = {};

DummySwitch.defaultProps = {};

export default DummySwitch;
