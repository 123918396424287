import { Suspense, lazy, memo } from "react";
import { useSelector } from "react-redux";
import { formatChannelUtilizationData, formatDevicesCardData, formatLoadData, formatMemoryData, formatNetworkCardData, formatNetworkThreshholdCardData } from "../../Dashboard/NewDashboard/BlazerUtil";
import Skeleton from "../../Dashboard/Graphs/Skeleton";
import NetworkThresholdChart from "../../Dashboard/Graphs/NetworkThresholdChart";
const ChartError = lazy(() => import("../../Dashboard/Graphs/ChartError"));
const LoadChart = lazy(() => import("../../Dashboard/Graphs/LoadChart"));
const MemoryChart = lazy(() => import("../../Dashboard/Graphs/MemoryChart"));
const DevicesChart = lazy(() => import("../../Dashboard/Graphs/DevicesChart"));
const NetworkChart = lazy(() => import("../../Dashboard/Graphs/NetworkChart"));
const ChannelUtilizationChart = lazy(() => import("../../Dashboard/Graphs/ChannelUtilizationChart"));

// lazy(() => import("../../Dashboard/Graphs/ChartError"))

const GraphLoader = () => {
    return (
        <div className="rounded shadow-sm bg-white p-2 mb-50">
            <Skeleton
                height={"120px"}
            />
        </div>
    );
}

const OneGraph = memo((props) => {
    const infraData = useSelector(state => state.oneInfra.data)
    const chartRef = useSelector(state => {
        switch (props?.chartType) {
            case 'LOAD':
                return state?.infra_load
            case 'MEMORY':
                return state?.infra_memory
            case 'Connected Wireless Clients':
                return state?.infra_devices
            case 'TRAFFIC':
                return state?.infra_network
            case 'THRESHOLD':
                return state?.infra_network
            case 'CHANNEL_2G':
                return state?.channel_utilization_2g
            case 'CHANNEL_5G':
                return state?.channel_utilization_5g
            case 'CHANNEL_6G':
                return state?.channel_utilization_6g
            default:
                return null
        }
    });

    if (chartRef?.isError)
        return (
            <Suspense fallback={<GraphLoader />}>
                <ChartError title={props?.chartType} value={chartRef?.isError} />
            </Suspense>
        )
    else {
        let formattedData;

        switch (props?.chartType) {
            case 'LOAD':
                formattedData = formatLoadData(JSON.parse(chartRef?.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <LoadChart
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            showPercentage={true}
                            ticks={props.ticks}
                            categories={formattedData.labels}
                            colors={["#f7cfa6", "#e68427", "#fcb56f"]}
                            borderColorArray={["", "", "#f59963"]}
                            series={formattedData.series}
                            seriesMin={formattedData.seriesMin}
                            seriesMax={formattedData.seriesMax}
                            isLoading={chartRef?.isLoading || (!chartRef?.isError && !chartRef?.data)}
                        />
                    </Suspense>
                );
            case 'MEMORY':
                formattedData = formatMemoryData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <MemoryChart
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            showPercentage={true}
                            ticks={props.ticks}
                            categories={formattedData.labels}
                            colors={["#99d8fb"]}
                            series={formattedData.series}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                        />
                    </Suspense>
                );
            case 'Connected Wireless Clients':
                formattedData = formatDevicesCardData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <DevicesChart
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            infraType={infraData.infraCategory}
                            range={props.range}
                            currentlyConnected={formattedData.currentlyConnected}
                            categories={formattedData.labels}
                            colors={["#5279CE"]}
                            series={formattedData.series}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                            ticks={props.ticks}
                        />
                    </Suspense>
                );
            case 'TRAFFIC':
                formattedData = formatNetworkCardData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <NetworkChart
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            up={formattedData.up}
                            down={formattedData.down}
                            total={formattedData.total}
                            categories={formattedData.labels}
                            series1={formattedData.series1}
                            series2={formattedData.series2}
                            // series3={formattedData.series3}
                            // series4={formattedData.series4}
                            // series5={formattedData.series5}
                            // series6={formattedData.series6}
                            typeSeriesTrafficDownlink={formattedData.typeSeriesTrafficDownlink}
                            typeSeriesTrafficUplink={formattedData.typeSeriesTrafficUplink}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                            ticks={props.ticks}
                        />
                    </Suspense>
                )
            case 'THRESHOLD':
                formattedData = formatNetworkThreshholdCardData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <NetworkThresholdChart
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            up={formattedData.up}
                            down={formattedData.down}
                            total={formattedData.total}
                            categories={formattedData.labels}
                            series1={formattedData.series1}
                            series2={formattedData.series2}
                            series3={formattedData.series3}
                            series4={formattedData.series4}
                            series5={formattedData.series5}
                            series6={formattedData.series6}
                            typeSeriesTrafficDownlink={formattedData.typeSeriesTrafficDownlink}
                            typeSeriesTrafficUplink={formattedData.typeSeriesTrafficUplink}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                            ticks={props.ticks}
                        />
                    </Suspense>
                )
            case 'CHANNEL_2G':
                formattedData = formatChannelUtilizationData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <ChannelUtilizationChart
                            title={"2G Airtime"}
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            up={formattedData.up}
                            down={formattedData.down}
                            total={formattedData.total}
                            categories={formattedData.labels}
                            colors={["#B347E6", "#F5A742"]}
                            series1={formattedData.series1}
                            series2={formattedData.series2}
                            channels={formattedData.channels}
                            typeSeriesTrafficDownlink={formattedData.totalUtilizationUnit}
                            typeSeriesTrafficUplink={formattedData.apUtilizationUnit}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                            ticks={props.ticks}

                        />
                    </Suspense>
                )
            case 'CHANNEL_5G':
                formattedData = formatChannelUtilizationData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <ChannelUtilizationChart
                            title={"5G Airtime"}
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            up={formattedData.up}
                            down={formattedData.down}
                            total={formattedData.total}
                            categories={formattedData.labels}
                            colors={["#B347E6", "#F5A742"]}
                            series1={formattedData.series1}
                            series2={formattedData.series2}
                            channels={formattedData.channels}

                            typeSeriesTrafficDownlink={formattedData.totalUtilizationUnit}
                            typeSeriesTrafficUplink={formattedData.apUtilizationUnit}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                            ticks={props.ticks}
                        />
                    </Suspense>
                )
            case 'CHANNEL_6G':
                formattedData = formatChannelUtilizationData(JSON.parse(chartRef.data));
                return (
                    <Suspense fallback={<GraphLoader />}>
                        <ChannelUtilizationChart
                            title={"6G Airtime"}
                            height={props?.height ? props?.height : "200px"}
                            width={"104%"}
                            range={props.range}
                            up={formattedData.up}
                            down={formattedData.down}
                            total={formattedData.total}
                            categories={formattedData.labels}
                            colors={["#B347E6", "#F5A742"]}
                            series1={formattedData.series1}
                            series2={formattedData.series2}
                            channels={formattedData.channels}

                            typeSeriesTrafficDownlink={formattedData.totalUtilizationUnit}
                            typeSeriesTrafficUplink={formattedData.apUtilizationUnit}
                            isLoading={chartRef.isLoading || (!chartRef.isError && !chartRef.data)}
                            ticks={props.ticks}
                        />
                    </Suspense>
                )
            default:
                return (<></>);
        }
    }
})
export default OneGraph;