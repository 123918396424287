/***
 *
 * Controller class for user.
 * @file BulkActions.js
 * @description BulkActions component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./BulkActions.scss";
import { ReactComponent as DeleteWhite } from "../../assets/images/icons/DeleteWhite.svg";
import { ReactComponent as Move } from "../../assets/images/icons/Move.svg";

const BulkActions = (props) => {
  const {
    noStatusChange,
    selectionLength, 
    onStatusClick, 
    onMoveClick, 
    onDeleteClick, 
    onCancel
  } = props;

  return (
    <div className="BulkActions d-flex" data-testid="BulkActions">
      <div className="d-flex align-items-center border rounded px-50 bg-primary">
        <span className="text-white font-weight--500">{selectionLength ?? 0}&nbsp;Selected</span>
        <div className={`d-flex align-items-center h-100 ${selectionLength > 0 ? '' : 'opacity-50'}`}>
          {noStatusChange ? null :
          <>
          <div 
            className={`d-flex align-items-center mx-50 ${
              selectionLength == 0 
              ? 'cursor-not-allowed' 
              : 'cursor-pointer'
            }`}
            onClick={onStatusClick}
          >
            <span class="material-symbols-outlined"
              className={`material-symbols-outlined
                text-white
                font-weight--200
                ${
                  selectionLength == 0 
                ? 'cursor-not-allowed' 
                : 'cursor-pointer'
              }`}
              width={20} height={20}
            >
              move_down
            </span>
            <span className="text-white">Status</span>
          </div>
          <div className="bulk-action-divider"></div>
          </>}
          <div 
            className={`d-flex align-items-center mx-50 ${
              selectionLength == 0 
              ? 'cursor-not-allowed' 
              : 'cursor-pointer'
            }`}
            onClick={onMoveClick}
          >
            <Move />
            <span className="text-white">Move</span>
          </div>
          <div className="bulk-action-divider mr-50"></div>
          <DeleteWhite 
            className={`${
              selectionLength == 0 
              ? 'cursor-not-allowed' 
              : 'cursor-pointer'
            }`}
            width={20} height={20}
            onClick={onDeleteClick}
          />
        </div>
      </div>
      <div 
        className="d-flex align-items-center border rounded px-1 cursor-pointer font-weight--500 bg-primary text-white"
        onClick={onCancel}
      >
        Cancel
      </div>
    </div>
  );
};

BulkActions.propTypes = {};

BulkActions.defaultProps = {};

export default BulkActions;
