/***
 *
 * Controller class for user.
 * @file HealthTimeline.js
 * @description HealthTimeline component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./HealthTimeline.scss";
import { dateTimeFormatter } from "../../utility/Localization";
import ToolTip from "react-portal-tooltip";
import { UPTIME } from "../../pages/Infrastructure/Details";

export const TIMELINE_HEIGHT = 4
export const TIMELINE_BACKGROUND = "#D9D9D9"
export const TIMELINE_EVENT_COLOR = "#5279CE"

const fillColor = (value = 0) => {
  // if(value>7)
  //   return "#1FC78F";
  // if(value>=5)
  //   return "#ECBA3B";
  // if(value<0)
  //   return "#808080";
  // return "#CA1C0D";
  if (value < 0)
    return "#808080";
  return "#1FC78F";
}

const RenderLabels = (axisLength, SVG_HEIGHT, diff, min, labelReq) => {
  let labelCount = 0;
  const labelGap = axisLength / labelReq
  const fixingFactor = 30 * 60 * 1000;
  let labels = []
  while (labelCount <= labelReq) {
    if (diff <= (24 * 60 * 60 * 1000))
      labels = [...labels, <text className="svg-label" x={(labelCount * labelGap) + 15} y={SVG_HEIGHT / 1.4}>{dateTimeFormatter((((diff * labelCount) / labelReq) + min), "", "short", false, false, false)}</text>]
    else if (diff <= (6 * 24 * 60 * 60 * 1000))
      labels = [...labels, <text className="svg-label" x={(labelCount * labelGap) + 10} y={SVG_HEIGHT / 1.4}>{dateTimeFormatter((((diff * labelCount) / labelReq) + min), "", "", true, true, false)}</text>]
    // labels = [...labels,<text className="svg-label" x={(labelCount * labelGap)+10} y={SVG_HEIGHT/1.1}>{dateTimeFormatter((((diff*labelCount)/labelReq) + min),"","short",true)+" "+dateTimeFormatter(((Math.round((((diff*labelCount)/labelReq)+ min)/fixingFactor) * fixingFactor)),"","short")}</text>]
    else
      labels = [...labels, <text className="svg-label" x={(labelCount * labelGap) + 15} y={SVG_HEIGHT / 1.4}>{dateTimeFormatter((((diff * labelCount) / labelReq) + min), "medium", "", true)}</text>]
    labelCount++;
  }

  return labels;
}

const EventLabel = ({ event, id, x1, SVG_HEIGHT, axisStart }) => {

  let svg = document.getElementById("timeline-svg");
  const [tooltipActive, setTooltipActive] = useState(false);
  const [radius, setRadius] = useState(3);
  const [colour, setColour] = useState(TIMELINE_EVENT_COLOR)

  return (
    <g>
      <circle
        cx={x1}
        cy={(SVG_HEIGHT / 2.03)}
        r={radius}
        fill={colour}
        id={`Event${id}`}
        onMouseOver={(event) => { setTooltipActive(true); setRadius(3.5); setColour("#FF7F50"); }}
        onMouseLeave={() => { setTooltipActive(false); setRadius(3); setColour("#5279CE") }} />
      <foreignObject x={x1} y={SVG_HEIGHT / 1.9}>
        <ToolTip active={tooltipActive} position="bottom" arrow="center" parent={`#Event${id}`}>
          <div className="px-1 tooltip-font">
            <div>{event.display_name}</div>
            <div className="font-weight-bolder">{dateTimeFormatter(event?.label, "medium", "short")}</div>
            {/* <div className="mt-1 text-primary">Firmware Updated to Rel 1.3</div> */}
          </div>
        </ToolTip>
      </foreignObject>
      {/* <text x={x1-20} y={(SVG_HEIGHT/1.8)+15} className="svg-event" fill="#5E5873">{event.display_name}</text> */}
    </g>
  )
}

const getMin = (dates = []) => {
  let min = dates[0];
  dates.forEach(it => { if (it < min) min = it });
  return min
}

const getMax = (dates = []) => {
  let max = dates[0];
  dates.forEach(it => { if (it > max) max = it });
  return max
}

const converter = (labelstart, labelend) => {
  const minutes = ((new Date(labelstart).getTime() - new Date(labelend).getTime()) / (60 * 1000)).toFixed(2);
  const sec = (new Date(labelend).getTime() - new Date(labelstart).getTime()) / 1000;
  return UPTIME(sec > 0 ? sec : 0);
}

export const Timeline = ({ data, SVG_HEIGHT, axisStart, axisLength, max, min, active, setActive, bgColor, sectionColor, on }) => {

  const value = (axisLength / (max - min));
  const isEventActive = (eventDate) => {
    if (active != -1)
      return new Date(eventDate) > new Date(active.labelstart) && new Date(eventDate) < new Date(active.labelend)
    else
      return false;
  }

  return (
    <React.Fragment>
      <rect x={axisStart} y={(SVG_HEIGHT - TIMELINE_HEIGHT - 12)} width={axisLength} height={TIMELINE_HEIGHT} fill={bgColor} opacity={(active == -1) ? 1 : 0.5} />
      {data?.map((point, index) => {
        let x1 = axisStart + ((new Date(point.labelstart).getTime() - min) * value)
        let x2 = !!point.labelend ? axisStart + ((new Date(point.labelend).getTime() - min) * value) : axisLength + axisStart;
        if (x1 < axisStart && x2 > axisStart)
          x1 = axisStart
        if (x2 > axisLength + axisStart)
          x2 = axisLength + axisStart
        // {console.log("input--->",point.timestamp,"output-->",new Date(point.timestamp))}
        if (x1 >= axisStart && x1 < (axisLength + axisStart))
          return (<g key={index}>
            {/* {(index%2) && <text className="svg-label" x={axisStart+(index * labelGap) - 10} y={SVG_HEIGHT/1.5 + 15}>{point.label}</text>} */}
            {/* Timeline */}
            <rect className={setActive ? "cursor-pointer" : ""}
              id={`up${index}`}
              x={x1}
              y={(SVG_HEIGHT) + (active.labelstart == point.labelstart ? -(TIMELINE_HEIGHT + 14) : -(TIMELINE_HEIGHT + 12))}
              width={Math.max((x2 - x1), 1)}
              height={TIMELINE_HEIGHT + (active.labelstart == point.labelstart ? 4 : 0)}
              fill={sectionColor}
              opacity={(active == -1 || active.labelstart == point.labelstart) ? 1 : 0.5}
              onClick={() => {
                if (active.labelstart == point.labelstart) { setActive && setActive(-1) }
                else { setActive && setActive(point) }
              }} />
            <ToolTip active={active.labelstart == point.labelstart} position="bottom" arrow="center" parent={`#up${index}`}>
              {
                point.labelend ?
                  <div style={{ width: "350px" }}>
                    <div style={{ width: "50%", display: "inline-block" }}>
                      {on} came online at :
                    </div>
                    <div className="font-weight-bolder pl-1" style={{ width: "50%", display: "inline-block" }}>
                      {dateTimeFormatter(point.labelstart, "short", "short")}
                    </div>
                    <div className="mt-1" style={{ width: "50%", display: "inline-block" }}>
                      {on} Uptime :
                    </div>
                    <div className="font-weight-bolder pl-1 mt-1" style={{ width: "50%", display: "inline-block" }}>
                      {converter(point.labelstart, point.labelend)}
                    </div>
                  </div>
                  : "Access Point is down."
              }
            </ToolTip>
            {/* <rect x={axisStart+(index * labelGap)} y={(SVG_HEIGHT/1.5)+(active==index?-30:-25)} width={labelGap+0.1} height={25+(active==index?10:0)} fill={fillColor(point.value)} opacity={(active ==-1 || active==index)?1:0.5}
                onClick={() => {
                  if(active == index)
                    setActive(-1);
                  else
                    setActive(index)
                }}/> */}

          </g>);
      })}
    </React.Fragment>
  )
}

const HealthTimeline = ({ active, setActive, data, events, minDate, maxDate, healthHeading, onClip, revertClip, bgColor, sectionColor, on }) => {

  const SVG_HEIGHT = 80;
  const SVG_WIDTH = 600;
  const [min, setMin] = useState(null)
  const [max, setMax] = useState(null)
  const [selection, setSelection] = useState({});
  const [selecting, setSelecting] = useState(false);
  const axisLength = SVG_WIDTH - 50;
  const axisStart = 24;
  const heightAdjust = 1.6;
  const value = (axisLength / (max - min));
  const labels = RenderLabels(axisLength, SVG_HEIGHT, (max - min), min, 6)
  
  function MousePosSVG(e, svg) {
    var p = svg.createSVGPoint();
    p.x = e.clientX;
    p.y = e.clientY;
    var ctm = svg.getScreenCTM().inverse();
    var p = p.matrixTransform(ctm);
    return p;
  }

  const OnMouseDown = (event) => {
    const svg = document.getElementById("timeline-svg")
    let point = MousePosSVG(event, svg);
    let singleFactor = (max-min)/(axisLength)
    setSelecting(true);
    setSelection(prevState =>{
      return{
        from: {
          x: point.x,
          date: new Date(((point.x-24)*singleFactor) + min).getTime(),
        },
        to: {
          x: point.x,
          date: new Date(((point.x-24)*singleFactor) + min).getTime(),
        }
      }
    })

  }

  const OnMouseMove = (event) => {
    const svg = document.getElementById("timeline-svg")
    let point = MousePosSVG(event, svg);
    let singleFactor = (max-min)/(axisLength)
    if(selecting && point.x>selection.from.x){
      setSelection(prevState =>{
        return{
          ...prevState,
          to: {
            x: point.x,
            date: new Date(((point.x-24)*singleFactor) + min).getTime(),
          }
        }
      })
    }
  }

  const OnMouseUp = (event) => {
    const svg = document.getElementById("timeline-svg")
    let point = MousePosSVG(event, svg);
    let singleFactor = (max-min)/(axisLength)
    if(selecting  && point.x>selection.from.x){
      setSelecting(false)
      if(Math.abs(selection?.from?.x - point.x) >= 10){
        setSelection(prevState =>{
          return{
            ...prevState,
            to: {
              x: point.x,
              date: new Date(((point.x-24)*singleFactor) + min).getTime(),
            }
          }
        })
        UpdateMaxMin();
        if(!!onClip)
          onClip(selection.from.date, selection.to.date);
      }
      else 
        setSelecting({})
    }
  }

  const UpdateMaxMin = () => {
    setMax(selection.to.date);
    setMin(selection.from.date);
  }

  useEffect(() => {
    setMin(!!minDate ? new Date(minDate).getTime() : getMin(data?.map(it => new Date(it.labelend).getTime())))
    setMax(!!maxDate ? new Date(maxDate).getTime() : getMax(data?.map(it => new Date(it.labelstart).getTime())))
  }, [])

  return (
    <div className="HealthTimeline" data-testid="HealthTimeline">
      {(!!min && !!max) &&
        <div>
          <svg viewBox={"0 0 " + SVG_WIDTH + " " + SVG_HEIGHT} id="timeline-svg" onMouseDown={OnMouseDown} onMouseMove={OnMouseMove} onMouseUp={OnMouseUp} onClick={()=>setSelecting(false)}>
            {/* <line x1={axisStart} y1={SVG_HEIGHT/2} x2={axisLength} y2={SVG_HEIGHT/2}  stroke="grey"/> */}
            {/* Labels  */}
            {labels?.map((label) => {
              return label;
            })}
            <text className="svg-headings" x={axisStart - 10} y={(SVG_HEIGHT / heightAdjust) - 35}>{healthHeading}</text>
            <text className="svg-label" x={axisLength - 105} y={(SVG_HEIGHT / heightAdjust) - 35}>Events</text>
            {(selection.from != null) && <foreignObject x={axisLength-140} y={(SVG_HEIGHT / heightAdjust) - 46} width={50} height={20} onClick={() => {
              setSelection({});
              setMin(!!minDate ? new Date(minDate).getTime() : getMin(data?.map(it => new Date(it.labelend).getTime())));
              setMax(!!maxDate ? new Date(maxDate).getTime() : getMax(data?.map(it => new Date(it.labelstart).getTime())));
              if(!!revertClip)
                revertClip();
            }}>
              <span className="material-symbols-outlined cursor-pointer" style={{fontSize:"10px"}}>arrow_back</span>
            </foreignObject>}
            <circle
              cx={axisLength - 80}
              cy={(SVG_HEIGHT / heightAdjust) - 37}
              r={3}
              fill={TIMELINE_EVENT_COLOR}
              />
            <text className="svg-label" x={axisLength - 20} y={(SVG_HEIGHT / heightAdjust) - 35}>Online</text>
            <rect x={axisLength + 3} y={(SVG_HEIGHT / heightAdjust) - 40} width={20} height={5} fill="#1FC78F" />
            <text className="svg-label" x={axisLength - 70} y={(SVG_HEIGHT / heightAdjust) - 35}>Offline</text>
            <rect x={axisLength - 48} y={(SVG_HEIGHT / heightAdjust) - 40} width={20} height={5} fill={TIMELINE_BACKGROUND} />
            <Timeline data={data} bgColor={bgColor} sectionColor={sectionColor} SVG_HEIGHT={SVG_HEIGHT / 1.5} axisStart={axisStart} axisLength={axisLength} max={max} min={min} active={active} setActive={setActive} on={on} />

            {/* Event */}
            {/* <text className="svg-headings" x={axisStart} y={(SVG_HEIGHT/1.5)-35}>{eventHeading}</text> */}
            {/* <line x1={axisStart} y1={(SVG_HEIGHT/4)} x2={axisStart+axisLength} y2={(SVG_HEIGHT/4)} stroke="#5E5873" /> */}
            {
              events?.map((event, index) => {
                let x1 = axisStart + ((new Date(event.label).getTime() - min) * value)
                if(x1>axisStart && x1<(axisLength + axisStart))
                  return <EventLabel event={event} id={index} x1={x1} axisStart={axisStart} SVG_HEIGHT={SVG_HEIGHT} />
                {/* <line x1={x1} y1={(SVG_HEIGHT/1.5)+(isEventActive(event.label) ? 5:0)} 
                  x2={x1} y2={(SVG_HEIGHT/1.5)-50} stroke="grey"/> */}
                  })
            }
            {selecting &&
              <rect x={selection.from.x} y={(SVG_HEIGHT/heightAdjust) - 30} width={selection.to.x - selection.from.x} height={40} fill="#5279CE" opacity={0.4}/>
            }

          </svg>
        </div>}
    </div>
  );
};

HealthTimeline.propTypes = {};

HealthTimeline.defaultProps = {};

export default HealthTimeline;
