/***
 *
 * Controller class for user.
 * @file NotiAcknowledgeModal.js
 * @description NotiAcknowledgeModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./NotiAcknowledgeModal.scss";
import { Button, Modal, ModalBody } from "reactstrap";

const NotiAcknowledgeModal = (props) => {
  const {
    isOpen,
    onToggle,
    loading,
    selectionCount,
    onAckClick
  } = props;

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={onToggle}
      className="NotiAcknowledgeModal" 
      data-testid="NotiAcknowledgeModal"
    >
      <ModalBody>
        <div className="heading mb-1">
          Do you want to acknowledge the {`${selectionCount===1?'alarm':'alarms'}`}?
        </div>
        This will move the {`${selectionCount===1?'alarm':'alarms'}`} from the actionable tab to the historical tab. Are you sure?
        <div className="d-flex justify-content-end mt-1">
          <Button.Ripple color="primary" outline onClick={onToggle} disabled={loading}>Cancel</Button.Ripple>
          <Button.Ripple color="primary" className="ml-1" onClick={onAckClick} disabled={loading}>Acknowledge</Button.Ripple>
        </div>
      </ModalBody>
    </Modal>
  );
};

NotiAcknowledgeModal.propTypes = {};

NotiAcknowledgeModal.defaultProps = {};

export default NotiAcknowledgeModal;
