import { useState } from "react";
import { LAYOUT_TYPES } from "../constants";

function useZoomProvider(currentOffset = false, currentZoomValue = false, setCurrentZoomValue = null, layouts) {

  let initialZoomValue = currentZoomValue ? currentZoomValue : 1 //check if currentZoom Value is present then use that or use DEFAULT_ZOOM_VALUE i.e 0.25
  // if (layouts && (layouts.length > 0)) {
  //   if (layouts[0]?.layoutType == LAYOUT_TYPES.IMAGE) { //hinthere for both support in 2.3
  //     initialZoomValue = 0.8
  //   }
  // }
  const [zoom, setZoom] = useState(initialZoomValue);

  const initialZoomPosition = currentOffset ? currentOffset : { x: 0, y: 0 }

  const [zoomPosition, setZoomPosition] = useState(initialZoomPosition);

  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Can't zoom below 20%
    if ((newScale > 0.20) && (newScale <= 2.029)) {
      setZoom(newScale);
      if (setCurrentZoomValue) {
        setCurrentZoomValue(newScale)
      }
      setZoomPosition({
        x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
      });
    }



  };

  return { zoom, setZoom, zoomPosition, setZoomPosition, handleWheel };
}

export default useZoomProvider;