
export const ORDERS_TABLE_HEADINGS = [
    {
        name: 'ORDER ID',
    },
    {
        name: 'ORDER STATUS',
    },
    {
        name: 'ORDER PRICE',
    },

    {
        name: 'CUSTOMER'
    },
    {
        name: 'PROJECT NAME'
    },

    {
        name: 'PAYMENT STATUS',
    },
    {
        name: 'ORDER DATE',
    },
    {
        name: 'SHIPPING DATE',
    }
]
export const SUBSCRIPTIONS_TABLE_HEADINGS = [
    {
        name: 'SUBSCRIPTION NAME',
    },
    {
        name: 'SUBSCRIPTION STATUS',
    },

    {
        name: 'CUSTOMER INFO'
    },
    {
        name: 'NEXT BILLING'
    },
    {
        name: 'AMOUNT',
    },
    {
        name: 'CREATED AT',
    },
    {
        name: 'ORGANIZATION NAME',
    },

]

export const HARDWARE_FAMILY_ID = 6;

export const HARDWARE_FAMILY_NAME = "Accessories";



export const FAKE_IDENTITY = "be8f4160-877b-11ed-8fa7-d96fc561c220";


export const COUNTRY_LIST = [
    { value: 'United States', label: 'United States' },
    { value: 'Canada', label: 'Canada' },
]

export const DELIVERY_TYPE = [
    { value: 'Venue', label: 'Venue' },
    { value: 'Custom', label: 'Other Property' },
]


export const DELIVERY_TYPE_MAPPING = {
    Venue: 0,
    Custom: 1
}

export const MONTH_LIST = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec'
}
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const WIFI_GENERATION_LIST = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: '6',
        value: '6'
    },
    {
        label: '6E',
        value: '6e'
    }
]
export const PRODUCT_COUNTRY_LIST = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'US',
        value: 'US'
    },
    {
        label: 'Canada',
        value: 'Canada'
    }
]
export const PRODUCT_TYPE_LIST = [
    {
        label: 'All',
        value: 'all',
        type: 'all'
    },
    {
        label: 'WiFi (All)',
        value: '',
        type: 'ap'
    },
    {
        label: 'WiFi Wall Plate',
        value: 'Wall Plate',
        type: 'ap'
    },
    {
        label: 'WiFi Ceiling',
        value: 'Ceiling',
        type: 'ap'
    },
    // {
    //     label: 'High Density',
    //     value: 'High Density',
    //     type: 'ap'
    // },
    {
        label: 'WiFi Outdoor',
        value: 'Outdoor',
        type: 'ap'
    },
    {
        label: 'Switching (All)',
        value: '',
        type: 'switch'
    },
    {
        label: 'Switching 48 Port',
        value: '48 Port Switch',
        type: 'switch'
    },
    {
        label: 'Switching 24 Port',
        value: '24 Port Switch',
        type: 'switch'
    },
    {
        label: 'Switching 8 Port',
        value: '8 Port Switch',
        type: 'switch'
    }

]
export const PLAN_LIST = [
    {
        label: 'Monthly, HW Refresh Included',
        value: 1
    },
    {
        label: '3 Year, One-time Payment',
        value: 2
    },
    {
        label: '5 Year, One-time Payment',
        value: 3
    },
    {
        label: 'Spare Hardware',
        value: 4
    },

]
export const PRODUCT_VENDOR_LIST = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Actiontec',
        value: 'actiontec'
    },
    {
        label: 'SonicFi',
        value: 'sonicfi'
    },
    {
        label: 'Edgecore',
        value: 'edgecore'
    }
]

export const PRODUCT_SORT_BY_LIST = [
    {
        label: 'All',
        value: 'all'
    }
]

export const QUOTE_STATUS_MAPPING = {
    open: "IN PROGRESS",
    accepted: "ACCEPTED",
    declined: "CLOSED",
    closed: "EXPIRED",
    invoiced: "ORDERED"
}
export const QUOTE_STATUS_TEXT_MAPPING = {
    'open': "In Progress",
    'accepted': "Accepted",
    'declined': "Closed",
    'closed': "Expired",
    invoiced: "Ordered"
}

export const QUOTE_COMMENT_LIMIT = 25
export const QUOTE_LISTING_LIMIT = 25
export const QUOTE_STATUS = {
    OPEN: "open",
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    ORDERED: 'invoiced',
    EXPIRED: "closed"
}
// export const QUOTE_STATUS_LIST = [QUOTE_STATUS.ACCEPTED, QUOTE_STATUS.DECLINED, QUOTE_STATUS.CLOSED]

export const ORDERS_API_LIMIT = 2500
export const SUBSCRIPTION_API_LIMIT = 2500

export const QUOTE_CHART_COLORS = {
    OPEN: '#ffc930',
    ACCEPTED: '#00b003',
    DECLINED: '#ff5959',
    CLOSED: '#c4c4c4',
    EXPIRING: '#c90000'
}





//  ------------------------------------------------- SUBSCRIPTION -------------------------------------------------

export const SUBSCRIPTION_STATUS_LIST = [

    "future",
    "in_trial",
    "active",
    "non_renewing",
    "paused",
    "cancelled"
]

export const SUBSCRIPTION_STATUS = {
    FUTURE: "future",
    IN_TRIAL: "in_trial",
    ACTIVE: "active",
    NON_RENEWING: "non_renewing",
    PAUSED: "paused",
    CANCELLED: "cancelled"
}
export const SUBSCRIPTION_STATUS_MAPPING = {
    "future": "FUTURE",
    "in_trial": "IN TRIAL",
    "active": " ACTIVE",
    "non_renewing": "ONE TIME",
    "paused": "PAUSED",
    "cancelled": "CANCELLED"
}

export const SUBSCRIPTION_STATUS_TEXT_MAPPING = {
    "future": "Future",
    "in_trial": "IN TRIAL",
    "active": " Active",
    "non_renewing": "One-time",
    "paused": "Pending",
    "cancelled": "Cancellec"
}








//  ------------------------------------------------- ORDERS -------------------------------------------------

export const ORDER_STATUS_LIST = [
    'new',
    'processing',
    'complete',
    'cancelled',
    'voided',
    'queued',
    'awaiting_shipment',
    'on_hold',
    'delivered',
    'shipped',
    'partially_delivered',
    'returned'
]


export const ORDER_STATUS = {
    NEW: 'new',
    PROCESSING: 'processing',
    COMPLETE: 'complete',
    CONCELLED: 'cancelled',
    VOIDED: 'voided',
    QUEUED: 'queued',
    AWAITING_SHIPMENT: 'awaiting_shipment',
    ON_HOLD: 'on_hold',
    DELIVERED: 'delivered',
    SHIPPED: 'shipped',
    PARTIALLY_DELIVERED: 'partially_delivered',
    RETURNED: 'returned'
}

export const ORDER_STATUS_MAPPING = {
    'new': "NEW",
    'processing': "PROCESSING",
    'complete': "COMPLETE",
    'cancelled': "CONCELLED",
    'voided': "VOIDED",
    'queued': "QUEUED",
    'awaiting_shipment': "AWAITING SHIPMENT",
    'on_hold': "ON HOLD",
    'delivered': "DELIVERED",
    'shipped': "SHIPPED",
    'partially_delivered': "PARTIALLY DELIVERED",
    'returned': "RETURNED"
}

export const ORDER_PAYMENT_STATUS = {
    PAID: 'paid',
    NOT_PAID: 'not_paid'
}

export const ORDER_PAYMENT_STATUS_MAPPING = {
    paid: 'PAID',
    not_paid: 'DUE'
}



export const PRODUCT_LIST_DISPLAY = 1
export const PRODUCT_GRID_DISPLAY = 2

export const PLAN_ID = {
    NAAS_MONTHLY: 1,
    SAAS_3_YEAR: 2,
    SAAS_5_YEAR: 3,
    SPARE_HARDWARE: 4
}


export const SAAS_PLAN_BREAKDOWN_TEXT = 'Cloud services, Software Updates, Support'
export const NAAS_PLAN_BREAKDOWN_TEXT = 'Cloud services, Software Updates, Support, Hardware Refresh'

export const PLAN_NAME = {
    NAAS: "NaaS Monthly",
    THREE_YEAR: "SaaS 3-year",
    FIVE_YEAR: "SaaS 5-year"
}
export const PLAN_NAME_LIST = [
    "NaaS Monthly",
    "SaaS 3-year",
    "SaaS 5-year",
    "Spare Hardware"
]

export const QUOTE_NOTES = [
    'Delivery date to be provided once proposal is approved.',
    // 'All prices are in USD, taxes not included.',
    'Prices are budgetary, final prices will be provided on proposal.',
    // 'Evergreen Hardware refresh included in Monthly.',
    // 'Cancellation charges may apply if service is discontinued in less than 36 months.'

]

export const PRODUCTS = {
    EAP_101: "EAP-101",
    EAP_102: "EAP-102",
    EAP_104: "EAP-104",
    EAP_111: "EAP-111",
    RAP630C_311G: "RAP630C-311G",
    RAP630W_311G: "RAP630W-311G",
    WF_186H: "WF-186H",
    WF_186W: "WF-186W",
    WF_188: "WF-188N",
    WF_196: "WF-196",
    WF_660A: "WF-660A",
    ECS_4125: "ECS-4125",
    ECS_4150_28P: "ECS-4150-28P",
    ECS_4150_54P: "ECS-4150-54P",
    EPS_202: "EPS-202",
    ECS_2100_28P: "ECS-2100-28P",
    ECS_2100_10P: "ECS-2100-10P",
    OAP_101: "OAP-101"
}

export const PLAN_TEXT_MAPPING = [
    '',
    'Monthly',
    '3 Years',
    '5 Years'
]

export const DISABLE_PRODUCT_ERROR = "0x772"