/***
 *
 * Controller class for user.
 * @file SupportTicketList.js
 * @description SupportTicketList component
 * @author Rajinder Singh
 * @since 20 Feb 2024
 *
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
// import PropTypes from 'prop-types';
import "./SupportTicketList.scss";
import { Button, Input, InputGroup, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import GroupButton from "../GroupButton";
import SearchIconAddon from "../SearchIconAddon";
import InfiniteScroll from "react-infinite-scroll-component";
import createRequest, { services } from "../../services";
import { useSelector } from "react-redux";
import { TICKET_API_LIMIT, TICKET_BADGE, TICKET_CATEGORY, TICKET_PRIORITY_MAPPING, TICKET_STATUS_MAPPING, TICKET_TABLE_HEADINGS } from "../../pages/Tickets/TicketConstants";
import { dateTimeFormatter } from "../../utility/Localization";
import { ReactComponent as Ticket } from "../../assets/images/icons/ticket-icon-col.svg";
import { dateGapFinder } from "../../utility/Utils";
import LightBadge from "../LightBadge";
import { ticketAction } from "../../redux/slices";
import store from "../../redux/store";
import ColumnHeader from "../ColumnHeader";
import { CatchedWebError } from "../../configs";
import { make_custom_toast } from "../../helpers/toasts";
import SupportModal from "../SupportModal";
import FilterSetter from "../FilterSetter";
import { PriorityFilter, SeverityFilter, StatusFilter, TicketCategoryFilter, TicketCategoryFilterNonDeployment } from "../../pages/Filters/filters";
import FilterButton from "../FilterButton";
import lodash from "lodash-es"
import { DEPLOYMENT_VIEW, SCROLL_MORE_TEXT, VIEW_MAPPING } from "../../utility/constants";
import LinkWrapper from "../LinkWrapper";

const initColumns = {
  Status: true,
  Severity: true,
  Title: true,
  Organization: true,
  Venue: true,
  Updated: true,
  Category: true,
  Reportedby: true,
  Comment: false,
  "Sub-Category": false,
  "Ticket reference": false
}

const SupportTableHeadings = [
  {
    name: 'TICKET',
    attribute: '',
    width: '10%'
  },
  {
    name: 'SUBJECT',
    attribute: 'subject',
    width: '16%'

  },
  {
    name: 'STATUS',
    attribute: 'status',
    width: '10%'

  },

  {
    name: 'SEVERITY',
    attribute: 'severity',
    width: '8%'
  },

  {
    name: 'CATEGORY',
    attribute: 'category',
    width: '8%'

  },
  {
    name: 'VENUE',
    attribute: '',
    width: '10%'

  },
  {
    name: 'REPORTED BY',
    attribute: 'requesterName',
    width: '10%'

  },

  // {
  //     name: 'CREATED AT',
  //     attribute: 'createdAt',
  //     width: '14%'

  // },
  {
    name: 'LAST UPDATE',
    attribute: 'updatedAt',
    width: '14%'
  },
]

const filterInitial = {
  priorities: [],
  statuses: [],
  categories: []
}

const SupportTicketList = () => {

  const activeOrgId = useSelector(store => store.activeOrg.data.orgId)
  const activeVenue = useSelector(store => store.activeVenue.data);
  const view = useSelector(store => store.identity.meta.view);
  const orgId = useSelector(state => state.identity.data.orgId);
  const [ticketSearch, setTicketSearch] = useState('')
  const [ticketLoading, setTicketLoading] = useState(false)
  const [ticketList, setTicketList] = useState([])
  let newParams = new URLSearchParams(window.location.search);
  newParams = Object.fromEntries(newParams);
  const [ticketFilter, setTicketFilter] = useState(JSON.parse(newParams?.filter ?? "{}")?.ticketFilter ?? "Active");

  const [hasMore, setHasMore] = useState(true)
  const [filterData, setFilterData] = useState(filterInitial)
  const [filterActive, setFilterActive] = useState(false);
  const [ticketFilterActive, setTicketFilterActive] = useState('')
  const scrollRef = useRef(null)

  const [ticketCount, setTicketCount] = useState({
    "active": 0,
    "awaitingfeedback": 0,
    "critical": 0,
    "all": 0
  })
  const [sort, setSort] = useState({
    order: "DESC",
    orderBy: ""
  })
  const [showFilter, setShowFilter] = useState(false);
  const [support, setSupport] = useState(false)
  const [mainFilter, setMainFilter] = useState('All')




  const createTicket = () => {
    setSupport(true)
  }

  // const getTicketCount = () => {
  //   const { run, controller } = createRequest(services.ticket.GET_ALL_TICKET_COUNT, [activeOrgId, ticketSearch, activeVenue.venueId, view])
  //   run()
  //     .then(res => {
  //       setTicketCount(res.data)
  //     })
  //     .catch(err => {

  //     })
  // }


  const getTicketList = useCallback((loading = false, ticketFilter, filter = filterData) => {
    setTicketLoading(loading)
    setHasMore(true)

    let filterDataForAPI = filter

    if (filterDataForAPI?.priorities?.includes("3") && ticketFilter == 'Critical') {
      const index = filterDataForAPI?.priorities.indexOf("3");
      if (index > -1) { // only splice array when item is found
        filterDataForAPI.priorities.splice(index, 1); // 2nd parameter means remove one item only
      }
    }

    const columns = Object.keys(initColumns ?? {})
      .map((col) => (initColumns[col] ? col : null))
      .filter((it) => it);

    const { run } = createRequest(services.ticket.GET_OPEN_TICKETS, [activeOrgId, 0, TICKET_API_LIMIT, ticketFilter, sort, filterDataForAPI, ticketSearch, columns, activeVenue.venueId, view])
    run().then(res => {
      setShowFilter(false)
      setTicketLoading(false)
      setTicketCount(res?.data?.counts)
      setTicketList([...res.data.tickets])
      if (res.data?.tickets.length < TICKET_API_LIMIT) {
        setHasMore(false)
      }

    })
      .catch(err => {
        setTicketLoading(false)
        make_custom_toast('error', 'Support', (new CatchedWebError(err)).message)
      })

  }, [activeOrgId, filterData, sort, ticketSearch, view, ticketFilter])

  useEffect(() => {
    let newParams = new URLSearchParams(window.location.search);
    newParams = Object.fromEntries(newParams);
    if (JSON.parse(newParams?.filter ?? "{}")?.ticketFilter == "Awaiting feedback")
      scrollRef.current.scrollIntoView()
  }, [])

  useEffect(() => {
    const delay = setTimeout(() => {
      getTicketList(false, ticketFilter)
    }, 1500)

    return () => clearTimeout(delay)
  }, [ticketSearch])



  useEffect(() => {


    let tempFilterData = { ...filterInitial } // this is passed to get ticket list
    setFilterActive(false)
    setFilterData(tempFilterData)

    // if (ticketFilter == "Closed") {  //ticket can't have two status together for closed and open
    //   tempFilterData = { ...filterData, statuses: [] }

    //   setFilterData(tempFilterData)
    // }
    // if (ticketFilter == "Critical") {  //ticket can't have two severity (i.e priority in code) together for critical and Major
    //   tempFilterData = { ...filterData, priorities: [] }
    //   setFilterData(tempFilterData)
    // }

    getTicketList(false, ticketFilter, tempFilterData)
  }, [ticketFilter, sort, view])

  return (
    <div className="bg-white rounded p-1 mb-2 SupportTicketList">
      {
        showFilter &&
        <FilterSetter
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          filter={filterData}
          setFilter={setFilterData}
          disabled={!Object.keys(filterData).length}
          handleApplyClick={() => {
            if (lodash.isEqual(filterData, filterInitial)) {
              setFilterActive(false);
            }
            let tempTicketFilter = ticketFilter
            if (ticketFilter == "Closed" && filterData.statuses.length > 0) {
              setTicketFilter("Open")
              tempTicketFilter = "Open"
            }
            setFilterActive(true);
            setShowFilter(false)
            getTicketList(true, tempTicketFilter)
          }}
          handleClearAll={
            () => {

              setFilterData(filterInitial)
              getTicketList(false, ticketFilter, {})
              setFilterActive(false);
              setShowFilter(false)
            }
          }
          elements={() => {
            let filterOptions = []
            if (view == DEPLOYMENT_VIEW) {
              if (ticketFilter == "Critical") {
                filterOptions = [
                  <StatusFilter filter={filterData} setFilter={setFilterData} removeClosedStatus={true} />,
                ]
              }
              else if (ticketFilter == "Closed") {
                filterOptions = [
                  <PriorityFilter filter={filterData} setFilter={setFilterData} />,
                ]
              }
              else {
                filterOptions = [
                  <PriorityFilter filter={filterData} setFilter={setFilterData} />,
                  <StatusFilter filter={filterData} setFilter={setFilterData} removeClosedStatus={true} />,
                ]
              }
            }
            else {
              if (ticketFilter == "Critical") {

                filterOptions = [
                  <StatusFilter filter={filterData} setFilter={setFilterData} removeClosedStatus={true} />,
                  <TicketCategoryFilterNonDeployment filter={filterData} setFilter={setFilterData} />
                ]
              }
              else if (ticketFilter == "Closed") {

                filterOptions = [
                  <PriorityFilter filter={filterData} setFilter={setFilterData} />,
                  <TicketCategoryFilterNonDeployment filter={filterData} setFilter={setFilterData} />
                ]
              }
              else {

                filterOptions = [
                  <PriorityFilter filter={filterData} setFilter={setFilterData} />,
                  <StatusFilter filter={filterData} setFilter={setFilterData} removeClosedStatus={true} />,
                  <TicketCategoryFilterNonDeployment filter={filterData} setFilter={setFilterData} />
                ]
              }
            }
            return filterOptions
          }
          }
        />
      }
      <div className="d-flex align-items-center" >
        <Ticket width={24} height={24} />
        <span className="fw-600 ml-1">Support Cases</span>
      </div>
      <div className="d-flex justify-content-between py-50">
        <div className="d-flex">
          <span className="mr-2" style={{ width: '350px' }}>
            <InputGroup className="input-group-merge ">
              <Input
                style={{ height: '2.4rem' }}
                type="text"
                value={ticketSearch}
                placeholder="Search"
                onChange={(e) => {
                  setTicketSearch(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                  }
                }}

              />
              <SearchIconAddon />
            </InputGroup>
          </span>
          <GroupButton className="mr-1">
            <div className={"grp-btn-custom " + (ticketFilter == "Active" && !ticketFilterActive ? "active" : "")} onClick={() => { if (!ticketLoading) setTicketFilter("Active") }} >Active ({ticketCount?.active})</div>
            <div className={"grp-btn-custom " + (ticketFilter == "Awaiting feedback" && !ticketFilterActive ? "active" : "")} onClick={() => { if (!ticketLoading) setTicketFilter("Awaiting feedback") }} >Awaiting feedback ({ticketCount?.awaitingfeedback})</div>
            <div className={"grp-btn-custom " + (ticketFilter == "Critical" && !ticketFilterActive ? "active" : "")} onClick={() => { if (!ticketLoading) setTicketFilter("Critical") }} >Critical ({ticketCount?.critical})</div>
            <div className={"grp-btn-custom " + (ticketFilter == "All" && !ticketFilterActive ? "active" : "")} onClick={() => { if (!ticketLoading) setTicketFilter("All") }} >All ({ticketCount?.all})</div>
          </GroupButton>
          <FilterButton
            size={22}
            active={filterActive}
            onClick={() => setShowFilter(true)}
            style={{ padding: '6.5px' }} />

        </div>

        <Button
          className="small-add-button"
          color="primary"
          onClick={createTicket}
        >
          Create Ticket
        </Button>
      </div>

      {/* --- Table --- */}
      {
        ticketLoading ? <div className="text-center p-3 text-primary">
          <Spinner />
        </div> :

          <InfiniteScroll
            className="mt-2"
            dataLength={ticketList.length}
            next={() => {
              const columns = Object.keys(initColumns ?? {})
                .map((col) => (initColumns[col] ? col : null))
                .filter((it) => it);
              const { run } = createRequest(services.ticket.GET_OPEN_TICKETS, [activeOrgId, ticketList.length, TICKET_API_LIMIT, ticketFilter, sort, filterData, ticketSearch, columns, activeVenue.venueId, view]);

              run().then((res) => {
                if (res.data?.tickets.length < TICKET_API_LIMIT)
                  setHasMore(false);
                setTicketList([...ticketList, ...res.data.tickets]);
              })
                .catch(error => {
                  setHasMore(false);
                })
            }
            }
            hasMore={hasMore}
            scrollableTarget="venue-ticket-list"
            loader={<div>{SCROLL_MORE_TEXT}</div>}
            endMessage={<div className="">Showing {ticketList.length} result(s)</div>}
          >
            <div id="venue-ticket-list" >
              <Table className="table-view fixed-header">
                <thead>
                  <tr>
                    {
                      SupportTableHeadings.map((heading, index) =>
                        <th className={`bg-color ${index === 0 ? '' : 'pl-0'}`}
                          style={{
                            width: heading.width, borderTopLeftRadius: index == 0 ? 12 : 0,
                            borderTopRightRadius: index == 7 ? 12 : 0,
                            paddingRight: heading.attribute == 'status' ? '2.5%' : ''

                          }}>
                          <ColumnHeader header={heading.name} attribute={heading.attribute} setter={setSort} sort={sort} centerText={heading.attribute == 'status'} />
                        </th>)
                    }

                  </tr>
                </thead>
                <tbody>
                  {ticketList.length > 0 ?
                    ticketList.map((item, i) => (
                      <tr className="hoverable " key={i}>

                        <td
                          className={`pl-0`}
                        >
                          <LinkWrapper
                            to={`/organization/${activeOrgId || orgId}/support/${item.freshworksTicketId}`}
                          >
                            <div className="d-flex align-items-center pl-1">
                              #{item.freshworksTicketId}
                              {
                                !item.read && <div className="notification-red-dot" />
                              }
                            </div>
                          </LinkWrapper>
                        </td>
                        <td className={`ticket-subject pl-0`}>{item.subject}</td>
                        <td className={`pl-0`}>
                          {/* <div className="d-flex"> */}
                          {/* <LightBadge
                              style={{ width: '75%' }}

                              color={
                                item.status === 2 ? "warning" :
                                  item.status === 3 ? "danger" :
                                    item.status === 4 ? "success" :
                                      item.status === 5 ? "secondary" :
                                        "warning"
                              }> {TICKET_STATUS_MAPPING[item.status]} </LightBadge> */}
                          {/* </div> */}
                          <div className="d-flex justify-content-center">
                            <div className={TICKET_BADGE[item.status]}>{TICKET_STATUS_MAPPING[item?.status]}</div>
                          </div>
                        </td>
                        <td className={`pl-0`}>

                          {TICKET_PRIORITY_MAPPING[item.severity]}
                        </td>
                        <td className={`pl-0`}>{item.category || '-'}</td>
                        <td
                          className={`pl-0`}
                        >
                          <LinkWrapper
                            to={
                              item.venueId == null || item.orgId == null
                                ? null
                                : `/organization/${item.orgId}/venues/${item.venueId}`
                            }
                          >
                            {item.venueName || '-'}
                          </LinkWrapper>
                        </td>
                        <td className={`pl-0`}>{item.requesterName || '-'}</td>
                        <td className={`pl-0 cursor-pointer`} id={`fullDate${i}`}>
                          {item.updatedAt ? dateGapFinder(item.updatedAt) : '-'}
                          <UncontrolledTooltip placement="left" target={`fullDate${i}`}>
                            {dateTimeFormatter(item.updatedAt, 'long', 'short')}
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    )) : <tr>
                      <td colSpan={7}>
                        <div className="mt-2 text-center"><h4>No ticket found</h4></div>
                      </td>
                    </tr>
                  }
                </tbody>
              </Table>
            </div>
          </InfiniteScroll>

      }
      <SupportModal
        isOpen={support}
        setIsOpen={setSupport}
        onSuccess={getTicketList}
        enableInfra
        ticketDetails={
          {
            venueName: activeVenue.venueName,
            venueId: activeVenue.venueId,
            category: TICKET_CATEGORY.NETWORK,
            subCategory: "Venue"
          }
        } />
      <div ref={scrollRef} />
    </div>
  );
};

SupportTicketList.propTypes = {};

SupportTicketList.defaultProps = {};

export default SupportTicketList;
