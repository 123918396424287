import { useSelector } from "react-redux";
import ChartCardWithAreaCharts from "./ChartCardWithAreaCharts";

const NetworkThresholdChart = ({ categories, series1, series2, series3, series4, series5, series6, isLoading, typeSeriesTrafficUplink, typeSeriesTrafficDownlink, height, width, ticks, noStyle, noCardBorder, smallTitle }) => {
    const range = useSelector(store => store.activeOrg.meta.dateRange);
    const borderColorArray = ['', "#ff9a63", '', '', '#993f70', '']
    const noMarker = true
    const colors = ["#FFE3C7", "#FFC185", "#FF9F43", "#F17E8E", "#DE3FC7", "#CC00FF"]

    return (
        <ChartCardWithAreaCharts
            title={smallTitle ? 'Throughput' : "Network Throughput"}
            categories={categories}
            series={[series1, series2, series3, series4, series5, series6]}
            colors={colors}
            height={height}
            range={range}
            width={width}
            isTraffic={true}
            ticks={ticks}
            xlabel={`Throughput (${typeSeriesTrafficDownlink})`}
            ylabel={range > 0 ? "Date" : "Time"}
            typeSeriesTrafficUplink={typeSeriesTrafficUplink}
            typeSeriesTrafficDownlink={typeSeriesTrafficDownlink}
            isLoading={isLoading}
            showBorders={true}
            noStyle={noStyle}
            noCardBorder={noCardBorder}
            noMarker={noMarker}
            borderColorArray={borderColorArray}

        // chartOptions = {{
        //   tooltip: {
        //     custom: function({seriesIndex}) {
        //       return (`
        //       <div>
        //         <div style="background-color: #D9D9D9;height:20px"></div>
        //         <div style="padding: 0.5rem 0 0.5rem 0.5rem; font-size: 10px; word-wrap: break-word">
        //           <div style="max-width: 15rem;text-wrap: wrap; word-wrap: break-word"><b style="margin-right: 0.1rem">Infrastructure:</b> Halway 11 StoneDome Technologies</div>
        //           <div style="max-width: 15rem;text-wrap: wrap; word-wrap: break-word"><b style="margin-right: 0.1rem">Network:</b>Guest Network</div>
        //           <div style="max-width: 15rem;text-wrap: wrap; word-wrap: break-word"><b style="margin-right: 0.1rem">Band:</b>5G</div>
        //           <div style="max-width: 15rem;text-wrap: wrap; word-wrap: break-word"><b style="margin-right: 0.1rem">Channel:</b>149 (80MHz)</div>
        //         </div>
        //       </div>
        //       `)
        //     }
        // }
        // }}
        />
    );
}

export default NetworkThresholdChart;