/***
 *
 * Controller class for user.
 * @file InfraList.js
 * @description InfraList component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Row, Col, Container } from "reactstrap";
import { breadcrumbActions } from "../../../redux/slices";
import createRequest, { services } from "../../../services";
import "./InfraList.scss";
import LightBadge from "../../../components/LightBadge";
import { ReactComponent as MSPIcon } from "../../../assets/images/icons/MSP.svg";
import { ReactComponent as BusIcon } from "../../../assets/images/icons/BUS.svg";
import { formatInfraCardData, formatOnlineInfraData } from "../../Dashboard/NewDashboard/BlazerUtil";
import useTimedCaller from "../../Dashboard/NewDashboard/useTimedCaller";
import blazer from "../../../services/blazer.service";
import { isoDate } from "../../Dashboard/Graphs/Utils";
import Blazer from "../../../redux/slices/blazer.slice";
import ChartError from "../../Dashboard/Graphs/ChartError";
import InfraChart from "../../Dashboard/Graphs/InfraChart";
import OnlineInfraCharts from "../../Dashboard/Graphs/OnlineInfraChart";
import FirmwareProgressGraph from "../../../components/FirmwareProgressGraph";
import { BUSINESS, THIRD_PARTY_TITLE } from "../../../utility/constants";
import { LIST_TYPES } from "../../../components/OrgWidget/utils";
const OrgWidget = lazy(() => import("../../../components/OrgWidget"));

export const statusColor = {
  "connected": "#13A877",
  "disconnected": "#ea5455",
  "demo": "#4b4b4b",
  disabled: "#F6F6F6",
  disabledBorder: '#D4D4D4',
  "pending": "#ff9f43"
}

export const statusText = {
  "connected": "Online",
  "disconnected": "Offline",
  "demo": THIRD_PARTY_TITLE,
  disabled: 'Disabled',
  "pending": "Pending",
}

export const orgtypesIcons = {
  1: <MSPIcon width="100%" height="100%" style={{ height: "16px", width: "16px" }} />,
  2: <MSPIcon width="100%" height="100%" style={{ height: "16px", width: "16px" }} />,
  3: <BusIcon width="100%" height="100%" style={{ height: "16px", width: "16px" }} />,
};

export const radioFormater = (radio) => {
  let str = "";

  if (!radio.supported || radio.active == null)
    return str
  if (!radio.active)
    return <div className="d-flex"><LightBadge color="secondary">Disabled</LightBadge></div>

  if (!!radio.channel)
    str += `Ch ${radio.channel}`
  if (!!radio.channel_width) {
    if (str.length)
      str += ", "
    str += `${radio.channel_width} MHz`
  }
  if (!!radio.tx_power) {
    if (str.length)
      str += ", "
    str += `${radio.tx_power} dBm TX`
  }

  return str

}

export const ipFormater = (ip = "") => {
  let ipSegments = ip.split('.');

  if (ipSegments.length < 4)
    return "";

  return <span>
    {ipSegments[0]}.
    <span className={ipSegments[0] == 10 ? "font-weight-bolder" : ""}>{ipSegments[1]}.</span>
    <span className={(ipSegments[0] == 10 || (ipSegments[0] == 192 && ipSegments[1] == 168) || (ipSegments[0] == 172 && ipSegments[1] >= 16 && ipSegments[1] <= 32)) ? "font-weight-bolder" : ""}>{ipSegments[2]}.</span>
    <span className={(ipSegments[0] == 10 || (ipSegments[0] == 192 && ipSegments[1] == 168) || (ipSegments[0] == 172 && ipSegments[1] >= 16 && ipSegments[1] <= 32)) ? "font-weight-bolder" : ""}>{ipSegments[3]}</span>
  </span>
}

export const getHierarchy = (orgId, setter) => {
  return new Promise((resolve, reject) => {
    const {run} = createRequest(services.telemetry.GET_ORG_HIERARCHY, [orgId]);
    run()
    .then(response => {
      setter(response.data)
      resolve(response.data)
    })
    .catch(err => reject(err))
  })
}

const InfraList = () => {
  const activeOrg = useSelector(store => store.activeOrg.data);
  const range = useSelector(store => store.activeOrg.meta.dateRange);
  const infraType = useSelector(store => store.infraTypes);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const infraRef = useSelector(state => state.infraDash_infra);
  const onlineInfraRef = useSelector(state => state.infraDash_online_infra);
  const selections = useRef(
    {
      orgs: new Set(),
      partialOrgs: new Set(),
      venues: new Set(),
      partialVenues: new Set(),
      infra: new Set()
    }
  );
  const [selectionsState, setSelectionsState] = useState({
    orgs: new Set(),
    partialOrgs: new Set(),
    venues: new Set(),
    partialVenues: new Set(),
    infra: new Set()
  });
  const [bulkOrg, setBulkOrg] = useState(null);
  const treeStruct = useRef();
  
  const dispatch = useDispatch();

  useTimedCaller({ service: blazer.ORG_INFRA, params: [activeOrg.orgId, true, isoDate(), new Date().getTimezoneOffset()], data: {} }, 0, activeOrg.orgId, state => state.infraDash_infra, Blazer.infraDash_infra.actions);
  useTimedCaller({ service: blazer.ORG_AP_ONLINE, params: [activeOrg.orgId, isoDate(range), isoDate(), true, new Date().getTimezoneOffset()], data: {} }, range, activeOrg.orgId, state => state.infraDash_online_infra, Blazer.infraDash_online_infra.actions);

  const resetSelections = useCallback(() => {
    selections.current = {
      orgs: new Set(),
      partialOrgs: new Set(),
      venues: new Set(),
      partialVenues: new Set(),
      infra: new Set()
    };
    setSelectionsState({
      orgs: new Set(),
      partialOrgs: new Set(),
      venues: new Set(),
      partialVenues: new Set(),
      infra: new Set()
    });
    setBulkOrg(null)
    treeStruct.current = null
  }, [])

  // const getTreeStruct = (orgId) => {
  //   return new Promise((resolve, reject) => {
  //     const {run} = createRequest(services.telemetry.GET_ORG_HIERARCHY, [orgId]);
  //     run()
  //     .then(response => {
  //       treeStruct.current = response.data
  //       resolve(response.data)
  //     })
  //     .catch(err => reject(err))
  //   })
  // }

  useEffect(() => {
    dispatch(breadcrumbActions.setData([
      {
        path: `/organization/${activeOrg.orgId}/infra`,
        text: "Infrastructure",
        active: true
      }
    ]))
  }, [])


  return (
    <div className="InfraList fadable" data-testid="InfraList">
      <Container fluid>
        <Row className="my-1">
          {(permissions?.manageInfra?.view) &&
            <Col xl={3} className="px-0 col-xxl-3">
              {infraRef.isError ?
                <ChartError title="Infrastructure" value={infraRef.isError} /> :
                <InfraChart className="pb-2" height={'12.6rem'} series={formatInfraCardData(JSON.parse(infraRef.data)).series} trend={formatInfraCardData(JSON.parse(infraRef.data)).trend} isLoading={infraRef.isLoading || (!infraRef.isError && !infraRef.data)} main={infraRef.active} setMain={(main) => dispatch(Blazer.infraDash_infra.actions.setActive(main))} />}
            </Col>
          }
          {(permissions?.manageInfra?.view) &&
            <Col xl={4} className="leftPush rightPush col-xxl-3">
              {onlineInfraRef.isError ?
                <ChartError title="Online Infrastructure" value={onlineInfraRef.isError} /> :
                <OnlineInfraCharts
                  ticks={3}
                  height={'143rem'}
                  categories={formatOnlineInfraData(JSON.parse(onlineInfraRef.data)).labels}
                  colors={["#ED9F9C"]}
                  series={formatOnlineInfraData(JSON.parse(onlineInfraRef.data)).series}
                  isLoading={onlineInfraRef.isLoading || (!onlineInfraRef.isError && !onlineInfraRef.data)}
                />}
            </Col>
          }
          <Col xl={5} className="leftPush rightPush col-xxl-4">
            <div className="h-100">
              <FirmwareProgressGraph orgId={activeOrg.orgId} />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bg-white rounded p-1">
        {infraType?.status?.loading ? null :
        <Suspense fallback={<></>}>
        <OrgWidget
          autoFocus
          listType={LIST_TYPES.INFRA_LIST}
          SCREEN_ID="infra-list"
          selections={selections}
          selectionsState={selectionsState}
          setSelectionsState={setSelectionsState}
          resetSelections={resetSelections}
          treeStruct={treeStruct}
          filterReq={{
            CategoryFilter: true,
            InfraTypeFilter: true,
            StatusFilter: true,
            OrgFilter: activeOrg.orgTypeId != BUSINESS,
            FWFilter: true,
          }}
          bulkOrg={bulkOrg}
          setBulkOrg={setBulkOrg}
          getTreeStruct={(orgId) => {
            return new Promise((resolve, reject) => {
              getHierarchy(orgId, (data) => treeStruct.current = data)
              .then(response => resolve(response))
              .catch(err => reject(err))
            })
          }}
          enableOrgSelection={true}
        />
        </Suspense>}
      </div>
    </div >
  );
};

InfraList.propTypes = {};

InfraList.defaultProps = {};

export default InfraList;
