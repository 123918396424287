/***
 *
 * Controller class for user.
 * @file InfraDisabledCard.js
 * @description InfraDisabledCard component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./InfraDisabledCard.scss";
import { Button, Spinner } from "reactstrap";
import Toggle from "../Toggle";

const InfraDisabledCard = (props) => {
  const {
    onEnableClick,
    disabled,
    enableToggle,
    loading
  } = props;
  return (
    <div 
      className="InfraDisabledCard bg-white rounded p-1 d-flex align-items-center justify-content-center flex-column" 
      data-testid="InfraDisabledCard"
    >

      <div className="disabled-card-heading">
        Infrastructure is disabled
      </div>
      {/* <div className="mt-50">Click on button to enable the infrastructure</div> */}
      <div
        className="d-flex align-items-center"
      >
      <div></div>
      <Toggle
        className={loading ? '' : 'margin-right-toggle'}
        value={enableToggle}
        disabled={disabled}
        displayText={true} 
        customText={{[true]: "Enabled", [false]: "Disabled"}}
        onClick={onEnableClick}
      />
      {loading ? <Spinner size='sm' className="ml-50 text-primary"/> : null}
      </div>
      {/* <Button.Ripple
        className='mt-1'
        color='primary'
        disabled={disabled}
        onClick={onEnableClick}
      >
        Enable
      </Button.Ripple> */}
    </div>
  );
};

InfraDisabledCard.propTypes = {};

InfraDisabledCard.defaultProps = {};

export default InfraDisabledCard;
