import { createSlice } from "@reduxjs/toolkit";

const oneInfraInitialState = {
  status: {
    loading: false,
    error: null
  },
  data: {
    infraItemId: null,
    venueId: null,
    infraTypeId: null,
    macAddress: null,
    infraDisplayName: null,
    createdAt: null,
    updatedAt: null,
    connected: false,
  },
  details:{
    data:{},
    loading: false,
    error:null
  },
  meta:{
    interval:30
  },
  stats: {},
  issues:null,
  alarms:null,
};

const oneInfraSlice = createSlice({
  name: "oneInfra",
  initialState: {...oneInfraInitialState},
  reducers: {
    setInfra: (state, action) => {
      state.data = {...action.payload}
    },
    resetInfra: (state) => {
      return {...oneInfraInitialState};
    },
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    },
    setStatus: (state,action) =>{
      state.data.connected = action.payload;
    },
    setFeatures: (state,action) =>{
      state.features = action.payload;
    },
    resetFeatures: (state,action) =>{
      state.features = {};
    },
    setActualToShow: (state,action) =>{
      state.actualToShow = action.payload;
    },
    resetActualToShow: (state,action) =>{
      state.actualToShow = null;
    },
    setShowToActual: (state,action) =>{
      state.showToActual = action.payload;
    },
    resetShowToActual: (state,action) =>{
      state.showToActual = null;
    },
    setStats: (state,action) =>{
      state.stats = action.payload;
    },
    resetStats: (state,action) =>{
      state.stats = oneInfraInitialState.stats;
    },
    setDetails:(state,action) => {
      state.details.data = action.payload;
      state.details.data.updatedAt = Date.now()
    },
    setDetailsLoading: (state, action) => {
      state.details.loading = action.payload;
    },
    setDetailsError:(state,action) => {
      state.details.error = action.payload;
    },
    resetDetails:(state)=>{
      state.details = {data:{},error:null}
    },
    setIssues:(state,action)=>{
      state.issues = action.payload;
    },
    setAlarms:(state,action)=>{
      state.alarms = action.payload;
    },
    setInterval:(state, action) => {
      state.meta.interval = action.payload;
    }
  }
});

export const oneInfraReducer = oneInfraSlice.reducer;
const oneInfraActions = oneInfraSlice.actions;
export default oneInfraActions;
