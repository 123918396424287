import { Button, Input, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import "./ImageUploadModal.scss";
import { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { make_custom_toast } from "../../../../helpers/toasts";
import createRequest, { services } from "../../../../services";
import { wre } from "../../../../services/wre.service";
import { useSelector } from "react-redux";
import axios from "axios";
import { CatchedWebError } from "../../../../configs";
import { TICKET_CATEGORY } from "../../../Tickets/TicketConstants";
import { createErrorContext } from "../../../../configs/ErrorContextMaker";
import { Navigate } from "react-router-dom";
import { SUPPORTED_IMAGE_TYPES } from "../constants";

/**
 * Modal for uploading layouts as PNG images
 * @param {{
 *  uploadImageDialog: boolean,
 *  setUploadImageDialog: (boolean) => void,
 *  afterSaveSuccess: () => void
 * }} props 
 */
const ImageUploadModal = (props) => {
  const venueId = useSelector(store => store.activeVenue.data.venueId);
  const orgId = useSelector(store => store.activeOrg.data.orgId);
  const [name, setName] = useState("");
  const [fileSelected, setFileSelected] = useState(null);
  const { afterSaveSuccess, setUploadImageDialog } = props;

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    return () => {
      setName("");
      setFileSelected(null);
    }
  }, []);

  const uploadLayout = useCallback(() => {
    async function uploadImage() {
      if (venueId && orgId) {
        setUploading(true);
        try {
          const { run } = createRequest(wre.CREATE_LAYOUT, [1], {
            venueId: venueId,
            name: name,
            layoutFile: fileSelected.name,
            isActive: true,
            infraPositions: []
          })
          const response = await run();
          const id = response.data.id;
          const newLayoutFileName = 'layout-' + id
          const { run: editLayout } = createRequest(wre.EDIT_LAYOUT, [id], {
            layoutFile: newLayoutFileName
          })
          await editLayout()
          const { run: run2 } = createRequest(wre.SET_IMAGE, [orgId, id]);
          const response2 = await run2();
          const urlToUploadTo = response2.data;
          await axios.put(urlToUploadTo, fileSelected);
          make_custom_toast("success", "Layouts", "Layout created successfully!")
          setName("");
          setFileSelected(null);
          afterSaveSuccess();
          setUploadImageDialog(false);
        } catch (err) {
          const x = new CatchedWebError(err);
          let apiContext = createErrorContext({
            method: err.config.method,
            url: err.request.responseURL,
            data: err.config.data
          }, 'Unable to Create Layout', TICKET_CATEGORY.VENUE, err)
          make_custom_toast("error", "Layouts", x.message, true, 'Create Ticket', () => {
            Navigate(
              `/organization/${orgId}/support/createticket`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          })
        } finally {
          setUploading(false);
        }
      }
    }
    uploadImage();
  }, [venueId, name, fileSelected, orgId, afterSaveSuccess, setUploadImageDialog]);

  return (
    <Modal className="ImageUploadModal" centered isOpen={props.uploadImageDialog} toggle={() => { if (!uploading) props.setUploadImageDialog(false); }}>
      <ModalHeader className="bg-white" toggle={() => { if (!uploading) props.setUploadImageDialog(false); }}></ModalHeader>
      <ModalBody className="modal-body">

        <div className="dotted-border mb-1">
          <Dropzone onDrop={file => {
            if (!SUPPORTED_IMAGE_TYPES.includes(file[0].type)) {
              make_custom_toast("error", "Layouts", "Please upload a supported file.");
            } else {
              setFileSelected(file[0]);
            }
          }}>
            {({ getRootProps, getInputProps }) => (
              <div className="text-center" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="material-symbols-outlined upload-icon" style={{ fontSize: '34px' }}>upload</div>
                <div className="upload-text">Drag & Drop or <span className="link-like">Choose file</span> to upload</div>
                <div className="upload-text">(jpeg, png, webp, svg are supported)</div>
              </div>
            )}
          </Dropzone>

          {fileSelected &&
            <div className="text-center mt-1">Uploaded file: <strong>{fileSelected.name}</strong></div>
          }
        </div>
        <div className="">Layout Name<span className="text-danger" >*</span></div>

        <Input autoFocus={true} className="input" placeholder="Enter Name" type="text" value={name} onChange={e => { setName(e.target.value) }} />



        <div className="d-flex justify-content-center mt-2">
          <Button color="outline-primary" size="sm" onClick={() => { setFileSelected(null); setName('') }} disabled={uploading}>Reset</Button>
          <Button
            disabled={name.trim().length < 1 || !fileSelected || uploading}
            color="primary"
            size="sm"
            className="ml-1"
            onClick={uploadLayout} >
            {
              uploading ? "Uploading..." : "Upload"
            }
          </Button>

        </div>

      </ModalBody>
    </Modal>
  );
};

ImageUploadModal.propTypes = {
  uploadImageDialog: PropTypes.bool,
  setUploadImageDialog: PropTypes.func,
  afterSaveSuccess: PropTypes.func
};
ImageUploadModal.defaultProps = {};

export default ImageUploadModal;