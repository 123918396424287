/***
 *
 * Controller class for user.
 * @file VenueAdmin.js
 * @description VenueAdmin component
 * @author Rajinder Singh
 * @since 19 Feb 2024
 */

import React, { useEffect } from "react";
import "./VenueAdmin.scss";
import SupportTicketList from "../../../components/SupportTicketList";
import Permissions from "../Permissions";
import Settings from "../Settings";
import { breadcrumbActions } from "../../../redux/slices";
import { useDispatch, useSelector } from "react-redux";

const VenueAdmin = () => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const activeVenue = useSelector(store => store.activeVenue.data);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const permissions = useSelector(store => store.rbac.permissions)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      text: activeVenue?.venueName,
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/admin/`,
      text: "Admin",
      active: true,
    }
    ]));
  }, [])
  return (
    <>
      <div className="VenueAdmin" data-testid="VenueAdmin">
        {/* Venue Settings */}
        <Settings />

        {/* Support Ticket */}
        {permissions?.manageTicket?.view && <SupportTicketList />}

        {/* Permission Table */}
        <div className="bg-white p-1">
          <Permissions />
        </div>
      </div>
    </>
  );
};

VenueAdmin.propTypes = {};

VenueAdmin.defaultProps = {};

export default VenueAdmin;
