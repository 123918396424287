/**
 * Redux Store
 * @file store.js
 * @description Standard Redux store
 * @author Utkarsh Gupta
 * @since 26 Jun 2022
 */

import { configureStore } from "@reduxjs/toolkit";
import { identityReducer } from "./slices/identity.slice";
import { activeOrgReducer } from "./slices/activeorg.slice";
import { permissionsReducer } from "./slices/permissions.slice";
import { identitiesReducer } from "./slices/identities.slice";
import { orgReducer } from "./slices/org.slice";
import { suborgsReducer } from "./slices/suborgs.slice";
import { criticalReducer } from "./slices/critical.slice";
import { orgtypesReducer } from "./slices/orgtypes.slice";
import { activeVenueReducer } from "./slices/activevenue.slice";
import { infraListReducer } from "./slices/infralist.slice";
import { oneInfraReducer } from "./slices/oneinfra.slice";
import { infraTypesReducer } from "./slices/infraTypes.slice";
import { breadcrumbReducer } from "./slices/breadcrumb.slice";
import { inviteSubOrgsReducer } from "./slices/invitedSubOrgs";
import { acceptedSubOrgsReducer } from "./slices/acceptedSubOrgs";
import { oneNetworkReducer } from "./slices/onenetwork.slice";
import { deviceReducer } from "./slices/device.slice";
import { ticketReducer } from "./slices/ticket.slice";
import { recurringGraphsReducer } from "./slices/recurringGraphs.slice";
import { filterReducer } from "./slices/filters.slice";
import { devicesDebugReducer } from "./slices/uedebug.slice";
import Blazer from "./slices/blazer.slice";
import { notificationReducer } from "./slices/notification.slice";
import { marketplaceReducer } from "./slices/marketplace.slice";
import { rbacReducer } from "./slices/rbac.slice";
import { rebootingReducer } from "./slices/rebooting.slice";
import { traceRouteReducer } from "./slices/traceRouteData.slice";
import { wreReducer } from "./slices/wre.slice";

const store = configureStore({
  reducer: {
    identity: identityReducer,
    activeOrg: activeOrgReducer,
    permissions: permissionsReducer,
    identities: identitiesReducer,
    ticket: ticketReducer,
    org: orgReducer,
    suborgs: suborgsReducer,
    critical: criticalReducer,
    orgtypes: orgtypesReducer,
    activeVenue: activeVenueReducer,
    infraList: infraListReducer,
    oneInfra: oneInfraReducer,
    infraTypes: infraTypesReducer,
    breadcrumb: breadcrumbReducer,
    oneNetwork: oneNetworkReducer,
    inviteSubOrgs: inviteSubOrgsReducer,
    acceptedSubOrgs: acceptedSubOrgsReducer,
    device: deviceReducer,
    recurringGraphs: recurringGraphsReducer,
    rbac: rbacReducer,
    rebooting: rebootingReducer,

    dashboard_infra: Blazer.dashboard_infra.reducer,
    dashboard_firmwareVersion: Blazer.dashboard_firmwareVersion.reducer,
    dashboard_ap_health: Blazer.dashboard_ap_health.reducer,
    dashboard_ap_reboot: Blazer.dashboard_ap_reboot.reducer,
    dashboard_venue: Blazer.dashboard_venue.reducer,
    dashboard_network: Blazer.dashboard_network.reducer,
    dashboard_support: Blazer.dashboard_support.reducer,
    dashboard_devices: Blazer.dashboard_devices.reducer,
    dashboard_online_infra: Blazer.dashboard_online_infra.reducer,
    dashboard_venue_alarms: Blazer.dashboard_venue_alarms.reducer,
    dashboard_clients_count: Blazer.dashboard_clients_count.reducer,

    venueDash_venue: Blazer.venueDash_venue.reducer,
    venueDash_tasks: Blazer.venueDash_tasks.reducer,
    venueDash_alarms: Blazer.venueDash_alarms.reducer,

    infraDash_infra: Blazer.infraDash_infra.reducer,
    infraDash_online_infra: Blazer.infraDash_online_infra.reducer,

    warehouseDash_infra: Blazer.warehouseDash_infra.reducer,
    warehouseDash_online_infra: Blazer.warehouseDash_online_infra.reducer,


    my_infra: Blazer.my_infra.reducer,
    my_venue: Blazer.my_venue.reducer,
    my_network: Blazer.my_network.reducer,
    my_support: Blazer.my_support.reducer,
    my_devices: Blazer.my_devices.reducer,
    my_clients: Blazer.my_clients.reducer,
    my_firmwareVersion: Blazer.my_firmwareVersion.reducer,
    my_ap_health: Blazer.my_ap_health.reducer,
    my_ap_reboot: Blazer.my_ap_reboot.reducer,

    venue_infra: Blazer.venue_infra.reducer,
    venue_devices: Blazer.venue_devices.reducer,
    venue_network: Blazer.venue_network.reducer,
    venue_health: Blazer.venue_health.reducer,
    venue_events: Blazer.venue_events.reducer,
    venue_event_count: Blazer.venue_event_count.reducer,

    venue_ap_health: Blazer.venue_ap_health.reducer,
    venue_cum_ap_health: Blazer.venue_cum_ap_health.reducer,

    venue_ap_reboot: Blazer.venue_ap_reboot.reducer,
    venue_cum_ap_reboot: Blazer.venue_cum_ap_reboot.reducer,


    venue_cum_infra: Blazer.venue_cum_infra.reducer,
    venue_cum_devices: Blazer.venue_cum_devices.reducer,
    venue_cum_network: Blazer.venue_cum_network.reducer,

    infra_devices: Blazer.infra_devices.reducer,
    infra_network: Blazer.infra_network.reducer,
    infra_events: Blazer.infra_events.reducer,
    infra_health: Blazer.infra_health.reducer,
    infra_load: Blazer.infra_load.reducer,
    channel_utilization_2g: Blazer.channel_utilization_2g.reducer,
    channel_utilization_5g: Blazer.channel_utilization_5g.reducer,
    channel_utilization_6g: Blazer.channel_utilization_6g.reducer,
    infra_memory: Blazer.infra_memory.reducer,

    network_network: Blazer.network_network.reducer,

    device_network: Blazer.device_network.reducer,
    device_phyRate: Blazer.device_phyRate.reducer,
    device_rssi: Blazer.device_rssi.reducer,

    webhook: Blazer.webhook.reducer,
    webhook_trend: Blazer.webhook_trend.reducer,
    webhook_error_trend: Blazer.webhook_error_trend.reducer,

    filter: filterReducer,
    notification: notificationReducer,
    devicesDebug: devicesDebugReducer,
    marketplace: marketplaceReducer,
    wre: wreReducer,
    traceRoute: traceRouteReducer
  }
});

export default store;