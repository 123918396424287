import axios from "axios"
import createRequest, { services } from "../../../services"
import { extractAttachmentName, extractFileExtension, extractFileName } from "./StringFunction"
import { useEffect } from "react"


const validateFileName = (fileName) => {
    const pattern = /^[A-Za-z0-9 _.-]+$/
    let result = pattern.test(fileName)
    return result
}

export const uploadFile = (file, activeOrgId) => {
    return new Promise((resolve, reject) => {
        if (file.size > 20971520) { //20mb limit 
            reject(
                {
                    errorCode: 900,
                    message: `Issue in uploading ${file.name}, file size exceeded 20MB`
                }
            )

            return
        }
        const fileType = extractFileExtension(file.name)
        const fileName = encodeURIComponent(extractFileName(file.name))
        const { run } = createRequest(services.ticket.UPLOAD_FILE, [activeOrgId, fileType, fileName])
        run().
            then(res => {
                const URL = res.data
                axios.put(URL, file)
                    .then(res => {
                        resolve({
                            fileName: extractAttachmentName
                                (URL),
                            displayName: file.name
                        })
                    })
                    .catch(err => {
                        reject('Unable to upload')
                    })
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.error) {
                    reject(err.response.data.error)
                }
                reject('Unable to upload ')
            })
    })
}


export const formatDateTime = (dateTime) => {
    const currentDateString = new Date(dateTime).toString()
    const year = currentDateString.substring(11, 16)
    const month = currentDateString.substring(4, 7)
    const day = currentDateString.substring(8, 10)
    const hour = currentDateString.substring(16, 18)
    const min = currentDateString.substring(19, 21)
    return `${day}-${month}-${year} ${hour}:${min}`
}

export const getFileUrl = (files) => {
    let temp = []
    files.map(file => temp.push({
        filename: file.name,
    }))
    return temp;
}


export const useResetWarning = (state1, state2, setPrev1, setPrev2) => {
    useEffect(() => {

        setPrev1(false)

        setPrev2(false)

    }, [state1, state2])
}

export const isValidFilter = (filter) => {
    if (filter == "Active" || filter == "Awaiting feedback" || filter == "Critical" || filter == "All")
        return filter
    return "Active"
}