/***
 *
 * Controller class for user.
 * @file DeleteInfraModal.js
 * @description DeleteInfraModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./DeleteInfraModal.scss";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import InfraInfo from "../InfraInfo";
import Select from "react-select";
import { INFRA_DELETE_OPTIONS, reactselectTheme } from "../../utility/constants";
import { useSelector } from "react-redux";

const DeleteInfraModal = (props) => {
  const { 
    isWarehouse,
    isOpen, 
    setIsOpen, 
    onDelete,
    option: optionValue, 
    setOption: setOptionValue, 
    infraIds, 
    disabled, 
    orgId 
  } = props;

  const permissions = useSelector(store => store?.rbac?.permissions);
  
  const hasWarehouse = useSelector(store => store.activeOrg.data.capabilities?.vwh?.ok);

  // useEffect(() => {
  //   console.log('permissions: ',permissions?.manageIntegration?.create)
  //   console.log('warehouse: ',!isWarehouse)
  // }, [permissions, isWarehouse])

  // useEffect(() => {
  //   console.log('option value: ',optionValue)
  // }, [optionValue])
  
  

  return (
    <Modal
      isOpen={!!isOpen}
      toggle={() => setIsOpen(false)}
      centered
      className="DeleteInfraModal"
      data-testid="DeleteInfraModal"
    >
      <ModalHeader
        toggle={() => setIsOpen(false)}
        className="p-0 bg-white"
      >
      </ModalHeader>
      <ModalBody>
        <div className="heading mb-1">
          Do you want to delete infrastructure?
        </div>
        <div
          className="InfraInfoWrapper"
        >
          {(hasWarehouse && permissions?.manageIntegration?.create && !isWarehouse)
          ? optionValue == 1
            ? `This will delete ${infraIds?.size} infrastructure from the venue, reset them and move them back to the virtual warehouse`
            : `This will permanently delete ${infraIds?.size} infrastructure from the system` 
          : <InfraInfo 
              infraIds={infraIds}
              type='delete'
              orgId={orgId}
            />
          }
        </div>
        {(hasWarehouse && permissions?.manageIntegration?.create && !isWarehouse) ?
        <Select
          className="mb-2"
          styles={reactselectTheme}
          options={INFRA_DELETE_OPTIONS}
          isDisabled={disabled}
          value={INFRA_DELETE_OPTIONS.find(it => it.value == optionValue)}
          onChange={(e) => {
            setOptionValue(Number(e.value))
          }} 
        /> : null}
        <div className="d-flex justify-content-end align-items-center mt-1 ButtonsDiv">
          <Button.Ripple
            color='primary'
            onClick={() => {
              setIsOpen(null);
            }}
            disabled={disabled}
          >
            Cancel
          </Button.Ripple>
          <Button.Ripple
            color='outline-danger'
            onClick={onDelete}
            disabled={disabled}
          >
            Delete
          </Button.Ripple>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteInfraModal.propTypes = {};

DeleteInfraModal.defaultProps = {};

export default DeleteInfraModal;
