/**
 * Text-Input
 * @file TextInputWithIcon.js
 * @description Formik compatible text-input styled with reactstrap components.
 * @author Utkarsh Gupta
 * @since 29 Jun 2022
 */

import { useField } from "formik";
import React from "react";
import { FormFeedback, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import PropTypes from 'prop-types';
import "./TextInputWithIcon.scss";
import { Tooltip } from "react-tooltip";
import { VENUE_DEFAULT } from "../../utility/tooltip-config-global";

const TextInputWithIcon = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const elementProps = { ...props, isrequired: "sampled" };
  delete elementProps.noLabel
  delete elementProps.labelfontauth
  delete elementProps.overallClasses
  return (
    <div className={"TextInputWithIcon " + (props.noBottomMargin ? "" : " mb-2 ") + (!!props.overallClasses ? props.overallClasses : '')} data-testid="TextInputWithIcon">
      {!props.noLabel &&
        <Label
          className={"labelfont" +
            (props?.labelfontauth === true ? "auth" : '')}
          for={props.id || props.name}>{label}{props.isrequired ?
            <span className="text-danger">*</span> : <></>}</Label>}
          <InputGroup className="input-group-merge">
            <InputGroupText className={((props.ignoreTouch || meta.touched) && meta.error) ? "invalid-border " : ""} style={{borderRight:0, borderTopRightRadius:0, borderBottomRightRadius:0}}>
              <div className="" >{props.icon}</div>
            </InputGroupText>

            <Input invalid={!!((props.ignoreTouch || meta.touched) && meta.error)} autoComplete="off" {...field} {...elementProps} />

            {/* <InputGroupText className={((props.ignoreTouch || meta.touched) && meta.error) ? "invalid-border" : ""} style={{borderLeft:0, borderTopLeftRadius:0, borderBottomLeftRadius:0}}>
              {props.defaultTag ? <div className="default-tag" id="default-venue-tooltip">default</div> : <></>}
            </InputGroupText> */}
          </InputGroup>
          {((props.ignoreTouch || meta.touched) && meta.error) ? (
            <FormFeedback>{meta.error}</FormFeedback>
          ) : null}

          {/* <Tooltip
            anchorSelect={`#default-venue-tooltip`}
            variant="light"
            clickable
            place="bottom"
            border="solid 2px #EAEAEA"
            opacity={1}
            style={{ width:"460px", boxShadow: "2px 2px 15px #EAEAEA", zIndex: 1 }}
            delayShow={100}
          >
            {VENUE_DEFAULT}
          </Tooltip> */}
    </div>
  );
};

TextInputWithIcon.propTypes = {
  label: PropTypes.string,
  props: PropTypes.any,
  isrequired: PropTypes.bool,
  labelfontauth: PropTypes.bool
};

TextInputWithIcon.defaultProps = {};

export default TextInputWithIcon;
