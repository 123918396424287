/***
 *
 * Controller class for user.
 * @file Infrastructure.js
 * @description Infrastructure component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { breadcrumbActions } from "../../../redux/slices";
import createRequest, { services } from "../../../services";
import store from "../../../redux/store";
import REDUX_WORKER from "../../../redux/workers";
import Header from "../_builder/Header";
import "./Infrastructure.scss";
import Copy from "../../Infrastructure/APOverview/Copy";
import { ReactComponent as Install } from "../../../assets/images/icons/install_cloud_color.svg"
import { ReactComponent as InstallIconBlack } from "../../../assets/images/icons/install_cloud.svg"
import { ReactComponent as LayoutIcon } from "../../../assets/images/icons/placement-icon.svg"
import { ReactComponent as LayoutIconBlack } from "../../../assets/images/icons/placement-icon-black.svg"
import { ReactComponent as ValidateIcon } from '../../../assets/images/icons/validate-icon.svg'
import { ReactComponent as ValidateIconBlack } from '../../../assets/images/icons/validate-icon-black.svg'
import { ReactComponent as CameraImage } from "../../../assets/images/icons/camera-icon.svg"
import { ReactComponent as CameraImageBlack } from "../../../assets/images/icons/camera-icon-black.svg"
import { ReactComponent as Success } from "../../../assets/images/icons/Success.svg"
import { handleCopy } from "../../Infrastructure/APOverview";
import ToolTip from "react-portal-tooltip";
import LayoutTooltipRenderer from "../FloorPlan2/LayoutTooltipRenderer";
import { FAKE_WALL_DATA, LAYOUT_TYPES } from "../FloorPlan2/constants";
import VenueInfraWidget from "../../../components/VenueInfraWidget";
import { dateTimeFormatter, getTimeZone } from "../../../utility/Localization";


const TableMac = (props) => {
  const [showCopy, setShowCopy] = useState(false);
  return (
    <div className='d-flex'
      onMouseEnter={() => { setShowCopy(true); }}
      onMouseLeave={() => { setShowCopy(false); }}
    >
      <div className='w-75'>
        <span>{props.mac.substring(0, 8)}</span>
        <span className="font-weight-bolder" style={{ fontSize: '1.08rem' }}>{props.mac.substring(8, 12)}</span>
      </div>
      <div className={'w-25 ' + (showCopy ? '' : 'invisible')}>
        <Copy className="cursor-pointer" style={{ height: "18px", marginLeft: '8px' }} onClick={() => { handleCopy(props.mac) }} />
      </div>
    </div>
  );
}

export function MAC_ADDRESS(sno, jsx = false) {
  if (jsx)
    return (
      <TableMac mac={sno} />
    );

  let mac = "";
  for (let i = 0; i < sno.length; i++) {
    mac += sno[i];
    if (i % 2 === 1 && i !== sno.length - 1) {
      mac += ":";
    }
  }
  return mac;
}

export const ImageTooltip = (props) => {
  const { infraId, hover, showColor } = props
  const [imgs, setImgs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tooltipActive, setTooltipActive] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)

  useEffect(() => {
    if (tooltipActive && !dataFetched) {
      setLoading(true);
      setImgs(null);
      const { run } = createRequest(services.infra.INSTALL_IMG, [infraId, "get", 0, true]);

      run()
        .then(response => {
          setImgs(response.data);
          setDataFetched(true)
        })
    }
  }, [infraId, tooltipActive])

  return <div
    className="task-div"
    id={`Image${infraId}`}
    onMouseOver={() => {
      if (hover)
        setTooltipActive(true);
    }}
    onMouseLeave={() => { setTooltipActive(false); }}>

    {
      showColor ?
        <CameraImage width={24} height={24} /> :
        <CameraImageBlack width={24} height={24} />
    }


    {showColor && !props.allTaskDone
      ? <div className="success-top">
        <Success width={16} height={16} />
      </div>
      : <></>
    }

    <ToolTip active={tooltipActive} position="left" arrow="center" parent={`#Image${infraId}`}>
      <div className="text-center" style={{ width: "256px", height: "140px" }}>
        {/* {(loading) ?
          <div className="p-5 text-center">
            <Spinner color="primary" />
          </div> : <></>} */}
        {
          (Object.keys(imgs ?? {}).length > 0) ?
            <img src={imgs[Object.keys(imgs ?? {})[0]]} onLoad={() => setLoading(false)} className={"ap-img " + (loading ? "d-none" : "")} />
            : <></>}
      </div>
    </ToolTip>

  </div >
}
export const LayoutTooltip = (props) => {
  const { infra, venueId, activeOrgId } = props
  const [loading, setLoading] = useState(true);
  const [floorplan, setFloorplan] = useState({})
  const [tooltipActive, setTooltipActive] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)

  useEffect(() => {
    if (tooltipActive && !dataFetched) {
      setLoading(true);
      const { run } = createRequest(services.wre.GET_PLACED_FLOORPLAN, [infra.infraItemId, venueId]);
      run()
        .then(response => {
          if (typeof (response.data) === 'object' && (response.data.length == undefined)) {
            const tempFloorplan = { ...response.data }
            if (!tempFloorplan.layoutJson) {  //cancomefromapi
              tempFloorplan.layoutType = LAYOUT_TYPES.IMAGE
              tempFloorplan.layoutJson = FAKE_WALL_DATA
            }
            setFloorplan(tempFloorplan)
            setLoading(false)
          }
          else {
            setFloorplan({})
            setLoading(false)
          }
          setDataFetched(true)
        })
        .catch(err => {
          setFloorplan({})
          setLoading(false)
        })
    }
  }, [infra, tooltipActive])

  return <div
    className="task-div"
    id={`Layout${infra.infraItemId}`}
    onMouseOver={() => {
      if (infra.installed)
        setTooltipActive(true);
    }}
    onMouseLeave={() => { setTooltipActive(false); }}>
    {
      infra.installed ?
        <LayoutIcon width="24" height="24" /> :
        <LayoutIconBlack width="24" height="24" />
    }
    {
      infra.installed && !props.allTaskDone ? <div className="success-top">
        <Success width={16} height={16} />
      </div>
        : <></>
    }
    <ToolTip active={tooltipActive} position="left" arrow="center" parent={`#Layout${infra.infraItemId}`}>
      <div style={{ width: "256px", maxHeight: "240px" }}>
        {(loading) ?
          <div className="p-5 text-center" style={{ height: '240px' }}>
            {/* <Spinner color="primary" /> */}
          </div>
          : (Object.keys(floorplan).length > 0) ?
            <div>
              <LayoutTooltipRenderer
                layout={floorplan}
                hideDropdown={true}
                isDesignLayout={true}
                hideBackGrid={true}
                showAP={true}
                showRealAp={true}
                hideLink={true}
                activeOrgId={activeOrgId}
                infraItemId={infra.infraItemId}
              />
            </div>
            :
            <div className="p-1" style={{ minHeight: "80px" }}>

              {infra?.room &&
                <div className="text-wrap text-break" ><strong>Room: </strong>{infra?.room || '-'}</div>
              }

              {infra?.location &&
                <div className="mt-50 text-wrap text-break"><strong>Location: </strong>{infra?.location || '-'}</div>
              }

            </div>}
      </div>
    </ToolTip>

  </div >
}
export const ValidateTooltip = (props) => {
  const { infraId, speedTestResult } = props
  const [tooltipActive, setTooltipActive] = useState(false)

  useEffect(() => {
    if (tooltipActive) {
    }
  }, [infraId, tooltipActive])

  return <div className="task-div"
    id={`Validate${infraId}`}
    onMouseOver={() => {
      if (!!speedTestResult)
        setTooltipActive(true);
    }}
    onMouseLeave={() => { setTooltipActive(false); }}>

    {
      !!speedTestResult ?
        <ValidateIcon width="24" height="24" /> :
        <ValidateIconBlack width="24" height="24" />
    }
    {
      !!speedTestResult && !props.allTaskDone ?
        <div className="success-top">
          <Success width={16} height={16} />
        </div>
        : <></>
    }
    <ToolTip active={tooltipActive} position="left" arrow="center" parent={`#Validate${infraId}`}>
      <div className="px-1 py-50" style={{ width: "200px", maxHeight: "140px" }}>
        {
          speedTestResult &&
          <div>
            <h5 className="fw-600">Speed Test Results</h5>
            <div className="d-flex justify-content-between mb-50"> <span className="">Uplink:</span> <span>{speedTestResult?.uplink ? speedTestResult?.uplink.toFixed(2) : '-'}Mb/s</span></div>
            <div className="d-flex justify-content-between mb-50"> <span className="" >Downlink: </span><span>{speedTestResult?.downlink ? speedTestResult?.downlink.toFixed(2) : '-'}Mb/s</span></div>
            <div className="d-flex justify-content-between"> <span className="">Ping: </span><span>{speedTestResult?.ping}ms</span></div>
          </div>
        }
      </div>
    </ToolTip>

  </div >
}

export const InstallTooltip = (props) => {
  const { infraId, placed, firstConnectedAt } = props
  const [tooltipActive, setTooltipActive] = useState(false)

  return <div className="task-div "
    id={`Install${infraId}`}
    onMouseOver={() => {
      if (firstConnectedAt != null)
        setTooltipActive(true);
    }}
    onMouseLeave={() => { setTooltipActive(false); }}
  >

    {
      placed ?
        <Install width="24" height="24" /> :
        <InstallIconBlack width="24" height="24" />
    }
    {
      placed && !props.allTaskDone ? <div className="success-top">
        <Success width={16} height={16} />
      </div>
        : <></>
    }
    <ToolTip active={tooltipActive} position="left" arrow="center" parent={`#Install${infraId}`}>
      <div className="text-center"
      // style={{ width: "256px", height: "140px" }}
      >
        First connected at:
        <br />
        {dateTimeFormatter(
          firstConnectedAt,
          "long",
          "medium"
        ) +
          " (" +
          getTimeZone(false) +
          ")"}
      </div>
    </ToolTip>

  </div >
}

const Infrastructure = (props) => {
  const SCREEN_ID = "venue-infra";
  const activeVenue = useSelector(store => store.activeVenue.data);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);
  const [selections, setSelections] = useState({ infras: {}, venues: {} })
  const [treeStruct, setTreeStruct] = useState();
  const [venueSelection, setVenueSelection] = useState({
    venues: new Set(),
    infras: new Set(),
    venueDelete: new Set(),
    infraDelete: new Set()
  })

  useEffect(() => {
    const { run } = createRequest(services.networks.GET_ASSOCIATION, [activeOrgId]);

    run()
      .then(response => {
        setTreeStruct(response.data);
        let sel = { infras: {}, venues: {} }
        Object.keys(response?.data?.infrastructure ?? {}).map(infra => {
          sel.infras[infra] = response?.data?.infrastructure[infra]
        })
        Object.keys(response?.data?.venue ?? {}).map(ven => {
          sel.venues[ven] = response?.data?.venue[ven].inheritance
        })
        setSelections({ ...sel })


        setVenueSelection(prevState => {
          let sel = { ...prevState }

          Object.keys(response?.data?.infrastructure ?? {}).map(infra => {
            if (response?.data?.infrastructure[infra] == 2)
              sel.infras.add(Number(infra))
          })
          Object.keys(response?.data?.venue ?? {}).map(ven => {
            if (response?.data?.venue[ven].inheritance == 2) {
              sel.venues.add(Number(ven));
              response?.data?.venue[ven].infrastructure.map(infra => {
                sel.infras.delete(infra);
              })
            }
          })
          return { ...sel }
        })
      })
  }, [])


  useEffect(() => {
    if (venuecrumb.venueId !== activeVenue.venueId)
      REDUX_WORKER.getVenueParent(activeVenue.venueId, activeOrgId);

    store.dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      text: activeVenue?.venueName,
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/infrastructure/`,
      text: "Infrastructure",
      active: true,
    }
    ]));
  }, [activeVenue.venueId, venuecrumb])

  if (!treeStruct) return null

  return (
    <div className="Infrastructure" data-testid="Infrastructure">
      <Header heading={activeVenue.venueName} />
      <VenueInfraWidget
        // showVenueOptions
        // addVenueButton
        // isSelector
        // hideStatusIcon
        infraFocus
        infraAPIs
        venueId={activeVenue.venueId}
        showInfraOptions
        SCREEN_ID={SCREEN_ID}
        filterReq={{
          CategoryFilter: true,
          InfraTypeFilter: true,
          StatusFilter: true,
          FWFilter: true,
        }}
        startLevel={1}
        selections={selections}
        setSelections={setSelections}
        treeStruct={treeStruct}
        venueSelection={venueSelection}
        setVenueSelection={setVenueSelection}
        profileScrn
        noAllSelection
      />
    </div >
  );
};

Infrastructure.propTypes = {};

Infrastructure.defaultProps = {};

export default Infrastructure;
