/***
 *
 * Controller class for user.
 * @file Webhooks.js
 * @description Webhooks component
 * @author Rajinder Singh
 * @since 11 Jul 2024
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./Webhooks.scss";
import { Button, Label, Modal, ModalBody, ModalHeader, Spinner, Table } from "reactstrap";
import { Copy, Delete } from "react-feather";
import InputBox from "../../../components/InputBox";
import { handleCopy } from "../../Infrastructure/APOverview";
import { reactselectTheme } from "../../../utility/constants";
import Select from "react-select";
import { formatWebhookErrorTrendData, formatWebhookTrendData } from "../../Dashboard/NewDashboard/BlazerUtil";
import WebhookTrendChart from "../../Dashboard/Graphs/WebhookTrendChart";
import ChartError from "../../Dashboard/Graphs/ChartError";
import { Toggle } from "../../../components";
import Blazer from "../../../redux/slices/blazer.slice";
import { isoDate } from "../../Dashboard/Graphs/Utils";
import createRequest, { services } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon } from "../../../assets/images/icons/Icons";
import DateRangeSelector from "../../Dashboard/Graphs/DateRangeSelector";
import { ReactComponent as QuestionIcon } from "../../../assets/images/icons/QuestionIcon.svg";
import { ReactComponent as Warning } from "../../../assets/images/icons/WarningWhiteExclamation.svg";
import WebhookErrorTrendChart from "../../Dashboard/Graphs/WebhookErrorTrendChart";
import { make_custom_toast } from "../../../helpers/toasts";
import { CatchedWebError } from "../../../configs";
import { createErrorContext } from "../../../configs/ErrorContextMaker";
import useTimedCaller from "../../Dashboard/NewDashboard/useTimedCaller";
import blazer from "../../../services/blazer.service";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { useNavigate } from "react-router-dom";
import { breadcrumbActions } from "../../../redux/slices";



const PreviewJson = {
  "type": "alarm",
  "payload": {
    "org": {
      "id": 1577,
      "name": "webhooks_business"
    },
    "infra": {
      "id": 42075,
      "mac": "d4babaa13ac0",
      "name": "AP-3ac0",
      "type": "ap"
    },
    "venue": {
      "id": 3157,
      "name": "Default Venue"
    },
    "status": "active",
    "alarm_id": 19949,
    "severity": "critical",
    "description": "AP went offline",
    "display_name": "AP went offline"
  },
  "timestamp": "2022-08-16 07:50:35",
  "webhook_id": 5
}



const safeParseJson = (value) => {
  try {
    let parsedJson = JSON.parse(value);
    return typeof parsedJson === "object" ? parsedJson : {};
  } catch (err) {
    return {};
  }
};

const KeyValuePairs = (props) => {
  const { jsonString } = props;

  const [jsonParsed, setJsonParsed] = useState(safeParseJson(jsonString));
  useEffect(() => {
    setJsonParsed(safeParseJson(jsonString));
  }, [jsonString]);

  return Object.keys(jsonParsed).map((key) => {
    return <div>{`${key}: ${jsonParsed[key]}`}</div>;
  });
};

const POST = "post";
const PUT = "put";

const requestTypeOptions = [
  { label: "Post", value: POST },
  { label: "Put", value: PUT },
];

const errorTexts = {
  headers: {
    incorrectFormat: "Please enter a valid JSON object.",
  },
  url: {
    incorrectFormat: "Please enter a valid URL.",
  },
};

const initialFormData = {
  enabled: false,
  portEvents: false,
  requestType: requestTypeOptions[0].value,
  requestTypeOption: requestTypeOptions[0],
  // requestTypeOption: {
  //   label: null,
  //   value: null,
  // },
  url: null,
  headers: null,
  headerInput: "",
  headerValueInput: "",
};

export const isUrl = (value) => {
  try {
    new URL(value);
    return true;
  } catch (error) {
    return false;
  }
};

const Webhooks = () => {
  const activeOrg = useSelector((store) => store.activeOrg.data);
  const activeOrgId = useSelector((store) => store.activeOrg.data.orgId);
  const [errors, setErrors] = useState({
    url: null,
    // headers: null,
  });
  const [showError, setShowError] = useState({
    url: false,
    headers: false,
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(false);
  // const [backendData, setBackendData] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(true);
  const [errorModalData, setErrorModalData] = useState({
    open: false,
    data: null,
  });
  const [disablerModalOpen, setDisablerModalOpen] = useState(false);
  // const webhookRef = useSelector((store) => store.webhook);
  const [testing, setTesting] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const webhookTrendRef = useSelector((store) => store.webhook_trend);
  const webhookErrorTrendRef = useSelector(
    (store) => store.webhook_error_trend
  );
  const range = useSelector((store) => store.activeOrg.meta.dateRange);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!formData.headerInput && (formData.headerInput.toLowerCase() !== 'content-type') && !!formData.headerValueInput && !loading) {
      setDisableAddButton(false);
    } else setDisableAddButton(true);
  }, [formData.headerInput, formData.headerValueInput, loading]);

  useEffect(() => {
    let shouldDisableSubmit = false;
    if (loading || !formData.requestType || !formData.url) {
      setDisableSubmit(true);
      return;
    }
    for (let input of Object.keys(errors)) {
      if (!!errors[input]) {
        shouldDisableSubmit = true;
        break;
      }
    }
    setDisableSubmit(shouldDisableSubmit);
  }, [errors, loading, formData]);


  //   if (formData.headers === "") {
  //     setErrors((prevState) => {
  //       return { ...prevState, headers: null };
  //     });
  //   } else {
  //     try {
  //       let latestHeaders = JSON.parse(formData.headers);
  //       if (typeof latestHeaders === "object")
  //         setErrors((prevState) => {
  //           return { ...prevState, headers: null };
  //         });
  //       else
  //         setErrors((prevState) => {
  //           return {
  //             ...prevState,
  //             headers: errorTexts.headers.incorrectFormat,
  //           };
  //         });
  //     } catch {
  //       setErrors((prevState) => {
  //         return { ...prevState, headers: errorTexts.headers.incorrectFormat };
  //       });
  //     }
  //   }
  // }, [formData.headers]);

  useEffect(() => {
    if (!!formData.requestType) {
      let option = { label: null, value: null };
      let foundOption = requestTypeOptions.find(
        (option) => option.value === formData.requestType
      );
      if (!!foundOption) option = foundOption;
      setFormData((prevState) => {
        return {
          ...prevState,
          requestTypeOption: option,
        };
      });
    }
  }, [formData.requestType]);

  useEffect(() => {

  }, [activeOrgId, dispatch]);

  useEffect(() => {
    const { run } = createRequest(services.telemetry.GET_WEBHOOK, [
      activeOrgId,
    ]);
    run()
      .then((response) => {
        const receivedData = response.data;
        if (!!receivedData) {
          // setBackendData(receivedData);
          setFormData((prevState) => {
            return { ...prevState, ...receivedData };
          });
        }
      })
      .catch((error) => {
        //code '0x5AB' means backend has no webhook data for this org
        // if (error?.response?.data?.error?.code === "0x5AB") return;
        let errorObj = new CatchedWebError(error);
        make_custom_toast("error", "Webhook Details", errorObj.message);
      })
      .finally(() => setLoading(false));
  }, [activeOrgId]);

  const ensureProtocol = (value) => {
    if (value == null) return value;
    return value.startsWith("http://") || value.startsWith("https://")
      ? value
      : `http://${value}`;
  };

  const handleUrlChange = (event) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        url: event.target.value,
      };
    });
    const eventValue = event.target.value;
    const protocolEnsuredValue = ensureProtocol(eventValue);
    if (!isUrl(protocolEnsuredValue)) {
      setErrors((prevState) => {
        return { ...prevState, url: errorTexts.url.incorrectFormat };
      });
    } else {
      setErrors((prevState) => {
        return { ...prevState, url: null };
      });
    }
  };

  const handleUrlBlur = () => {
    setShowError((prevState) => {
      return { ...prevState, url: true };
    });
    const url = ensureProtocol(formData.url);
    if (isUrl(url)) {
      setFormData((prevState) => {
        return {
          ...prevState,
          url: url,
        };
      });
      setErrors((prevState) => {
        return { ...prevState, url: null };
      });
    }
  };

  const handleFormDataChange = (key, value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const addCustomHeader = () => {
    setFormData((prevState) => {
      const newState = {
        ...prevState,
        headers: !!prevState.headers
          ? {
            ...prevState.headers,
            [prevState.headerInput.toLowerCase()]: prevState.headerValueInput,
          }
          : {
            [prevState.headerInput.toLowerCase()]: prevState.headerValueInput,
          },
      };
      newState.headerInput = "";
      newState.headerValueInput = "";
      return newState;
    });
  };

  const editData = (data) => {
    const { context, run } = createRequest(
      services.telemetry.PUT_WEBHOOK,
      [data.webhookId],
      data
    );
    setLoading(true);
    setSubmitting(true)
    run()
      .then((response) => {
        make_custom_toast("success", "Edit Webhook Details", "Details saved.");
      })
      .catch((error) => {
        const apiContext = createErrorContext(
          context,
          "Edit Webhook Details",
          TICKET_CATEGORY.NETWORK,
          error
        );
        make_custom_toast(
          "error",
          "Edit Webhook Details",
          new CatchedWebError(error).message,
          true,
          "Create Ticket",
          () => {
            navigate(
              `/organization/${activeOrgId}/support/createticket`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          }
        );
      })
      .finally(() => { setSubmitting(false); setLoading(false) });
  };

  const createData = (data) => {
    const { context, run } = createRequest(
      services.telemetry.POST_WEBHOOK,
      [],
      data
    );
    setLoading(true);
    setSubmitting(true)
    run()
      .then((response) => {
        make_custom_toast("success", "Webhook Details", "Details saved.");
      })
      .catch((error) => {
        // make_custom_toast(
        //   "error",
        //   "Webhook Details",
        //   new CatchedWebError(err).message
        // );
        const apiContext = createErrorContext(
          context,
          "Webhook Details",
          TICKET_CATEGORY.NETWORK,
          error
        );
        make_custom_toast(
          "error",
          "Webhook Details",
          new CatchedWebError(error).message,
          true,
          "Create Ticket",
          () => {
            navigate(
              `/organization/${activeOrgId}/support/createticket`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          }
        );
      })
      .finally(() => { setSubmitting(false); setLoading(false) });
  };

  // {
  //   "requestType": "put",
  //   "url": "https://webhook.com",
  //   "headers": {},
  //   "additionalProp1": {}
  // }

  const testForm = () => {
    let data = createRequestData(true);
    data.data = {
      ...PreviewJson,
      payload: {
        ...PreviewJson.payload,
        org: {
          ...PreviewJson.payload.org,
          id: activeOrgId,
          name: activeOrg.orgDisplayName ?? PreviewJson.payload.org.name
        }
      }
    };
    const { run } = createRequest(services.telemetry.TEST_WEBHOOK, [], data);
    setLoading(true);
    setTesting(true)
    run()
      .then((response) => {
        if (!!response.data.error) {
          setErrorModalData({
            open: true,
            data: JSON.stringify(response.data.error, null, 2),
          });
        } else
          make_custom_toast(
            "success",
            "Test Webhook",
            "Data successfully sent via webhook."
          );
      })
      .catch((err) => {
        make_custom_toast(
          "error",
          "Test Webhook",
          new CatchedWebError(err).message
        );
      })
      .finally(() => {
        setLoading(false);
        setTesting(false)
      });
  };

  const createRequestData = (testing) => {
    let data = { ...formData };
    delete data.headerInput;
    delete data.headerValueInput;
    let urlValue = ensureProtocol(data.url);
    if (isUrl(urlValue)) {
      data.url = urlValue;
      setFormData((prevState) => {
        return {
          ...prevState,
          url: urlValue,
        };
      });
    }
    if (data.headers == null) data.headers = {};
    delete data.requestTypeOption;
    if (testing) {
      return {
        requestType: data.requestType,
        url: data.url,
        headers: data.headers
      }
    }
    return data;
  };

  const disableForm = () => {
    const { context, run } = createRequest(
      services.telemetry.PUT_WEBHOOK,
      [formData.webhookId],
      { enabled: false }
    );
    setLoading(true);
    run()
      .then((response) => {
        setFormData((prevState) => {
          return { ...initialFormData, webhookId: prevState.webhookId };
        });
        make_custom_toast("success", "Disable Webhook", "Webhook disabled.");
      })
      .catch((error) => {
        const apiContext = createErrorContext(
          context,
          "Disable Webhook",
          TICKET_CATEGORY.NETWORK,
          error
        );
        make_custom_toast(
          "error",
          "Disable Webhook",
          new CatchedWebError(error).message,
          true,
          "Create Ticket",
          () => {
            navigate(
              `/organization/${activeOrgId}/support/createticket`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          }
        );
      })
      .finally(() => {
        setLoading(false);
        setDisablerModalOpen(false);
      });
  };

  const submitForm = () => {
    let data = createRequestData();
    data?.webhookId != null
      ? editData(data)
      : createData({ ...data, orgId: activeOrgId });
  };

  // useTimedCaller(
  //   {
  //     service: blazer.WEBHOOK,
  //     params: [
  //       activeOrgId,
  //       false,
  //       isoDate(range),
  //       isoDate(),
  //       new Date().getTimezoneOffset(),
  //     ],
  //     data: {},
  //   },
  //   range,
  //   activeOrgId,
  //   (store) => store.webhook,
  //   Blazer.webhook.actions
  // );
  useTimedCaller(
    {
      service: blazer.WEBHOOK_TREND,
      params: [
        activeOrgId,
        true,
        isoDate(range),
        isoDate(),
        new Date().getTimezoneOffset(),
        "success",
      ],
      data: {},
    },
    range,
    activeOrgId,
    (store) => store.webhook_trend,
    Blazer.webhook_trend.actions
  );
  useTimedCaller(
    {
      service: blazer.WEBHOOK_TREND,
      params: [
        activeOrgId,
        true,
        isoDate(range),
        isoDate(),
        new Date().getTimezoneOffset(),
        "failure",
      ],
      data: {},
    },
    range,
    activeOrgId,
    (store) => store.webhook_error_trend,
    Blazer.webhook_error_trend.actions
  );

  return (
    <div className="Webhooks" data-testid="Webhooks">
      <Modal
        centered
        isOpen={disablerModalOpen}
        toggle={() => {
          if (!loading) setDisablerModalOpen(false);
        }}
      >
        <ModalBody className="text-center p-1">
          <div className="text-center">
            <span className="material-symbols-outlined text-warning display-2">
              error
            </span>
          </div>
          <h3 className="font-weight-bolder mt-1 mb-2">Are you sure?</h3>
          {/* This will disable the current settings for webhook. */}
          <div className="d-flex justify-content-center mt-2">
            <Button
              color="danger"
              outline
              onClick={() => {
                if (!loading) setDisablerModalOpen(null);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading}
              className="ml-1"
              onClick={() => {
                disableForm();
              }}
            >
              {loading ? <Spinner color="white" size="sm" /> : "Yes"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={errorModalData.open}
        centered
        toggle={() => {
          setErrorModalData({ open: false, data: null });
        }}
      >
        <ModalHeader
          toggle={() => {
            setErrorModalData({ open: false, data: null });
          }}
          className="bg-white"
        >
          <div className="d-flex align-items-center"><Warning className='mr-50' />Error while testing webhook</div>
        </ModalHeader>
        <ModalBody>
          {!!errorModalData.data ? (
            <pre className="p-1" style={{ maxHeight: "50vh" }}>
              {errorModalData.data}
            </pre>
          ) : null}
        </ModalBody>
      </Modal>

      <div className="mt-1">
        <div className="d-flex justify-content-between align-items-center">
          <div className="headings heading-style-text">Statistics</div>
          <div>
            <DateRangeSelector />
          </div>
        </div>
        <div className="d-flex mb-1" id="charts-div">
          {/* <Col sm={12} md={6} lg={3} className="pr-0">
            {webhookRef.isError ? (
              <ChartError title="Webhook" value={webhookRef.isError} />
            ) : (
              <WebhookChart
                height={"160px"}
                series={
                  formatWebhookCardData(JSON.parse(webhookRef.data)).series
                }
                total={formatWebhookCardData(JSON.parse(webhookRef.data)).total}
                trend={formatWebhookCardData(JSON.parse(webhookRef.data)).trend}
                isLoading={
                  webhookRef.isLoading ||
                  (!webhookRef.isError && !webhookRef.data)
                }
                main={webhookRef.active}
                setMain={(val) =>
                  store.dispatch(Blazer.webhook.actions.setActive(val))
                }
              />
            )}
          </Col> */}
          <div sm={12} md={6} lg={4} className="chart">
            {webhookTrendRef.isError ? (
              <ChartError
                title="Webhook Success"
                value={webhookTrendRef.isError}
              />
            ) : (
              <WebhookTrendChart
                height={"125px"}
                width={"110%"}
                ticks={3}
                categories={
                  formatWebhookTrendData(JSON.parse(webhookTrendRef.data))
                    .labels
                }
                colors={["#9CDAED"]}
                series={
                  formatWebhookTrendData(JSON.parse(webhookTrendRef.data))
                    .series
                }
                isLoading={
                  webhookTrendRef.isLoading ||
                  (!webhookTrendRef.isError && !webhookTrendRef.data)
                }
              />
            )}
          </div>
          <div className="chart">
            {webhookErrorTrendRef.isError ? (
              <ChartError
                title="Webhook Errors"
                value={webhookErrorTrendRef.isError}
              />
            ) : (
              <WebhookErrorTrendChart
                height={"125px"}
                width={"110%"}
                ticks={3}
                categories={
                  formatWebhookErrorTrendData(
                    JSON.parse(webhookErrorTrendRef.data)
                  ).labels
                }
                colors={["#DC4EFF", "#9CDAED", "#FF984E"]}
                series1={
                  formatWebhookErrorTrendData(
                    JSON.parse(webhookErrorTrendRef.data)
                  ).series1
                }
                series2={
                  formatWebhookErrorTrendData(
                    JSON.parse(webhookErrorTrendRef.data)
                  ).series2
                }
                series3={
                  formatWebhookErrorTrendData(
                    JSON.parse(webhookErrorTrendRef.data)
                  ).series3
                }
                isLoading={
                  webhookErrorTrendRef.isLoading ||
                  (!webhookErrorTrendRef.isError && !webhookErrorTrendRef.data)
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className="form-div rounded border bg-white p-1 mt-1">
        <div className="d-flex justify-content-between">
          <div className="headings heading-style-text">
            Webhooks for events and alarms
          </div>
          <div
            className="border rounded help-div d-flex justify-content-center align-items-center cursor-pointer"
            onClick={() => {
              navigate(
                `/organization/${activeOrgId}/integration-docs/webhooks`
              );
            }}
          >
            <QuestionIcon className="help-icon mr-50" />
            Help
          </div>
        </div>
        <div className="d-flex align-items-center mb-50">
          <Toggle
            value={formData.enabled}
            disabled={loading}
            onClick={(e) => {
              setFormData((prevState) => {
                if (prevState.enabled && prevState.webhookId != null) {
                  setDisablerModalOpen(true);
                  return prevState;
                }
                return {
                  ...prevState,
                  enabled: !prevState.enabled,
                };
              });
            }}
            customText={{ [true]: "Enabled", [false]: "Disabled" }}
          />
        </div>
        {formData.enabled ? (
          <>
            <div className="d-flex integration-form w-100">
              <div>
                <div className="d-flex" style={{ gap: "1rem" }}>
                  <div id="url">
                    <label>URL<span className="text-danger">*</span></label>
                    <InputBox
                      className={`form-control ${showError.url && !!errors.url ? "is-invalid" : "mb-50"
                        }`}
                      placeholder="https://1.1.1.1"
                      value={formData.url}
                      type="url"
                      onChange={handleUrlChange}
                      onBlur={handleUrlBlur}
                      disabled={!formData.enabled || loading}
                    />
                    <div className="text-danger error-div">
                      {showError.url && !!errors.url ? errors.url : null}
                    </div>
                  </div>
                  <div id="request-type">
                    <label>Request Type</label>
                    <Select
                      autoComplete="off"
                      name="requestType"
                      placeholder="Select..."
                      styles={{
                        ...reactselectTheme,
                        container: (provided, state) => ({
                          ...provided,
                          borderColor: state.isSelected
                            ? "#5279CE"
                            : state.isFocused
                              ? "#5279CE1F"
                              : "#d8d6de",
                        }),
                      }}
                      className="selectBox"
                      classNamePrefix="select"
                      menuPosition="fixed"
                      value={formData.requestTypeOption}
                      options={requestTypeOptions}
                      isDisabled={!formData.enabled || loading}
                      isSearchable={false}
                      onChange={(option) => {
                        setFormData((prevState) => {
                          return {
                            ...prevState,
                            requestType: option.value,
                          };
                        });
                      }}
                    // onBlur = {() => {
                    //   setTouched({...touched, securityType: true})
                    // }}
                    />
                  </div>
                </div>

                <div className="mb-50">
                  <Label>Port Events</Label>
                  <Toggle
                    value={formData.portEvents}
                    disabled={loading}
                    onClick={(e) => {
                      setFormData((prevState) => {
                        return {
                          ...prevState,
                          portEvents: !prevState.portEvents,
                        };
                      });
                    }}
                    customText={{ [true]: "Enabled", [false]: "Disabled" }}
                  />
                </div>
                {/* <div className="mt-1">
                <label>Add Custom Headers</label>
                <textarea
                  className="form-control"
                  value={formData.headers}
                  // placeholder="Enter one custom header value per line"
                  onChange={(event) =>
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        headers: event.target.value,
                      };
                    })
                  }
                  onBlur={() =>
                    setShowError((prevState) => {
                      return { ...prevState, headers: true };
                    })
                  }
                  disabled={!formData.enabled || loading}
                />
              </div> */}
                <div className="headings heading-style-text">
                  Add Custom Headers
                </div>
                <div
                  id="custom-header-adder"
                  className="mt-1 d-flex align-items-center"
                >
                  <div className="custom-header-input-div">
                    <label>Header</label>
                    <InputBox
                      className="form-input w-100"
                      // placeholder="Content-Type"
                      value={formData.headerInput}
                      type="text"
                      onChange={(event) =>
                        handleFormDataChange("headerInput", event.target.value)
                      }
                      disabled={!formData.enabled || loading}
                    />
                  </div>
                  <div className="custom-header-input-div">
                    <label>Value</label>
                    <InputBox
                      className="form-input w-100"
                      // placeholder="application/json"
                      value={formData.headerValueInput}
                      type="text"
                      onChange={(event) =>
                        handleFormDataChange(
                          "headerValueInput",
                          event.target.value
                        )
                      }
                      disabled={!formData.enabled || loading}
                    />
                  </div>
                  <div
                    tabIndex="0"
                    className={`add-icon-div rounded ${disableAddButton
                      ? "cursor-not-allowed disable"
                      : "cursor-pointer"
                      }`}
                    onKeyUp={(event) => {
                      if (
                        !disableAddButton &&
                        (event.key === " " || event.key === "Enter")
                      )
                        addCustomHeader();
                    }}
                    onClick={() => {
                      if (!disableAddButton) addCustomHeader();
                    }}
                  >
                    <AddIcon
                      className={disableAddButton ? "disable" : "active"}
                      height={19}
                      width={19}
                    />
                  </div>
                </div>
                {/* <div className="text-danger mt-50">
                {showError.headers && !!errors.headers ?
                  errors.headers:null}
              </div> */}
                <div className="mt-1" id="header-table-div">
                  <Table
                    className="table-view fixed-header"
                    id="custom-header-table"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "42.5%" }}>Custom Header</th>
                        <th style={{ width: "42.5%" }}>Value</th>
                        <th style={{ width: "15%" }}></th>
                      </tr>
                    </thead>
                    {Object.keys(formData.headers ?? {}).length === 0 ? (
                      <tbody>
                        <tr>
                          <td colSpan={10} className="text-center p-2">
                            <div className="heading-style-text no-entry-text">No Custom Headers</div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {Object.keys(formData.headers).map((header) => {
                          return (
                            <tr className="hoverable">
                              <td className="header-table-text">{header}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="header-table-text"
                                  >
                                    {formData.headers[header]}
                                  </div>
                                </div>
                              </td>
                              <td className="text-right">
                                <div className="d-flex align-items-center justify-content-between hover-show">
                                  <Copy
                                    className="cursor-pointer"
                                    height={18}
                                    onClick={() =>
                                      handleCopy(formData.headers[header])
                                    }
                                  />
                                  <Delete
                                    className="cursor-pointer"
                                    width={20}
                                    height={20}
                                    onClick={() => {
                                      setFormData((prevState) => {
                                        const newState = { ...prevState };
                                        delete newState.headers[header];
                                        return newState;
                                      });
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </Table>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="border rounded-top p-50">Preview</div>
                <div className="preview-body border bg-light flex-grow-1 rounded-bottom p-1 overflow-auto">
                  <div>
                    {formData.url ?? ""}
                    <br />
                    content-type: application/json
                    {/* {!errors.headers && formData.headers != null && Object.keys(formData.headers).length > 0
                    ? formData.headers
                    : null} */}
                    {formData.headers != null &&
                      Object.keys(formData.headers).length > 0 ? (
                      <KeyValuePairs
                        jsonString={JSON.stringify(formData.headers)}
                      />
                    ) : null}
                    <pre>
                      {JSON.stringify(
                        {
                          ...PreviewJson,
                          payload: {
                            ...PreviewJson.payload,
                            org: {
                              ...PreviewJson.payload.org,
                              id: activeOrgId,
                              name: activeOrg.orgDisplayName ?? PreviewJson.payload.org.name
                            }
                          }
                        },
                        null,
                        2
                      )}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <Button.Ripple
                color="primary"
                outline
                className="mr-50"
                disabled={disableSubmit}
                onClick={testForm}
              >
                {testing ? <Spinner size='sm' color="secondary" /> : 'Test'}
              </Button.Ripple>
              <Button.Ripple
                color="primary"
                disabled={disableSubmit}
                type="submit"
                onClick={submitForm}
              >
                {submitting ? <Spinner size='sm' color="light" /> : 'Save'}
              </Button.Ripple >
            </div>
          </>
        ) : (
          false
        )}
      </div>    </div>
  );
};

Webhooks.propTypes = {};

Webhooks.defaultProps = {};

export default Webhooks;
