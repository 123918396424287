/***
 *
 * Controller class for user.
 * @file InfraStatusChangeModal.js
 * @description InfraStatusChangeModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useState } from "react";
// import PropTypes from 'prop-types';
import "./InfraStatusChangeModal.scss";
import { Button, Modal, ModalBody } from "reactstrap";
import createRequest, { services } from "../../services";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from "react-redux";
import { make_custom_toast } from "../../helpers/toasts";
import { CatchedWebError } from "../../configs";
import { identityActions } from "../../redux/slices";

const InfraStatusChangeModal = (props) => {
  const { 
    isOpen,
    setIsOpen,
    infraIds,
    disabled,
    orgId,
    onSuccess
  } = props;

  const [submitting, setSubmitting] = useState(false)

  const dispatch = useDispatch();

  const changeStatus = (state) => {
    if(submitting) return;
    const disableStatus = state === 'Disable';
    const UUID = uuidv4();
    const { run } = createRequest(services.infra.BULK_STATUS_CHANGE, [
        orgId,
        UUID,
        state
      ], 
      Array.from(infraIds ?? {})
    )
    setSubmitting(true)
    run()
    .then(() => {
      if(disableStatus) {
        dispatch(identityActions.setDisableUUID(UUID))
      } else {
        dispatch(identityActions.setEnableUUID(UUID))
      }
      make_custom_toast(
        'success',
        'Infrastructure Status Change',
        'Changing Infrastructure Status'
      )
      setIsOpen(false)
      onSuccess()
    })
    .catch(err => {
      make_custom_toast(
        'error',
        'Change Infrastructure Status', 
        (new CatchedWebError(err)).message
      )
    })
    .finally(() => {
      setSubmitting(false);
    })
  }

  return (
    <Modal
      isOpen={!!isOpen}
      toggle={() => setIsOpen(false)}
      centered
      className="InfraStatusChangeModal"
      data-testid="InfraStatusChangeModal"
    >
      {/* <ModalHeader
        toggle={() => setIsOpen(false)}
        className="p-0 bg-white"
      >
      </ModalHeader> */}
      <ModalBody>
        <div className="heading mb-1">
          Do you want to change the status of infrastructure?
        </div>
        This action will change the status of {infraIds?.size??''} selected infrastructure
        <div className="d-flex justify-content-end align-items-center mt-1 ButtonsDiv">
          <Button.Ripple
            color='primary'
            outline
            onClick={() => {
              changeStatus('Disable')
              setIsOpen(null);
            }}
            disabled={disabled}
          >
            Disable All
          </Button.Ripple>
          <Button.Ripple
            color='primary'
            onClick={() => {
              changeStatus('Enable')
              setIsOpen(null);
            }}
            disabled={disabled}
          >
            Enable All
          </Button.Ripple>
        </div>
      </ModalBody>
    </Modal>
  );
};

InfraStatusChangeModal.propTypes = {};

InfraStatusChangeModal.defaultProps = {};

export default InfraStatusChangeModal;
