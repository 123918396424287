/***
 *
 * Controller class for user.
 * @file AlarmDesc.js
 * @description AlarmDesc component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./AlarmDesc.scss";
import LinkWrapper from "../LinkWrapper";

const AlarmDesc = (props) => {

  const { alarmType, data, desc = "", orgId } = props;

  switch (alarmType) {
    case "pipeline.ap.down":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The AP (<LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper>) has lost connection to the cloud, possibly due to becoming unresponsive or losing its network connection.
        </div>
      );

    case "pipeline.ap.up":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The AP (<LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper>) has established a connection to the cloud
        </div>
      );

    case "pipeline.switch.down":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The Switch (<LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper>) has lost connection to the cloud, possibly due to becoming unresponsive or losing its network connection
        </div>
      );

    case "pipeline.switch.up":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The Switch (<span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper></span>) has established a connection to the cloud
        </div>
      );

    case "unit.boot-up":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The reboot operation on <span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper></span> was successful.
        </div>
      );

    case "wired.carrier-down":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          Link on Port {data?.name ?? "<name>"} on <span><LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper></span> changed from Up { (!!data.speed && !!data.duplex) ? `(${data?.speed ?? "<speed>"} Mbps/${data?.duplex ?? "<duplex>"})` : ""} to Down
        </div>
      );

    case "wired.carrier-up":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          Link on {data?.name ?? "<name>"} on <span><LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper></span> changed from Down to Up { (!!data.speed && !!data.duplex) ? `(${data?.speed ?? "<speed>"} Mbps/${data?.duplex ?? "<duplex>"})` : ""}
        </div>
      );

    case "shasta.ap_blink":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          LED blink operation initiated on <span><LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper></span> by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""}
        </div>
      );

    case "shasta.ap_reboot":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The reboot operation initiated on <span><LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper></span> by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""}
        </div>
      );

    case "shasta.factory_reset":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The factory reset operation initiated on <span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"} </LinkWrapper> </span> by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""}
        </div>
      );

    case "shasta.rtty":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A Remote TTY (RTTY) connection to the {data.infra_category_name ?? "<infra_category_name>"} <span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}> {data.infra_name ?? "<infra_name>"} </LinkWrapper> </span> initiated by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""}.
        </div>
      );

    case "shasta.wifi_scan":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The Wi-Fi Scan operation initiated by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""}
        </div>
      );

    case "shasta.configuration_fail":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          An updated configuration failed to apply to the {data.infra_category_name ?? "<infra_category_name>"} (<span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}> {data.infra_name ?? "<infra_name>"} </LinkWrapper> </span>). An automatic retry will occur. If this persists, please open a support ticket
        </div>
      );

    case "shasta.configuration_mismatch_success":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          An updated configuration that failed to apply previously was now successfully applied to the {data.infra_category_name ?? "<infra_category_name>"} (<span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}> {data.infra_name ?? "<infra_name>"} </LinkWrapper> </span>).
        </div>
      );

    case "shasta.configuration_retry_success":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          An updated configuration that failed to apply previously was now successfully applied to the {data.infra_category_name ?? "<infra_category_name>"} (<span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}> {data.infra_name ?? "<infra_name>"} </LinkWrapper> </span>).
        </div>
      );

    case "shasta.configuration_success":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A new configuration was pushed to the {data.infra_category_name ?? "<infra_category_name>"} (<span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}> {data.infra_name ?? "<infra_name>"} </LinkWrapper> </span>) after setting up the {data.operation ?? "<operation>"}
        </div>
      );

    case "shasta.venue_configuration":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A new configuration was pushed to the Venue <span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.venue_name ?? "<venue_name>"} </LinkWrapper> </span> after setting up the {data.operation ?? "<operation>"}
        </div>
      );

    case "shasta.firmware_change":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A firmware update from {data.previous_version ?? "<previous_version>"} to {data.current_version ?? "<current_version>"} has been started
        </div>
      );

    case "shasta.firmware_update_failure":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A firmware update from {data.previous_version ?? "<previous_version>"} to {data.current_version ?? "<current_version>"} has failed {data.venue_event ? "for one or more infrastructure" : ""}
        </div>
      );

    case "shasta.firmware_update_success":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A firmware update from {data.previous_version ?? "<previous_version>"} to {data.current_version ?? "<current_version>"} was successful
        </div>
      );

    case "shasta.firmware_update_offline":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A firmware update from {data.previous_version ?? "<previous_version>"} to {data.current_version ?? "<current_version>"} was unsuccessful as the infrastructure are offline
        </div>
      );

    case "shasta.ap_image_upload":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          An installation photo of the {data.infra_category_name ?? "<infra_category_name>"} (<span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}> {data.infra_name ?? "<infra_name>"} </LinkWrapper> </span>) was added by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""}
        </div>
      );

    case "shasta.initialize":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The {data.infra_category_name ?? "<infra_category_name>"} (<span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}> {data.infra_name ?? "<infra_name>"} </LinkWrapper> </span>) has connected to the cloud for the first time by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""}
        </div>
      );
    
      case "shasta.infra_disable":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The {data.infra_category_name ?? "<infra_category_name>"} (<span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}> {data.infra_name ?? "<infra_name>"} </LinkWrapper> </span>) has been {data.is_enable ? "enabled" : "disabled" } by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""}
        </div>
      );

    case "shasta.location_place":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A placement pin was added by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email}) ` : ""}for the {data.infra_category_name ?? "<infra_category_name>"} (<LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper>){!!data.floor_plan_name ? ` to ${data.floor_plan_name}` : ""}
        </div>
      );

    case "shasta.text_place":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A text based placement was added by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""} for the {data.infra_category_name ?? "<infra_category_name>"} (<span> <LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}> {data.infra_name ?? "<infra_name>"} </LinkWrapper> </span>) to room: {data.room ?? ""}, location: {data.location ?? ""}
        </div>
      );

    case "shasta.location_place_update":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A placement pin was updated by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email}) ` : ""}for the {data.infra_category_name ?? "<infra_category_name>"} (<LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper>){!!data.floor_plan_name ? ` to ${data.floor_plan_name}` : ""}
        </div>
      );

    case "shasta.text_place_update":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A text based placement was updated by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""} for the {data.infra_category_name ?? "<infra_category_name>"} (<LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper>) to room: {data.room ?? ""}, location: {data.location ?? ""}
        </div>
      );

    case "shasta.validation":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The AP (<LinkWrapper to={data.infra_id == null ? null : `/organization/${orgId}/infra/${data.infra_id}`}>{data.infra_name ?? "<infra_name>"}</LinkWrapper>) was validated by {data.user_name ?? "<user_name>"} {!!data.email ? `(${data.email})` : ""}: {data.upload_speed ?? "<upload_speed>"} Mbps upload, {data.download_speed ?? "<download_speed>"} Mbps download, {data.latency ?? "<latency>"} ms latency, {data.rssi ?? "<rssi>"} dBm rssi, {data.channel ?? "<channel>"} channel, {data.band ?? "<band>"} band
        </div>
      );

    case "alr_1":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The {data.infra_category_name ?? "<infra_category_name>"} has lost connection to the cloud, possibly due to becoming unresponsive or losing its network connection
        </div>
      );

    case "alr_2":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The CPU usage of the {data.infra_category_name ?? "<infra_category_name>"} has exceeded 75%. High CPU usage can cause performance degradation and potentially lead to service interruptions
        </div>
      );

    case "alr_3":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The memory usage of the {data.infra_category_name ?? "<infra_category_name>"} has exceeded 75%. High memory usage can cause performance degradation and potentially lead to becoming unresponsive
        </div>
      );

    case "alr_4":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The AP uplink port speed is {data.port_speed ?? "<port_speed>"} Mbps.  This can cause unexpected performance limitations
        </div>
      );

    case "alr_5":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          Due to a configuration change, there are more than 8 networks associated to this AP which is above the supported limit. Networks {data?.network_names.map((it, index) => `${it}${index == data?.network_names?.length - 1 ? "" : ","} `)} were not configured to this AP
        </div>
      );

    case "alr_6":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          One or more clients failed to connect within the past minute due to the AAA server {data.aaa_server ?? "<aaa_server>"}:{data.aaa_port ?? "<aaa_port>"} not responding
        </div>
      );

    case "alr_7":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The average response time of the AAA server {data.aaa_server ?? "<aaa_server>"}:{data.aaa_port ?? "<aaa_port>"} is above 100 ms over the past minute
        </div>
      );

    case "alr_8":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          The DNS server {data.ip_address ?? "<ip_address>"} is unreachable
        </div>
      );

    case "alr_10":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          A firmware update to {data.scheduled_version ?? "<scheduled_version>"} is scheduled
        </div>
      );

    case "alr_11":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          {data.description ?? "<description>"}
        </div>
      );

    case "venue_alr_1":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          {data?.alerts_infra_count ?? "<alerts_infra_count>"} out of {data?.total_infra_count ?? "<total_infra_count>"} infrastructure has lost connection to the cloud, possibly due to becoming unresponsive or losing its network connection
        </div>
      );

    case "venue_alr_6":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          {data?.alerts_infra_count ?? "<alerts_infra_count>"} out of {data?.total_infra_count ?? "<total_infra_count>"} infrastructure have AAA server not responding
        </div>
      );

    case "venue_alr_7":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          {data?.alerts_infra_count ?? "<alerts_infra_count>"} out of {data?.total_infra_count ?? "<total_infra_count>"} infrastructure have AAA server slow response
        </div>
      );

    case "venue_alr_8":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          {data?.alerts_infra_count ?? "<alerts_infra_count>"} out of {data?.total_infra_count ?? "<total_infra_count>"} infrastructure have DNS server unreachable
        </div>
      );

    case "venue_alr_9":
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          Unknown AP broadcasting same SSID as venue APs - with BSSID: {data.bssid ?? "<bssid>"} is reported by {data.reported_by?.map((ap, ind) => <span>AP: <LinkWrapper to={ap.infra_id == null ? null : `/organization/${orgId}/infra/${ap.infra_id}`}>{ap.infra_name ?? "<infra_name>"},</LinkWrapper> SSID: <LinkWrapper to={ap.network_id == null ? null : `/organization/${orgId}/networks/${ap.network_id}`}>{ap.ssid ?? "<ssid>"}</LinkWrapper>, Channel: {ap.channel ?? "<channel>"}, Band: {ap.band ?? "<band>"}, RSSI: {ap.rssi_avg?.toFixed(0) ?? "<rssi>"}{ap.rssi_avg ? " dBm" : ""}{ind == data.reported_by?.length - 1 ? "" : "; "}</span>)}
        </div>
      );



    default:
      return (
        <div className="AlarmDesc" data-testid="AlarmDesc">
          {desc}
        </div>
      );
  }
};

AlarmDesc.propTypes = {};

AlarmDesc.defaultProps = {};

export default AlarmDesc;
