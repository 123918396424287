import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
// import Infra from "./infraIcon.svg";
// import InfraSelected from "./infraIconSelected.svg";
import { useSelector } from "react-redux";
import { Point } from "../../../utils";
import { COLORS } from "../../../constants";
import { Html } from "react-konva-utils";
import { CatchedWebError } from "../../../../../../configs";
import { make_custom_toast } from "../../../../../../helpers/toasts";
import createRequest, { services } from "../../../../../../services";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import SingleInfraSelector from "../../../../../../components/SingleInfraSelector";
import { useState } from "react";
import { INSTALLED_DOT, ONLINE_DOT, PLACE_INFRA_ICON, PLANNED_DOT } from "../AccessPoint";
import SwitchSVG from "../../../../../Infrastructure/InfraList/SwitchSVG";

function truncate(val, n) {
    if (val.length > n) {
        return val.slice(0, n);
    }
    return val;
}

/**
 * Switch
 * @param {{
 *  data: { infra_type_id: number, x: number, y: number },
 *  isSelected: boolean,
 *  scaleFactor: number,
 *  onClick: () => void
 * }} props 
 */


const Switch = (props) => {
    const infraTypes = useSelector(store => store.infraTypes.data);
    const activeVenue = useSelector(store => store.activeVenue.data);
    const [showModal, setShowModal] = useState(false);
    const [convertingModal, setConvertingModal] = useState(false);
    const [placedSwitch, setPlacedSwitch] = useState(props.real)

    const floorplanId = props?.layout?.id || 0



    const InfraListModal = () => {
        const [selectedInfra, setSelectedInfra] = useState(null)

        const onPlace = (infra = selectedInfra) => {
            return new Promise((resolve) => {


                const convertPayload = {
                    planId: props.data.id,
                    venueId: activeVenue.venueId,
                    infraItemId: infra?.infraItemId,
                    macAddress: infra?.macAddress,
                    infraDisplayName: infra?.infraName,
                    infraTypeId: infra?.infraTypeId,
                    location: props.data.location
                }

                const { run } = createRequest(services.wre.CONVERT_PLANNED_PIN, [floorplanId], convertPayload)
                run()
                    .then(res => {
                        setShowModal(false);
                        // make_custom_toast('success', "Floorplan", "Infrastructure placed successfully")
                        props.saveFloorplan()
                    })
                    .catch(err => {
                        make_custom_toast('error', 'Floorplan', (new CatchedWebError(err)).message)
                    })
                    .finally(() => {
                        setConvertingModal(false);
                        resolve();
                    })
            })

        }

        return (
            <Modal style={{ maxWidth: '1020px', width: '100%' }} isOpen={showModal} centered toggle={() => setShowModal(false)}>
                <ModalHeader className="p-0 bg-white" toggle={() => setShowModal(false)}></ModalHeader>
                <ModalBody>
                    <SingleInfraSelector
                        venueId={activeVenue.venueId}
                        venueName={activeVenue.venueName}
                        defaultDeviceType={props.data?.infraTypeId}
                        defaultDeviceCategory={props.data?.infraCategory}
                        selectedInfra={selectedInfra}
                        setSelectedInfra={setSelectedInfra}
                        setShowModal={setShowModal}
                        showAP={false}
                        showSWITCH={true}
                        onPlace={onPlace}
                        showAddButton
                    />
                </ModalBody>
            </Modal>
        )
    }

    const RemoveInstall = () => {
        const { run } = createRequest(services.wre.REMOVE_PLACE_PIN, [floorplanId, props.data.infraItemId]);
        run()
            .then(res => {
                make_custom_toast("success", "Floorplan", "Removed placement.")
                props.saveFloorplan()
            })
            .catch(err => {
                make_custom_toast("error", "Floorplan", (new CatchedWebError(err)).message)
            })
    }

    return (
        <Group
            x={props.data.location.x * props.scaleFactor}
            y={props.data.location.y * props.scaleFactor}
            draggable={!placedSwitch && props.moveSwitch && props.uuid == props.selectedSwitch}
            onDragEnd={
                (e) => {
                    const newPosition = new Point(
                        e.target.x() / props.scaleFactor,
                        e.target.y() / props.scaleFactor
                    );
                    if (!placedSwitch)
                        props.translateSwitch(props.uuid, newPosition);
                }
            }
            onClick={props.onClick}
            onMouseEnter={(e) => {
                const container = e.target.getStage().container();
                if (props.moveSwitch)
                    container.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = "default";
            }}

        >

            <Rect
                x={0}
                y={0}
                width={15}
                height={15}
                offsetX={4}
                offsetY={0}
                strokeWidth={1}
                opacity={!props.moveSwitch ? 0.65 : 1}
                cornerRadius={10}
                stroke={props.isSelected ? COLORS.PRIMARY : placedSwitch ? COLORS.AP_INSTALLED : COLORS.AP_COLOR}
                fill={props?.data?.online ? COLORS.SWITCH_ONLINE : placedSwitch ? COLORS.AP_INSTALLED : COLORS.AP_COLOR}


                scaleX={1 / props.stageReference.current.scaleX()}
                scaleY={1 / props.stageReference.current.scaleY()}
            />
            {
                props.isSelected &&
                <Html
                    divProps={{ style: {} }}
                    transformFunc={(attrs) => {
                        const newAttrs = { ...attrs }
                        newAttrs.scaleX = 2
                        newAttrs.scaleY = 2
                        return newAttrs
                    }}

                >
                    <div


                        style={{
                            zoom: 'reset',
                            width: '140px',
                            minHeight: '30px',
                            top: '0.8rem',
                            position: 'absolute',
                            padding: '0.2em 0.5em',
                            backgroundColor: 'white',
                            border: '1px solid #d4d4d4',
                            borderRadius: '3px'
                        }}
                    >

                        <div className="" style={{ fontSize: '0.5em' }}>
                            <div style={{ marginBottom: '4px' }}>
                                <img style={{ marginRight: '4px' }} src={props?.data?.online ? ONLINE_DOT : placedSwitch ? INSTALLED_DOT : PLANNED_DOT} height="8px" width="8px" />
                                <span className="font-weight-bolder">{placedSwitch ? "Installed" : "Planned"} Position</span>
                            </div>

                            <div className="mt-50 d-flex justify-content-between">
                                <div>
                                    <div style={{ fontSize: "7px", paddingLeft: "1px" }}>Model</div>
                                    <span className="font-weight-bold">
                                        <SwitchSVG width="12px" height="10px" />
                                        <span style={{ marginLeft: '2px' }}>
                                            {truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 14)}
                                        </span>
                                    </span>
                                </div>

                                <div>
                                    <div style={{ fontSize: "6px", marginBottom: '2px' }}>Type</div>
                                    <span className="font-weight-bold" >
                                        {truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.type ?? "Unknown", 14)}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-end cursor-pointer">
                                {
                                    props.data.id && !placedSwitch &&
                                    <div className="add-infra-button" onClick={() => { setShowModal(true) }} >
                                        <img src={PLACE_INFRA_ICON} style={{ height: '8px', width: '8px', marginRight: '4px' }} />
                                        Place Infrastructure
                                    </div>
                                }
                                {
                                    props.data.id && placedSwitch &&
                                    <div className="add-infra-button" onClick={() => { RemoveInstall(); }} >Remove Installation</div>
                                }
                            </div>
                        </div>
                        <div className="ap-close-button shadow-sm">
                            <span class="material-symbols-outlined cursor-pointer" onClick={() => props?.setSelectedSwitch(null)} style={{ fontSize: '13px' }}>
                                close
                            </span>
                        </div>
                    </div>
                    <InfraListModal />
                    {
                        convertingModal &&
                        <Modal
                            centered
                            isOpen={convertingModal} >
                            <ModalBody>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span>
                                        Converting...
                                    </span>
                                    <Spinner color="primary" size={'sm'} />
                                </div>
                            </ModalBody>
                        </Modal>
                    }
                </Html>
            }
        </Group>
        // {/* <Rect height={40} width={40} x={0} y={0} strokeWidth={1} dash={[2, 2]} stroke={props.isSelected ? "white" : "#5279CE"} cornerRadius={2} fill={props.isSelected ? "#5279CE" : "white"} /> */ }
        // {/* <Image image={props.isSelected ? imgrS : imgr} height={26} width={26} offsetX={-7} offsetY={0} /> */ }
    );
};

Switch.propTypes = {};
Switch.defaultProps = {};

export default Switch;