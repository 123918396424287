import { createSlice } from "@reduxjs/toolkit";

const identityInitialstate = {
  status: {
    loading: false,
    error: null
  },
  data: {
    identityId: null,
    userName: null,
    email: null,
    phone: null,
    blocked: null,
    lastLogin: null,
    roleIds: null,
    orgId: null,
    picture: null,
    createdAt: null,
    updatedAt: null,
    organization: {
      auth0OrgId: null,
      orgDisplayName: null
    }
  },
  meta: {
    token: null,
    socketData: null,
    updateDashboardMessage: null,
    notification: null,
    importVenueNotification:null,
    importNotification:null,
    enableNotification:null,
    disableNotification:null,
    moveNotification:null,
    deleteNotification:null,
    view:1,
    enableUUID:null,
    disableUUID:null,
    moveUUID:null,
    deleteUUID:null,
    UUID:null
  }
};

const identitySlice = createSlice({
  name: "identity",
  initialState: identityInitialstate,
  reducers: {
    setLoading: (state, action) => {
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    },
    setIdentity: (state, action) => {
      state.data = action.payload;
    },
    setToken: (state, action) => {
      state.meta.token = action.payload;
    },
    setPicture: (state, action) =>{
      state.data.picture = action.payload;
    },
    setSocketData: (state, action) => {
      state.meta.socketData = action.payload
    },
    setUpdateDashboardMessage: (state, action) => {
      state.meta.updateDashboardMessage = action.payload
    },
    clearUpdateDashboardMessage: (state, action) => {
      state.meta.updateDashboardMessage = null
    },
    clearToken: (state) => {
      state.meta.token = null;
    },
    setUpgradeNotification: (state, action) => {
      state.meta.notification = action.payload
    },
    setImportVenueNotification: (state, action) => {
      state.meta.importVenueNotification = action.payload
    },
    setImportNotification: (state, action) => {
      state.meta.importNotification = action.payload
    },
    setEnableNotification: (state, action) => {
      state.meta.enableNotification = action.payload
    },
    setDisableNotification: (state, action) => {
      state.meta.disableNotification = action.payload
    },
    setMoveNotification: (state, action) => {
      state.meta.moveNotification = action.payload
    },
    setDeleteNotification: (state, action) => {
      state.meta.deleteNotification = action.payload
    },
    setView: (state, action) => {
      state.meta.view = action.payload
    },
    setUUID: (state, action) => {
      state.meta.UUID = action.payload
    },
    setEnableUUID: (state, action) => {
      state.meta.enableUUID = action.payload
    },
    setDisableUUID: (state, action) => {
      state.meta.disableUUID = action.payload
    },
    setMoveUUID: (state, action) => {
      state.meta.moveUUID = action.payload
    },
    setDeleteUUID: (state, action) => {
      state.meta.deleteUUID = action.payload
    },
  }
});

export const identityReducer = identitySlice.reducer;
const identityActions = identitySlice.actions;
export default identityActions;

